import _ from "lodash";
export function paginate(items, pageNumber, pageSize) {
  let startIndex = (pageNumber - 1) * pageSize;
  if (items && items.length < startIndex) {
    startIndex = items.length-1;
  }
  return _(items)
    .slice(startIndex)
    .take(pageSize)
    .value();
}
