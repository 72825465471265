import {
  FILES_BYTES_REQUEST,
  FILES_BYTES_SUCCESS,
  FILES_BYTES_FAILURE,
  FILES_BYTES_SUCCESS_B64_UPDATE,
  FILES_BYTES_SUCCESS_B64_REQUEST,
  FILES_BYTES_CACHED
} from "../actions/filesFromBytes";

export function filesFromBytes(
  state = {
    isFetching: false,
    isFetchingB64: false,
    response: [],
    reponseB64: [],
    downloadable: false,
    clickedFile: false
    // responseCode: "",
    // isError: ""
  },
  action
) {
  switch (action.type) {
    case FILES_BYTES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        downloadable: false,
        clickedFile: true
      });
    case FILES_BYTES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        response: action.data,
        isError: false,
        downloadable: false,
        clickedFile: false
      });
    case FILES_BYTES_CACHED:
      return Object.assign({}, state, {
        isFetching: false,
        response: action.data,
        isError: false,
        downloadable: true
      });
    case FILES_BYTES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        response: action,
        downloadable: false,
        clickedFile: false
        // responseCode: action.responseCode,
        // error: action.message
      });
    case FILES_BYTES_SUCCESS_B64_REQUEST:
      let data = [];
      if (state && state.reponseB64) {
        data = [...state.reponseB64, action.dataB64]
      }
      return Object.assign({}, state, {
        reponseB64: data,
        isError: false,
      });
    case FILES_BYTES_SUCCESS_B64_UPDATE:

      const imagesBytes = state && state.reponseB64 && state.reponseB64.filter(item => {
        if (item["email"] !== action.dataB64.email) {
          return item;
        }
        return null;
      });
      let imagesBytesData = [];
      if (imagesBytes) {
        imagesBytesData = [...imagesBytes, action.dataB64]
      }
      return {
        ...state,
        reponseB64: imagesBytesData,
        isError: false,
      };
    default:
      return state;
  }
}

export default filesFromBytes;

