import "core-js/es";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store/store";
import ScrollTop from "./components/Shared/ScrollTop/ScrollTop";
import { whiteLabels } from "./constants/whiteLabelConstants";
import Helmet from "react-helmet";
import "./styles/style.scss";
import "video.js/dist/video-js.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const whiteLabelUrl = process.env.REACT_APP_WHITELABEL_URL;
function isWhiteLabel() {
  if (
    window.location.origin &&
    window.location.origin.toUpperCase() === whiteLabelUrl.toUpperCase()
  ) {
    return true;
  } else {
    return false;
  }
}

function setApplicationContext() {
  if (isWhiteLabel()) {
    return (
      whiteLabels &&
      whiteLabels.find((item) => {
        const createdUrl = whiteLabelUrl + "/" + item.name;
        if (
          window.location.href.toUpperCase().includes(createdUrl.toUpperCase())
        ) {
          return item;
        } else {
          return null;
        }
      })
    );
  } else {
    return null;
  }
}

function setBaseName() {
  const appContext = setApplicationContext();
  if (appContext) {
    return appContext.basename;
  } else {
    return "/";
  }
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={setBaseName()}>
      {isWhiteLabel() && setBaseName() === "/serveyou" && (
        <Helmet>
          <link
            id="favicon"
            rel="shortcut icon"
            href="/serveYou_ico.ico"
            type="image/x-icon"
          />
        </Helmet>
      )}
      {isWhiteLabel() && setBaseName() === "/welldyne" && (
        <Helmet>
          <link
            id="favicon"
            rel="icon"
            href="/welldyne_ico.ico"
            type="image/x-icon"
          />
        </Helmet>
      )}
      {isWhiteLabel() && setBaseName() === "/" && (
        <Helmet>
          <link
            id="favicon"
            rel="icon"
            href="/favicon_tran.ico"
            type="image/x-icon"
          />
        </Helmet>
      )}
      <ScrollTop>
        <App
          isWhiteLabel={isWhiteLabel()}
          setApplicationContext={setApplicationContext()}
        />
      </ScrollTop>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
