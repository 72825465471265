export const WHITE_LABEL_VALUE = "WHITE_LABEL_VALUE ";
export const WHITE_LABEL_CLIENT = "WHITE_LABEL_CLIENT ";

export function checkForWhiteLabel(val) {
  return {
    type: WHITE_LABEL_VALUE,
    val
  };
}

export function whiteLabelClient(val, data) {
  return {
    type: WHITE_LABEL_CLIENT,
    val,
    data
  };
}
