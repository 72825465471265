import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
export const LANDING_CONTENT_REQUEST = "LANDING_CONTENT_REQUEST";
export const LANDING_CONTENT_SUCCESS = "LANDING_CONTENT_SUCCESS";
export const LANDING_CONTENT_FAILURE = "LANDING_CONTENT_FAILURE";

export function requestLandingContent() {
  return {
    type: LANDING_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function receiveLandingContent(data) {
  return {
    type: LANDING_CONTENT_SUCCESS,
    isFetched: true,
    data,
  };
}

export function landingContentFailure(message) {
  return {
    type: LANDING_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getLandingContent(whiteLabel) {
  return (dispatch) => {
    dispatch(requestLandingContent());
    const endPoint = whiteLabel.whiteLabelVal
      ? "/cms/getNodeByCode/t1/WLHomePage"
      : "/cms/getNodeByCode/t1/homepage";
    const url = fetchGeneralContentUrl(endPoint);
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(landingContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveLandingContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(landingContentFailure(error));
      });
  };
}
