import React from "react";
import renderHTML from "react-render-html";
const ClinicalPublicationDetail = ({ response, contentUrl }) => {
  return (
    <React.Fragment>
      <div className="marT20">
        {response &&
          response.length > 0 &&
          response.map((resourceData) => (
            <div style={{ display: "block" }} key={resourceData.link}>
              <p>
                <strong>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${contentUrl}${resourceData.link}.html`}
                    link-id={resourceData.title}
                  >
                    {resourceData.title && renderHTML(resourceData.title)}
                  </a>
                </strong>{" "}
                ({resourceData.formatedDate})
                <br />
                {resourceData.description &&
                  renderHTML(resourceData.description)}
              </p>
            </div>
          ))}
      </div>
    </React.Fragment>
  );
};

export default ClinicalPublicationDetail;
