export const PTEOBTB = [
  {
    label: "Explanation of benefits",
    value: "Explanation of benefits",
    id: "EOB",
  },
  {
    label: "Transition benefits",
    value: "Transition benefits",
    id: "TB",
  },
];
export const PTEOB = [
  {
    label: "Explanation of benefits",
    value: "Explanation of benefits",
    id: "EOB",
  },
];
export const PTTB = [
  {
    label: "Transition benefits",
    value: "Transition benefits",
    id: "TB",
  },
];

export const searchCriteriaEob = [
  {
    id: "carrierId",
    name: "Carrier ID",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "accountId",
    name: "Account ID",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "groupId",
    name: "Group ID",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "memberId",
    name: "Member ID",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "lastName",
    name: "Last Name",
    states: ["Equals"],
  },
  {
    id: "firstName",
    name: "First Name",
    states: ["Equals"],
  },
  {
    id: "memberCity",
    name: "Member City",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "memberState",
    name: "Member State",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "memberZip",
    name: "Member Zip",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "claimStartDate",
    name: "Claim date start",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "claimEndDate",
    name: "Claim date end",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "dateRange",
    name: "Date range",
    states: [
      "Equals",
      "greater than",
      "greater than and equals",
      "less than",
      "less than and equals",
    ],
  },
  {
    id: "benefitYear",
    name: "Benefit Year",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "stage",
    name: "Stage 1, 2, 3, 4, 5",
    states: ["Equals", "contains", "ends with", "begins with"],
    stageValue: ["1", "2", "3", "4", "5"],
  },
  {
    id: "format",
    name: "Format S, L, B, A",
    states: ["Equals", "contains", "ends with", "begins with"],
    formatValue: ["S", "L", "B", "A"],
  },
  {
    id: "languageCode",
    name: "Language code – ENG, SPA, CHI",
    states: ["Equals", "contains", "ends with", "begins with"],
    languageValue: ["ENG", "SPA", "CHI"],
  },
  {
    id: "fileName",
    name: "Filename",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "drugName",
    name: "Drug Name",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "rxNumber",
    name: "Rx Number",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "memberAddress",
    name: "Address",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "mailedFromDate",
    name: "Mailed from",
    states: [
      "Equals",
      "greater than",
      "greater than and equals",
      "less than",
      "less than and equals",
    ],
  },
  {
    id: "mailedToDate",
    name: "Mailed to",
    states: [
      "Equals",
      "greater than",
      "greater than and equals",
      "less than",
      "less than and equals",
    ],
  },
  {
    id: "receivedFromDate",
    name: "Received from",
    states: [
      "Equals",
      "greater than",
      "greater than and equals",
      "less than",
      "less than and equals",
    ],
  },
  {
    id: "receivedToDate",
    name: "Received to",
    states: [
      "Equals",
      "greater than",
      "greater than and equals",
      "less than",
      "less than and equals",
    ],
  },
];

export const searchCriteriaTB = [
  {
    id: "carrierId",
    name: "Carrier ID",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "accountId",
    name: "Account ID",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "groupId",
    name: "Group ID",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "memberId",
    name: "Member ID",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "lastName",
    name: "Last Name",
    states: ["Equals"],
  },
  {
    id: "firstName",
    name: "First Name",
    states: ["Equals"],
  },
  {
    id: "memberCity",
    name: "Member City",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "memberState",
    name: "Member State",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "memberAddress",
    name: "Address",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "date",
    name: "Date",
    states: [
      "Equals",
      "greater than",
      "greater than and equals",
      "less than",
      "less than and equals",
    ],
  },
  {
    id: "drugName",
    name: "Drug Name",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "languageCode",
    name: "Language code – ENG, SPA, CHI",
    states: ["Equals", "contains", "ends with", "begins with"],
    languageValue: ["ENG", "SPA", "CHI"],
  },
  {
    id: "fileType",
    name: "File type",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "crif",
    name: "CRIF",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "fileName",
    name: "Filename",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "reprintFileName",
    name: "Detail, Manual or Reprint Input data file name",
    states: ["Equals", "contains", "ends with", "begins with"],
  },
  {
    id: "mailedFromDate",
    name: "Mailed from",
    states: [
      "Equals",
      "greater than",
      "greater than and equals",
      "less than",
      "less than and equals",
    ],
  },
  {
    id: "mailedToDate",
    name: "Mailed to",
    states: [
      "Equals",
      "greater than",
      "greater than and equals",
      "less than",
      "less than and equals",
    ],
  },
  {
    id: "receivedFromDate",
    name: "Received from",
    states: [
      "Equals",
      "greater than",
      "greater than and equals",
      "less than",
      "less than and equals",
    ],
  },
  {
    id: "receivedToDate",
    name: "Received to",
    states: [
      "Equals",
      "greater than",
      "greater than and equals",
      "less than",
      "less than and equals",
    ],
  },
];

export const searchResultEob = [
  {
    id: "carrierId",
    label: "CARRIER ID",
  },
  {
    id: "accountId",
    label: "ACCOUNT ID",
  },
  {
    id: "groupId",
    label: "GROUP ID",
  },
  {
    id: "memberId",
    label: "MEMBER ID",
  },
  {
    id: "memberLastName",
    label: "LAST NAME",
  },
  {
    id: "memberFirstName",
    label: "FIRST NAME",
  },
  {
    id: "memberCity",
    label: "MEMBER CITY",
  },
  {
    id: "memberState",
    label: "MEMBER STATE",
  },
  {
    id: "memberZip",
    label: "MEMBER ZIP",
  },
  {
    id: "claimStartDate",
    label: "CLAIM START DATE",
  },
  {
    id: "claimEndDate",
    label: "CLAIM END DATE",
  },
  {
    id: "dateRange",
    label: "DATE RANGE",
  },
  {
    id: "benefitYear",
    label: "BENEFIT YEAR",
  },
  {
    id: "date",
    label: "CLAIM DATE",
  },
  {
    id: "stage",
    label: "STAGE 1, 2, 3, 4, 5",
  },
  {
    id: "format",
    label: "FORMAT S, L, B, A",
  },
  {
    id: "languageCode",
    label: "LANGUAGE CODE – ENG, SPA, CHI",
  },
  {
    id: "psFileName",
    label: "FILE NAME",
  },
  {
    id: "drugName",
    label: "DRUG NAME",
  },
  {
    id: "rxNumber",
    label: "RX NUMBER",
  },
  {
    id: "memberAddress",
    label: "ADDRESS",
  },
  {
    id: "mailedFrom",
    label: "MAILED FROM",
  },
  {
    id: "mailedTo",
    label: "MAILED TO",
  },
  {
    id: "receivedFrom",
    label: "RECEIVED FROM",
  },
  {
    id: "receivedto",
    label: "RECEIVED TO",
  },
];

export const searchResultTB = [
  {
    id: "carrierId",
    label: "CARRIER ID",
  },
  {
    id: "accountId",
    label: "ACCOUNT ID",
  },
  {
    id: "groupId",
    label: "GROUP ID",
  },
  {
    id: "memberId",
    label: "MEMBER ID",
  },
  {
    id: "memberLastName",
    label: "LAST NAME",
  },
  {
    id: "memberFirstName",
    label: "FIRST NAME",
  },
  {
    id: "memberCity",
    label: "MEMBER CITY",
  },
  {
    id: "memberState",
    label: "MEMBER STATE",
  },
  {
    id: "address",
    label: "ADDRESS",
  },
  {
    id: "date",
    label: "DATE",
  },
  {
    id: "drugName",
    label: "DRUG NAME",
  },
  {
    id: "languageCode",
    label: "LANGUAGE CODE – ENG, SPA, CHI",
  },
  {
    id: "fileType",
    label: "FILE TYPE",
  },
  {
    id: "crif",
    label: "CRIF",
  },
  {
    id: "psFileName",
    label: "FILE NAME",
  },
  {
    id: "reprintFileName",
    label: "DETAIL, MANUAL OR REPRINT INPUT DATA FILE NAME",
  },
  {
    id: "mailedFrom",
    label: "MAILED FROM",
  },
  {
    id: "mailedTo",
    label: "MAILED TO",
  },
  {
    id: "receivedFrom",
    label: "RECEIVED FROM",
  },
  {
    id: "receivedto",
    label: "RECEIVED TO",
  },
];
