import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@material-ui/core";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { FormDialogErrorStyles } from "./FormDialogErrorStyles";

function FormDialogError(props) {
  const { isOpen, handleClose, data } = props;
  const classes = FormDialogErrorStyles();

  return (
    <div>
      <Box>
        <Dialog
          open={isOpen}
          onClose={handleClose}
          maxWidth="sm"
          height="100px"
          className={classes.main}
        >
          <DialogTitle>{data.dialogErrorTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{data.dialogErrorContent}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryButton height="30px" width="12%" onClick={handleClose}>
              OK
            </PrimaryButton>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
}
export default FormDialogError;
