import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const NOACCESS_CONTENT_REQUEST = "NOACCESS_CONTENT_REQUEST";
export const NOACCESS_CONTENT_SUCCESS = "NOACCESS_CONTENT_SUCCESS";
export const NOACCESS_CONTENT_FAILURE = "NOACCESS_CONTENT_FAILURE";

function requestNoAccessContent() {
  return {
    type: NOACCESS_CONTENT_REQUEST,
    isFetched: false
  };
}

function receiveNoAccessContent(data) {
  return {
    type: NOACCESS_CONTENT_SUCCESS,
    isFetched: true,
    data
  };
}

function noAccessContentFailure(message) {
  return {
    type: NOACCESS_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message
  };
}

export function getNoAccessContent() {
  return dispatch => {
    dispatch(requestNoAccessContent());
    const url = fetchGeneralContentUrl("/cms/getNodeByCode/page/CPNoAccess");
    http
      .get(url)
      .then(response => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(noAccessContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveNoAccessContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(noAccessContentFailure(error));
      });
  };
}
