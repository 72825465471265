import { REQUEST_PRODUCT_DATA, RECEIVED_PRODUCT_DATA, SET_SELECTED_PRODUCT_DATA } from "../actions/myProduct";
function myProduct(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case REQUEST_PRODUCT_DATA:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case RECEIVED_PRODUCT_DATA:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case SET_SELECTED_PRODUCT_DATA:
      return Object.assign({}, state, {
      });
    default:
      return state;
  }
}

// We combine the reducers here so that they
// can be left split apart above

export default myProduct;
