import { fetchMessageBoxContent } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const MESSAGEBOX_CONTENT_REQUEST = "MESSAGEBOX_CONTENT_REQUEST";
export const MESSAGEBOX_CONTENT_SUCCESS = "MESSAGEBOX_CONTENT_SUCCESS";
export const MESSAGEBOX_CONTENT_FAILURE = "MESSAGEBOX_CONTENT_FAILURE";

export function requestMessageBoxContent() {
  return {
    type: MESSAGEBOX_CONTENT_REQUEST,
  };
}

export function receiveMessageBoxContent(data, pageId, pageType) {
  const messageResponse = {};
  messageResponse["pageId"] = pageId;
  messageResponse["messageContent"] = data;
  messageResponse["pageType"] = pageType;
  return {
    type: MESSAGEBOX_CONTENT_SUCCESS,
    data: messageResponse,
  };
}

export function messageBoxContentFailure(message, pageId, pageType) {
  const messageResponse = {};
  messageResponse["pageId"] = pageId;
  messageResponse["messageContent"] = [];
  messageResponse["pageType"] = pageType;
  return {
    type: MESSAGEBOX_CONTENT_FAILURE,
    message,
    data: messageResponse,
  };
}

export function getMessageBoxContent(pageId, pageType) {
  if (pageId) {
    return (dispatch) => {
      dispatch(requestMessageBoxContent());
      const url = fetchMessageBoxContent(pageId);
      http
        .get(url)
        .then((response) => {
          const data = response.data;
          if (response.status !== 200) {
            dispatch(messageBoxContentFailure(data.message, pageId, pageType));
            return Promise.reject(data);
          } else {
            dispatch(receiveMessageBoxContent(data, pageId, pageType));
            return data;
          }
        })
        .catch(function (error) {
          dispatch(messageBoxContentFailure(error, pageId, pageType));
        });
    };
  }
  return null;
}
