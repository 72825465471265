import React, { Component } from "react";
import renderHTML from "react-render-html";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import {
  getRelatedLinkMetaDataPart,
  getRelatedLinkMetaDataPro,
} from "../actions/csRelatedLinksMetaData";
import globalConstants from "../constants/global-constants";
import { getFilesFromBytes } from "../actions/filesFromBytes";
import { isInternal } from "../constants/service";

class CSRelatedLinkDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csRelatedLinkMetaData: "",
      key: "",
    };
    this.containerRef = React.createRef();
  }

  overrideLinkHandler = (e) => {
    const { dispatch } = this.props;
    if (
      e.target.tagName === "A" &&
      e.target.getAttribute("class") === "internalLink"
    ) {
      e.preventDefault();
      this.props.history.push(e.target.getAttribute("href"));
    } else if (
      e.target.tagName === "A" &&
      e.target.getAttribute("href") &&
      e.target.getAttribute("class") === "downloadLink"
    ) {
      e.preventDefault();
      const hrefData = e.target.getAttribute("href");
      const fileName = hrefData.substr(hrefData.lastIndexOf("/") + 1);
      const req = {};
      req["filePath"] = hrefData;
      dispatch(getFilesFromBytes(req, fileName));
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getRLData(nextProps);
    this.addListener();
  }

  componentDidMount() {
    this.getRLData(this.props);
    this.addListener();
  }

  addListener() {
    ReactDOM.findDOMNode(this).addEventListener(
      "click",
      this.overrideLinkHandler
    );
  }

  getRLData(props) {
    const { user } = props;
    if (isInternal(user)) {
      this.configureForInternalUser(props);
    } else {
      this.getRelatedLinkCMS(props);
    }
  }

  configureForInternalUser(props) {
    const {
      selectedMode,
      relatedkey,
      csRelatedLinksMetaData,
      type,
      subType,
    } = props;
    const data = this.configData(
      relatedkey,
      csRelatedLinksMetaData.data,
      selectedMode
    );
    if (type || subType) {
      if (csRelatedLinksMetaData && !csRelatedLinksMetaData.isFetching) {
        if (data) {
          if (data.key !== this.state.csRelatedLinkMetaData.key) {
            this.setState({
              csRelatedLinkMetaData: data,
            });
            if (data.value) {
              const response = data.value;
              if (response && response.title) {
                this.props.getTitle(response.title);
              }
            }
          }
        } else {
          selectedMode === globalConstants.PARTICIPANT
            ? this.props.dispatch(
                getRelatedLinkMetaDataPart(
                  selectedMode,
                  type,
                  subType,
                  relatedkey
                )
              )
            : this.props.dispatch(
                getRelatedLinkMetaDataPro(
                  selectedMode,
                  type,
                  subType,
                  relatedkey
                )
              );
        }
      }
    }
  }

  getRelatedLinkCMS(nextProps) {
    const {
      subType,
      type,
      csRelatedLinksMetaData,
      relatedkey,
      selectedAccount,
    } = nextProps;
    const pageType =
      selectedAccount[globalConstants.NAVIGATION_CLINICAL_SERVICE_TAB];
    if (type || subType) {
      if (csRelatedLinksMetaData && !csRelatedLinksMetaData.isFetching) {
        const data = this.configData(
          relatedkey,
          csRelatedLinksMetaData.data,
          pageType
        );
        if (data) {
          if (data.key !== this.state.csRelatedLinkMetaData.key) {
            this.setState({
              csRelatedLinkMetaData: data,
            });
            if (data.value) {
              const response = data.value;
              if (response && response.title) {
                this.props.getTitle(response.title);
              }
            }
          }
        } else {
          if (pageType === globalConstants.Participant) {
            this.props.dispatch(
              getRelatedLinkMetaDataPart(pageType, type, subType, relatedkey)
            );
          } else {
            this.props.dispatch(
              getRelatedLinkMetaDataPro(pageType, type, subType, relatedkey)
            );
          }
        }
      }
    }
  }

  configData(key, data, pageType) {
    if (data && data.length > 0) {
      return data.find((item) => {
        return item.key === key && item.pageType === pageType;
      });
    }
    return null;
  }

  render() {
    const { csRelatedLinkMetaData } = this.state;
    if (csRelatedLinkMetaData && csRelatedLinkMetaData.value) {
      const response = csRelatedLinkMetaData.value;
      const html = response.body;
      return <div ref={this.containerRef}>{renderHTML(html)}</div>;
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = ({
  csRelatedLinksMetaData,
  userAccount,
  user,
  auth,
}) => {
  return {
    selectedMode: userAccount.selectedMode,
    csRelatedLinksMetaData,
    user,
    selectedAccount: userAccount.selectedAccount,
    auth,
  };
};

export default connect(mapStateToProps)(
  withRouter(CSRelatedLinkDetailContainer)
);
