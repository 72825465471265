import { MEDICARE } from "../../utils/constants";

export function getUniqueDrugTiers(drugs) {
  const tiers = drugs.map((drug) => drug.tierDesc);
  const uniqueTiers = [...new Set(tiers)];
  return uniqueTiers.sort();
}

export function camelCase(word) {
  let camelCaseSentence="";
  for(let innerIndex=0;innerIndex<word.length;innerIndex++)
  {
  if(innerIndex===0 || word[innerIndex-1]===' ')
      camelCaseSentence+=word[innerIndex].toUpperCase();
  else
  camelCaseSentence+=word[innerIndex].toLowerCase();
  }
  return camelCaseSentence;
}

export function isPartD(formularyPlan) {
  if ([MEDICARE].includes(formularyPlan)) {
    return true;
  } 
  return false;
}