import React, { Component } from "react";
import routePath from "../../../constants/routes";
import { NavLink } from "react-router-dom";
class ClinicalPublicationComponent extends Component {
  state = {};
  render() {
    return (
      <article>
        <div className="list">
          <h6>Clinical publications</h6>
          <div className="content">
            <p dir="ltr" />
            <ul>
              <li>
                <NavLink link-id={"RxNews"} to={routePath.clinicalPublication_RxNews}>
                  RxNews<sup>®</sup>
                </NavLink>

                <br />
                <p>
                  Weekly update of drug approvals and launches and select
                  industry news
                </p>
              </li>
              <li>
                <NavLink link-id={"RxOutlook"} to={routePath.clinicalPublication_RxOutlook}>
                  RxOutlook<sup>®</sup>
                </NavLink>
                <br />
                <p>
                  Comprehensive quarterly review of brand and generic drug
                  pipelines
                </p>
              </li>
              <li>
                <NavLink link-id={"RxHighlights"} to={routePath.clinicalPublication_RxHighlight}>
                  RxHighlights
                </NavLink>
                <br />
                <p>Monthly recap of key pharmacy news</p>
              </li>
            </ul>

            <p />
          </div>
        </div>
      </article>
    );
  }
}

export default ClinicalPublicationComponent;
