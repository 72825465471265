import React from "react";
import GovernmentProgramContent from "./GovernmentProgramContent/GovernmentProgramContent";
import MessageContainer from "../../containers/MessageContainer";
import pageTypeEnum from "../../constants/pageType";
import RelatedLinksComponent from "./RelatedLinksComponent/RelatedLinksComponent";
import VideoContainer from "../../containers/VideoContainer";

const GovernmentPrograms = ({ contentData }) => {
  let pageIdGovt = "";
  if (contentData && contentData.length > 0) {
    pageIdGovt = contentData[0].nid;
  }

  if (contentData && contentData.length > 0) {
    return (
      <div className="row-">
        <div className="col-sm-9 leftBlock">
          <article>
            <GovernmentProgramContent data={contentData[0]} />
          </article>
        </div>
        <div className="col-sm-4 rightBlock">
          <RelatedLinksComponent />
          <VideoContainer
            pageType={pageTypeEnum.GOVERNMENT_PROGRAMS}
            pageId={pageIdGovt}
          />
          <MessageContainer
            pageType={pageTypeEnum.GOVERNMENT_PROGRAMS}
            pageId={pageIdGovt}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default GovernmentPrograms;
