import React, { Component } from "react";
import { connect } from "react-redux";
import { getCopyRightContent } from "../../actions/copyRight";
import Content from "../Shared/Content/Content";

class Copyright extends Component {
  state = {
    copyRight: "",
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setCopyRight(nextprops);
  }

  componentDidMount() {
    this.setCopyRight(this.props);
  }

  setCopyRight(props) {
    const { isFetching, copyRight, isError } = props;
    if (!isError) {
      if (!isFetching) {
        if (copyRight) {
          if (copyRight !== this.state.copyRight) {
            this.setState({ copyRight: copyRight });
          }
        } else {
          this.props.dispatch(getCopyRightContent());
        }
      }
    }
  }

  render() {
    const contentData = this.state.copyRight[0];
    const convertedYear =
      contentData &&
      contentData.body &&
      contentData.body.replace("[%YEAR%]", new Date().getFullYear());
    return (
      <React.Fragment>
        {contentData && contentData.body && <Content html={convertedYear} />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ copyRightContent }) => {
  return copyRightContent;
};

export default connect(mapStateToProps)(Copyright);
