import React, { Component } from "react";
import { connect } from "react-redux";
import Report from "../components/LandingPage/Report/Report";
import { getReportLinksData } from "../actions/reportLinks";
import globalConstants from "../constants/global-constants";

class ReportContainer extends Component {
  state = { title: "", isVisible: "0", reportData: [] };

  componentDidMount() {
    this.getReportsContent(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getReportsContent(newProps);
  }

  getReportsContent(newProps) {
    const { landingContent } = newProps;
    if (landingContent) {
      const data = landingContent.landingContent;
      if (data && data.length > 0) {
        this.setState({
          title: data[0].field_reports_title,
          isVisible: data[0].field_reports_visibility
        });
      }
    }
    this.getReportLinks(newProps);
  }

  getReportLinks(props) {
    const { getReportLinks, userAccount } = props;
    const selectedAccount = userAccount.selectedAccount;
    if (selectedAccount && selectedAccount["u_account.u_number"]) {
      const reportData = this.findData(
        getReportLinks.reportLinks,
        selectedAccount["u_account.u_number"]
      );
      if (reportData) {
        if (reportData !== this.state.reportData) {
          this.setState({ reportData });
        }
      } else {
        this.props.dispatch(
          getReportLinksData(selectedAccount["u_account.u_number"])
        );
      }
    }
  }

  findData(data, accId) {
    return data.find(item => {
      return item.accountId === accId;
    });
  }

  render() {
    const { title, isVisible, reportData } = this.state;
    const { userAccount } = this.props;
    const reportDatalinks = reportData && reportData.links;
    const selectedAccount = userAccount.selectedAccount;
    return (
      <>
        {isVisible === "1" &&
        reportDatalinks !== globalConstants.UNABLETOGETTEXT ? (
          <article>
            <h6>{title}</h6>
            <div className="content">
              <div className="list">
                <ul>
                  {reportDatalinks &&
                    reportDatalinks.length > 0 &&
                    reportDatalinks.map((item, index) => (
                      <Report
                        key={index}
                        linkName={item.value}
                        linkId={item.key}
                        accountNumber={
                          selectedAccount &&
                          selectedAccount["u_account.u_number"]
                        }
                      />
                    ))}
                </ul>
              </div>
            </div>
          </article>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStateToProps = ({ landingContent, userAccount, getReportLinks }) => {
  return { landingContent, userAccount, getReportLinks };
};

export default connect(mapStateToProps)(ReportContainer);
