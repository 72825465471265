import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ProtectedRoute from "../Shared/ProtectedRoutes/ProtectedRoutes";
import AccountProfileContainer from "../../containers/AccountProfileContainer";
import GovernmentProgramContainer from "../../containers/GovernmentProgramContainer";
import routePath from "../../constants/routes";
import NotFound from "../NotFound/NotFound";
import TermsOfUseContainer from "../../containers/TermsOfUseContainer";
import PrivacyPolicyContainer from "../../containers/PrivacyPolicyContainer";
import {
  getUserAccount,
  selectedAccountData,
  getUserAccountForInternalUser,
} from "../../actions/userAccount";
import { onLogoutUser } from "../../actions/logout";
import {
  getUserProfileData,
  getMSUserProfileData,
} from "../../actions/userProfile";
import NoAccessContentContainer from "../../containers/NoAccessContentContainer";
import globalConstants from "../../constants/global-constants";
import UnAuthorized from "../UnAuthorized/UnAuthorized";
import RequestContainer from "../../containers/RequestContainer";
import ClientApplicationContentContainer from "../../containers/ClientApplicationContentContainer";
import HomeDeliveryContainer from "../../containers/HomeDeliveryContainer";
import LandingContentContainer from "../../containers/LandingContentContainer";
import SpecialtyContainer from "../../containers/SpecialtyContainer";
import ClinicalServicesContainer from "../../containers/ClinicalServicesContainer";
import FileCabinetContentContainer from "../../containers/FileCabinetContentContainer";
import { setUserType } from "../../actions/user";
import userTypeEnum from "../../constants/UserType";
import { isInternal } from "../../constants/service";
import MemberIdGenerator from "../MemberIdGenerator/MemberIdGenerator";
import IdleTimer from "react-idle-timer";
import IdleTimeOutModal from "../Shared/IdlePopUp/IdlePopUp";
import RxTrackSSO from "../RxTrackSSO/RxTrackSSO";
import PrestoSSO from "../RxClaimPresto/PrestoSSO";
// import { onRxTrackSSO } from "../../actions/rxTrackSSO";
// import PaLite from "../PALite/PALite";
// import routes from "../../constants/routes";
import SingleSignOn from "../SingleSignOn/SingleSignOn";
import CircularLoader from "../Loader/Loader";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Content from "../Shared/Content/Content";
import { withStyles } from "@material-ui/core/styles";
import { clearNetworkErr, getNetworkContent } from "../../actions/networkError";
import { setSessionsStorage } from "../../actions/oidAuthentication";
import Cookies from "universal-cookie";
import FormularyDrugSearchContainer from "../../containers/FormularyDrugSearchContainer";

const cookies = new Cookies();

let countdownInterval;
let timeout;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

class HomePage extends Component {
  constructor(props) {
    super(props);
    //Idle time out
    this.state = {
      selectedAccount: {},
      isFetched: false,
      isError: false,
      isPrimaryAccountSelected: false,
      onChangeAccountID: "",
      userAccData: "",
      userProfileDetail: null,
      timeout: globalConstants.SESSIONTIME,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      rxTrackId: "",
      serviceNowSSOId: "",
      countDown: 0,
      openPopUp: false,
      networkContent: "",

      // isRxTrackSSoLoading: true,
    };
    this.idleTimer = null;
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    const userType = sessionStorage.getItem("type");
    this.setRxTrackData(this.props);
    if (userType) {
      this.checkUserType(userType);
    }
    const { networkError } = this.props;
    if (networkError && networkError.data) {
      this.setNetworkContent(this.props);
    }
  }

  checkUserType(type) {
    if (this.isInternalUser(type)) {
      this.configureMSIDUser();
    } else if (this.isExternalUser(type)) {
      this.configureOIDUser();
    } else {
      // this.loggingOut()
    }
  }

  loggingOut() {
    this.props.dispatch(onLogoutUser());
  }

  configureOIDUser() {
    // get user profile.
    this.props.dispatch(getUserProfileData());
  }

  configureMSIDUser() {
    // get user profile.
    this.props.dispatch(getMSUserProfileData());
  }

  configureInternalUser(userProfile) {
    this.props.dispatch(
      getUserAccountForInternalUser(
        userProfile.userId,
        userProfile.profileData.email,
        userProfile.profileData
      )
    );
  }

  configureExternalUser(userProfile) {
    if (userProfile && userProfile.userId) {
      this.props.dispatch(setUserType(userTypeEnum.EXTERNAL_USER, null));
      this.props.dispatch(
        getUserAccount(userProfile.opData, userProfile.profileData)
      );
    } else {
      //  this.loggingOut()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { userProfileDetail } = this.state;
    const { selectedAccountID, userProfile } = nextProps;
    const { networkError } = nextProps;
    if (networkError && networkError.data) {
      this.setNetworkContent(nextProps);
    }
    this.setNetworkError(nextProps);
    if (userProfileDetail) {
      const { onChangeAccountID } = this.state;
      if (selectedAccountID && onChangeAccountID !== selectedAccountID) {
        this.setState({ onChangeAccountID: selectedAccountID });
        this.updateStoreWithSelectedAccount(selectedAccountID);
        this.updateManagerType(nextProps, selectedAccountID);
        this.props.history.push(routePath.landing);
      } else {
        this.setSelectedAccount(nextProps);
      }
    } else if (!userProfileDetail && userProfile) {
      const userType = sessionStorage.getItem("type");
      // set data in state for user Profile.
      if (
        (this.isExternalUser(userType) && userProfile.opData) ||
        (this.isInternalUser(userType) && userProfile.profileData)
      ) {
        this.getAccountDetail(userProfile);
      }
    } else {
      if (userProfile.isProfileError) {
        this.loggingOut();
      }
    }
    this.setRxTrackData(nextProps);
  }

  setNetworkError(props) {
    const { networkError } = props;
    if (networkError && networkError.data) {
      this.setState({ openPopUp: true });
    }
  }

  setNetworkContent(props) {
    const { networkContent } = props;
    if (!networkContent.isError) {
      if (!networkContent.isFetching) {
        if (networkContent.networkContent) {
          if (
            networkContent.networkContent &&
            networkContent !== this.state.networkContent
          ) {
            this.setState({
              networkContent: networkContent.networkContent,
            });
          }
        } else {
          this.props.dispatch(getNetworkContent());
        }
      }
    }
  }

  handlePopUpClose = () => {
    this.setState({ openPopUp: false });
    this.props.dispatch(clearNetworkErr());
  };

  getRxTrackId(opData) {
    if (opData && opData.userIdentifiers) {
      return opData.userIdentifiers.filter((item) => {
        return item && item.name === "u_rxtrack_user_id" ? true : false;
      });
    }
    return null;
  }

  setRxTrackData(props) {
    const { userProfile } = props;
    const userType = sessionStorage.getItem("type");
    let rxTrackId = "";
    if (userType === "msid") {
      rxTrackId = userProfile && userProfile.userId ? userProfile.userId : "";
    } else {
      const rxTrackIdVal = this.getRxTrackId(userProfile && userProfile.opData);
      rxTrackId =
        rxTrackIdVal && rxTrackIdVal.length && rxTrackIdVal[0].value
          ? rxTrackIdVal[0].value
          : "";
    }
    if (rxTrackId) {
      this.setState({ rxTrackId });
    }
    /*   if (rxTrackId) {
        this.setState({ rxTrackId });
        if (!rxTrackSSO.isFetching) {
          if (rxTrackSSO.rxTrackSSOData) {
            this.setState({
              rxTrackData: rxTrackSSO.rxTrackSSOData,
              isRxTrackSSoLoading: false,
            });
          } else {
            if (rxTrackId) {
              this.props.dispatch(onRxTrackSSO(rxTrackId));
            }
          }
        }
      } else {
        this.setState({
          isRxTrackSSoLoading: false,
        });
      } */
  }

  getAccountDetail(userProfile) {
    this.setState({ userProfileDetail: userProfile });
    const userType = sessionStorage.getItem("type");
    if (this.isExternalUser(userType)) {
      this.configureExternalUser(userProfile);
    } else if (this.isInternalUser(userType)) {
      this.configureInternalUser(userProfile);
    }
  }

  isInternalUser(type) {
    if (type) {
      if (type === "msid") {
        return true;
      }
      return false;
    }
    return null;
  }

  isExternalUser(type) {
    if (type) {
      if (type === "oid") {
        return true;
      }
      return false;
    }
    return null;
  }

  updateManagerType(nextProps, selectedAccountID) {
    const { user } = nextProps;
    if (user.userType === userTypeEnum.MANAGER_USER) {
      this.props.dispatch(setUserType(user.userType, selectedAccountID));
    }
  }

  updateStoreWithSelectedAccount(accountID) {
    const { userAccount } = this.props;
    if (userAccount.account && userAccount.account.length > 0) {
      const selectedAccount = userAccount.account.find((item) => {
        if (item && item["u_account"] === accountID) {
          return true;
        }
        return null;
      });
      if (selectedAccount) {
        this.props.dispatch(selectedAccountData(selectedAccount));
      }
    }
  }

  setSelectedAccount(nextProps) {
    const { selectedAccount, isError, isFetched, isPrimaryAccountSelected } =
      nextProps.userAccount;

    if (selectedAccount && selectedAccount.u_account) {
      if (selectedAccount.u_account !== this.state.selectedAccount.u_account) {
        this.setState({ selectedAccount });
      }
    }
    if (isFetched && isFetched !== this.state.isFetched) {
      this.setState({ isFetched });
    }
    if (isError && isError !== this.state.isError) {
      this.setState({ isError });
    }
    if (
      isPrimaryAccountSelected &&
      isPrimaryAccountSelected !== this.state.isPrimaryAccountSelected
    ) {
      this.setState({ isPrimaryAccountSelected });
    }
  }

  isAccessOnClientPortal(account) {
    if (isInternal(this.props.user)) {
      return true;
    } else {
      if (
        account &&
        account["u_cic_access"] === "yes" &&
        account["u_contact.active"] === "true"
      ) {
        return true;
      }
      return false;
    }
  }

  isAccessOnPage(nodename) {
    if (isInternal(this.props.user)) {
      return true;
    } else {
      const { selectedAccount } = this.state;
      if (
        selectedAccount &&
        selectedAccount[nodename] &&
        selectedAccount[nodename] !== globalConstants.NO_ACCESS
      ) {
        return true;
      }
      return false;
    }
  }

  _onActive(e) {
    if (!this.state.showTimerPopUp) {
      this.setState({ isTimedOut: false });
    }
  }

  _onIdle = () => {
    const delay = 1000;
    if (!this.state.showModal) {
      timeout = setTimeout(() => {
        let countDown = globalConstants.WARNINGTIME;
        this.setState({ showModal: true, countDown });
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            --countDown;
            this.setState({ countDown: countDown });
          } else {
            sessionStorage.removeItem("access_token");
            // cookies.remove("access_token");
            sessionStorage.removeItem("pxCustomData");
            this.setState({ isTimedOut: true });
          }
        }, 1000);
      }, delay);
    }
  };

  // logging out user after getting Session time out.
  handleClose() {
    this.props.dispatch(onLogoutUser());
  }

  clearSessionTimeout = () => {
    clearTimeout(globalConstants.SESSIONTIME);
  };

  clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  handleLogout = () => {
    this.setState({ showModal: false });
    this.clearSessionInterval();
    this.clearSessionTimeout();
    this.props.dispatch(onLogoutUser());
  };

  handleContinue = () => {
    this.setState({ showModal: false });
    this.clearSessionInterval();
    this.clearSessionTimeout();
  };

  render() {
    const { auth, user, isWhiteLabel, userAccount } = this.props;
    const {
      selectedAccount,
      isFetched,
      isError,
      isPrimaryAccountSelected,
      rxTrackId,
      openPopUp,
      networkContent,
    } = this.state;
    const userType = sessionStorage.getItem("type");
    if (
      !this.isInternalUser(userType) &&
      this.props.isLoaderVisible &&
      window.location.pathname.includes("/home/sso/launch")
    ) {
      return <CircularLoader color={"#e87722"} />;
    }
    if (!user.userType) {
      return <div />;
    }
    if (!isFetched && !isPrimaryAccountSelected) {
      return <div />;
    }
    if (
      !isInternal(user) &&
      (isError || !this.isAccessOnClientPortal(selectedAccount))
    ) {
      return (
        <div>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            debounce={250}
            timeout={this.state.timeout}
          />
          <main className="divider rPad">
            <div className="container">
              <div className="row">
                <Switch>
                  <ProtectedRoute
                    path={routePath.termsOfUseAuth}
                    exact
                    component={TermsOfUseContainer}
                    isAccessOnPage={true}
                    auth={auth}
                  />
                  <ProtectedRoute
                    path={routePath.privacyPolicyAuth}
                    exact
                    component={PrivacyPolicyContainer}
                    isAccessOnPage={true}
                    auth={auth}
                  />
                  <ProtectedRoute
                    from={routePath.noaccess}
                    auth={auth}
                    isAccessOnPage={true}
                    component={NoAccessContentContainer}
                  />
                  <Redirect from={routePath.home} to={routePath.noaccess} />
                  <Route path="*" exact component={NotFound} />
                </Switch>
              </div>
            </div>
          </main>
          {networkContent && (
            <Dialog
              onClose={this.handlePopUpClose}
              aria-labelledby="customized-dialog-title"
              open={openPopUp}
              className="responsedialog"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.handlePopUpClose}
              >
                {networkContent && networkContent[0].title && (
                  <Content html={networkContent[0].title} />
                )}
              </DialogTitle>
              <DialogContent>
                {" "}
                {networkContent && networkContent[0].body && (
                  <Content html={networkContent[0].body} />
                )}
              </DialogContent>
            </Dialog>
          )}
          <IdleTimeOutModal
            showModal={this.state.showModal}
            handleClose={this.handleClose}
            handleLogout={this.handleLogout}
            handleContinue={this.handleContinue}
            countDown={this.state.countDown}
          />
        </div>
      );
    }
    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          debounce={250}
          timeout={this.state.timeout}
        />
        {window.location.pathname.includes("/resource-server/login") && (
          <Switch>
            <ProtectedRoute
              auth={auth}
              isAccessOnPage={true}
              path="*"
              render={(props) => (
                <PrestoSSO
                  {...props}
                  userAccount={userAccount}
                  selectedAccount={selectedAccount}
                  userProfile={this.props.userProfile}
                />
              )}
            />
          </Switch>
        )}

        {!window.location.pathname.includes("/resource-server/login") && (
          <main className="divider rPad">
            <div className="container">
              <div className="row">
                <Switch>
                  <ProtectedRoute
                    auth={auth}
                    isAccessOnPage={true}
                    path={routePath.rxTrackSSOLaunch}
                    render={(props) => (
                      <RxTrackSSO {...props} rxTrackId={rxTrackId} />
                    )}
                  />

                  <ProtectedRoute
                    auth={auth}
                    isAccessOnPage={true}
                    exact
                    path={routePath.ssolaunch}
                    render={(props) => <SingleSignOn {...props} />}
                  />
                  {!isWhiteLabel && (
                    <ProtectedRoute
                      path={routePath.requests}
                      exact
                      isAccessOnPage={this.isAccessOnPage(
                        globalConstants.NAVIGATION_REQUEST_TAB
                      )}
                      render={(props) => (
                        <RequestContainer {...props} auth={auth} />
                      )}
                      auth={auth}
                    />
                  )}
                  <ProtectedRoute
                    path={routePath.applications}
                    component={ClientApplicationContentContainer}
                    isAccessOnPage={this.isAccessOnPage(
                      globalConstants.NAVIGATION_APPLICATION_TAB
                    )}
                    auth={auth}
                  />
                  {!isWhiteLabel && (
                    <ProtectedRoute
                      path={routePath.clinicalServices}
                      render={(props) => (
                        <ClinicalServicesContainer {...props} auth={auth} />
                      )}
                      isAccessOnPage={this.isAccessOnPage(
                        globalConstants.NAVIGATION_CLINICAL_SERVICE_TAB
                      )}
                      auth={auth}
                    />
                  )}
                  {!isWhiteLabel && (
                    <ProtectedRoute
                      path={routePath.homedelivery}
                      component={HomeDeliveryContainer}
                      isAccessOnPage={this.isAccessOnPage(
                        globalConstants.NAVIGATION_HOME_DELIVERY_TAB
                      )}
                      auth={auth}
                    />
                  )}

                  {!isWhiteLabel && (
                    <ProtectedRoute
                      path={routePath.specialty}
                      component={SpecialtyContainer}
                      isAccessOnPage={this.isAccessOnPage(
                        globalConstants.NAVIGATION_SPECIALTY_TAB
                      )}
                      auth={auth}
                    />
                  )}
                  {!isWhiteLabel && (
                    <ProtectedRoute
                      path={routePath.govermentPrograms}
                      component={GovernmentProgramContainer}
                      isAccessOnPage={this.isAccessOnPage(
                        globalConstants.NAVIGATION_GOVERNMENT_PROGRAM_TAB
                      )}
                      auth={auth}
                    />
                  )}
                  {!isWhiteLabel && (
                    <ProtectedRoute
                      path={routePath.filecabinet}
                      component={FileCabinetContentContainer}
                      isAccessOnPage={true}
                      auth={auth}
                      userProfile={this.props.userProfile}
                    />
                  )}
                  <ProtectedRoute
                    path={routePath.termsOfUseAuth}
                    exact
                    component={TermsOfUseContainer}
                    isAccessOnPage={true}
                    auth={auth}
                  />
                  {!isWhiteLabel && (
                    <ProtectedRoute
                      path={routePath.memberIdGeneratorAuth}
                      exact
                      component={MemberIdGenerator}
                      isAccessOnPage={true}
                      auth={auth}
                    />
                  )}
                  <ProtectedRoute
                    path={routePath.privacyPolicyAuth}
                    exact
                    component={PrivacyPolicyContainer}
                    isAccessOnPage={true}
                    auth={auth}
                  />
                  <ProtectedRoute
                    path={routePath.profile}
                    exact
                    component={AccountProfileContainer}
                    isAccessOnPage={true}
                    auth={auth}
                  />

                  <ProtectedRoute
                    path={routePath.formularylookup}
                    isAccessOnPage={true}
                    auth={auth}
                    exact
                    render={(props) => (
                      <FormularyDrugSearchContainer
                        {...props}
                        user={this.props.user}
                      />
                    )}
                  />
                  <ProtectedRoute
                    auth={auth}
                    path={routePath.landing}
                    isAccessOnPage={true}
                    exact
                    component={LandingContentContainer}
                    render={(props) => (
                      <LandingContentContainer
                        {...props}
                        user={this.props.user}
                      />
                    )}
                  />
                  <ProtectedRoute
                    auth={auth}
                    path={routePath.unauthorized}
                    isAccessOnPage={true}
                    exact
                    component={UnAuthorized}
                  />
                  <Redirect
                    from={routePath.home}
                    exact
                    to={routePath.landing}
                  />
                  {!window.location.pathname.includes(
                    "/resource-server/login"
                  ) && <Route path="*" exact component={NotFound} />}
                </Switch>
              </div>
            </div>
          </main>
        )}
        {networkContent && (
          <Dialog
            onClose={this.handlePopUpClose}
            aria-labelledby="customized-dialog-title"
            open={openPopUp}
            className="responsedialog"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handlePopUpClose}
            >
              {networkContent && networkContent[0].title && (
                <Content html={networkContent[0].title} />
              )}
            </DialogTitle>
            <DialogContent>
              {" "}
              {networkContent && networkContent[0].body && (
                <Content html={networkContent[0].body} />
              )}
            </DialogContent>
          </Dialog>
        )}
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
          handleContinue={this.handleContinue}
          countDown={this.state.countDown}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  userAccount,
  user,
  userProfile,
  rxTrackSSO,
  networkError,
  networkContent,
}) => {
  return {
    userAccount,
    user,
    userProfile,
    rxTrackSSO,
    networkError,
    networkContent,
    isLoaderVisible:
      userProfile.opData && userProfile.profileData && userAccount.account
        ? false
        : true,
  };
};

export default connect(mapStateToProps)(withRouter(HomePage));
