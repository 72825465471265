import { GET_BCBS_USER_SUCCESS } from "../actions/bcbsUser";

function getBcbsUser(
  state = {
    isFetching: false,
    bcbsUser: []
  },
  action
) {
  switch (action.type) {
    case GET_BCBS_USER_SUCCESS:
      const data = state.bcbsUser;
      const index = data.findIndex(
        item => item && item.accountId === action.data.accountId
      );
      if (index !== -1) {
        data.splice(index, 1);
      }
      data.push(action.data);
      return {
        ...state,
        isFetching: false,
        bcbsUser: data
      };
    default:
      return state;
  }
}

export default getBcbsUser;
