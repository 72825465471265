import React from "react";
import ProductContainer from "../../../containers/ProductContainer";
const MyProducts = ({ productList, selectedAccount }) => {
  return (
    <React.Fragment>
      <div className="tab">
        <div className="container30">
          <div id="myProducts" className="tab-content">
            <ul className="proList" name="myProduct" id="myProductContainer">
              {productList &&
                productList.length > 0 &&
                productList.map(productData => (
                  <ProductContainer
                    key={productData.pname}
                    product={productData}
                    selectedAccount={selectedAccount}
                  />
                ))}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyProducts;
