import {
  RxTrack_SSO_REQUEST,
  RxTrack_SSO_SUCCESS,
  RxTrack_SSO_FAILURE,
} from "../actions/rxTrackSSO";

function rxTrackSSO(
  state = {
    isFetching: false,
    rxTrackSSOData: "",
  },
  action
) {
  switch (action.type) {
    case RxTrack_SSO_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        rxTrackSSOData: "",
      });
    case RxTrack_SSO_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        rxTrackSSOData: action.ssoData,
      });
    case RxTrack_SSO_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        rxTrackSSOData: action.ssoData,
        isError: true,
      });
    default:
      return state;
  }
}

export default rxTrackSSO;
