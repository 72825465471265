import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const INVOICE_CONTENT_REQUEST = "INVOICE_CONTENT_REQUEST";
export const INVOICE_CONTENT_SUCCESS = "INVOICE_CONTENT_SUCCESS";
export const INVOICE_CONTENT_FAILURE = "INVOICE_CONTENT_FAILURE";

export function requestInvoiceContent() {
  return {
    type: INVOICE_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function receiveInvoiceContent(data) {
  return {
    type: INVOICE_CONTENT_SUCCESS,
    isFetched: true,
    data,
  };
}

export function invoiceContentFailure(message) {
  return {
    type: INVOICE_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getInvoiceContent() {
  return (dispatch) => {
    dispatch(requestInvoiceContent());
    const url = fetchGeneralContentUrl(
      "/cms/getNodeByCode/page/InvoicesAndFinancialReports"
    );
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(invoiceContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveInvoiceContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(invoiceContentFailure(error));
      });
  };
}
