import { CLINICALSERVICES_SUCCESS } from "../actions/clinicalServices";

export function clinicalServices(
  state = {
    isFetching: false,
    accountId: "",
    clinicalServices: [],
  },
  action
) {
  switch (action.type) {
    case CLINICALSERVICES_SUCCESS:
      const data = state.clinicalServices;
      const index =
        data &&
        data.findIndex(
          (item) => item && item.pageType === action.data.pageType
        );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        isFetching: false,
        clinicalServices: data,
      };

    default:
      return state;
  }
}

export default clinicalServices;
