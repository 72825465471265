import { fetchDocumentRetrieval } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
import globalConstants from "../constants/global-constants";
export const GET_DOCUMENT_RETRIEVAL_SUCCESS =
  "GET_DOCUMENT_RETRIEVAL_SUCCESS";
  export const CLEAR_DOC_RETR_RESPONSE =
  "CLEAR_DOC_RETR_RESPONSE";

export function requestDocumentRetrieval(id, productType) {
  
  const GenerateDocument = {};
  GenerateDocument["id"] = id;
  GenerateDocument["productType"] = productType;
  GenerateDocument["isFetching"] = true;
  GenerateDocument["isError"] = false;
  return {
    type: GET_DOCUMENT_RETRIEVAL_SUCCESS,
    data: GenerateDocument,
  };
}

export function receiveDocumentRetrieval(res, id, productType) {
  const GenerateDocument = {};
  GenerateDocument["generatedLink"] = res;
  GenerateDocument["id"] = id;
  GenerateDocument["productType"] = productType;
  GenerateDocument["isFetching"] = false;
  GenerateDocument["isError"] = false;
  return {
    type: GET_DOCUMENT_RETRIEVAL_SUCCESS,
    data: GenerateDocument,
  };
}

export function DocumentRetrievalFailure(message, id, productType) {
  const GenerateDocument = {};
  GenerateDocument["id"] = id;
  GenerateDocument["productType"] = productType;
  GenerateDocument["generatedLink"] = message;
  GenerateDocument["isFetching"] = false;
  GenerateDocument["isError"] = true;
  return {
    type: GET_DOCUMENT_RETRIEVAL_SUCCESS,
    data: GenerateDocument,
    message,
  };
}

export function clearDocumentRetrieval() {
  return {
    type: CLEAR_DOC_RETR_RESPONSE,
  };
}
export function getDocumentRetrieval(id, productType) {
  return (dispatch) => {
    dispatch(requestDocumentRetrieval(id, productType));
    const url = fetchDocumentRetrieval(id, productType);
    http
      .get(url)
      .then((response) => {     
        const data = response.data;
        if (response.status === 200) {        
          dispatch(receiveDocumentRetrieval(data, id, productType));
          return data;
        } else {         
          dispatch(
            DocumentRetrievalFailure(
              globalConstants.UNABLETOGETTEXT,
              id,
              productType
            )
          );
          return Promise.reject(data);
        }
      })
      .catch(function (error) {
        dispatch(
            DocumentRetrievalFailure(
            globalConstants.UNABLETOGETTEXT,
            id,
            productType
          )
        );
      });
  };
}