import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const GET_RESOURCE_REQUEST = "GET_RESOURCE_REQUEST";
export const GET_RESOURCE_SUCCESS = "GET_RESOURCE_SUCCESS";
export const GET_RESOURCE_FAILURE = "GET_RESOURCE_FAILURE";
export const GET_NEWS_REQUEST = "GET_NEWS_REQUEST";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_FAILURE = "GET_NEWS_FAILURE";

export function requestResource() {
  return {
    type: GET_RESOURCE_REQUEST,
  };
}

export function requestNews() {
  return {
    type: GET_NEWS_REQUEST,
  };
}

export function receiveResource(resourceData) {
  return {
    type: GET_RESOURCE_SUCCESS,
    resourceData,
  };
}

export function receiveNews(newsData) {
  return {
    type: GET_NEWS_SUCCESS,
    newsData,
  };
}

export function resourceFailure(message) {
  return {
    type: GET_RESOURCE_FAILURE,
    message,
  };
}

export function newsFailure(message) {
  return {
    type: GET_NEWS_FAILURE,
    message,
  };
}

export function getResourceContent() {
  return (dispatch) => {
    dispatch(requestResource());
    const url = fetchGeneralContentUrl("/aem/getOptumResource");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(resourceFailure(data.message));
          return Promise.reject(data);
        } else {
          if (data && data.resources) {
            dispatch(receiveResource(data));
          }
          return data;
        }
      })
      .catch(function (error) {
        dispatch(resourceFailure(error));
      });
  };
}

export function getNewsContent() {
  return (dispatch) => {
    dispatch(requestNews());
    const url = fetchGeneralContentUrl("/aem/getOptumNews");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(newsFailure(data.message));
          return Promise.reject(data);
        } else {
          if (data && data.resources) {
            dispatch(receiveNews(data));
          }
          return data;
        }
      })
      .catch(function (error) {
        dispatch(newsFailure(error));
      });
  };
}
