import React from "react";
import { connect } from "react-redux";
import globalConstants from "../../constants/global-constants";
import { onRxClaimSSO } from "../../actions/rxClaimSSO";
import { getAccountProductQuickLauch } from "../../actions/accountProductQuickLaunch";

const whiteLabelUrl = process.env.REACT_APP_WHITELABEL_URL;
// const prestoUrl = process.env.REACT_APP_PRESTO_ACTION_URL;

class PrestoSSO extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            rxClaimSSOData: "",
            isRxClaimSSOLoading: true,
            isProdQuickLaunchLoading: true,
            productQuickLaunch: {},
            selectedAccount: {},
            authenticationError: false
        };
    }

    componentDidMount() {
        const accountID = sessionStorage.getItem("acc_id");
        const selectedAccount = this.getSelectedAccDetails(accountID);
        const { accountProductQuickLaunch } = this.props;
        if (!accountID) {
            this.setState({
                authenticationError: true
            });
        }
        if (selectedAccount) {
            this.state.selectedAccount = selectedAccount;
            this.setState({
                selectedAccount
            });
        }
        this.getAccountProductQuickLaunch(this.props);
        if (accountProductQuickLaunch && accountProductQuickLaunch.productQuickLaunch
            && accountProductQuickLaunch.productQuickLaunch.length > 0) {
            if (accountProductQuickLaunch.productQuickLaunch[0].productAndquickLaunch) {
                this.setData(this.props);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { accountProductQuickLaunch } = newProps;
        // this.getAccountProductQuickLaunch(newProps);
        if (accountProductQuickLaunch && accountProductQuickLaunch.productQuickLaunch
            && accountProductQuickLaunch.productQuickLaunch.length > 0) {
            if (accountProductQuickLaunch.productQuickLaunch[0].productAndquickLaunch) {
                this.setData(newProps);
            }
        }
    }

    componentDidUpdate() {
        document.getElementById("presto") && document.getElementById("presto").submit()
    }

    getValue(opData, key) {
        if (opData) {
            const data = opData.filter((item) => {
                if (item && item.name.toLowerCase().includes(key && key.toLowerCase())) {
                    return item.value;
                }
            });
            return (data && data.length > 0) ? data[0].value : null;
        }
        return;
    }

    setData(props) {
        const {
            rxClaimSSO,
            userProfile,
            accountProductQuickLaunch
        } = props;
        const { selectedAccount } = this.state;
        const productQuickLaunch = accountProductQuickLaunch && accountProductQuickLaunch.productQuickLaunch;
        const opData = userProfile && userProfile.opData;
        const application = sessionStorage.getItem("QLApp");
        const req = {};

        req["uuid"] = userProfile && userProfile.profileData.sub;
        req["ohid"] = opData && this.getValue(opData.userIdentifiers, "Optum ID");
        req["u_contact"] = opData && this.getValue(opData.userIdentifiers, "u_contact");
        req["u_account_u_number"] = selectedAccount && selectedAccount["u_account.u_number"];
        req["email"] = opData && opData.workEmail;
        req["u_contact_last_name"] = selectedAccount && selectedAccount["u_contact.last_name"];
        var prodData = "";
        if (productQuickLaunch && productQuickLaunch.length > 0 && productQuickLaunch[0].productAndquickLaunch) {
            prodData = productQuickLaunch[0].productAndquickLaunch && productQuickLaunch[0].productAndquickLaunch.productList.filter((item) => {
                if (application && item && (item.pname.toLowerCase().split(" ").join('') === application.toLowerCase().split(" ").join(''))) {
                    return item;
                }
            });
        }

        req["u_platform"] = prodData && prodData.length > 0 && prodData[0].pPlatform;
        req["subject"] = opData && this.getValue(opData.userIdentifiers, prodData && prodData.length > 0 && prodData[0].pPlatform);
        req["u_url"] = prodData && prodData.length > 0 && prodData[0].purl && prodData[0].purl.split('//')[1];
        req["branded"] = this.isWhiteLabel() ? false : true;
        const authError = this.checkProperties(req);
        if (!authError) {
            if (rxClaimSSO && !rxClaimSSO.isFetching) {
                if (rxClaimSSO.rxClaimSSOData) {
                    if (!this.state.rxClaimSSOData) {
                        this.setState({
                            rxClaimSSOData: rxClaimSSO.rxClaimSSOData,
                            isRxClaimSSOLoading: false,
                        });
                        this.hitEventListener(rxClaimSSO.rxClaimSSOData, props)
                    }
                } else {
                    if (!this.state.rxClaimSSOData) {
                        this.props.dispatch(onRxClaimSSO(req));
                    }
                }
            }

        }
    }
    checkProperties(obj) {
        for (var key in obj) {
            if (obj[key] === null || obj[key] === "") {
                this.setState({
                    authenticationError: true
                })
                return true;
            }
        }
        this.setState({
            authenticationError: false
        })
        return false;
    }

    hitEventListener(rxClaimSSOData, props) {
        if (rxClaimSSOData) {
            this.addListener(rxClaimSSOData, props);

            if (
                JSON.stringify(rxClaimSSOData) !== JSON.stringify(this.state.rxClaimSSOData)
            ) {
                this.setState({ rxClaimSSOData });
            }
        }
    }

    addListener(rxClaimSSOData, props) {
        if (
            rxClaimSSOData &&
            rxClaimSSOData !== globalConstants.UNABLETOGETTEXT
        ) {
            if (props) {
                this.containerRef &&
                    this.containerRef.current &&
                    this.containerRef.current.submit();
            }
        }
    }
    getSelectedAccDetails(accountID) {
        const { userAccount } = this.props;
        if (userAccount && userAccount.account && userAccount.account.length > 0) {
            const selectedAccount = userAccount.account.find(item => {
                if (item && item["u_account"] === accountID) {
                    return true;
                }
                return null;
            });
            return selectedAccount;
        }
        return null;
    }

    getAccountProductQuickLaunch(nextProps) {
        const {
            accountProductQuickLaunch,
        } = nextProps;
        const { selectedAccount } = this.state;
        if (selectedAccount) {
            if (selectedAccount.u_account) {
                if (accountProductQuickLaunch && !accountProductQuickLaunch.isFetching) {
                    if (accountProductQuickLaunch.productQuickLaunch
                        && accountProductQuickLaunch.productQuickLaunch.length > 0) {
                        this.setState({
                            productQuickLaunch: accountProductQuickLaunch.productQuickLaunch,
                        });
                        if (accountProductQuickLaunch.productQuickLaunch[0].productAndquickLaunch) {
                            this.setData(this.props);
                            this.setState({
                                isProdQuickLaunchLoading: false,
                            });
                        }
                    } else {
                        this.props.dispatch(
                            getAccountProductQuickLauch(
                                selectedAccount[globalConstants.U_ACCOUNT_NAME],
                                selectedAccount.u_account
                            )
                        );
                    }
                }
            }
        }
    }
    configQuickLaunchAndProduct(accountID, accountTeams) {
        return (
            accountTeams &&
            accountTeams.length > 0 &&
            accountTeams.find((item) => {
                return item && item.accountId === accountID;
            })
        );
    }
    isWhiteLabel() {
        return (
            (window.location.origin && whiteLabelUrl) &&
            (window.location.origin.toUpperCase() === whiteLabelUrl.toUpperCase())
        ) ?
            true : false;
    }

    getUrl() {
        let searchQ = window.location.search;
        var token = searchQ && searchQ.split('as%2F')[1].substring(0, 5);
        let prestoUrl = this.isWhiteLabel() ? (process.env.REACT_APP_WHITELABEL_PRESTO_URL) : (process.env.REACT_APP_PRESTO_ACTION_URL);
        return prestoUrl && prestoUrl.replace("GFryH", token);
    }

    render() {
        const { rxClaimSSOData, productQuickLaunch } = this.state;
        const { isRxClaimSSOLoading, userAccount } = this.props;
        const actionUrl = this.getUrl();
        const token = this.isWhiteLabel() ? "kcdNBtoken" : "kcdtoken";
        if (this.state.authenticationError) {
            return (
                <React.Fragment>
                    {
                        <p style={{ textAlign: "center", marginTop: "10px" }}>
                            <b>You are not provisioned to use this application.</b>
                        </p>

                    }
                </React.Fragment>
            );
        }
        if (this.state.isRxClaimSSOLoading || isRxClaimSSOLoading) {
            return (
                <React.Fragment>
                    {
                        <h6>
                            <p style={{ textAlign: "center", marginTop: "10px" }}>
                                Loading...
                      </p>
                        </h6>
                    }
                </React.Fragment>
            );
        } else {
            if (rxClaimSSOData === globalConstants.UNABLETOGETTEXT) {
                return (
                    <React.Fragment>
                        <h1>Service error</h1>
                        <div>
                            <span class="error">
                                <p dir="ltr">
                                    There is some error with the services. Please try after
                                    sometime.
                                </p>
                            </span>
                        </div>
                    </React.Fragment>
                );
            } else if (this.state.authenticationError) {
                return (
                    <React.Fragment>
                        {
                            <p style={{ textAlign: "center", marginTop: "10px" }}>
                                <b>You are not provisioned to use this application.</b>
                            </p>

                        }
                    </React.Fragment>
                );
            } else if (rxClaimSSOData && !this.state.authenticationError) {
                return (
                    <form
                        method="post"
                        ref={this.containerRef}
                        action={actionUrl}
                        id="presto"
                    >
                        <input
                            type="hidden"
                            // name="kcdtoken"
                            name={token || ""}
                            value={rxClaimSSOData.openToken || ""}
                            readOnly
                        />
                    </form>
                );
            }
            return null;
        }
    }
}

const mapStateToProps = ({ rxClaimSSO, accountProductQuickLaunch }) => {
    return { rxClaimSSO, accountProductQuickLaunch };
};
export default connect(mapStateToProps)(PrestoSSO);

