import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { getApplicationContent } from "../actions/applicationContent";
import ProtectedRoute from "../components/Shared/ProtectedRoutes/ProtectedRoutes";
import Application from "../components/Applications/Applications";
import MyProductContainer from "./MyProductContainer";
import ProductDetailContainer from "./ProductDetailContainer";
import ProductOfferingContentContainer from "./ProductOfferingContentContainer";
import NotFound from "../components/NotFound/NotFound";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import globalConstants from "../constants/global-constants";
import Label from "../constants/labels";
import routePath from "../constants/routes";
import { pageLinkAction } from "../actions/tealium";

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class ClientApplicationContentContainer extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const parameter = new URLSearchParams(location && location.search);
    const defaultValue =
      parameter && parameter.get("value") && parameter.get("value") === "1"
        ? 1
        : 0;

    this.state = {
      application: "",
      value: defaultValue,
      noDataError: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, value) {
    // if (event.target.innerText) {
    //   this.props.dispatch(pageLinkAction(event.target.innerText, "", ""));
    // }
    this.setState({ value });
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setApplication(nextprops);
  }

  componentDidMount() {
    document.title = "Client Portal - Applications";
    this.setApplication(this.props);
  }

  setApplication(props) {
    const { applicationContent } = props;
    const { whiteLabel } = this.props;
    if (!applicationContent.isError) {
      if (!applicationContent.isFetching) {
        if (applicationContent.applicationContent) {
          if (
            applicationContent.applicationContent &&
            applicationContent.applicationContent !== this.state.application
          ) {
            this.setState({
              application: applicationContent.applicationContent,
            });
            if (
              applicationContent &&
              applicationContent.applicationContent.length > 0 &&
              applicationContent.applicationContent[0]
            ) {
              this.setState({
                noDataError:
                  applicationContent.applicationContent[0].field_body_secondary,
              });
            }
          }
        } else {
          this.props.dispatch(getApplicationContent(whiteLabel));
        }
      }
    }
  }

  isAccessOnPage(nodename) {
    // const { selectedAccount } = this.props;
    const { userAccount } = this.props;

    if (
      userAccount.selectedAccount &&
      userAccount.selectedAccount[nodename] &&
      userAccount.selectedAccount[nodename] === globalConstants.SHOW
    ) {
      return true;
    }
    return false;
  }

  render() {
    const contentData = this.state.application[0];
    const { auth, location,userAccount } = this.props;
    const { value, noDataError } = this.state;

    var isItemVisible = "";
    if (location.pathname.match(routePath.productdetail)) {
      isItemVisible = false;
    } else {
      isItemVisible = true;
    }
    if (
      this.isAccessOnPage(globalConstants.NAVIGATION_MY_PRODUCT_TAB) &&
      isItemVisible &&
      this.isAccessOnPage(globalConstants.NAVIGATION_PRODUCT_OFFERING_TAB)
    ) {
      return (
        <React.Fragment>
          {isItemVisible && <Application contentData={contentData} />}
          <div className="tabWrapper row--">
            {isItemVisible && (
              <StyledTabs value={value} onChange={this.handleChange}>
                {this.isAccessOnPage(
                  globalConstants.NAVIGATION_MY_PRODUCT_TAB
                ) && (
                    <StyledTab label={Label.MyProduct} link-id="My products" />
                  )}
                {this.isAccessOnPage(
                  globalConstants.NAVIGATION_PRODUCT_OFFERING_TAB
                ) && (
                    <StyledTab
                      label={Label.ProductOffering}
                      link-id="Product offering"
                    />
                  )}
              </StyledTabs>
            )}
            {isItemVisible &&
              this.isAccessOnPage(globalConstants.NAVIGATION_MY_PRODUCT_TAB) &&
              value === 0 && (
                <TabContainer>
                  <MyProductContainer noDataError={noDataError} />
                </TabContainer>
              )}
            {isItemVisible &&
              this.isAccessOnPage(
                globalConstants.NAVIGATION_PRODUCT_OFFERING_TAB
              ) &&
              value === 1 && (
                <TabContainer>
                  <ProductOfferingContentContainer noDataError={noDataError} />
                </TabContainer>
              )}
            <Switch>
              {!isItemVisible && (
                <ProtectedRoute
                  exact
                  path={routePath.productdetailWithNameAndId}
                  render={(props) => <ProductDetailContainer {...props} userAccount={userAccount} />}
                  isAccessOnPage={this.isAccessOnPage(
                    globalConstants.NAVIGATION_MY_PRODUCT_TAB
                  )}
                  auth={auth}
                />
              )}
              {!isItemVisible && <Route path="*" exact component={NotFound} />}
            </Switch>
          </div>
        </React.Fragment>
      );
    } else if (
      !this.isAccessOnPage(globalConstants.NAVIGATION_MY_PRODUCT_TAB) &&
      isItemVisible &&
      this.isAccessOnPage(globalConstants.NAVIGATION_PRODUCT_OFFERING_TAB)
    ) {
      return (
        <React.Fragment>
          {isItemVisible && <Application contentData={contentData} />}
          <div className="tabWrapper row--">
            {isItemVisible && (
              <StyledTabs value={0} onChange={this.handleChange}>
                {this.isAccessOnPage(
                  globalConstants.NAVIGATION_PRODUCT_OFFERING_TAB
                ) && (
                    <StyledTab
                      label={Label.ProductOffering}
                      link-id="Product offering"
                    />
                  )}
              </StyledTabs>
            )}

            {isItemVisible &&
              this.isAccessOnPage(
                globalConstants.NAVIGATION_PRODUCT_OFFERING_TAB
              ) && (
                <TabContainer>
                  <ProductOfferingContentContainer noDataError={noDataError} />
                </TabContainer>
              )}
            <Switch>
              {!isItemVisible && (
                <ProtectedRoute
                  exact
                  path={routePath.productdetailWithNameAndId}
                  render={(props) => <ProductDetailContainer {...props} userAccount={userAccount}/>}
                  isAccessOnPage={this.isAccessOnPage(
                    globalConstants.NAVIGATION_MY_PRODUCT_TAB
                  )}
                  auth={auth}
                />
              )}
              {!isItemVisible && <Route path="*" exact component={NotFound} />}
            </Switch>
          </div>
        </React.Fragment>
      );
    } else if (
      this.isAccessOnPage(globalConstants.NAVIGATION_MY_PRODUCT_TAB) &&
      isItemVisible &&
      !this.isAccessOnPage(globalConstants.NAVIGATION_PRODUCT_OFFERING_TAB)
    ) {
      return (
        <React.Fragment>
          {isItemVisible && <Application contentData={contentData} />}
          <div className="tabWrapper row--">
            {isItemVisible && (
              <StyledTabs value={0} onChange={this.handleChange}>
                {this.isAccessOnPage(
                  globalConstants.NAVIGATION_MY_PRODUCT_TAB
                ) && (
                    <StyledTab label={Label.MyProduct} link-id="My products" />
                  )}
              </StyledTabs>
            )}
            {isItemVisible &&
              this.isAccessOnPage(
                globalConstants.NAVIGATION_MY_PRODUCT_TAB
              ) && (
                <TabContainer>
                  <MyProductContainer noDataError={noDataError} />
                </TabContainer>
              )}

            <Switch>
              {!isItemVisible && (
                <ProtectedRoute
                  exact
                  path={routePath.productdetailWithNameAndId}
                  render={(props) => <ProductDetailContainer {...props} userAccount={userAccount}/>}
                  isAccessOnPage={this.isAccessOnPage(
                    globalConstants.NAVIGATION_MY_PRODUCT_TAB
                  )}
                  auth={auth}
                />
              )}
              {!isItemVisible && <Route path="*" exact component={NotFound} />}
            </Switch>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {isItemVisible && <Application contentData={contentData} />}

          <div className="tabWrapper row--">
            <Switch>
              {!isItemVisible && (
                <ProtectedRoute
                  exact
                  path={routePath.productdetailWithNameAndId}
                  render={props => (<ProductDetailContainer {...props} userAccount={userAccount} />)}
                  isAccessOnPage={this.isAccessOnPage(
                    globalConstants.NAVIGATION_MY_PRODUCT_TAB
                  )}
                  auth={auth}
                />
              )}
              {!isItemVisible && <Route path="*" exact component={NotFound} />}
            </Switch>
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = ({
  applicationContent,
  auth,
  userAccount,
  user,
  whiteLabel,
}) => {
  return {
    applicationContent,
    auth,
    userAccount,
    // selectedAccount: userAccount.selectedAccount,
    user,
    whiteLabel,
  };
};

const StyledTab = withStyles({
  root: {
    background: "#282a2e",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "solid 1px transparent",
    color: "#ffffff",
    fontFamily: '"frutiger-light", Arial, sans-serif',
    fontSize: "14px",
    marginRight: "5px",
    opacity: "1",
    textTransform: "none",
    position: "relative",
    bottom: "-1px",
    height: "auto",
    minHeight: "auto",
    minWidth: "230px",
    maxWidth: "none",
    padding: "10px 30px 9px",

    "&:hover": {
      background: "#ffffff",
      border: "solid 1px #282a2e",
      borderBottom: "solid 1px #ffffff",
      color: "#282a2e",
    },
  },
  selected: {
    background: "#ffffff",
    border: "solid 1px #282a2e",
    borderBottom: "solid 1px #ffffff",
    color: "#282a2e",
  },
  wrapper: {
    fontSize: "14px",
  },
})(Tab);

const StyledTabs = withStyles({
  fixed: {
    borderBottom: "solid 1px #282a2e",
    overflow: "visible !important",
    padding: "14px 30px 0",
  },
  indicator: {
    height: 0,
  },
})(Tabs);

export default connect(mapStateToProps)(
  withRouter(ClientApplicationContentContainer)
);
