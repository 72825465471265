
import { useEffect, useState } from "react";
const useAdvancedFilters = (
    isPartD,
    initialTier,
    initialDrugType,
    initialSelectedChips,
    submitClickCount,
    handleAdvanceFilter
) => {
    // const initialState = {
    //     tier: [tiers[0]],
    //     drugType: cms_drug_types[0],
    //     selectedChips: [],
    //     submitClickCount: 0,
    // }

    const initialState = {
        tier: initialTier, 
        drugType: initialDrugType,
        selectedChips: initialSelectedChips,
        submitClickCount: submitClickCount,
    };
    const [advancedFilterData, setAdvancedFilterData] = useState(initialState);
    const [filterChangeCount, setFilterChangeCount] = useState(0);
    
    const {  selectedChips } = advancedFilterData;
     
    useEffect(() => {
        setFilterChangeCount((prevValues) => prevValues + 1);
        handleAdvanceFilter(advancedFilterData, filterChangeCount);
    }, [advancedFilterData]);

    useEffect(() => {
        setAdvancedFilterData(initialState);
    }, [isPartD]);

     const handleTierUpdate = (event, checked) => {
        let checkedTierArray=Array()
        for(let tier in checked)
        {
            if(checked[tier]) {
                checkedTierArray.push(tier);
            }
       }
       setAdvancedFilterData((prevValues) => ({...prevValues, tier: checkedTierArray}));
    }
    
    const handleDrugTypeUpdate = (drugTypeValue) => {
        setAdvancedFilterData((prevValues) => ({...prevValues,  drugType: drugTypeValue }));
    };

    const handleChipsArrayUpdate = (value) => {
        const updatedChips = selectedChips.includes(value)
          ? selectedChips.filter((chip) => chip !== value)
          : [...selectedChips, value];
        setAdvancedFilterData((prevValues) =>({...prevValues, selectedChips: updatedChips}));
    };

    function tierArrayToObject(tierArray, childList, parent) {
        let tierObject = {};
        tierArray.map((value) => (tierObject[value] = true));
        if (tierObject[parent])
          for (const child of childList) {
            tierObject[child] = true;
          }
        return tierObject;
      }


    return {
        advancedFilterData,
        handleTierUpdate,
        handleDrugTypeUpdate,
        handleChipsArrayUpdate,
        tierArrayToObject,
    }

}

export default useAdvancedFilters;