import React, { Component } from "react";
import { connect } from "react-redux";
import { getLandingContent } from "../actions/landingContent";
import LandingPage from "../components/LandingPage/LandingPage";
import { getBcbsUsers } from "../actions/bcbsUser";
import { getClientAccountListContent } from "../actions/clientAccountListContent";

class LandingContentContainer extends Component {
  state = {
    landingContent: "",
    clientAccountListContent: "",
    bcbsUser: false,
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setLandingContent(nextprops);
    this.setClientAccountListContent(nextprops);
    this.setBcbsUser(nextprops);
  }

  componentDidMount() {
    document.title = "Client Portal - Home page";

    this.setLandingContent(this.props);
    this.setClientAccountListContent(this.props);
    this.setBcbsUser(this.props);
  }

  setLandingContent(props) {
    const { landingContent, whiteLabel } = props;
    if (!landingContent.isError) {
      if (!landingContent.isFetching) {
        if (landingContent && landingContent.landingContent) {
          if (landingContent.landingContent !== this.state.landingContent) {
            this.setState({ landingContent: landingContent.landingContent });
          }
        } else {
          this.props.dispatch(getLandingContent(whiteLabel));
        }
      }
    }
  }
  setClientAccountListContent(props) {
    const { clientAccountListContent } = props;
    if (!clientAccountListContent.isError) {
      if (!clientAccountListContent.isFetching) {
        if (
          clientAccountListContent &&
          clientAccountListContent.clientAccountListContent
        ) {
          if (
            clientAccountListContent.clientAccountListContent !==
            this.state.clientAccountListContent
          ) {
            this.setState({
              clientAccountListContent:
                clientAccountListContent.clientAccountListContent,
            });
          }
        } else {
          this.props.dispatch(getClientAccountListContent());
        }
      }
    }
  }
  setBcbsUser(props) {
    const { getBcbsUser, userAccount } = props;
    const selectedAccount = userAccount.selectedAccount;
    if (selectedAccount && selectedAccount["u_account.u_number"]) {
      const bcbsUserIdData = this.findbcbsUserId(
        getBcbsUser.bcbsUser,
        selectedAccount["u_account.u_number"]
      );
      if (bcbsUserIdData) {
        if (bcbsUserIdData !== this.state.bcbsUser) {
          this.setState({ bcbsUser: bcbsUserIdData });
        }
      } else {
        this.props.dispatch(
          getBcbsUsers(selectedAccount["u_account.u_number"])
        );
      }
    }
  }

  findbcbsUserId(data, accId) {
    return data.find((item) => {
      return item.accountId === accId;
    });
  }

  render() {
    const { bcbsUser } = this.state;
    const { userAccount } = this.props;
    const landingContentData = this.state.landingContent[0];
    const clientAccountListContentData =
      this.state.clientAccountListContent.accountname;
    const selectedAccount = userAccount.selectedAccount;

    if (!landingContentData) {
      return null;
    }
    return (
      <section>
        <LandingPage
          data={landingContentData}
          accountList={clientAccountListContentData}
          bcbsUser={bcbsUser}
          selectedAccount={selectedAccount}
        />
      </section>
    );
  }
}

const mapStateToProps = ({
  landingContent,
  clientAccountListContent,
  getBcbsUser,
  auth,
  userAccount,
  whiteLabel,
}) => {
  return { landingContent, getBcbsUser, auth, userAccount, whiteLabel,clientAccountListContent };
};

export default connect(mapStateToProps)(LandingContentContainer);
