import { makeStyles } from "@material-ui/core";

export const ChipStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    paddingTop: "4px",
    paddingBottom: "4px",
    height: theme.typography.h3.lineHeight,
    fontWeight: theme.typography.h4.fontWeight,
    borderRadius: theme.typography.h3.lineHeight,
    paddingRight: "16px",
    maxWidth: "156px",
    fontSize: "12.64px",
    paddingLeft: "0px",
    minWidth: "150px",
  },
  icon: {
    color: theme.palette.common.white,
  },
}));
