import React, { Component } from "react";
import { connect } from "react-redux";
import { getFormularyList } from "../actions/relatedLinkFormularyList";
import FormularyList from "../components/ClinicalServices/RelatedLinksComponent/FormularyList/FormularyList";

class CSRelatedLinksFormularyAEMContainer extends Component {
  state = {
    formularyList: {}
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getFormularyListData(nextProps);
  }

  componentDidMount() {
    if (this.props) {
      this.getFormularyListData(this.props);
    }
  }

  getFormularyListData(nextProps) {
    const { formularyList } = nextProps;
    if (!formularyList.isError) {
      if (!formularyList.isFetching) {
        if (
          formularyList.formularyListContent &&
          formularyList.formularyListContent !== this.state.formularyList
        ) {
          this.setState({
            formularyList: formularyList.formularyListContent
          });
        } else {
          this.props.dispatch(getFormularyList());
        }
      }
    }
  }

  render() {
    const { formularyList } = this.state;
    if (
      formularyList &&
      formularyList.resources &&
      formularyList.resources.length > 0
    ) {
      const response = formularyList.resources;
      return <FormularyList response={response} />;
    }
    return null;
  }
}

const mapStateToProps = ({ formularyList, auth }) => {
  return { formularyList, auth };
};

export default connect(mapStateToProps)(CSRelatedLinksFormularyAEMContainer);
