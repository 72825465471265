import {
  FILES_REQUEST,
  FILES_SUCCESS,
  FILES_FAILURE,
  CLEAR_FILESTRUCUTRE
} from "../actions/getAllFiles";
function fetchAllFiles(
  state = {
    isFetching: false,
    allFiles: null,
    error: false,
    errorMessage: null
  },
  action = {}
) {
  switch (action.type) {
    case FILES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        error: false,
        errorMessage: null
      });
    case FILES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        allFiles: action.data
      });
    case FILES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message
      });
    case CLEAR_FILESTRUCUTRE:
      return {
        isFetching: false,
        allFiles: null
      };
    default:
      return state;
  }
}

export default fetchAllFiles;
