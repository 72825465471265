import React from "react";
import globalConstants from "../../../constants/global-constants";

const ErrorText = () => {
  return (
    <div>
      <p className="cux-icon-warning_filled error pad0 padT5 txtSub marB0">
        {globalConstants.SERVICEERRORMSG}
      </p>
    </div>
  );
};

export default ErrorText;
