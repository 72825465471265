import {
  GET_INVOICE_MONTHS_REQUEST,
  GET_INVOICE_MONTHS_SUCCESS,
  GET_INVOICE_MONTHS_FAILURE,
} from "../actions/getMonths";

export function getMonths(
  state = {
    isFetching: false,
    invoiceMonths: "",
  },
  action
) {
  switch (action.type) {
    case GET_INVOICE_MONTHS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        invoiceMonths: "",
      });
    case GET_INVOICE_MONTHS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        invoiceMonths: action.data,
      });
    case GET_INVOICE_MONTHS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default getMonths;
