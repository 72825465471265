import * as React from "react";
import { Box, Checkbox, FormControlLabel, FormLabel } from "@material-ui/core";
import { IndeterminateCheckboxStyle } from "./IndeterminateCheckboxStyle";
import FormControl from "@material-ui/core/FormControl";
import { useState,useEffect } from "react";

export default function IndeterminateCheckbox({
  childList,
  parent,
  handlechange,
  color,
  childLeftPadding,
  formLabel,
  mandatory,
  errorMessage,
  initialObject
}) {
  let checked={...initialObject};
  const [checkedState,setCheckedState]=useState(initialObject);
  useEffect(()=>{
    setCheckedState(initialObject);
  },[initialObject])
  
  const classes = IndeterminateCheckboxStyle({color});
  const onChange = (event, isParent) => {
    if (isParent === true) {
      let childValues = { [parent]: event.target.checked };
      childList.map((value) => {
        childValues = { ...childValues, [value]: event.target.checked };
      });
      checked={...childValues}
    } else {
      let flag = 0;
      for (let childIndex = 0; childIndex < childList.length; childIndex++) {
        if (
          childList[childIndex] != event.target.name &&
          checked[childList[childIndex]] != event.target.checked
        ) {
          flag = 1;
          break;
        }
      }
      // if all childs having the same state then the parent should also having the same state.
      if (flag === 0) {
        checked={...checked,[event.target.name]: event.target.checked,[parent]: event.target.checked,}
      }
      // if parent is checked but you are changing the child to unchecked then  need to change the parent also unchecked. 
       else if (!event.target.checked && checked[parent]) {
        checked={...checked,[event.target.name]: event.target.checked,[parent]: event.target.checked}
      }
      //or else change the state of child  
      else
      {
          checked={...checked, [event.target.name]: event.target.checked}
      }
    }
    setCheckedState(checked);
    handlechange(event,checked);
  };

  const isIndeterminate = () => {
    var indeterminateFlag = false;
    for (let childIndex = 0; childIndex < childList.length - 1; childIndex++) {
      if (
        checkedState[childList[childIndex]] != checkedState[childList[childIndex + 1]]
      ) {
        indeterminateFlag = true;
      }
    }
    return indeterminateFlag;
  };

  return (
    <div>
      <FormControl component="fieldset">
      <FormLabel color="#0000" component="legend" className={classes.label}>
        <Box sx={{displayText:"inline"}}>
        {formLabel}
        {mandatory && Object.values(checkedState).every((value)=>value===false) ? <span style={{color:"red",fontSize:"18px"}}>*</span>:""}
        </Box>
        <Box color={"red"}>{Object.values(checkedState).every((value)=>value===false)?errorMessage:''}</Box>
      </FormLabel>
      <FormControlLabel
        label={parent}
        control={
          <Checkbox
            name={parent}
            checked={checkedState[parent] ? true : false}
            onClick={(event) => onChange(event, true)}
            indeterminate={isIndeterminate()}
            className={classes.textstyle}
          />
        }
      />
      <Box paddingLeft={childLeftPadding} sx={{ display: "flex", flexDirection: "column"}}>
      {childList.map((value) => {
        return (
          <FormControlLabel
            label={value}
            control={
              <Checkbox
                className={classes.textstyle}
                name={value}
                checked={checkedState[value] ? true : false}
                onClick={onChange}
              />
            }
          />
        );
      })}
      </Box>
      </FormControl>
    </div>
  );
}
