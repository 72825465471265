import React from "react";

const HyperLink = ({ link, name, isExternal }) => {
  return (
    <a
      href={link}
      target={isExternal ? "_blank" : null}
      rel="noopener noreferrer"
      link-id={name}
    >
      {name}
    </a>
  );
};

export default HyperLink;
