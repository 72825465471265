import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
export const DRUG_SEARCH_FORMULARY_CONTENT_REQUEST = "DRUG_SEARCH_FORMULARY_CONTENT_REQUEST";
export const DRUG_SEARCH_FORMULARY_CONTENT_SUCCESS = "DRUG_SEARCH_FORMULARY_CONTENT_SUCCESS";
export const DRUG_SEARCH_FORMULARY_CONTENT_FAILURE = "DRUG_SEARCH_FORMULARY_CONTENT_FAILURE";
export const SEARCH_ERROR_ANALYTICS = "SEARCH_ERROR_ANALYTICS";
export const SEARCH_FILTER_SUCCESS_ANALYTICS = "SEARCH_FILTER_SUCCESS_ANALYTICS";
export const SEARCH_BTN_LINK_ANALYTICS = "SEARCH_BTN_LINK_ANALYTICS";
export const SEARCH_FILTER_LINK_ANALYTICS = "SEARCH_FILTER_LINK_ANALYTICS";
export const EXPORT_CSV_LINK_ANALYTICS = "EXPORT_CSV_LINK_ANALYTICS";
export const DRUG_RESULT_SELECTED_ROWS_LINK_ANALYTICS = "DRUG_RESULT_SELECTED_ROWS_LINK_ANALYTICS";
export const SEARCH_RADIO_LINK_ANALYTICS = "SEARCH_RADIO_LINK_ANALYTICS";
export const SET_DRUG_RESULTS= "SET_DRUG_RESULTS";

export function requestDrugSearchFormularyContent() {
  return {
    type: DRUG_SEARCH_FORMULARY_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function receiveDrugSearchFormularyContent(data) {
  return {
    type: DRUG_SEARCH_FORMULARY_CONTENT_SUCCESS,
    isFetched: true,
    data,
  };
}

export function drugSearchFormularyContentFailure(message) {
  return {
    type: DRUG_SEARCH_FORMULARY_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function searchErrorAnalytics(data) {
  return {
    type: SEARCH_ERROR_ANALYTICS,
    data
  }
}

export function searchFilterSuccessAnalytics(data) {
  return {
    type: SEARCH_FILTER_SUCCESS_ANALYTICS,
    data
  }
}


export function searchBtnLinkAnalytics() {
  return {
    type: SEARCH_BTN_LINK_ANALYTICS
  }
}

export function searchFilterLinkAnalytics() {
   return {
    type: SEARCH_FILTER_LINK_ANALYTICS
   }
}

export function exportCSVLinkAnalytics(){
  return {
    type: EXPORT_CSV_LINK_ANALYTICS
  }
}

export function drugResultRowCheckLinkAnalytics(data) {
  return {
    type: DRUG_RESULT_SELECTED_ROWS_LINK_ANALYTICS,
    data
  }
}

export function searchRadioLinkAnalytics(data) {
  return {
    type: SEARCH_RADIO_LINK_ANALYTICS,
    data,
  }
}

export function setDrugResults(data) {
  return {
    type: SET_DRUG_RESULTS,
    data,
  }
}

function formularyIdName(data)
{
    if(data && data.displayFormularyName==="1")
    {
      for(let key in data) 
      if(['commercial','medicare','medicaid'].includes(key)) 
      {
        data[key].map((obj) =>(obj.formularyIdName=obj.formularyId+" - "+obj.formularyName));
      }
    }
    return data;
}

export function getDrugsSearchFormularyFieldsData(props) {
  return (dispatch) => {
    dispatch(requestDrugSearchFormularyContent());
    if(props && props['u_account.name'])
    {
    const url = fetchGeneralContentUrl(
      "/cms/getTextObjectFromByCode/"+props['u_account.name'].replace(/\s+/g,"")
    );
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(drugSearchFormularyContentFailure(data.message));
          return Promise.reject(data);
        } else {
          let dataWithFormularyName= formularyIdName(data);
          dispatch(receiveDrugSearchFormularyContent(dataWithFormularyName));
          return dataWithFormularyName;
        }
      })
      .catch(function (error) {
        dispatch(drugSearchFormularyContentFailure(error));
      });
    }
  };
}
