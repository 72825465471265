import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
export const ACCOUNT_PROFILE_CONTENT_REQUEST =
  "ACCOUNT_PROFILE_CONTENT_REQUEST";
export const ACCOUNT_PROFILE_CONTENT_SUCCESS =
  "ACCOUNT_PROFILE_CONTENT_SUCCESS";
export const ACCOUNT_PROFILE_CONTENT_FAILURE =
  "ACCOUNT_PROFILE_CONTENT_FAILURE";

export function requestAccountProfileContent() {
  return {
    type: ACCOUNT_PROFILE_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function receiveAccountProfileContent(data) {
  return {
    type: ACCOUNT_PROFILE_CONTENT_SUCCESS,
    isFetched: true,
    data,
  };
}

export function accountProfileContentFailure(message) {
  return {
    type: ACCOUNT_PROFILE_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getAccountProfileContent() {
  return (dispatch) => {
    dispatch(requestAccountProfileContent());
    const url = fetchGeneralContentUrl(
      "/cms/getNodeByCode/page/AccountProfile"
    );
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(accountProfileContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveAccountProfileContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(accountProfileContentFailure(error));
      });
  };
}
