import { fetchProductOfferingUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const PRODUCTOFFERING_SUCCESS = "PRODUCTOFFERING_SUCCESS";

export function requestProductOffering(accountId) {
  const productOfferingResponse = {};
  productOfferingResponse["accountId"] = accountId;
  productOfferingResponse["isFetching"] = true;
  productOfferingResponse["isError"] = false;
  return {
    type: PRODUCTOFFERING_SUCCESS,
    accountId: accountId,
    data: productOfferingResponse,
  };
}

export function receiveProductOffering(data, accountId) {
  const productOfferingResponse = {};
  productOfferingResponse["accountId"] = accountId;
  productOfferingResponse["productOffering"] = data;
  productOfferingResponse["isFetching"] = false;
  productOfferingResponse["isError"] = false;
  return {
    type: PRODUCTOFFERING_SUCCESS,
    data: productOfferingResponse,
  };
}

export function productOfferingFailure(message, accountId) {
  const productOfferingResponse = {};
  productOfferingResponse["accountId"] = accountId;
  productOfferingResponse["productOffering"] = [];
  productOfferingResponse["isFetching"] = false;
  productOfferingResponse["isError"] = true;
  return {
    type: PRODUCTOFFERING_SUCCESS,
    accountId: accountId,
    data: productOfferingResponse,
    message,
  };
}

export function getProductOfferingData(accountname, accountId) {
  if (accountname && accountId) {
    return (dispatch) => {
      dispatch(requestProductOffering(accountId));
      const url = fetchProductOfferingUrl(accountId);
      http
        .get(url)
        .then((response) => {
          const data = response.data;
          if (response.status === 200) {
            dispatch(receiveProductOffering(data, accountId));
            return data;
          } else {
            dispatch(productOfferingFailure(data.message, accountId));
            return Promise.reject(data);
          }
        })
        .catch(function (error) {
          dispatch(productOfferingFailure(error, accountId));
        });
    };
  }
  return null;
}
