import React, { Component } from "react";
import FileTree from "../components/Shared/FileTree/FileTree";
import { connect } from "react-redux";
import { getFileTreeContent } from "../actions/fileTree";

const TYPE = "product";

class ProductDetailContainer extends Component {
  title = "";
  state = {};

  UNSAFE_componentWillMount() {
    const { location, match } = this.props;
    let title = `${match.params.productname} - `;
    if (location.search.indexOf("TrainingDetail") !== -1) {
      title += "Training Documents";
    } else {
      title += "Product details";
    }
    this.title = title;
  }

  render() {
    const fetchDatafromContactId = this.props.userAccount && this.props.userAccount.fetchDatafromContactId;
    return (
      <FileTree
        title={this.title}
        fileTreeContent={this.props.fileTreeContent}
        isFetching={this.props.isFetching}
        isFetched={this.props.isFetched}
        error={this.props.error && this.props.errorMessage}
        fetchDatafromContactId={fetchDatafromContactId}
        getFileTreeContent={getFileTreeContent}
        byId={this.props.location.search.indexOf("byId") !== -1}
        productFolderId={this.props.match.params.productDocID}
        type={TYPE}
      />
    );
  }
}

const mapStateToProps = ({ fileTree }) => {
  return { fileTree };
};

export default connect(mapStateToProps)(ProductDetailContainer);
