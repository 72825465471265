import {
  DOCUMENT_URL_REQUEST,
  DOCUMENT_URL_SUCCESS,
  DOCUMENT_URL_FAILURE,
  CLEAR_RESPONSE,
} from "../actions/getDocumentUrl";

export function fetchDocumentUrl(
  state = {
    isFetching: false,
    documentResponse: [],
    fileUrl: "",
  },
  action
) {
  switch (action.type) {
    //   case DOCUMENT_URL_REQUEST:
    //     return Object.assign({}, state, {
    //       isFetching: true,
    //       documentName: action.documentName,
    //       responseCode: null
    //     });
    case DOCUMENT_URL_SUCCESS:
      const data = state.documentResponse;
      const index =
        data &&
        data.findIndex(
          (item) =>
            item && item.documentName === action.documentResponse.documentName
        );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.documentResponse);
      return {
        ...state,
        isFetching: false,
        fileUrl: action.fileUrl,
        documentResponse: data,
      };
    //   case DOCUMENT_URL_FAILURE:
    //     return Object.assign({}, state, {
    //       isFetching: false,
    //       isError: true,
    //       documentName: action.documentName,
    //       responseCode: action.responseCode
    //     });
    case CLEAR_RESPONSE:
      const responseData = state.documentResponse;
      const responseIndex =
        responseData &&
        responseData.findIndex(
          (item) =>
            item &&
            item.displayName === action.reportName &&
            item.documentName === action.documentName
        );
      if (responseIndex !== -1) {
        responseData && responseData.splice(responseIndex, 1);
      }
      // data.push(action.documentResponse);
      return {
        ...state,
        isFetching: false,
        documentResponse: responseData,
      };
    default:
      return state;
  }
}

export default fetchDocumentUrl;
