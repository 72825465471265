import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import routePath from "../../../constants/routes";

const ProtectedRoute = ({
  path,
  component: Component,
  render,
  isAccessOnPage,
  auth,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!auth || !auth.isAuthenticated) {
          return <Redirect to={routePath.login} />;
        } else if (!isAccessOnPage) {
          return <Redirect to={routePath.unauthorized} />;
        }
        return Component ? <Component {...props} auth={auth} /> : render(props);
      }}
    />
  );
};

const mapStateToProps = ({ userAccount }) => {
  return {
    selectedAccount: userAccount && userAccount.selectedAccount
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
