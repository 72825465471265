import React, { Component } from "react";
import Checkbox from "../../../Shared/CheckBox/Checkbox";
import globalConstants from "../../../../constants/global-constants";
import moment from "moment";

class SideFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedItems: new Map()
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked)
    }));
  }

  onFilterSubmit() {
    const { checkedItems } = this.state;
    const { filterFromBillingEnity } = this.props;
    const selectedEntity = [];
    for (const entry of checkedItems.entries()) {
      if (entry[1]) {
        selectedEntity.push(entry[0]);
      }
    }
    filterFromBillingEnity(selectedEntity);
  }

  render() {
    const { sideFilterValue, entityItem, currentDate, restrictDate } = this.props;
    const { checkedItems } = this.state;
    var inputFromDate;
    var inputToDate;
    if (
      sideFilterValue &&
      sideFilterValue.fromDate === "Invalid date" &&
      sideFilterValue.toDate === "Invalid date"
    ) {
      inputFromDate = "";
      inputToDate = "";
    } else if (
      sideFilterValue.invoicetext &&
      sideFilterValue.fromDate === "" &&
      sideFilterValue.toDate === ""
    ) {
      inputFromDate = restrictDate.format(
        globalConstants.FORMATDATEWITHSLASH
      );
      inputToDate = currentDate.format(
        globalConstants.FORMATDATEWITHSLASH
      );

    } else {
      inputFromDate = sideFilterValue.fromDate;
      inputToDate = sideFilterValue.toDate;
    }
    return (
      <div className="col-sm-3">
        <b>Search criteria:</b>
        <br />
        <div className="padL5 marB10 padT5">
          Client:{" "}
          {sideFilterValue && sideFilterValue.selectedValue
            ? sideFilterValue.selectedValue
            : ""}
          <br />
          Invoice #:{" "}
          {sideFilterValue && sideFilterValue.invoicetext
            ? sideFilterValue.invoicetext
            : ""}
          <br />
          From date: {inputFromDate ? inputFromDate : ""}
          <br />
          To date: {inputToDate ? inputToDate : ""}
        </div>
        <b>Filter by billing entity</b>
        <table
          name="billingEntitychk"
          className="marB10 padL5 marT5 cusCheck1"
          cellPadding="3"
          cellSpacing="0"
          border="0"
        >
          <tbody>
            {entityItem &&
              entityItem.length > 0 &&
              entityItem.map(entityData => (
                <tr key={entityData.billingEntityID}>
                  <td>
                    <Checkbox
                      value={entityData.billingEntityID}
                      name={entityData.billingEntityID}
                      checked={checkedItems.get(entityData.billingEntityID)}
                      onChange={this.handleChange}
                    />
                    <label>{entityData.billingEntityName}</label>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <input
          name="billingEntitysubmt"
          data-testid="billingEntity"
          className="noCap button-reverse wefDesktopButton"
          value="Filter results"
          onClick={() => this.onFilterSubmit()}
          type="submit"
          link-id="invoice and financial reports - filter results"
        />
      </div>
    );
  }
}

export default SideFilter;
