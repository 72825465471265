import { fetchProductMetaDataUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const PRODUCT_METADATA_REQUEST = "PRODUCT_METADATA_REQUEST";
export const PRODUCT_METADATA_UPDATE = "PRODUCT_METADATA_UPDATE";

export function requestProductMetaData(productName) {
  const productMetaData = {};
  productMetaData["isFetching"] = true;
  productMetaData["value"] = {};
  productMetaData["name"] = productName;
  return {
    type: PRODUCT_METADATA_REQUEST,
    data: productMetaData,
  };
}

export function receiveProductMetaData(data, productName) {
  const productMetaData = {};
  productMetaData["value"] = data;
  productMetaData["isFetching"] = false;
  productMetaData["name"] = productName;
  return {
    type: PRODUCT_METADATA_UPDATE,
    data: productMetaData,
  };
}

export function productMetaDataFailure(message, productName) {
  const productMetaData = {};
  productMetaData["value"] = null;
  productMetaData["isFetching"] = false;
  productMetaData["name"] = productName;
  return {
    type: PRODUCT_METADATA_UPDATE,
    message,
    data: productMetaData,
  };
}

export function getProductMetaData(productName) {
  if (productName) {
    return (dispatch) => {
      dispatch(requestProductMetaData(productName));
      const url = fetchProductMetaDataUrl(productName);
      http
        .get(url)
        .then((response) => {
          const data = response.data;
          if (response.status !== 200) {
            dispatch(productMetaDataFailure(data.message, productName));
            return Promise.reject(data);
          } else {
            if (data && data.length > 0) {
              dispatch(receiveProductMetaData(data[0], productName));
              return data;
            }
          }
          return null;
        })
        .catch(function (error) {
          dispatch(productMetaDataFailure(error, productName));
        });
    };
  }
  return null;
}
