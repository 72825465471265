import { createStore, applyMiddleware } from "redux";
import { appReducer } from "../reducers/index";
import thunkMiddleware from "redux-thunk";

let createStoreWithMiddleware = applyMiddleware(thunkMiddleware)(createStore);

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = createStoreWithMiddleware(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
