import {
  DOWNLOAD_INVOICE_REQUEST,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_FAILURE,
  CLEAR_RESPONSE_FOR_DOWNLOAD
} from "../actions/downloadInvoice";

export function getDownloadInvoice(
  state = {
    isFetching: false,
    downloadResponse: []
  },
  action
) {
  switch (action.type) {
    // case DOWNLOAD_INVOICE_REQUEST:
    //   return Object.assign({}, state, {
    //     isFetching: true,
    //     documentName: action.documentName,
    //     responseCode: null
    //   });
    case DOWNLOAD_INVOICE_SUCCESS:
      const data = state.downloadResponse;
      const index = data && data.findIndex(
        item =>
          item && item.documentName === action.downloadResponse.documentName
      );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.downloadResponse);

      return {
        ...state,
        isFetching: false,
        downloadResponse: data
      };
    // case DOWNLOAD_INVOICE_FAILURE:
    //   return Object.assign({}, state, {
    //     isFetching: false,
    //     isError: true,
    //     documentName: action.documentName,
    //     responseCode: action.responseCode
    //   });
    case CLEAR_RESPONSE_FOR_DOWNLOAD:
      const responseData = state.downloadResponse;
      const responseIndex = responseData && responseData.findIndex(
        item =>
          item &&
          item.displayName === action.reportName &&
          item.documentName === action.documentName
      );
      if (responseIndex !== -1) {
        responseData && responseData.splice(responseIndex, 1);
      }
      // data.push(action.downloadResponse);
      return {
        ...state,
        isFetching: false,
        downloadResponse: responseData
      };
    default:
      return state;
  }
}

export default getDownloadInvoice;
