import { getRxTrackSSO } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
import globalConstants from "../constants/global-constants";

export const RxTrack_SSO_REQUEST = "RxTrack_SSO_REQUEST";
export const RxTrack_SSO_SUCCESS = "RxTrack_SSO_SUCCESS";
export const RxTrack_SSO_FAILURE = "RxTrack_SSO_FAILURE";

function requestRxTrackSSO() {
  return {
    type: RxTrack_SSO_REQUEST,
    isFetching: true,
  };
}

function receiveRxTrackSSO(ssoData) {
  return {
    type: RxTrack_SSO_SUCCESS,
    isFetching: false,
    ssoData,
  };
}

function rxTrackSSOFailure(ssoData) {
  return {
    type: RxTrack_SSO_FAILURE,
    isFetching: false,
    ssoData,
  };
}

export function onRxTrackSSO(userId, userType, product) {
  return (dispatch) => {
    dispatch(requestRxTrackSSO());
    const url = getRxTrackSSO(userId, userType, product);
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(receiveRxTrackSSO(data));
        } else if (response.status === 500) {
          dispatch(rxTrackSSOFailure(globalConstants.UNABLETOGETTEXT));
        } else {
          dispatch(rxTrackSSOFailure(globalConstants.UNABLETOGETTEXT));
          return Promise.reject(data);
        }
        return data;
      })
      .catch(function (error) {
        dispatch(rxTrackSSOFailure(error));
      });
  };
}
