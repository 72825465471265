import React, {useMemo, useRef} from "react";

// material ui
import { Box, Typography, Grid, RadioGroup, FormControl, FormControlLabel, Radio  } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { FormularyDrugSearchStyles } from "./FormularyDrugSearchStyles";

import Logo from "../Logo";

// shared components
import FormularyTextField from "../../Shared/TextField/FormularyTextField";
import PrimaryButton from "../../Shared/PrimaryButton/PrimaryButton";
import LoaderSpin from "../../Shared/Loader/LoaderSpin";
import AutoComplete from "../../Shared/autoComplete/AutoComplete";
import FormularySelect from "../../Shared/Select/FormularySelect";
import FormDialogError from "../../Shared/FormDialogError/FormDialogError";

import { getUniqueDrugTiers } from "../utils";

import useFormularyDrugSearch from './useFormularyDrugSearch';

// component
import DrugResult from "../DrugResult/DrugResult";
import AdvancedFilters from "../AdvancedFilters/AdvancedFilters";
import formularyData from "../../../common/formularyDrugSearch/content.en.json";
import { isPartD } from "../utils";

const FormularyDrugSearch = (props) => {

  const classes = FormularyDrugSearchStyles();
  const { drugSearchContent, drugSearchFormularyContent } = props;
  const drugSearchContentData = drugSearchContent.drugSearchContent;
  const drugSearchFormularyContentData = drugSearchFormularyContent.drugSearchFormularyContent;

  const theme = useTheme(drugSearchContentData);
  const submitClickCount = useRef(0);

  const { 
    values,
    setValues,
    errors,
    submit,
    handleChange,
    handleSubmit,
    handleTherapeuticClassChange,
    handleTherapeuticSubclassChange,
    isErrorModalOpen,
    setIsErrorModalOpen,
    handleFilterCriteriaData,
    handleDrugSearchSelect,
    handleTherapeuticSelect,
  } = useFormularyDrugSearch(drugSearchContentData, drugSearchFormularyContentData, submitClickCount)
  const { toolbarExportText, noResultsMessage } = formularyData[0];
  const emptyRowMessage = values.drugResults ? noResultsMessage : "";
  const shouldDisplayNDC = values.formularyPlan !== drugSearchContentData.formularyplan_values[0];
  
  const memoizedFormularyPlanSelect = useMemo(() => (
    <FormularySelect
      value={values.formularyPlan}
      name="formularyPlan"
      onChange={handleChange}
      values={values.formularyplan_values}
      />
  ), [values.formularyPlan, handleChange, values.formularyplan_values])

  const memoizedFormularyIdSelect = useMemo(() => (
    <FormularySelect
      value={values.formularyID}
      name="formularyID"
      onChange={handleChange}
      values={values.formularyids}
      showEllipseText={true}
      displayNameAttribute={"formularyIdName"}
      displayValueAttribute={"formularyId"}
      menuItemwidth="450px"
   />
  ), [values.formularyID, handleChange, values.formularyids])

  const transformedDrugs = useMemo(() => {
      return values.drugResults
        ? values.drugResults.map((drug) => {
            const {
              id,
              hasPriorAuthorization: hasPA,
              boldedDrugFlag: drugFlag,
              isSpecialtyPharmacy: isSpecialty,
              stepFlag,
              quantityLimitNotes,
            } = drug;
            const hasPriorAuthorization = hasPA ? "Yes" : "No";
            const boldedDrugFlag = drugFlag === "B" ? "Brand" : "Generic";
            const isSpecialtyPharmacy = isSpecialty ? "Yes" : "No";
            const hasQuantityLimit = quantityLimitNotes;
            
            return {
              ...drug,
              id,
              hasPriorAuthorization,
              boldedDrugFlag,
              isSpecialtyPharmacy,
              hasQuantityLimit,
              stepFlag
            };
          })
        : [];
    }, [values.drugResults, values.formularyPlan]);

  const selectedClassdata = Array.isArray(values.therapeuticData) && values.therapeuticData.find((opt) => opt.className === values.selectedTherapeutic);
  const theropeticSubClassOptions = selectedClassdata
    ? selectedClassdata.subclasses
    : [];

  const uniqueTiers = getUniqueDrugTiers(transformedDrugs);
  const tiers = uniqueTiers.length ? ["All", ...uniqueTiers] : ["All"];

  const handleAdvancedFilterOnChange = (filterData, filterChangeCount) => {
    setValues((prevValues) => ({...prevValues, foc: false}));
    setValues((prevValues) => ({...prevValues, filters: filterData}));
    if (filterChangeCount > 0) {
      handleFilterCriteriaData(filterData, filterChangeCount)
    }
  };
  const isPartDValue = isPartD(values.formularyPlan)
  return (
    <Box>
      {drugSearchContentData && (
        <div className={classes.main}>
          <Box
            width="100%"
            height="96px"
            bgcolor={"#D9F6FA"}
            display="flex"
            alignItems="center"
            paddingLeft={"-100px"}
          >
            <Box paddingLeft={"20px"}>
              <Grid container display="flex" alignItems="center" spacing={4}>
                <Grid item>
                  {" "}
                  <Logo></Logo>{" "}
                </Grid>

                <Grid
                  item
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="h3"
                    className={classes.header1}
                    display="flex"
                    align="center"
                  >
                    {drugSearchContentData.title}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
        </Box>
          <Box paddingLeft={"30px"}>
            <Box paddingTop={"32px"}>
              <Typography>{drugSearchContentData.subtitle}</Typography>
            </Box>
          
            <Box paddingTop={"32px"}>
               <Grid container direction="row" spacing={10}>
                 <Grid item>
                   <Typography variant="h4">
                     <Box
                       sx={{
                         ...theme.formularySearchTextName,
                       }}
                     >
                       {drugSearchContentData.formularyplan}
                     </Box>
                   </Typography>

                   {memoizedFormularyPlanSelect}
                 </Grid>

                  <Grid item>
                   <Typography variant="h4">
                     <Box
                       sx={{
                         ...theme.formularySearchTextName,
                       }}
                     >
                       {drugSearchContentData.formularyid}
                     </Box>
                   </Typography>

                    {memoizedFormularyIdSelect}

                   {values.formularyID === "Other" && (
                     <Box paddingTop={"40px"}>
                       <Typography variant="h4">
                         <Box
                           sx={{
                             ...theme.formularySearchTextName,
                           }}
                         >
                           {drugSearchContentData.dynamic}
                           </Box>
                       </Typography>

                       <FormularyTextField
                         variant="outlined"
                         value={values.other}
                         name="other"
                         error={errors.other}
                         helperText={
                           errors.other ? drugSearchContentData.othererror : errors.other
                         }
                         onChange={handleChange}
                       ></FormularyTextField>
                     </Box>
                   )}
                   
                </Grid> 
               </Grid>
             </Box>
           </Box>

           <FormDialogError
             isOpen={isErrorModalOpen}
             handleClose={() => setIsErrorModalOpen(!isErrorModalOpen)}
             data={values.errorMessage}
           />

         <Box paddingLeft={"25px"}>
             <Box paddingLeft={"5px"} className={classes.margin}></Box>
          </Box>

          <Box paddingLeft={"30px"} paddingTop={"72px"}>
             <Grid container spacing={8}>
               <FormControl>
                  <RadioGroup
                     name="radioValue"
                     value={values.radioValue}
                     onChange={handleChange}
                     id="radioValueDrugName"
                  >
                    <Box paddingLeft={"25px"}>
                     <Grid
                       item
                       container
                       direction="row"
                       spacing={7}
                       className={classes.flex}
                     >
                      <Grid item container direction="row">
                         <Grid item>
                           <FormControlLabel
                             value="drugName"
                             control={<Radio />}
                             className={classes.mgright}
                             onClick={handleDrugSearchSelect}
                           />
                         </Grid>

                         <Grid item>
                           <Typography variant="h4">
                             <Box
                               sx={{
                                 ...theme.formularySearchTextName,
                               }}
                             >
                               {drugSearchContentData.drugname}
                             </Box>
                           </Typography>
                            
                          <FormularyTextField
                             variant="outlined"
                             placeholder={drugSearchContentData.search}
                             value={values.drugName}
                             name="drugName"
                             onChange={handleChange}
                             className={classes.textstyle}
                             disabled={!(values.radioValue === "drugName")}
                             error={errors.drugName}
                             helperText={
                               errors.drugName
                                 ? drugSearchContentData.drugnameerror
                                 : errors.drugName
                             }
                             inputRef={(input) =>
                               values.radioValue === "drugName" &&
                               values.foc &&
                               input &&
                               input.focus()
                             }
                           ></FormularyTextField>

                        </Grid>
                      </Grid>
                    </Grid>
                    </Box>

                    <Grid item>
                      <Box paddingLeft={"25px"} paddingTop={"32px"}>
                        <Grid container spacing={2} className={classes.flex}>
                          
                          <Grid item container direction="row">
                            <Grid item>
                              <Box marginRight={"0px"}>
                                <FormControlLabel
                                  value="therapeutic"
                                  control={<Radio />}
                                  className={classes.mgright}
                                   onClick={handleTherapeuticSelect}
                                />
                              </Box>
                            </Grid>

                            <Grid item>
                              <Typography variant="h4">
                                <Box
                                  sx={{
                                    ...theme.formularySearchTextName,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {drugSearchContentData.therapeuticclass}
                                  {values.isLoading && (
                                    <LoaderSpin
                                      color={theme.customPalette.color.grey2}
                                      marginLeft={"8px"}
                                    />
                                  )}
                                </Box>
                                </Typography>
                          
                                  <AutoComplete
                                    disabled={!(values.radioValue === "therapeutic")}
                                    value={values.selectedTherapeutic}
                                    name="therapeutic"
                                    id="therapeutic"
                                    options={values.therapeuticOptions}
                                    getOptionLabel={(option) => option.toString()}
                                    onChange={handleTherapeuticClassChange}
                                    placeholderText="Search Therapeutic class"
                                    error={errors.therapeutic}
                                    inputRef={(input) =>
                                      values.radioValue === "therapeutic" &&
                                      values.foc &&
                                      input &&
                                      input.focus()
                                    }
                                  />


                                  {errors.therapeutic && (
                                    <Box sx={{ mt: "8px", ml: "12px" }}>
                                      <Typography
                                        variant="body2"
                                        color="error"
                                        className={classes.autocompleteErrorText}
                                      >
                                        {" "}
                                        {errors.therapeutic}
                                      </Typography>
                                    </Box>
                                  )}

                            </Grid>

                         
                            <Grid item>
                              <Box
                                sx={{
                                  ml: "30px",
                                  pr: "5px",
                                  mt: {xs: "40px", md: "20px", lg: "0px"},
                                }}
                              >
                              <Typography variant="h4">
                                <Box
                                  sx={{
                                    ...theme.formularySearchTextName,
                                  }}
                                >
                                  {drugSearchContentData.therapeuticsubclass}
                                </Box>
                              </Typography>
                                <AutoComplete
                                  disabled={!(values.radioValue === "therapeutic")}
                                  name="therapeuticSubClass"
                                  id="therapeuticSubClass"
                                  value={values.selectedTherapeuticSubclass}
                                  options={theropeticSubClassOptions}
                                  getOptionLabel={(option) => option.toString()}
                                  onChange={handleTherapeuticSubclassChange}
                                  placeholderText="Search Therapeutic Subclass"
                                  error={errors.therapeuticSubclass}
                                />
                                {errors.therapeuticSubclass && (
                                    <Box sx={{ mt: "8px", ml: "12px" }}>
                                      <Typography
                                        variant="body2"
                                        color="error"
                                        className={classes.autocompleteErrorText}
                                      >
                                        {" "}
                                        {errors.therapeuticSubclass}
                                      </Typography>
                                    </Box>
                                )}
                                </Box>
                              </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                   </Grid>
                 </RadioGroup>
              </FormControl>
            </Grid>

            <Box paddingTop={"70px"} paddingLeft={"25px"}>
               <PrimaryButton
                 width={"240px"}
                 height={"56px"}
                 onClick={(e) => {
                   handleSubmit(e);
                 }}
               >
                 {drugSearchContentData.search}
               </PrimaryButton>
             </Box>

            <Box className={classes.margin}></Box>

             {submit.flag === true && (
               <Box>
                 <Box
                   sx={{ ...theme.search }}
                   paddingTop={"53px"}
                   paddingBottom={"11px"}
                   display={"flex"}
                 >
                   <Box minWidth={"350px"}>
                    {drugSearchContentData.searchresults}&nbsp;
                   </Box>
                   <Box className={classes.resultText}
                   >
                     {submit.formularyID === "Other"
                       ? submit.other
                       : submit.formularyIDName}
                   </Box>
                 </Box>

                 <Typography variant="body2">
                   <Box sx={{ fontWeight: 700, color: "#002677" }}>
                     {` ${values.drugResults?.length} ${drugSearchContentData.resultfound} below`}
                   </Box>
                 </Typography>
               </Box>
             )}
          </Box>
          <Box paddingTop={"30px"}>
              <AdvancedFilters
                  isPartD={isPartDValue}
                  drugsData={transformedDrugs}
                  drugFilterCMSData={drugSearchContentData}
                  tiers={tiers}
                  submitClickCount={submitClickCount.current}
                  handleAdvanceFilter={handleAdvancedFilterOnChange}
                />
           </Box>
          <div id="resultwrapper">
            <Box
              sx={{
                width: "100%",
                my: 8,
              }}
            >
              <DrugResult
                drugResults={transformedDrugs}
                toolbarExportText={toolbarExportText}
                filterCriteria={values.filterCriteria}
                noRowsMessage={emptyRowMessage}
                shouldDisplayNDC={shouldDisplayNDC}
                isPartD={isPartDValue}
              />
            </Box>
          </div>
      </div>
      )}
    </Box>
    
  )
}

export default React.memo(FormularyDrugSearch);
