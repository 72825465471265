import { SPECIALTY_SUCCESS } from "../actions/specialty";

export function specialty(
  state = {
    isFetching: false,
    accountId: "",
    specialty: [],
  },
  action
) {
  switch (action.type) {
    case SPECIALTY_SUCCESS:
      const data = state.specialty;
      const index =
        data &&
        data.findIndex(
          (item) => item && item.pageType === action.data.pageType
        );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        isFetching: false,
        specialty: data,
      };
    default:
      return state;
  }
}

export default specialty;
