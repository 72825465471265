import {
    DRUG_SEARCH_FORMULARY_CONTENT_REQUEST,
    DRUG_SEARCH_FORMULARY_CONTENT_SUCCESS,
    DRUG_SEARCH_FORMULARY_CONTENT_FAILURE,
    SEARCH_ERROR_ANALYTICS,
    SEARCH_FILTER_SUCCESS_ANALYTICS,
    SEARCH_BTN_LINK_ANALYTICS,
    SEARCH_FILTER_LINK_ANALYTICS,
    EXPORT_CSV_LINK_ANALYTICS,
    DRUG_RESULT_SELECTED_ROWS_LINK_ANALYTICS,
    SEARCH_RADIO_LINK_ANALYTICS,
    SET_DRUG_RESULTS,
  } from "../actions/drugSearchActionFormulary";
  
  function drugSearchFormularyContent(
    state = {
      isFetching: false,
      drugSearchFormularyContent: "",
      error: null,
      isError: false,
      drugResults: []
    },
    action
  ) {
    switch (action.type) {
      case DRUG_SEARCH_FORMULARY_CONTENT_REQUEST:
        return Object.assign({}, state, {
          isFetching: true,
          drugSearchFormularyContent: "",
        });
      case DRUG_SEARCH_FORMULARY_CONTENT_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
          drugSearchFormularyContent: action.data,
        });
      case DRUG_SEARCH_FORMULARY_CONTENT_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          isError: true,
          error: action.message,
        });
      case SEARCH_ERROR_ANALYTICS:
        return onDrugSearchError(state, action);
      case SEARCH_FILTER_SUCCESS_ANALYTICS:
        return onDrugSearchFormularyAdvancedFilterSuccess(state, action);
      case SEARCH_BTN_LINK_ANALYTICS:
        return onDrugSearchButtonClickLinkTrack(state);
      case SEARCH_FILTER_LINK_ANALYTICS:
        return onDrugSearchAdvancedFilterClickLinkTrack(state);
      case EXPORT_CSV_LINK_ANALYTICS:
        return onExportCSVClickLinkTrack(state);
      case DRUG_RESULT_SELECTED_ROWS_LINK_ANALYTICS:
        return onDrugResultRowCheckLinkTrack(state, action);
      case SEARCH_RADIO_LINK_ANALYTICS:
        return onSearchRadioSelectLinkTrack(state, action);
      case SET_DRUG_RESULTS:
        return onDrugResultsChange(state, action);
      default:
        return state;
    }
  }
  


  function onDrugSearchError(state, action) {
      let customObject = {};  
      if (window.adobeDataLayer && action.data.error) {
        var dataLayerObj = {
          data: {
            error: {
              message: action.data.error.message,
              source: action.data.error.source,
              sourceURL: action.data.error.sourceURL,
              friendly: action.data.error.friendly,
              errorType: action.data.error.errorType,
              code: action.data.error.code
            }
          }
        }

        customObject.data = {
          error: {...dataLayerObj.data.error}
        }

        window.adobeDataLayer.push({ ...customObject, event: "error"})
      }

      return state;
  }

  function onDrugSearchFormularyAdvancedFilterSuccess(state, action) {
    let customObject = {};
    // search event tracking object for adobe analytics (advanced filters)
    if (window.adobeDataLayer && action.data.search) {
      var dataLayerObj = {
        data: {
          search: action.data.search,
        }
      }
      customObject.data = {
        search: {...dataLayerObj.data.search},
      }
      window.adobeDataLayer.push({...customObject, event: "search results"});
    }
    return state;
  }

  function onDrugSearchButtonClickLinkTrack(state) {
    let customObject = {};

    // search button link tracking
    if (window.adobeDataLayer) {
       var dataLayerObj = {
        data: {
          innerText: 'Search',
          linkType: 'button'
        }
       }
       customObject = dataLayerObj;
       window.adobeDataLayer.push({...customObject, event: "linktrack"})
    }
    return state;
  }

  function onDrugSearchAdvancedFilterClickLinkTrack(state) {
    let customObject = {};

    // search button link tracking
    if (window.adobeDataLayer) {
       var dataLayerObj = {
        data: {
          innerText: 'Filter search results',
          linkType: 'button'
        }
       }
       customObject = dataLayerObj;
       window.adobeDataLayer.push({...customObject, event: "linktrack"})
    }
    return state;
  }


  function onExportCSVClickLinkTrack(state) {
    let customObject = {};

    // search button link tracking
    if (window.adobeDataLayer) {
       var dataLayerObj = {
        data: {
          innerText: 'EXPORT AS CSV',
          linkType: 'text link'
        }
       }
       customObject = dataLayerObj;
       window.adobeDataLayer.push({...customObject, event: "linktrack"})
    }
    return state;
  }

  function onDrugResultRowCheckLinkTrack(state, action) {
    let customObject = {};
    let selectedRows = [];

    for (let i = 0; i< action.data.length; i++) {
      const row = action.data[i];
      let customRowObj = {};
      customRowObj.brandName = row.brandName;
      customRowObj.genericName = row.genericName;
      customRowObj.id = row.id;
      selectedRows.push(customRowObj);
    }

    // drug results row checked link tracking 
    if (window.adobeDataLayer) {
      var dataLayerObj = {
        data: {
          drugResults: {
            linkType: "checkbox",
            selectedRows: selectedRows,
          }
        }
      }
      customObject = dataLayerObj;
      window.adobeDataLayer.push({...customObject, event: "linktrack"})
    }
    return state;
  }

  function onSearchRadioSelectLinkTrack(state, action) {
    let customObject = {};
    // radio button link tracking
    if (window.adobeDataLayer) {
       var dataLayerObj = {
        data: {
          innerText: action.data.innerText,
          linkType: 'radio button'
        }
       }
       customObject = dataLayerObj;
       window.adobeDataLayer.push({...customObject, event: "linktrack"})
    }
    return state;
  }

  function onDrugResultsChange (state, action) {
    return Object.assign({}, state, {
      drugResults: action.data,
    });
  }
  
  export default drugSearchFormularyContent;
  