import React, { Component } from "react";
import { connect } from "react-redux";
import { getAccountProductQuickLauch } from "../actions/accountProductQuickLaunch";
import MyProducts from "../components/Applications/MyProducts/MyProducts";
import { Scrollbars } from "react-custom-scrollbars";
import Content from "../components/Shared/Content/Content";
import { isInternal } from "../constants/service";
import InternalProductContainer from "./InternalProductContainer";
import globalConstants from "../constants/global-constants";

class MyProductContainer extends Component {
  state = {
    productDataList: {},
    productLoadComplete: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getAccountMyProductList(nextProps);
  }

  componentDidMount() {
    this.getAccountMyProductList(this.props);
  }

  getAccountMyProductList(nextProps) {
    const { selectedAccount, user } = nextProps;
    if (!isInternal(user)) {
      if (selectedAccount) {
        if (selectedAccount && selectedAccount.u_account) {
          const { accountProductQuickLaunch } = nextProps;
          if (
            accountProductQuickLaunch &&
            !accountProductQuickLaunch.isFetching
          ) {
            const productList = this.configQuickLaunchAndProduct(
              selectedAccount.u_account,
              accountProductQuickLaunch.productQuickLaunch
            );
            if (productList) {
              if (
                productList.accountId !== this.state.productDataList.accountId
              ) {
                if (productList.productAndquickLaunch) {
                  this.setState({
                    productDataList: productList,
                    productLoadComplete: true,
                  });
                }
              }
            } else {
              this.props.dispatch(
                getAccountProductQuickLauch(
                  selectedAccount[globalConstants.U_ACCOUNT_NAME],
                  selectedAccount.u_account
                )
              );
            }
          }
        }
      }
    }
  }

  configQuickLaunchAndProduct(accountID, accountTeams) {
    return accountTeams.find((item) => {
      return item.accountId === accountID;
    });
  }

  render() {
    const { noDataError, user, selectedAccount } = this.props;
    const { productDataList, productLoadComplete } = this.state;
    if (isInternal(user)) {
      return (
        <React.Fragment>
          <InternalProductContainer type="MyProduct" />
        </React.Fragment>
      );
    } else {
      if (!productLoadComplete) {
        return (
          <React.Fragment>
            <div align="center">Loading data...</div>
          </React.Fragment>
        );
      } else {
        if (
          productDataList &&
          productDataList.productAndquickLaunch &&
          productDataList.productAndquickLaunch.productList
        ) {
          var productList = productDataList.productAndquickLaunch.productList;
          return (
            <React.Fragment>
              <Scrollbars style={{ width: 1410, height: 500, marginTop: 16 }}>
                {productList && (
                  <MyProducts
                    productList={productList}
                    selectedAccount={selectedAccount}
                  />
                )}
              </Scrollbars>
            </React.Fragment>
          );
        }
        return (
          <div className="tab">
            <div className="container30">
              <div id="myProducts" className="tab-content">
                <div id="prodoffdivmsg" className="noProduct">
                  <Content html={noDataError} />
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

const mapStateToProps = ({
  accountProductQuickLaunch,
  userAccount,
  user,
  auth,
}) => {
  return {
    accountProductQuickLaunch,
    selectedAccount: userAccount.selectedAccount,
    user,
    auth,
  };
};
export default connect(mapStateToProps)(MyProductContainer);
