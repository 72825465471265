import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import {
  getDocumentRetrieval,
  clearDocumentRetrieval,
} from "../../../actions/getDocumentRetrieval";
import { Box } from "@material-ui/core";
import {
  searchResultTB,
  searchResultEob,
} from "../../../constants/searchCriteria_EOB";
import "../BASTab.scss";
import PdfViewer from "./PdfViewer";

class ResultTableHead extends React.Component {
  render() {
    const {
      onSelectAllClick,
      numSelected,
      filterObject,
      productTypeList,
      pageSize,
      resultData
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < resultData.length}
              checked={numSelected === resultData.length}
              onChange={onSelectAllClick}
              color="primary"
            />
            <span>Select all</span>
          </TableCell>
          {filterObject &&
          filterObject.productType !== "Transition benefits" &&
          productTypeList &&
          productTypeList[0].label === "Explanation of benefits"
            ? searchResultEob.map((row1) => <TableCell>{row1.label}</TableCell>)
            : searchResultTB.map((row2) => <TableCell>{row2.label}</TableCell>)}
        </TableRow>
      </TableHead>
    );
  }
}

ResultTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  filterObject: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    boxShadow: "none",

    "& .MuiTablePagination-root": {
      "& .MuiTablePagination-toolbar": {
        height: 70,
      },
      "& .MuiTablePagination-spacer": {
        display: "none",
      },
    },
  },
  table: {
    minWidth: 1020,

    "& tr:nth-child(even)": {
      background: "#f5f5f5",
    },
  },
  tableWrapper: {
    overflowX: "auto",
    border: "1px solid #ccc",

    "& .MuiTableCell-head": {
      lineHeight: "normal",
      background: "#f5f5f5",
      color: "#000",

      "&:not(.MuiTableCell-paddingCheckbox)": {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
    "& .MuiCheckbox-colorPrimary.Mui-checked": {
      color: "#0879ff",
    },
    "& .MuiTableCell-root": {
      padding: "5px 21px",

      "& .MuiCheckbox-root": {
        padding: 0,
        marginLeft: 10,

        "& + span": {
          whiteSpace: "nowrap",
          fontSize: 10,
        },
      },
    },
  },
  tablePagination: {
    display: "flex",
    justifyContent: "center",

    "& .MuiToolbar-regular": {
      padding: 0,
    },

    "& .MuiTablePagination-caption": {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
    },

    "& .MuiTablePagination-actions": {
      width: 400,
      display: "flex",
      justifyContent: "space-between",
      margin: 0,

      "& button": {
        padding: 0,
        color: "#002677",

        "&.Mui-disabled": {
          opacity: "0.5",
        },

        "& .MuiIconButton-label": {
          position: "relative",

          "&:before": {
            content: "'Previous'",
            position: "absolute",
            display: "block",
            fontSize: 18,
            top: 1,
            left: 23,
          },
        },

        "& + button .MuiIconButton-label:before": {
          content: "'Next'",
          left: "auto",
          right: 23,
        },
      },
    },
  },
});

class ResultTable extends React.Component {
  state = {
    selected: [],
    open: false,
    fileName: "",
    isLoading: true,
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const { resultData } = this.props;
      this.setState((state) => ({
        selected: resultData.map((n) => n.memberId),
      }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({
      selected: newSelected,
    });
  };

  handlePdfViewer = (id, productType, psFileName) => {
    this.props.dispatch(getDocumentRetrieval(id, productType));
    this.setState({ open: true, fileName: psFileName });
  };

  handleClose() {
    this.setState({ open: false });
    this.props.dispatch(clearDocumentRetrieval());
  }

  handleChangePage = (event, page) => {
    // this.setState({ page });
    this.props.handlePageChange(page);
  };

  handleChangeRowsPerPage = (event) => {
    // this.setState({ rowsPerPage: event.target.value });
    this.props.handlePageSize(event.target.value);
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes } = this.props;

    const {
      resultData,
      filterObject,
      handleDownloadZip,
      handleReprint,
      handleJobSummary,
      totalResult,
      page,
      pageSize,
      productTypeList,
      isSelected,
    } = this.props;

    const { selected } = this.state;

    const generatedLink = this.props.getDocumentRetrievalLink.link[0];

    const pageCount = totalResult / pageSize;

    return (
      <>
        <Box mt={5}>
          <Box>
            <Box fontWeight={700} fontSize={30} fontFamily="Open Sans">
              <b>Search results</b>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <Box>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={totalResult}
                  rowsPerPage={pageSize}
                  page={page}
                  backIconButtonProps={{
                    style: { display: "none" },
                  }}
                  nextIconButtonProps={{
                    style: { display: "none" },
                  }}
                  onChangePage={this.handleChangePage}
                  className={
                    totalResult === 1
                      ? "required-file noGutter"
                      : "required-files noGutter"
                  }
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Box ml={2}>
                  <a
                    href="javavascript:void(0)"
                    name="downloadZp"
                    className="button button-primary block noCap"
                    onClick={(e) => handleDownloadZip(e)}
                    disabled={selected.length === 0}
                  >
                    Download ZIP
                  </a>
                </Box>
                <Box ml={2}>
                  <a
                    href="javavascript:void(0)"
                    name="jobSummary"
                    className="button button-primary block noCap"
                    onClick={(e) => handleJobSummary(e)}
                    disabled={selected.length === 0}
                  >
                    Job Summary
                  </a>
                </Box>
                <Box ml={2}>
                  <a
                    href="javavascript:void(0)"
                    name="reprint"
                    className="button button-primary block noCap"
                    onClick={(e) => handleReprint(e)}
                    disabled={selected.length === 0}
                  >
                    Reprint
                  </a>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <ResultTableHead
                numSelected={selected.length}
                onSelectAllClick={this.handleSelectAllClick}
                filterObject={filterObject}
                productTypeList={productTypeList}
                pageSize={pageSize}
                resultData={resultData}
              />
              <TableBody>
                {resultData
                  // .slice(page * pageSize, page * pageSize + pageSize)
                  .map((n) => {
                    const isSelected = this.isSelected(n.memberId);
                    return (
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected}
                            onClick={(event) =>
                              this.handleClick(event, n.memberId)
                            }
                            color="primary"
                          />
                        </TableCell>
                        {filterObject &&
                        filterObject.productType !== "Transition benefits" &&
                        productTypeList &&
                        productTypeList[0].label ===
                          "Explanation of benefits" ? (
                          <>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <span>
                                <a
                                  href="# "
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handlePdfViewer(
                                      n.globalDocumentId,
                                      filterObject &&
                                        filterObject.productType !==
                                          "Transition benefits" &&
                                        productTypeList &&
                                        productTypeList[0].label ===
                                          "Explanation of benefits"
                                        ? "EOB"
                                        : "TB",
                                      n.psFileName
                                    );
                                  }}
                                >
                                  <Box
                                    style={{ color: "#002677" }}
                                    fontWeight={700}
                                  >
                                    {n.carrierId}
                                  </Box>
                                </a>
                              </span>
                            </TableCell>
                            <TableCell>{n.accountId}</TableCell>
                            <TableCell>{n.groupId}</TableCell>
                            <TableCell>{n.memberId}</TableCell>
                            <TableCell>{n.memberLastName}</TableCell>
                            <TableCell>{n.memberFirstName}</TableCell>
                            <TableCell>{n.memberCity}</TableCell>
                            <TableCell>{n.memberState}</TableCell>
                            <TableCell>{n.memberZip}</TableCell>
                            <TableCell>{n.claimStartDate}</TableCell>
                            <TableCell>{n.claimEndDate}</TableCell>
                            <TableCell>{n.claimDate}</TableCell>
                            <TableCell>{n.benefitYear}</TableCell>
                            <TableCell>{n.date}</TableCell>
                            <TableCell>{n.stage}</TableCell>
                            <TableCell>{n.format}</TableCell>
                            <TableCell>{n.languageCode}</TableCell>
                            <TableCell>{n.psFileName}</TableCell>
                            <TableCell>{n.drugName}</TableCell>
                            <TableCell>{n.rxNumber}</TableCell>
                            <TableCell>{n.memberAddress1}</TableCell>
                            <TableCell>{n.mailedFrom}</TableCell>
                            <TableCell>{n.mailedTo}</TableCell>
                            <TableCell>{n.receivedFrom}</TableCell>
                            <TableCell>{n.receivedTo}</TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <span>
                                <a
                                  // className={fileClass}
                                  href="# "
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handlePdfViewer(
                                      n.globalDocumentId,
                                      filterObject.productType ===
                                        "Transition benefits"
                                        ? "TB"
                                        : "EOB",
                                      n.psFileName
                                    );
                                  }}
                                >
                                  <Box
                                    style={{ color: "#002677" }}
                                    fontWeight={700}
                                  >
                                    {n.carrierId}
                                  </Box>
                                </a>
                              </span>
                            </TableCell>
                            <TableCell>{n.accountId}</TableCell>
                            <TableCell>{n.groupId}</TableCell>
                            <TableCell>{n.memberId}</TableCell>
                            <TableCell>{n.memberLastName}</TableCell>
                            <TableCell>{n.memberFirstName}</TableCell>
                            <TableCell>{n.memberCity}</TableCell>
                            <TableCell>{n.memberState}</TableCell>
                            <TableCell>{n.memberAddress1}</TableCell>
                            <TableCell>{n.date}</TableCell>
                            <TableCell>{n.drugName}</TableCell>
                            <TableCell>{n.languageCode}</TableCell>
                            <TableCell>{n.fileType}</TableCell>
                            <TableCell>{n.crif}</TableCell>
                            <TableCell>{n.psFileName}</TableCell>
                            <TableCell>{n.reprintFileName}</TableCell>
                            <TableCell>{n.mailedFrom}</TableCell>
                            <TableCell>{n.mailedTo}</TableCell>
                            <TableCell>{n.receivedFrom}</TableCell>
                            <TableCell>{n.receivedTo}</TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
          {totalResult > pageSize ? (
            <TablePagination
              labelRowsPerPage="Items per page"
              rowsPerPageOptions={[]}
              component="div"
              count={totalResult}
              rowsPerPage={pageSize}
              page={page}
              backIconButtonProps={{
                style: {
                  marginLeft: "-35px",
                  display: page === 0 ? "none" : "flex",
                },
              }}
              nextIconButtonProps={{
                style: {
                  marginLeft: "348px",
                  display: page === parseInt(pageCount) ? "none" : "flex",
                },
              }}
              className={clsx(
                classes.tablePagination,
                totalResult === 1 ? "required-file" : "required-files"
              )}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          ) : (
            <TablePagination
              labelRowsPerPage="Items per page"
              rowsPerPageOptions={[]}
              component="div"
              count={totalResult}
              rowsPerPage={pageSize}
              page={page}
              backIconButtonProps={{
                style: { visibility: "hidden" },
              }}
              nextIconButtonProps={{
                style: { visibility: "hidden" },
              }}
              className={clsx(
                classes.tablePagination,
                totalResult === 1 ? "required-file" : "required-files"
              )}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          )}
        </Paper>
        <PdfViewer
          generatedLink={generatedLink}
          handleClose={this.handleClose.bind(this)}
          open={this.state.open}
          pdfFileName={this.state.fileName}
        />
      </>
    );
  }
}

ResultTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ getDocumentRetrievalLink }) => {
  return { getDocumentRetrievalLink };
};
export default withStyles(styles)(connect(mapStateToProps)(ResultTable));
