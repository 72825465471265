import {
  TERMSOFUSE_CONTENT_REQUEST,
  TERMSOFUSE_CONTENT_SUCCESS,
  TERMSOFUSE_CONTENT_FAILURE,
} from "../actions/termsOfUseContent";

export function termsOfUseContent(
  state = {
    isFetching: false,
    termsOfUseContent: "",
  },
  action
) {
  switch (action.type) {
    case TERMSOFUSE_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        termsOfUseContent: "",
      });
    case TERMSOFUSE_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        termsOfUseContent: action.data,
      });
    case TERMSOFUSE_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default termsOfUseContent;
