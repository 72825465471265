import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const PRIVACYPOLICY_CONTENT_REQUEST = "PRIVACYPOLICY_CONTENT_REQUEST";
export const PRIVACYPOLICY_CONTENT_SUCCESS = "PRIVACYPOLICY_CONTENT_SUCCESS";
export const PRIVACYPOLICY_CONTENT_FAILURE = "PRIVACYPOLICY_CONTENT_FAILURE";

export function requestPrivacyPolicyContent() {
  return {
    type: PRIVACYPOLICY_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function receivePrivacyPolicyContent(data) {
  return {
    type: PRIVACYPOLICY_CONTENT_SUCCESS,
    isFetched: true,
    data,
  };
}

export function privacyPolicyContentFailure(message) {
  return {
    type: PRIVACYPOLICY_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getPrivacyPolicyContent() {
  return (dispatch) => {
    dispatch(requestPrivacyPolicyContent());
    const url = fetchGeneralContentUrl("/cms/privacypolicy");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(privacyPolicyContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receivePrivacyPolicyContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(privacyPolicyContentFailure(error));
      });
  };
}
