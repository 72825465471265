import { GET_DOCUMENT_RETRIEVAL_SUCCESS, CLEAR_DOC_RETR_RESPONSE } from "../actions/getDocumentRetrieval";

export function getDocumentRetrievalLink(
  state = {
    isFetching: false,
    link: [],
  },
  action
) {
  switch (action.type) {
    case GET_DOCUMENT_RETRIEVAL_SUCCESS:
      const data = state.link;
      const index =
        data &&
        data.findIndex(
          (item) =>
            item &&
            item.id === action.data.id &&
            item.productType === action.data.productType
        );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        isFetching: false,
        link: data,
      };

      case CLEAR_DOC_RETR_RESPONSE:
        return {
          ...state,
          isFetching: false,
          link: [],
        };
    default:
      return state;
  }
}

export default getDocumentRetrievalLink;