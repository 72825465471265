import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const SIGNOUT_CONTENT_REQUEST = "SIGNOUT_CONTENT_REQUEST";
export const SIGNOUT_CONTENT_SUCCESS = "SIGNOUT_CONTENT_SUCCESS";
export const SIGNOUT_CONTENT_FAILURE = "SIGNOUT_CONTENT_FAILURE";

function requestSignOutContent() {
  return {
    type: SIGNOUT_CONTENT_REQUEST,
    isFetched: false
  };
}

function receiveSignOutContent(data) {
  return {
    type: SIGNOUT_CONTENT_SUCCESS,
    isFetched: true,
    data
  };
}

function signOutContentFailure(message) {
  return {
    type: SIGNOUT_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message
  };
}

export function getSignOutContent() {
  return dispatch => {
    dispatch(requestSignOutContent());
    const url = fetchGeneralContentUrl("/cms/getsignoutcontent");
    http.get(url).then(
      response => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(signOutContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveSignOutContent(data));
          return data;
        }
      }
    ).catch(function (error) {
      dispatch(signOutContentFailure(error))
    });
  };
}
