import {
  GP_RELATEDLINK_REQUEST,
  GP_RELATEDLINK_SUCCESS,
  GP_RELATEDLINK_FAILURE
} from "../actions/governmentRelatedLinksMetaData";

export function governmentRelatedLinks(
  state = {
    isFetching: false,
    data: []
  },
  action
) {
  switch (action.type) {
    case GP_RELATEDLINK_REQUEST:
      return { ...state, isFetching: true };
    case GP_RELATEDLINK_SUCCESS:
      let data=[];
      if (state.data) {
         data = [...state.data, action.data]
      }
      return {
        ...state,
        isFetching: false,
        data: data
      };
    case GP_RELATEDLINK_FAILURE:
      return {
        ...state,
        isGovernmentUpdatesError: true,
        isFetching: false,
        isError: true,
        error: action.message
      };
    default:
      return state;
  }
}

export default governmentRelatedLinks;
