import {
  OID_LOGIN_REQUEST,
  OID_LOGIN_SUCCESS,
  OID_LOGIN_FAILURE,
} from "../actions/oidAuthentication";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export function auth(
  state = {
    isFetching: false,
    // isAuthenticated: cookies.get('access_token') ? true : false,
    isAuthenticated: sessionStorage.getItem("session_id") ? true : false,
    // // token: sessionStorage.getItem("id_token"),
    accessToken: sessionStorage.getItem("session_id"),
    // accessToken: cookies.get('access_token'),
    isSignOut: localStorage.getItem("signOut") ? true : false,
  },
  action
) {
  switch (action.type) {
    case OID_LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
      });
    case OID_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        errorMessage: "",
        // token: action.token,
        accessToken: sessionStorage.getItem("session_id"),
        // accessToken: cookies.get('access_token'),

        // user: action.user
      });
    case OID_LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        isError: true,
        errorMessage: action.message,
      });
    default:
      return state;
  }
}

// We combine the reducers here so that they
// can be left split apart above

export default auth;
