import { makeStyles, createStyles } from "@material-ui/core";

export const AutoCompleteStyles = makeStyles((theme) =>
  createStyles({
    textstyle: {
      height: "44px",
      width: "490px",
    },
    autoCompleteSelectBox: {
      height: "44px",
      width: "500px",
      marginTop: '0px',
      marginBottom: '0px',
      "& input": {
        fontSize: "15px",
        lineHeight: "1.3",
        padding: "7px 64px  7px 18px !important"
      },
      "& .MuiInputBase-root": {
        paddingRight: "0px !important",
      },
    },
  })
);
