import {
  fetchGeneralContentUrl,
  fetchExtLaunchUrl,
  fetchIntLaunchUrl,
  fetchExtLaunchUrlNew,
} from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
import globalConstants from "../constants/global-constants";
export const CLIENTREQUESTS_CONTENT_REQUEST = "CLIENTREQUESTS_CONTENT_REQUEST";
export const CLIENTREQUESTS_CONTENT_SUCCESS = "CLIENTREQUESTS_CONTENT_SUCCESS";
export const CLIENTREQUESTS_CONTENT_FAILURE = "CLIENTREQUESTS_CONTENT_FAILURE";
export const CLIENTREQUESTS_EXTERNAL_SSO_DATA_REQUEST =
  "CLIENTREQUESTS_EXTERNAL_SSO_DATA_REQUEST";
export const CLIENTREQUESTS_EXTERNAL_SSO_DATA_SUCCESS =
  "CLIENTREQUESTS_EXTERNAL_SSO_DATA_SUCCESS";
export const CLIENTREQUESTS_EXTERNAL_SSO_DATA_FAILURE =
  "CLIENTREQUESTS_EXTERNAL_SSO_DATA_FAILURE";
export const CLIENTREQUESTS_LAUNCH_URL = "CLIENTREQUESTS_LAUNCH_URL";

export function requestClientRequestsContent() {
  return {
    type: CLIENTREQUESTS_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function receiveClientRequestsContent(data) {
  return {
    type: CLIENTREQUESTS_CONTENT_SUCCESS,
    isFetched: true,
    data,
  };
}

export function clientRequestsContentFailure(message) {
  return {
    type: CLIENTREQUESTS_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function requestExternalLaunchSSO() {
  return {
    type: CLIENTREQUESTS_EXTERNAL_SSO_DATA_REQUEST,
    isFetching: true,
  };
}

export function receiveExternalLaunchSSO(ssoData) {
  return {
    type: CLIENTREQUESTS_EXTERNAL_SSO_DATA_SUCCESS,
    isFetching: false,
    ssoData,
  };
}

export function externalLaunchSSOFailure(ssoData) {
  return {
    type: CLIENTREQUESTS_EXTERNAL_SSO_DATA_FAILURE,
    isFetching: false,
    ssoData,
  };
}

export function recieveLaunchUrl(url) {
  var win = window.open(url, "_blank");
  win && win.focus();
  return false;
}

export function getClientRequestsContent() {
  return (dispatch) => {
    dispatch(requestClientRequestsContent());
    const url = fetchGeneralContentUrl(
      "/cms/getNodeByCode/page/ClientRequests"
    );

    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(clientRequestsContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveClientRequestsContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(clientRequestsContentFailure(error));
      });
  };
}

export function fetchExternalLaunchUrl(user) {
  if (user) {
    const url = fetchExtLaunchUrl(user);
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          return Promise.reject(data);
        } else {
          recieveLaunchUrl(data.launchUrl);
          return data;
        }
      })
      .catch(function (error) { });
  }
}

export function fetchExternalLaunchUrl_new(user) {
  return (dispatch) => {
    dispatch(requestExternalLaunchSSO());
    const url = fetchExtLaunchUrlNew();
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(receiveExternalLaunchSSO(data));
        } else if (response.status === 500) {
          dispatch(externalLaunchSSOFailure(globalConstants.UNABLETOGETTEXT));
        } else {
          dispatch(externalLaunchSSOFailure(globalConstants.UNABLETOGETTEXT));
          return Promise.reject(data);
        }
        return data;
      })
      .catch(function (error) {
        dispatch(externalLaunchSSOFailure(error));
      });
  };
}

export function fetchInternalLaunchUrl() {
  const url = fetchIntLaunchUrl();
  http
    .get(url)
    .then((response) => {
      const data = response.data;
      if (response.status !== 200) {
        return Promise.reject(data);
      } else {
        recieveLaunchUrl(data.launchUrl);
        return data;
      }
    })
    .catch(function (error) { });
}
