import React from "react";
import PropTypes from "prop-types";

const WorkTeam = ({ accountTeam, title }) => {
  return (
    <section>
      {accountTeam && accountTeam.length > 0 && (
        <h3
          className="accounting-heading"
          name="heading"
          styles={{ display: "block" }}
        >
          <span name="InsideBottom_heading">{title}</span>
        </h3>
      )}
      <ul className="row- teamList">
        {accountTeam.map(data => (
          <li key={data.u_user} className="col-sm-6">
            <figure>
              {/* <img
                src={
                  data.imgSrc ? data.imgSrc : "/assets/images/defaultimage.jpg"
                }
                alt=""
              /> */}
              {/* <AccountTeamImageContainer email={data["u_user.email"]} /> */}

              <figcaption>
                <h4 className="name">
                  <span name="Name">{data.u_user}</span>
                </h4>
                <div className="info">
                  <div className="title" name="Title">
                    {data["u_role"]}
                  </div>
                  <div className="bussinessUnit" name="BusinessUnit" />
                  <div className="phone" name="Phone">
                    {data["u_user.phone"]}
                  </div>
                </div>
                <a
                  className="subhead"
                  name="Email"
                  data-email={data["u_user.email"]}
                  href={"mailto:" + data["u_user.email"]}
                >
                  Send Email
                </a>
              </figcaption>
            </figure>
          </li>
        ))}
      </ul>
    </section>
  );
};

WorkTeam.propTypes = {
  accountTeam: PropTypes.array
};

export default WorkTeam;
