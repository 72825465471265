import { REQUEST_AEM_DATA, RECEIVED_AEM_DATA} from "../actions/AEM";
function AEMData(
  state = {
    isLoading: false,
    
  },
  action
) {
  switch (action.type) {
    case REQUEST_AEM_DATA:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case RECEIVED_AEM_DATA:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

// We combine the reducers here so that they
// can be left split apart above

export default AEMData;
