import {
  PRELOGIN_FOOTER_CONTENT_REQUEST,
  PRELOGIN_FOOTER_CONTENT_SUCCESS,
  PRELOGIN_FOOTER_CONTENT_FAILURE,
  PRELOGIN_FOOTER_IMAGE_REQUEST,
  PRELOGIN_FOOTER_IMAGE_SUCCESS,
  PRELOGIN_FOOTER_IMAGE_FAILURE,
} from "../actions/preLoginFooter";

export function preLoginFooterContent(
  state = {
    isFetching: false,
    isImageFetching: false,
    preLoginFooter: "",
    preLoginFooterImage: "",
  },
  action
) {
  switch (action.type) {
    case PRELOGIN_FOOTER_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        preLoginFooter: "",
      });
    case PRELOGIN_FOOTER_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        preLoginFooter: action.preLoginFooterData,
      });
    case PRELOGIN_FOOTER_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    case PRELOGIN_FOOTER_IMAGE_REQUEST:
      return Object.assign({}, state, {
        isImageFetching: true,
        preLoginFooterImage: "",
      });
    case PRELOGIN_FOOTER_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        isImageFetching: false,
        preLoginFooterImage: action.preLoginFooterImage,
      });
    case PRELOGIN_FOOTER_IMAGE_FAILURE:
      return Object.assign({}, state, {
        isImageFetching: false,
        isImageError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default preLoginFooterContent;
