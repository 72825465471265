
import {useState, useEffect } from 'react';
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getDrugDetails, getDrugs } from "../../../constants/microservice-helpers";
import {
    searchErrorAnalytics, 
    searchBtnLinkAnalytics,
    searchFilterSuccessAnalytics,
    searchRadioLinkAnalytics,
   } from "../../../actions/drugSearchActionFormulary";
import { camelCase } from '../utils';

const useFormularyDrugSearch = (drugSearchContentData, drugSearchFormularyContent, submitClickCount) => {

    const dispatch = useDispatch();
    const { commercial, medicare, medicaid} = drugSearchFormularyContent;
    const { commercial_and_exchange_usertype, medicare_type, formularyplan_values} = drugSearchContentData;

      // initial state
    const initialValues = {
        formularyids: commercial,
        formularyID: commercial[0],
        formularyPlan: formularyplan_values[0],
        formularyplan_values: formularyplan_values,
        drugName: "",
        ndc: "",
        therapeuticSubClass: "",
        therapeuticSubClassEmpty: false,
        therapeuticOptions: [],
        therapeuticData: [],
        theropeticSubClassOptions: [],
        drug: true,
        radioValue: "drugName",
        other: "",
        selectedTherapeutic: " ",
        selectedTherapeuticSubclass: " ",
        initialTherapeticsectionFlag: false,
        therapeuticFieldRequired: false,
        foc: true,
        errorMessage: {
            dialogErrorTitle: "Error",
            dialogErrorContent: "There is an error with the services. Please try again later.",
        },
        isLoading: false,
        isApiError: false, 
        userType: "Other",
        drugResults: [],
        filterCriteria: {},
        filters: {},
    }

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({ formularyID: "" });
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [submit, setSubmit] = useState({
        formularyIDName: "",
        formularyID: "",
        flag: "abc",
        other: "",
    });

    const fetchTherapeuticOptions = async () => {
        try {
      
          setValues((prevValues) => ({...prevValues, isLoading: true}));
          const url = getDrugDetails();
          
          const requestHeader = {
            "Content-Type": "application/json",
          };

          const generatedCorrelationId = uuidv4();
    
          const data = {
            searchInputMetaData: {
              userId: process.env.REACT_APP_FORMULARY_ID,
              customerId: process.env.REACT_APP_FORMULARY_ID,
              applicationId: process.env.REACT_APP_FORMULARY_ID,
              correlationId: generatedCorrelationId,
              transactionId: null,
              consumingAppId: process.env.REACT_APP_FORMULARY_CONSUMINGAPPID,
            },
            formularyId: values.formularyID,
            userType: values.userType,
          };
          const res = await axios.post(url, data, {
            headers: requestHeader,
          });
    
          if (res.status === 200) {

            const therapeuticOptionsData = res.data?.therapeuticClasses.map((opt) => opt.className) || []
            setValues((prevValues) => ({...prevValues, 
                therapeuticOptions: therapeuticOptionsData, 
                therapeuticData: res.data.therapeuticClasses
            }))

            if (res.data.therapeuticClasses.length > 0) {
                setValues((prevValues) => ({...prevValues, 
                    selectedTherapeutic: '', 
                    selectedTherapeuticSubclass: '', 
                    initialTherapeticsectionFlag: true
                }))
            }
            setValues((prevValues) =>({...prevValues, isLoading: false, isApiError: false}))
            
          } else {
            setValues((prevValues) => ({
                ...prevValues,
                isLoading: false,
                isApiError: true,
            }))

          }
        } catch (err) {
            setValues((prevValues) => ({
                ...prevValues,
                isLoading: false,
                isApiError: true
            }));
        }
      };

      useEffect(() => {
        setErrors({ ...errors, drugName: "", ndc: "" });
        setValues((prevValues) => ({...prevValues, drugName: "", ndc: "" }));
      }, [values.radioValue]);

      useEffect(() => {
        setErrors({ ...errors, drugName: "" });
      }, [values.drugName]);
    
      useEffect(() => {
        setErrors({ ...errors, ndc: "" });
      }, [values.ndc]);
    
      useEffect(() => {
        setErrors({ ...errors, other: "" });
      }, [values.other.trim()]);

     
        useEffect(() => {
            if (values.formularyPlan === values.formularyplan_values[0]) {
               
                setValues((prevValues) => ({...prevValues, 
                    formularyids: commercial,
                    formularyID: commercial[0].formularyId,
                    ndc: "",
                    userType: commercial_and_exchange_usertype
                }));

            } else if (values.formularyPlan === formularyplan_values[1]) {

                setValues((prevValues) => ({...prevValues, 
                    formularyids: medicare,
                    formularyID: medicare[0].formularyId,
                    userType: medicare_type
                }));
            } else if (values.formularyPlan === formularyplan_values[2]) {
                setValues((prevValues) => ({...prevValues, 
                    formularyids: medicaid,
                    formularyID: medicaid[0].formularyId,
                    userType: commercial_and_exchange_usertype
                }));
            }
        }, [values.formularyPlan]);


    useEffect(() => {
        // when the radio button is switched between drugs and therapeutic
        // and when the formularyId is changed
        if (values.radioValue === "drugName") {
            setValues((prevValues) => ({
                ...prevValues,  
                selectedTherapeutic: "",
                selectedTherapeuticSubclass: "",
                initialTherapeticsectionFlag: false,
                therapeuticFieldRequired: true
            }))
            setErrors({});
        }

        if (values.radioValue === "therapeutic") {
            fetchTherapeuticOptions();
        }

    }, [values.radioValue, values.formularyID]);

    useEffect(() => {
        if (values.selectedTherapeutic && values.therapeuticData.length > 0) {
          const initialSelectedClassData = values.therapeuticData.find(
            (opt) => opt.className === values.selectedTherapeutic
          );
          if (
            initialSelectedClassData &&
            initialSelectedClassData.subclasses.length > 0
          ) {
            setValues((prevValues) => ({...prevValues, 
                selectedTherapeuticSubclass: initialSelectedClassData.subclasses[0]
            }));
          }
        }
      }, [values.selectedTherapeutic, values.therapeuticData]);


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "drug") {
            values.drug = false;
        }

        if (name === "other") {
            setValues((prevValues) => ({...prevValues, foc: false}));
            setValues((prevValues) => ({...prevValues,  [name]: value.toUpperCase() }))
        }

        if (name === "ndc") {
            setValues((prevValues) => ({...prevValues, foc: false}));
        }
      
        else {
            if (value === "drugName") {
                setValues((prevValues) => ({
                    ...prevValues,  
                    selectedTherapeutic: "",
                    selectedTherapeuticSubclass: "",
                    initialTherapeticsectionFlag: false,
                    therapeuticFieldRequired: true
                }))
 
                setErrors({});
            }
            if (value === "therapeutic") {
                if (values.selectedTherapeutic !== "") {
                    setValues((prevValues) => ({...prevValues, 
                        selectedTherapeutic: "",
                        selectedTherapeuticSubclass: "",
                        initialTherapeticsectionFlag: true,
                    }))
                }
                if (values.selectedTherapeutic === "" && values.therapeuticFieldRequired) {
                    setValues((prevValues) => ({...prevValues, 
                        selectedTherapeutic: "",
                        selectedTherapeuticSubclass: "",
                        initialTherapeticsectionFlag: true,
                    }))
                }
            }
            setValues((prevValues) => ({...prevValues, foc: true}));
            setValues((prevValues) => ({...prevValues, [name]: value}));
        }
    };

    const handleTherapeuticClassChange = (event, value) => {
        setValues((prevValues) =>({ 
            ...prevValues, 
            selectedTherapeutic: value,
            selectedTherapeuticSubclass: " "
        }));

        if (value === null) {
            setValues((prevValues) => ({
                ...prevValues,
                selectedTherapeuticSubclass: "",
                initialTherapeticsectionFlag: true,
                therapeuticSubClassEmpty: false,
            }));
        }
        else if (value !== "") {
            setValues((prevValues) => ({
                ...prevValues,
                initialTherapeticsectionFlag: false,
            }));
        }
    }

    const handleTherapeuticSubclassChange = (event, value) => {
        setValues((prevValues) => ({
            ...prevValues,
            selectedTherapeuticSubclass: value,
        }));

        if (value === null) {
            setValues((prevValues) => ({
                ...prevValues,
                initialTherapeticsectionFlag: true,
                therapeuticSubClassEmpty: true,
            }))
        } else if (value !== "") {
            setValues((prevValues) => ({
                ...prevValues,
                initialTherapeticsectionFlag: false,
            }))
        }
    };

    const getFormularyIdName = (formularyID) => {
        if (values.formularyPlan === values.formularyplan_values[0]) {
          for (let index = 0; index < commercial.length; index++) {
            if (commercial[index].formularyId === formularyID)
              return commercial[index].formularyIdName;
          }
        } else if (values.formularyPlan === values.formularyplan_values[1]) {
          for (let index = 0; index < medicare.length; index++) {
            if (medicare[index].formularyId === formularyID)
              return medicare[index].formularyIdName;
          }
        } else if (values.formularyPlan === values.formularyplan_values[2]) {
          for (let index = 0; index < medicaid.length; index++) {
            if (medicaid[index].formularyId === formularyID)
              return medicaid[index].formularyIdName;
          }
        } 
      };

    const setSearchFormErrorAnalytics = (formErrors) => {
        // for search track adobe analytics when clicked on submit
        // when form is having errors

        let errorMessage = ""
        let sourceMessage = ""
        let errorCode = ""
        let friendlyMessage = ""
        let errorSourceURL = ""
        let errorType = ""

        if ("drugName" in formErrors) {
            errorMessage = formErrors.drugName;
        }

        if ("formularyId" in formErrors) {
            errorMessage = formErrors.formularyID;
        }

        if ("ndc" in formErrors) {
            errorMessage = formErrors.ndc;
        }

        if ("other" in formErrors) {
            errorMessage = formErrors.other;
        }

        if ("therapeutic" in formErrors) {
            errorMessage = formErrors.therapeutic
        }

        if ("therapeuticSubclass" in formErrors) {
            errorMessage = formErrors.therapeuticSubclass
        }

        sourceMessage = "formulary drug search";
        friendlyMessage = "Form validation error occured";
        errorSourceURL = "home/formularylookup";
        errorType = "form-validation"


        // event for form errors
        let validationErrorObj = {
            error: {
                message: errorMessage,
                source: sourceMessage,
                code: errorCode,
                friendly: friendlyMessage,
                sourceURL: errorSourceURL,
                errorType: errorType
            }
        };

        // drug found on search
        dispatch(searchErrorAnalytics(validationErrorObj))
    }

    const validateSearchForm = () => {
        let formErrors = {};
        if (!values.formularyID.trim) {
            formErrors.formularyID = "Enter Formulary ID";
        }
        if (values.radioValue === "drugName" && values.drugName.trim().length < 3) {
            formErrors.drugName = "Enter 3 characters";
        }
        if (values.radioValue === "ndc" && values.ndc.trim().length > 20) {
            formErrors.ndc = "Enter valid NDC";
        }
        if (values.formularyID === "Other" && values.other.trim().length === 0) {
            formErrors.other = "Enter formularyID";
        }
        if (values.radioValue === "therapeutic" && values.initialTherapeticsectionFlag && values.selectedTherapeutic === "" || values.selectedTherapeutic == null || values.selectedTherapeuticSubclass === null) {
            if (!values.selectedTherapeutic) {
                formErrors.therapeutic = "Select therapeutic class";
            }
            formErrors.therapeuticSubclass = "Select therapeutic Subclass";
        }
       
        setErrors(formErrors);

        if (JSON.stringify(formErrors) !== "{}") {
            setSearchFormErrorAnalytics(formErrors);
           return true;
        }
        return false;
    }

    const setAdvancedFilterAnalytics = (rows) => {
        let formularyPlans = [];
        let drugNames = [];
        let therapeuticClassNames = [];
        let therapeuticSubClassNames = [];

        if (values.formularyPlan) formularyPlans.push(values.formularyPlan);
        if (values.drugName) drugNames.push(values.drugName);
        if (values.selectedTherapeutic) therapeuticClassNames.push(values.selectedTherapeutic);
        if (values.selectedTherapeuticSubclass) therapeuticSubClassNames.push(values.selectedTherapeuticSubclass);

         // track analytics for search - advanced filter
        const { tier, drugType, selectedChips } = values.filters;

        let cmsDrugTypes = [];

        if (drugType) cmsDrugTypes.push(drugType);
     
        const searchObj = {
            search : {
                "formularyPlan": formularyPlans,
                "drugName": drugNames,
                "therapeuticClass": therapeuticClassNames,
                "therapeuticSubclass":therapeuticSubClassNames,
                "tier": tier,
                "cmsDrugType": cmsDrugTypes,
                "results": rows.length,
                "resultRows": rows,
                "selectedChips": selectedChips
            }
        }
        dispatch(searchFilterSuccessAnalytics(searchObj))
    }

    const setSearchErrorAnalytics = (error) => {
         // for search track adobe analytics when clicked on submit
         // result is error
        let searchErrorObject = {
            error: {
              message: error.message,
              source: "formulary drug search",
              code: error?.response?.status ? error?.response?.status : 500,
              friendly: "There is an error with the services. Please try again later.",
              sourceURL: "home/formularylookup",
              errorType: 'API Error'
            }
          }
          dispatch(searchErrorAnalytics(searchErrorObject));
    }

    const fetchSearchResults = async () => {
        try {
            const fetchDrugsURL = getDrugs();
            const requestHeader = { "Content-Type": "application/json" };
            const correlationId = uuidv4();

            const apiBody = {
                searchInputMetaData: {
                  userId: process.env.REACT_APP_FORMULARY_ID,
                  customerId: process.env.REACT_APP_FORMULARY_ID,
                  applicationId: process.env.REACT_APP_FORMULARY_ID,
                  correlationId,
                  transactionId: null,
                  consumingAppId: process.env.REACT_APP_FORMULARY_CONSUMINGAPPID,
                },
                formularyId: values.formularyID.toLowerCase().includes("other")
                  ? values.other
                  : values.formularyID,
                userType: values.formularyPlan.toLowerCase().includes("medicare")
                  ? "PDP"
                  : "other",
                viewMode:
                  values.radioValue === "drugName"
                    ? "name"
                    : values.radioValue === "ndc"
                      ? "name"
                      : "class",
                drugName: values.drugName,
                ndc: values.ndc,
                therapeuticClass: values.selectedTherapeutic,
                therapeuticSubclass:  values.selectedTherapeuticSubclass,
            };

            const {
                data: { drugs = [] },
            } = await axios.post(fetchDrugsURL, apiBody, { headers: requestHeader });
            
            if (!drugs.length && submitClickCount.current === 1) {
                setAdvancedFilterAnalytics(drugs);
            }

            for(let index=0;index<drugs.length;index++)
            {
              let camelCaseSentence=camelCase(drugs[index]["tierDesc"]);
              drugs[index]["tierDesc"]=camelCaseSentence;
            }

            setValues((prevValues) => ({
                ...prevValues,
                drugResults: drugs,
            }));

        } catch (error) {
            setSearchErrorAnalytics(error);
            setIsErrorModalOpen(true);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(searchBtnLinkAnalytics());

        const formErrorOccured = validateSearchForm();

        if (!formErrorOccured) {
     
            // incrementing submit button click count
           submitClickCount.current = submitClickCount.current + 1;
           setSubmit({
            ...submit,
            formularyIDName: getFormularyIdName(values.formularyID) ,
            formularyID: values.formularyID,
            flag: true,
            other: values.other,
          });

          await fetchSearchResults();
        }
    
    }


    const getUpdatedTierDesc = (tier) => {
        if (tier.includes("All")) {
        return {};
        } else {
        return {
            tierDesc: {
            filterOperator: "ArrayIncludes", value: tier
            }
        }
        }
    }

    const getUpdateDrugFilterData = (drugType) => {
        if (drugType === "All") {
        return {};
        } else if (drugType === "Brand name") {
        return { boldedDrugFlag: { filterOperator: "=", value: "Brand" } };
        } else {
        return { boldedDrugFlag: { filterOperator: "=", value: drugType } };
        }
    }

    const getOtherFilters = (selectedChips) => {
        const othersFilterMap = {
        "PA required": "hasPriorAuthorization",
        "Quantity limits": "hasQuantityLimit",
        Specialty: ['Commercial/Exchange', 'Medicaid'].includes(values.formularyPlan) ? "specialtyFlag" : "isSpecialtyPharmacy",
        "Step therapy": "stepFlag",
        };
        return selectedChips.reduce((acc, chip) => {
        const filterKey = othersFilterMap[chip];
        if (filterKey === "hasQuantityLimit") {
            return {
            ...acc,
            [filterKey]: { filterOperator: "not in", value: "No" },
            };
        }
        return { ...acc, [filterKey]: { filterOperator: "=", value: "Yes" } };
        }, {});
    }


    const resultRows = useSelector((state) => state.drugSearchFormularyContent.drugResults);

    useEffect(() => {
        if (submitClickCount.current > 0) {
            setAdvancedFilterAnalytics(resultRows);
        }
      
    }, [resultRows]);

    const handleFilterCriteriaData = (filterData, filterChangeCount) => {
        // advance filter search
        setValues((prevValues) => ({...prevValues, foc: false}));

        const { tier, drugType, selectedChips } = filterData;
    
        const updatedTierFilterData = getUpdatedTierDesc(tier);
        const updatedDrugTypeFilterData = getUpdateDrugFilterData(drugType);
        const otherFilters = getOtherFilters(selectedChips);
        
        setValues((prevValues) => ({...prevValues, 
            filterCriteria: { 
                ...updatedTierFilterData, 
                ...updatedDrugTypeFilterData, 
                ...otherFilters 
            }}
        ));
    }

    const handleDrugSearchSelect = () => {
        const data = {
            innerText: 'Drug name',
        }
        dispatch(searchRadioLinkAnalytics(data));
    }

    const handleTherapeuticSelect = () => {
        const data = {
            innerText: 'Therapeutic class'
        }
        dispatch(searchRadioLinkAnalytics(data));
    }

    return {
        values,
        errors,
        submit,
        setValues,
        handleChange,
        handleSubmit,
        handleTherapeuticClassChange,
        handleTherapeuticSubclassChange,
        isErrorModalOpen,
        setIsErrorModalOpen,
        handleFilterCriteriaData,
        handleDrugSearchSelect,
        handleTherapeuticSelect,
    }
}

export default useFormularyDrugSearch;