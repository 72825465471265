import React from "react";
import { NavLink } from "react-router-dom";
import routePath from "../../../../constants/routes";
const RxNewsContent = () => {
  return (
    <React.Fragment>
      <h3 className="marT0">
        RxNews<sup>®</sup>
      </h3>

      <p dir="ltr" />

      <p dir="ltr" />

      <p dir="ltr" />
      <div>
        <p>
          <strong>
            <NavLink
              link-id="Clinical updates"
              to={
                routePath.clinicalPublication_RxNews + "?type=clinicalUpdates"
              }
            >
              Clinical updates
            </NavLink>
          </strong>
          <br />
          Features late-breaking developments in the fields of medicine and
          pharmacy, including landmark clinical trials and emerging evidence.
        </p>

        <p>
          <strong>
            <NavLink
              link-id="Drug recalls/shortages"
              to={routePath.clinicalPublication_RxNews + "?type=drugRecalls"}
            >
              Drug recalls/shortages
            </NavLink>
          </strong>
          <br />
          Updates from the Food and Drug Administration (FDA) about drug
          recalls, shorages and market withdrawals.
        </p>

        <p>
          <strong>
            <NavLink
              link-id="Drug safety"
              to={routePath.clinicalPublication_RxNews + "?type=drugSafety"}
            >
              Drug safety
            </NavLink>
          </strong>
          <br />
          Updates from the Food and Drug Administration (FDA) pertaining to
          safety-related drug labeling and MedWatch alerts.
        </p>

        <p>
          <strong>
            <NavLink
              link-id="Drug approvals"
              to={routePath.clinicalPublication_RxNews + "?type=drugApprovals"}
            >
              Drug approvals
            </NavLink>
          </strong>
          <br />
          Updates from the Food and Drug Administration (FDA) about new
          FDA-approved products, drug indications, dosage forms and labeling
          changes
        </p>

        <p>
          <strong>
            <NavLink
              link-id="New generics"
              to={routePath.clinicalPublication_RxNews + "?type=newGenerics"}
            >
              New generics
            </NavLink>
          </strong>
          <br />
          Details of recent and upcoming drug patent changes as well as new
          generic product releases.
        </p>
      </div>
      <p />
    </React.Fragment>
  );
};

export default RxNewsContent;
