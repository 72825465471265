import {
  REQUEST_SSO_REQUEST,
  REQUEST_SSO_SUCCESS,
  REQUEST_SSO_FAILURE,
} from "../actions/ssoRequest";

export function requestSSOData(
  state = {
    isFetching: false,
    sso: "",
  },
  action
) {
  switch (action.type) {
    case REQUEST_SSO_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        sso: "",
      });
    case REQUEST_SSO_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        sso: action.data,
      });
    case REQUEST_SSO_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default requestSSOData;
