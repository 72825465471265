import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
import { pageLinkAction } from "./tealium";
import axios from "axios";

export const FILES_BYTES_REQUEST = "FILES_BYTES_REQUEST";
export const FILES_BYTES_SUCCESS = "FILES_BYTES_SUCCESS";
export const FILES_BYTES_FAILURE = "FILES_BYTES_FAILURE";
export const FILES_BYTES_CACHED = "FILES_BYTES_CACHED";
export const FILES_BYTES_SUCCESS_B64_UPDATE = "FILES_BYTES_SUCCESS_B64_UPDATE";
export const FILES_BYTES_SUCCESS_B64_REQUEST =
  "FILES_BYTES_SUCCESS_B64_REQUEST";
var FileSaver = require("file-saver");

export function fileBytesFailure(message, responseCode, fileName) {
  return {
    type: FILES_BYTES_FAILURE,
    isFetched: false,
    isError: true,
    responseCode,
    message,
    fileName
  };
}

export function fileBytesResponse(data, status) {
  return {
    type: FILES_BYTES_SUCCESS,
    isFetched: false,
    data,
  };
}
export function fileBytesCached(data) {
  return {
    type: FILES_BYTES_CACHED,
    // isFetched: false,
    data,
  };
}

export function requestFileBytesB64(email) {
  const byteData = {};
  byteData["isFetching"] = true;
  byteData["bytes"] = null;
  byteData["email"] = email;
  return {
    type: FILES_BYTES_SUCCESS_B64_REQUEST,
    dataB64: byteData,
  };
}

export function fileBytesResponseB64(dataB64, email) {
  const byteData = {};
  byteData["bytes"] = dataB64;
  byteData["isFetching"] = false;
  byteData["email"] = email;
  return {
    type: FILES_BYTES_SUCCESS_B64_UPDATE,
    isFetchedB64: false,
    dataB64: byteData,
  };
}

export function fileBytesFailureB64(messageB64, email) {
  const byteData = {};
  byteData["bytes"] = null;
  byteData["isFetching"] = false;
  byteData["email"] = email;
  return {
    type: FILES_BYTES_SUCCESS_B64_UPDATE,
    isFetchedB64: false,
    isErrorB64: true,
    messageB64,
    dataB64: byteData,
  };
}

export function getFilesFromBytes(req, fileName) {
  return (dispatch) => {
    dispatch(pageLinkAction(fileName, "d", ""));
    const url = fetchGeneralContentUrl("/cms/getFileArray");
    http
      .post(url, JSON.stringify(req), { responseType: "blob" })
      .then((response) => {
        if (response.status !== 200) {
          dispatch(fileBytesFailure(response, response.status, fileName));
          return Promise.reject();
        } else {
          var file = new Blob([response.data], {
            type: "application/pdf",
          });
          dispatch(fileBytesResponse(response.data, response.status));
          FileSaver.saveAs(file, fileName);
          dispatch(fileBytesCached(response.data));
          return response.data;
        }
      })
      .catch(function (error) {
        dispatch(fileBytesFailure(error, error && error.response && error.response.status, fileName));
      });
  };
}

export function getFilesFromBytesB64(req, email) {
  if (req) {
    return (dispatch) => {
      dispatch(requestFileBytesB64(email));
      const url = fetchGeneralContentUrl("/cms/getFileArrayB64");
      http
        .post(url, JSON.stringify(req))
        .then((response) => {
          const data = response.data;
          if (response.status !== 200) {
            dispatch(fileBytesFailureB64(data.message, email));
            return Promise.reject(data);
          } else {
            if (data && data.data) {
              dispatch(fileBytesResponseB64(data.data, email));
              return data.data;
            } else {
              dispatch(fileBytesResponseB64(null, email));
            }
          }
          return data;
        })
        .catch(function (error) {
          dispatch(fileBytesFailureB64(error));
        });
    };
  }
  return null;
}

export function getBasicFilesFromBytesB64(req, name) {
  const requestHeader = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(req),
  };
  return (dispatch) => {
    dispatch(requestFileBytesB64(name));
    const url = fetchGeneralContentUrl("/cms/getFileArrayB64");
    return fetch(url, requestHeader)
      .then((response) => response.json().then((data) => ({ data, response })))
      .then(({ data, response }) => {
        if (!response.ok) {
          dispatch(fileBytesFailureB64(data.message, name));
          return Promise.reject(data);
        } else {
          if (data) {
            dispatch(fileBytesResponseB64(data.data, name));
            return data.data;
          }
        }
        return data;
      })
      .catch((err) => {
        dispatch(fileBytesFailureB64(err));
      });
  };
}
