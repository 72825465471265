import React from "react";
import { connect } from "react-redux";
import globalConstants from "../../constants/global-constants";
import { onRxTrackSSO } from "../../actions/rxTrackSSO";
class RxTrackSSO extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      rxTrackData: "",
      rxTrackId: "",
      isRxTrackSSoLoading: true,
    };
  }

  componentDidMount() {
    this.setData(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setData(newProps);
  }

  setData(props) {
    const {
      rxTrackId,
      rxTrackSSO,
      match: { params },
    } = props;
    const userType =
      sessionStorage.getItem("type") === "msid" ? "INTERNAL" : "EXTERNAL";
    let productType;
    if (params && params.product && params.product === "RxTrack") {
      productType = "RXTRACK";
    } else if (params && params.product && params.product === "RxTrack IDW") {
      productType = "RXTRACKIDW";
    } else {
      productType = "RXTRACKCONFIDENTIAL";
    }

    if (rxTrackId) {
      this.setState({ rxTrackId });
      if (!rxTrackSSO.isFetching) {
        if (rxTrackSSO.rxTrackSSOData) {
          this.setState({
            rxTrackData: rxTrackSSO.rxTrackSSOData,
            isRxTrackSSoLoading: false,
          });
          this.hitEventListener(rxTrackSSO.rxTrackSSOData, rxTrackId, props);
        } else {
          if (userType && productType) {
            this.props.dispatch(onRxTrackSSO(rxTrackId, userType, productType));
          }
        }
      }
    } else {
      this.setState({
        isRxTrackSSoLoading: false,
      });
    }
  }

  hitEventListener(rxTrackData, rxTrackId, props) {
    if (rxTrackData) {
      this.addListener(rxTrackData, rxTrackId, props);

      if (
        JSON.stringify(rxTrackData) !== JSON.stringify(this.state.rxTrackData)
      ) {
        this.setState({ rxTrackData });
      }
    }
  }

  addListener(rxTrackData, rxTrackId, props) {
    if (
      rxTrackData &&
      rxTrackData !== globalConstants.UNABLETOGETTEXT &&
      rxTrackId
    ) {
      if (props) {
        this.containerRef &&
          this.containerRef.current &&
          this.containerRef.current.submit();
      }
    }
  }

  render() {
    const { rxTrackData, rxTrackId } = this.state;
    const { isRxTrackSSoLoading } = this.props;
    if (isRxTrackSSoLoading) {
      return <p>Loading...</p>;
    } else {
      if (!rxTrackId) {
        return (
          <React.Fragment>
            <h1>Unable to access Rxtrack</h1>
            <div>
              <span class="error">
                <p dir="ltr">
                  Unable to access Rxtrack. Please contact your Account Manager
                  for escalation
                </p>
              </span>
            </div>
          </React.Fragment>
        );
      } else if (rxTrackData === globalConstants.UNABLETOGETTEXT) {
        return (
          <React.Fragment>
            <h1>Service error</h1>
            <div>
              <span class="error">
                <p dir="ltr">
                  There is some error with the services. Please try after
                  sometime.
                </p>
              </span>
            </div>
          </React.Fragment>
        );
      } else if (rxTrackData && rxTrackId) {
        return (
          <form
            method="post"
            ref={this.containerRef}
            action={rxTrackData.actionUrl || ""}
          >
            <input
              type="hidden"
              name="PARTNER"
              value={rxTrackData.partnerId || ""}
            />
            <input
              type="hidden"
              name="TARGET"
              value={rxTrackData.targetResource || ""}
            />
            <input
              type="hidden"
              name="opentoken"
              value={rxTrackData.openToken || ""}
            />
          </form>
        );
      }
      return null;
    }
  }
}

const mapStateToProps = ({ rxTrackSSO }) => {
  return { rxTrackSSO };
};
export default connect(mapStateToProps)(RxTrackSSO);
