import { fetchInvoiceMonths } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const GET_INVOICE_MONTHS_REQUEST = "GET_INVOICE_MONTHS_REQUEST";
export const GET_INVOICE_MONTHS_SUCCESS = "GET_INVOICE_MONTHS_SUCCESS";
export const GET_INVOICE_MONTHS_FAILURE = "GET_INVOICE_MONTHS_FAILURE";

export function requestInvoiceMonth() {
  return {
    type: GET_INVOICE_MONTHS_REQUEST,
    isFetched: false,
  };
}

export function receiveInvoiceMonth(data) {
  return {
    type: GET_INVOICE_MONTHS_SUCCESS,
    isFetched: true,
    data,
  };
}

export function invoiceMonthFailure(message) {
  return {
    type: GET_INVOICE_MONTHS_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getInvoiceMonths() {
  return (dispatch) => {
    dispatch(requestInvoiceMonth());
    const url = fetchInvoiceMonths();
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(invoiceMonthFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveInvoiceMonth(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(invoiceMonthFailure(error));
      });
  };
}
