import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const GET_CLINICAL_PUBLICATION_REQUEST =
  "GET_CLINICAL_PUBLICATION_REQUEST";
export const GET_CLINICAL_PUBLICATION_SUCCESS =
  "GET_CLINICAL_PUBLICATION_SUCCESS";
export const GET_CLINICAL_PUBLICATION_FAILURE =
  "GET_CLINICAL_PUBLICATION_FAILURE";
export const GET_CLINICAL_PUBLICATION_UPDATE =
  "GET_CLINICAL_PUBLICATION_UPDATE";

export function requestClinicalPublicationData(subType, type, key) {
  const response = {};
  response["pageType"] = type;
  response["pageSubType"] = subType;
  response["isFetching"] = true;
  response["key"] = key;
  response["value"] = {};
  return {
    type: GET_CLINICAL_PUBLICATION_REQUEST,
    data: response,
  };
}

export function receiveClinicalPublicationData(subType, data, type, key) {
  const response = {};
  response["pageType"] = type;
  response["pageSubType"] = subType;
  response["key"] = key;
  response["isFetching"] = false;
  response["value"] = data;
  return {
    type: GET_CLINICAL_PUBLICATION_UPDATE,
    data: response,
  };
}

export function clinicalPublicationDataFailure(subType, message, type, key) {
  const response = {};
  response["pageType"] = type;
  response["pageSubType"] = subType;
  response["key"] = key;
  response["isFetching"] = false;
  response["value"] = null;
  return {
    type: GET_CLINICAL_PUBLICATION_UPDATE,
    message,
  };
}

export function getRxOutlook(subType, type, key) {
  return (dispatch) => {
    dispatch(requestClinicalPublicationData(subType, type, key));
    const url = fetchGeneralContentUrl("/aem/rxOutlook");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(
            clinicalPublicationDataFailure(subType, data.message, type, key)
          );
          return Promise.reject(data);
        } else {
          if (data && data.resources) {
            dispatch(receiveClinicalPublicationData(subType, data, type, key));
          }
          return data;
        }
      })
      .catch(function (error) {
        dispatch(clinicalPublicationDataFailure(subType, error, type, key));
      });
  };
}

export function getRxHighlights(subType, type, key) {
  return (dispatch) => {
    dispatch(requestClinicalPublicationData(subType, type, key));
    const url = fetchGeneralContentUrl("/aem/rxHighliights");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(
            clinicalPublicationDataFailure(subType, data.message, type, key)
          );
          return Promise.reject(data);
        } else {
          if (data && data.resources) {
            dispatch(receiveClinicalPublicationData(subType, data, type, key));
          }
          return data;
        }
      })
      .catch(function (error) {
        dispatch(clinicalPublicationDataFailure(subType, error, type, key));
      });
  };
}

export function getClinicalUpdate(subType, type, key) {
  return (dispatch) => {
    dispatch(requestClinicalPublicationData(subType, type, key));
    const url = fetchGeneralContentUrl("/aem/clinicalupdates");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(
            clinicalPublicationDataFailure(subType, data.message, type, key)
          );
          return Promise.reject(data);
        } else {
          if (data && data.resources) {
            dispatch(receiveClinicalPublicationData(subType, data, type, key));
          }
        }
        return data;
      })
      .catch(function (error) {
        dispatch(clinicalPublicationDataFailure(subType, error, type, key));
      });
  };
}

export function getDrugRecalls(subType, type, key) {
  return (dispatch) => {
    dispatch(requestClinicalPublicationData(subType, type, key));
    const url = fetchGeneralContentUrl("/aem/rxnewsdrugcalls");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(
            clinicalPublicationDataFailure(subType, data.message, type, key)
          );
          return Promise.reject(data);
        } else {
          if (data && data.resources) {
            dispatch(receiveClinicalPublicationData(subType, data, type, key));
          }
          return data;
        }
      })
      .catch(function (error) {
        dispatch(clinicalPublicationDataFailure(subType, error, type, key));
      });
  };
}

export function getDrugApprovals(subType, type, key) {
  return (dispatch) => {
    dispatch(requestClinicalPublicationData(subType, type, key));
    const url = fetchGeneralContentUrl("/aem/rxnewsdrugapprovals");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(
            clinicalPublicationDataFailure(subType, data.message, type, key)
          );
          return Promise.reject(data);
        } else {
          if (data && data.resources) {
            dispatch(receiveClinicalPublicationData(subType, data, type, key));
          }
        }
        return data;
      })
      .catch(function (error) {
        dispatch(clinicalPublicationDataFailure(subType, error, type, key));
      });
  };
}

export function getDrugSafety(subType, type, key) {
  return (dispatch) => {
    dispatch(requestClinicalPublicationData(subType, type, key));
    const url = fetchGeneralContentUrl("/aem/drugsafety");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(
            clinicalPublicationDataFailure(subType, data.message, type, key)
          );
          return Promise.reject(data);
        } else {
          if (data && data.resources) {
            dispatch(receiveClinicalPublicationData(subType, data, type, key));
          }
        }
        return data;
      })
      .catch(function (error) {
        dispatch(clinicalPublicationDataFailure(subType, error, type, key));
      });
  };
}

export function getNewGenerics(subType, type, key) {
  return (dispatch) => {
    dispatch(requestClinicalPublicationData(subType, type, key));
    const url = fetchGeneralContentUrl("/aem/newgenerics");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(
            clinicalPublicationDataFailure(subType, data.message, type, key)
          );
          return Promise.reject(data);
        } else {
          if (data && data.resources) {
            dispatch(receiveClinicalPublicationData(subType, data, type, key));
          }
          return data;
        }
      })
      .catch(function (error) {
        dispatch(clinicalPublicationDataFailure(subType, error, type, key));
      });
  };
}
