import { makeStyles } from "@material-ui/core";

export const AdvancedFiltersStyles = makeStyles((theme) => ({
  wrapper: {
    width: "1400px",
    borderRadius: "10px",
    backgroundColor: theme.palette.grey[50],
    maxWidth: "1400px",
    padding: theme.spacing(3),
  },
  othersContainer: {
    marginTop: theme.spacing(5),
    minWidth: "300px",
  },
  title: {
    color: theme.customPalette.color.grey2,
  },
  subtitle: {
    color: theme.customPalette.color.grey2,
  },
  buttonContainer: {
    marginTop: "56px",
    marginLeft: "56px",
    "@media (max-width:960px)": {
      marginTop: "20px",
      marginLeft: "20px",
    },
  },
}));
