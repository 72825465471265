import {
  GET_ANNOUNCEMENT_REQUEST,
  GET_ANNOUNCEMENT_SUCCESS,
  GET_ANNOUNCEMENT_FAILURE,
} from "../actions/Announcement";

export function getAnnouncements(
  state = {
    isFetching: false,
    announcementData: "",
  },
  action
) {
  switch (action.type) {
    case GET_ANNOUNCEMENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        announcementData: "",
      });
    case GET_ANNOUNCEMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        announcementData: action.data,
      });
    case GET_ANNOUNCEMENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default getAnnouncements;
