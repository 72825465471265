import { fetchVideoContentURL } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const VIDEO_REQUEST = "VIDEO_REQUEST";
export const VIDEO_SUCCESS = "VIDEO_SUCCESS";
export const VIDEO_FAILURE = "VIDEO_FAILURE";

export function requestVideo() {
  return {
    type: VIDEO_REQUEST,
  };
}

export function receiveVideo(data, pageId, pageType) {
  const videoRespose = {};
  videoRespose["pageId"] = pageId;
  videoRespose["videoMetaData"] = data;
  videoRespose["pageType"] = pageType;
  return {
    type: VIDEO_SUCCESS,
    data: videoRespose,
  };
}

export function videoFailure(message, pageId, pageType) {
  const videoRespose = {};
  videoRespose["pageId"] = pageId;
  videoRespose["videoMetaData"] = [];
  videoRespose["pageType"] = pageType;
  return {
    type: VIDEO_FAILURE,
    message,
    data: videoRespose,
  };
}

export function getVideo(pageId, pageType) {
  if (pageId) {
    return (dispatch) => {
      dispatch(requestVideo());
      const url = fetchVideoContentURL(pageId);
      http
        .get(url)
        .then((response) => {
          const data = response.data;
          if (response.status !== 200) {
            dispatch(videoFailure(data.message, pageId, pageType));
            return Promise.reject(data);
          } else {
            dispatch(receiveVideo(data, pageId, pageType));
            return data;
          }
        })
        .catch(function (error) {
          dispatch(videoFailure(error, pageId, pageType));
        });
    };
  }
  return null;
}
