import React from "react";
import { connect } from "react-redux";
import { getAccountProductForInternalUser } from "../actions/internalEmployeeProducts";
import { Scrollbars } from "react-custom-scrollbars";
import MyProducts from "../components/Applications/MyProducts/MyProducts";
import ProductOffering from "../components/Applications/ProductOffering/ProductOffering";
import { isInternal } from "../constants/service";
import UserType from "../constants/UserType";
import ErrorText from "../components/Shared/ErrorText/ErrorText";

class InternalProductContainer extends React.Component {
  state = {
    internalProductData: {},
    internalProductLoading: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getInternalProductsForEmployee(nextProps);
  }

  componentDidMount() {
    this.getInternalProductsForEmployee(this.props);
  }

  getInternalProductsForEmployee(nextProps) {
    const { selectedAccount, internalEmployeeProducts, user, auth } = nextProps;
    if (isInternal(user)) {
      this.configureForInternal(internalEmployeeProducts, auth);
    } else {
      this.configureForInternalManager(
        selectedAccount,
        internalEmployeeProducts,
        auth
      );
    }
  }

  configureForInternalManager(selectedAccount, internalEmployeeProducts, auth) {
    if (selectedAccount) {
      if (selectedAccount && selectedAccount.u_account) {
        if (internalEmployeeProducts) {
          const internalProductData = this.configProductData(
            selectedAccount.u_account,
            internalEmployeeProducts.internalProducts
          );
          if (internalProductData) {
            if (
              internalProductData &&
              internalProductData.internalProductList
            ) {
              this.setState({
                internalProductData,
                internalProductLoading: true,
              });
            }
          } else {
            this.props.dispatch(getAccountProductForInternalUser());
          }
        }
      }
    }
  }

  configureForInternal(internalEmployeeProducts, auth) {
    if (internalEmployeeProducts) {
      const internalProductData = this.configProductData(
        UserType.INTERNAL_DEFAULT_TYPE,
        internalEmployeeProducts.internalProducts
      );
      if (internalProductData) {
        if (internalProductData && internalProductData.internalProductList) {
          this.setState({
            internalProductData,
            internalProductLoading: true,
          });
        }
      } else {
        this.props.dispatch(getAccountProductForInternalUser());
      }
    }
  }

  configProductData(accountID, products) {
    return (
      products &&
      products.length > 0 &&
      products.find((item) => {
        return item && item.accountId === accountID;
      })
    );
  }

  render() {
    const { internalProductData, internalProductLoading } = this.state;
    const { type } = this.props;
    if (!internalProductLoading) {
      return (
        <React.Fragment>
          <div align="center">Loading data...</div>
        </React.Fragment>
      );
    } else {
      if (
        internalProductData &&
        internalProductData.internalProductList &&
        internalProductData.internalProductList.length > 0
      ) {
        var productList = internalProductData.internalProductList;
        return (
          <Scrollbars style={{ width: 940, height: 500, marginTop: 16 }}>
            {productList && type === "MyProduct" && (
              <MyProducts productList={productList} />
            )}
            {productList && type === "ProductOffering" && (
              <ProductOffering productOfferingList={productList} />
            )}
          </Scrollbars>
        );
      } else {
        return <ErrorText />;
      }
    }
  }
}

const mapStateToProps = ({
  internalEmployeeProducts,
  userAccount,
  user,
  auth,
}) => {
  return {
    internalEmployeeProducts,
    selectedAccount: userAccount.selectedAccount,
    user,
    auth,
  };
};

export default connect(mapStateToProps)(InternalProductContainer);
