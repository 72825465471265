import { makeStyles, createStyles } from "@material-ui/core";

const DrugResultsStyle = makeStyles((theme) =>
  createStyles({
    odd: {
      backgroundColor: theme.palette.info.light,
    },
    even: {
      backgroundColor: theme.palette.background.default,
    },
    brandName: {
      color: theme.customPalette.color.blue2,
      fontWeight: theme.typography.h1.fontWeight,
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
    },
    cell: {
      color: theme.customPalette.color.grey2,
      fontWeight: theme.typography.body1.fontWeight,
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
    },
  })
);
export default DrugResultsStyle;
