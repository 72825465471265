import React from "react";
import videojs from "video.js";
import { connect } from "react-redux";
import "./videojs-resolution-switcher.scss";
import { videoAction } from "../../../actions/tealium";
if (window) {
  window.videojs = videojs;
}
require("../../lib/videojs-resolution-switcher");

class VideoPlayer extends React.Component {
  componentDidMount() {
    // instantiate Video.
    const { dispatch } = this.props;
    const videoObj = this.createVideoObject(this.props);
    const srcUpdate = this.createUpdateSrc(this.props);
    this.player = videojs(this.videoNode, videoObj, function onPlayerReady() {
      this.updateSrc(srcUpdate);
      this.on("resolutionchange", function () {
        // console.info("Source changed to %s", self.src());
      });
    });
    this.player.on("playing", function (e) {
      e.preventDefault();
      const currentTime = Number(this.player_.currentTime());
      const status = this.player_.paused() ? "[pause]" : "[play]";
      const length = this.player_.duration()
        ? `${"[" + this.player_.duration() + "]"}`
        : "[0]";
      const name = videoObj.title ? videoObj.title : "";
      dispatch(videoAction(status, length, name, "Video Start", currentTime));
      // you customized callback
    });

    this.player.on("pause", function (e) {
      e.preventDefault();
      const currentTime = Number(this.player_.currentTime());
      const status = this.player_.paused() ? "[pause]" : "[play]";
      // const length = Number(this.player_.duration())
      //   ? `${"[" + this.player_.duration() + "]"}`
      //   : "[0]";
      // const name = videoObj.title ? videoObj.title : "";
      var percentViewed = Math.floor(
        (currentTime / this.player_.duration()) * 100
      );
     /*  if (percentViewed < 25) {
        if (status === "[pause]") {
          dispatch(
            videoAction(status, length, name, "Video Paused", currentTime)
          );
        } else {
          dispatch(
            videoAction(status, length, name, "Video Start", currentTime)
          );
        }
      } */
      // you customized callback
    });

    this.player._isEventViewed = {};
    this.player.on("timeupdate", function () {
      var currentTime = Number(this.player_.currentTime());
      var duration = Number(this.player_.duration());
      const status = this.player_.paused() ? "[pause]" : "[play]";
      const length = duration ? `${"[" + duration + "]"}` : "[0]";
      const name = videoObj.title ? videoObj.title : "";
      var percentViewed = Math.floor((currentTime / duration) * 100);
      var ev = this._isEventViewed;
       if (percentViewed < 2) {
        if (status === "[pause]") {
          dispatch(
            videoAction(status, length, name, "Video Paused", currentTime)
          );
        }
        //  else {
        //   dispatch(
        //     videoAction(status, length, name, "Video Start", currentTime)
        //   );
        // }
      } 
      switch (true) {
        case !ev["25"] && percentViewed >= 25:
          ev["25"] = true;
          dispatch(videoAction(status, length, name, "Video 25%", currentTime));
          break;
        case !ev["50"] && percentViewed >= 50:
          ev["50"] = true;
           dispatch(videoAction(status, length, name, "Video 50%", currentTime));
          break;
        case !ev["75"] && percentViewed >= 75:
          ev["75"] = true;
          dispatch(videoAction(status, length, name, "Video 75%", currentTime));
          break;
      } // end switch percentViewed
    });

    this.player.on("ended", function () {
      if (!this._isEventViewed.ended) {
         var currentTime = Number(this.player_.currentTime());
          var duration = Number(this.player_.duration());
           const status = this.player_.paused() ? "[pause]" : "[play]";
           const length = duration ? `${"[" + duration + "]"}` : "[0]";
           const name = videoObj.title ? videoObj.title : "";
        this._isEventViewed.ended = true;
        this.player._isEventViewed = {};
        dispatch(
          videoAction(status, length, name, "Video Complete", currentTime)
        );
      }
    });
  }

  createVideoObject(props) {
    const { poster, title } = props;
    const videoObj = {};
    videoObj["poster"] = poster;
    videoObj["title"] = title;
    videoObj["plugins"] = {
      videoJsResolutionSwitcher: {
        default: "high",
        dynamicLabel: true,
        controls: true,
      },
    };
    return videoObj;
  }

  createUpdateSrc(props) {
    const { videoUrlLow, videoUrlHigh, type } = props;
    const sources = [];
    if (videoUrlLow) {
      const videoSourceLow = {};
      videoSourceLow["src"] = videoUrlLow;
      videoSourceLow["type"] = type;
      videoSourceLow["label"] = "Low";
      sources.push(videoSourceLow);
    }
    if (videoUrlHigh) {
      const videoSourceHigh = {};
      videoSourceHigh["src"] = videoUrlHigh;
      videoSourceHigh["type"] = type;
      videoSourceHigh["label"] = "High";
      sources.push(videoSourceHigh);
    }
    return sources;
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    const { title } = this.props;
    return (
      <>
        <article className="video">
          <figure className="video play">
            <div>
              <div data-vjs-player>
                <video
                  ref={(node) => (this.videoNode = node)}
                  width="258"
                  height="145"
                  className="video-js vjs-default-skin vjs-big-play-centered"
                  preload="auto"
                  autoPlay={false}
                  controls={true}
                  link-id={title}
                />
              </div>
            </div>
          </figure>
          <div className="caption">{title}</div>
        </article>
      </>
    );
  }
}

const mapStateToProps = ({ dispatch }) => {
  return { dispatch };
};
export default connect(mapStateToProps)(VideoPlayer);
