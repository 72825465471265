export const REQUEST_PRODUCT_DATA = "REQUEST_PRODUCT_DATA";
export const RECEIVED_PRODUCT_DATA = "RECEIVED_PRODUCT_DATA";
export const SET_SELECTED_PRODUCT_DATA = "SET_SELECTED_PRODUCT_DATA";

function requestProductData() {
  return {
    type: REQUEST_PRODUCT_DATA,
    loading: true
  };
}

function receivedProductData(myProduct) {
  return {
    type: RECEIVED_PRODUCT_DATA,
    loading: false,
    payload: myProduct
  };
}

function setSelectedProductData(myProduct, selectedProduct) {
  return {
    type: SET_SELECTED_PRODUCT_DATA,
    loading: false,
    payload: { myProduct: myProduct, selectedProduct: selectedProduct }
  };
}

export function onRequestProductData() {
  return dispatch => {
    //TODO: Fetching the My Product data
    dispatch(requestProductData());
  };
}

export function onReceivedProductData(myProduct, selectedProduct) {
  return dispatch => {
    dispatch(receivedProductData(myProduct));
    dispatch(setSelectedProductData(myProduct, selectedProduct));
  };
}

