import React from "react";
// import ComodoSecure from "./Comodo/ComodoSecure";
import Skeleton from "@material-ui/lab/Skeleton";
import Content from "../Shared/Content/Content";
import "./PreLoginFooter.scss";

const PreLoginFooter = ({
  contentData,
  byteInfoData,
  hrefData,
  isFetching
}) => {
  return (
    <section className="subNav clearfix padT50 padB30 milli">
      <div className="row-">
        {byteInfoData && byteInfoData.bytes && (
          <div>
            <figure className="col-sm-3">
              <a
                link-id="Optum"
                href={hrefData}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    byteInfoData && byteInfoData.bytes
                      ? `data:image/png;base64, ${byteInfoData.bytes}`
                      : ""
                  }
                  height="44"
                  width="146"
                  alt=""
                />
              </a>
            </figure>
          </div>
        )}
        {(!byteInfoData.bytes || isFetching) && (
          <ul className="row inlineBlock w100 padT15 padB15 social">
            <li>
              {!byteInfoData.bytes && (
                <div>
                  <Skeleton
                    variant="rect"
                    width={120}
                    height={70}
                    className="imageLoader"
                  />
                </div>
              )}
            </li>
            <li>
              {isFetching && (
                <div style={{ marginLeft: 100 }}>
                  <ul>
                    <li>
                      <Skeleton variant="text" width={150} height={20} />
                      <Skeleton variant="text" width={150} height={20} />
                      <Skeleton variant="text" width={150} height={20} />
                      <Skeleton variant="text" width={150} height={20} />
                    </li>
                    <li>
                      <div style={{ marginLeft: 50 }}>
                        <Skeleton variant="text" width={100} height={20} />
                        <Skeleton variant="text" width={100} height={20} />
                        <Skeleton variant="text" width={100} height={20} />
                        <Skeleton variant="text" width={100} height={20} />
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </li>
          </ul>
        )}
        {contentData && contentData.body && (
          <div
            style={{
              marginLeft: !byteInfoData.bytes ? 170 : 0,
              marginTop: -105
            }}
          >
            <Content html={contentData.body} />
          </div>
        )}

        {/* <ul className="col-sm-3">
          <li className="posRel">
            <ComodoSecure />
          </li>
        </ul> */}
      </div>
    </section>
  );
};

export default PreLoginFooter;