import { downloadInvoiceDocuments } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const DOWNLOAD_INVOICE_REQUEST = "DOWNLOAD_INVOICE_REQUEST";
export const DOWNLOAD_INVOICE_SUCCESS = "DOWNLOAD_INVOICE_SUCCESS";
export const DOWNLOAD_INVOICE_FAILURE = "DOWNLOAD_INVOICE_FAILURE";
export const CLEAR_RESPONSE_FOR_DOWNLOAD = "CLEAR_RESPONSE_FOR_DOWNLOAD";
var FileSaver = require("file-saver");

export function downloadRequest(documentName, displayName) {
  const downloadResponse = {};
  downloadResponse["documentName"] = documentName;
  downloadResponse["isFetching"] = true;
  downloadResponse["responseCode"] = null;
  downloadResponse["displayName"] = displayName;
  return {
    type: DOWNLOAD_INVOICE_SUCCESS,
    isFetched: false,
    downloadResponse
  };
}

export function downloadFailure(documentName, displayName, responseCode) {
  const downloadResponse = {};
  downloadResponse["documentName"] = documentName;
  downloadResponse["isFetching"] = false;
  downloadResponse["isError"] = true;
  downloadResponse["responseCode"] = responseCode;
  downloadResponse["displayName"] = displayName;
  return {
    type: DOWNLOAD_INVOICE_SUCCESS,
    isFetched: false,
    isError: true,
    responseCode,
    downloadResponse
  };
}

export function downloadSuccess(documentName, displayName, responseCode) {
  const downloadResponse = {};
  downloadResponse["documentName"] = documentName;
  downloadResponse["isFetching"] = false;
  downloadResponse["responseCode"] = responseCode;
  downloadResponse["displayName"] = displayName;
  return {
    type: DOWNLOAD_INVOICE_SUCCESS,
    isFetched: false,
    isError: false,
    responseCode,
    downloadResponse
  };
}

export function clearDownloadResponse(reportName, documentName) {
  return {
    type: CLEAR_RESPONSE_FOR_DOWNLOAD,
    reportName,
    documentName
  };
}

export function getInvoiceDownload(req, documentName, displayName) {
  return dispatch => {
    dispatch(downloadRequest(documentName, displayName));
    const url = downloadInvoiceDocuments();
    http
      .post(url, JSON.stringify(req), { responseType: "blob" })
      .then(response => {
        if (response.status === 200) {
          dispatch(downloadSuccess(documentName, displayName, response.status));
          var file = new Blob([response.data], {
            type: "application/pdf"
          });
          FileSaver.saveAs(file, documentName);
          return response.data;
        } else {
          dispatch(downloadFailure(documentName, displayName, response.status));
          return Promise.reject();
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status) {
          dispatch(
            downloadFailure(documentName, displayName, error.response.status)
          );
        } else {
          dispatch(downloadFailure(documentName, displayName, ""));
        }
      });
  };
}
