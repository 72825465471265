import React from "react";
import ProductOfferingContainer from "../../../containers/ProductOfferingContainer";
const ProductOffering = ({ productOfferingList }) => {
  return (
    <React.Fragment>
      <div className="tab">
        <div className="container30">
          <div id="productOffering" className="tab-content">
            <ul className="proList" name="myProduct" id="myProductContainer">
              {productOfferingList &&
                productOfferingList.length > 0 &&
                productOfferingList.map(productOfferingData => (
                  <ProductOfferingContainer
                    key={
                      productOfferingData.u_number ||
                      productOfferingData.productDetailURL
                    }
                    productOfferingData={productOfferingData}
                  />
                ))}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductOffering;
