import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const NOTIFY_ERROR = "NOTIFY_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const NETWORK_CONTENT_REQUEST = "NETWORK_CONTENT_REQUEST";
export const NETWORK_CONTENT_SUCCESS = "NETWORK_CONTENT_SUCCESS";
export const NETWORK_CONTENT_FAILURE = "NETWORK_CONTENT_FAILURE";

export function notifyNetworkErr(metaData) {
  return {
    type: NOTIFY_ERROR,
    metaData
  };
}
export function clearNetworkErr() {
  return {
    type: CLEAR_ERROR
  };
}

function requestNetworkContent() {
  return {
    type: NETWORK_CONTENT_REQUEST,
    isFetched: false
  };
}

function receiveNetworkContent(logo) {
  return {
    type: NETWORK_CONTENT_SUCCESS,
    isFetched: true,
    logo
  };
}

function NetworkContentFailure(message) {
  return {
    type: NETWORK_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message
  };
}

export function getNetworkContent() {
  return dispatch => {
    dispatch(requestNetworkContent());
    const url = fetchGeneralContentUrl("/cms/getNodeByCode/page/NetworkError");
    http
      .get(url)
      .then(response => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(NetworkContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveNetworkContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(NetworkContentFailure(error));
      });
  };
}
