import {
  COPYRIGHT_CONTENT_REQUEST,
  COPYRIGHT_CONTENT_SUCCESS,
  COPYRIGHT_CONTENT_FAILURE,
} from "../actions/copyRight";

export function copyRightContent(
  state = {
    isFetching: false,
    copyRight: "",
  },
  action
) {
  switch (action.type) {
    case COPYRIGHT_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        copyRight: "",
      });
    case COPYRIGHT_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        copyRight: action.copyRightData,
      });
    case COPYRIGHT_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default copyRightContent;
