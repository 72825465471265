import React from "react";
import { NavLink } from "react-router-dom";
import routePath from "../../../../constants/routes";

const GovernmentProgramsRelatedLinksLN = ({ type, subType }) => {
  return (
    <React.Fragment>
      <strong
        style={{ color: "#006ca2" }}
        className={
          type === "EmployerGroupWaiverPlansLN" && !subType ? "selected" : ""
        }
      >
        Government Programs
      </strong>
      <ul>
        <li
          data-testid="EGWPlan"
          className={
            type === "GPRelatedLinksEmployerGroupWaiverPlans" ? "selected" : ""
          }
        >
          <NavLink
            link-id="Employer Group Waiver Plans"
            to={routePath.relatedLink_EmployerGroupWaiverPlans}
          >
            Employer Group Waiver Plans
          </NavLink>
        </li>

        <li
          data-testid="HIExchnage"
          className={
            type === "GPRelatedLinksHealthInsuranceExchanges" ? "selected" : ""
          }
        >
          <NavLink
            link-id="Health Insurance Exchanges"
            to={routePath.relatedLink_HealthInsuranceExchanges}
          >
            Health Insurance Exchanges
          </NavLink>
        </li>

        <li
          data-testid="Managed"
          className={type === "GPRelatedLinksManagedMedicaid" ? "selected" : ""}
        >
          <NavLink
            link-id="Managed Medicaid"
            to={routePath.relatedLink_ManagedMedicaid}
          >
            Managed Medicaid
          </NavLink>
        </li>

        <li
          data-testid="Medicare"
          className={type === "GPRelatedLinksMedicarePartD" ? "selected" : ""}
        >
          <NavLink
            link-id="Medicare Part D"
            to={routePath.relatedLink_MedicarePartD}
          >
            Medicare Part D
          </NavLink>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default GovernmentProgramsRelatedLinksLN;
