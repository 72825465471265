import React from "react";
import { Autocomplete, } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { AutoCompleteStyles } from './AutoCompleteStyles';

const AutoComplete = ({ disabled, value, options, getOptionLabel, onChange, placeholderText, error, inputRef, id }) => {
  const classes = AutoCompleteStyles();
  return (
    <Autocomplete
      disabled={disabled}
      disablePortal
      value={value}
      options={options}
      id={id}
      getOptionLabel={getOptionLabel}
      className={classes.autoCompleteSelectBox}
      onChange={onChange}
      renderInput={(params) => <TextField
        placeholder={placeholderText}  {...params} error={error} inputRef={inputRef} />}
    />
  );
};

export default AutoComplete;
