import {
  GET_RESOURCE_FAILURE,
  GET_RESOURCE_REQUEST,
  GET_RESOURCE_SUCCESS,
  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAILURE,
} from "../actions/resourceAndNews";

export function resourceAndNews(
  state = {
    isResourceFetching: false,
    isNewsFetching: false,
    resourceContent: "",
    newsContent: "",
  },
  action
) {
  switch (action.type) {
    case GET_RESOURCE_REQUEST:
      return Object.assign({}, state, {
        isResourceFetching: true,
        resourceContent: "",
      });
    case GET_NEWS_REQUEST:
      return Object.assign({}, state, {
        isNewsFetching: true,
        newsContent: "",
      });
    case GET_RESOURCE_SUCCESS:
      return Object.assign({}, state, {
        isResourceFetching: false,
        resourceContent: action.resourceData,
      });
    case GET_NEWS_SUCCESS:
      return Object.assign({}, state, {
        isNewsFetching: false,
        newsContent: action.newsData,
      });
    case GET_RESOURCE_FAILURE:
      return Object.assign({}, state, {
        isResourceFetching: false,
        isResourceError: true,
        error: action.message,
      });
    case GET_NEWS_FAILURE:
      return Object.assign({}, state, {
        isNewsFetching: false,
        isNewsError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default resourceAndNews;
