import React from "react";
import Content from "../Shared/Content/Content";
import './applications.scss';

const Application = ({ contentData }) => {
  if (!contentData) {
    return null;
  }
  return (
    <React.Fragment>
      {contentData && contentData.title && (
        <h1>
          <Content html={contentData.title} />
        </h1>
      )}
      {contentData && contentData.body && <Content html={contentData.body} />}
    </React.Fragment>
  );
};

export default Application;
