import React, { Component } from "react";
import LaunchProduct from "../components/Launch/Launch";

class LaunchContainer extends Component {
  state = {};
  componentDidMount() {
    document.title = "Client Portal -Launch";
  }
  render() {
    const { location } = this.props;
    const url = location.search.replace("?url=", "");

    return (
      <React.Fragment>
        {url && <LaunchProduct launchURL={url} />}
      </React.Fragment>
    );
  }
}

export default LaunchContainer;
