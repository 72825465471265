import { fetchFileTreeContentUrl, fetchFileWithMSId } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const FILETREE_CONTENT_REQUEST = "FILETREE_CONTENT_REQUEST";
export const FILETREE_SUCCESS_EMPTY = "FILETREE_SUCCESS_EMPTY";
export const FILETREE_CONTENT_SUCCESS = "FILETREE_CONTENT_SUCCESS";
export const FILETREE_CONTENT_RESET = "FILETREE_CONTENT_RESET";
export const FILETREE_CONTENT_REQUEST_ERROR = "FILETREE_CONTENT_REQUEST_ERROR";

function requestFileTreeContent() {
  return {
    type: FILETREE_CONTENT_REQUEST,
    isFetched: false
  };
}

export function resetFileTreeContent() {
  return {
    type: FILETREE_CONTENT_RESET
  };
}

// Generates the object path used to insert nested folders in the file tree
function generateFolderPathKey({ rootFolderId, isRootResponse, data, pk }) {
  if (isRootResponse) {
    return "";
  }
  let generatedPath;
  if (pk.length === 0) {
    generatedPath = `${rootFolderId}.LoadedChildContent`;
  } else {
    generatedPath = `${pk}.foldersObject.${rootFolderId}.LoadedChildContent`;
  }
  return generatedPath;
}

function transformResponse(res, isRootResponse, parentKey) {
  if (res) {
    if (res.files) {
      var newFiles = {};
      for (var i = 0; i < res.files.length; i++) {
        var file = res.files[i];
        file.itemType = "file";
        file.isAtRootLevel = isRootResponse;
        file.parentKey = parentKey;
        newFiles[file.field_media_file] = file;
      }
      res.filesObject = newFiles;
    }
    if (res.folders) {
      var newFolders = {};
      for (var j = 0; j < res.folders.length; j++) {
        var folder = res.folders[j];
        folder.itemType = "folder";
        folder.isAtRootLevel = isRootResponse;
        folder.parentKey = parentKey;
        newFolders[folder.tid] = folder;
      }
      res.foldersObject = newFolders;
    }
  }
  return res;
}

function receiveFileTreeContent({
  data,
  currentTree,
  isRootResponse = false,
  pk
}) {
  if (!data || !data.current || data.current.length === 0) {
    return {
      type: FILETREE_SUCCESS_EMPTY
    };
  }

  const rootFolderId = data.current[0].tid;
  const parentKey = generateFolderPathKey({
    rootFolderId,
    isRootResponse,
    data,
    pk
  });
  const fileTreeContentResponse = {
    type: FILETREE_CONTENT_SUCCESS,
    isFetched: true,
    isRootResponse,
    childData: transformResponse(data, isRootResponse, parentKey),
    parentKey: parentKey
  };
  return fileTreeContentResponse;
}

function requestFileTreeError({ err }) {
  return {
    type: FILETREE_CONTENT_REQUEST_ERROR,
    errorMessage: err
  };
}
function isInternalUser() {
  const userType = sessionStorage.getItem("type");
  if (userType) {
    if (userType === "msid") {
      return true;
    }
    return false;
  }
  return null;
}


export function getFileTreeContent({
  folderId,
  isRootRequest,
  rootUrl,
  productFolderId,
  isAtRootLevel,
  parentKey,
  accessToken,
  byId,
  contactid,
  uuid,
  emailId,
  fetchDatafromContactId,
  type
}) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(requestFileTreeContent());
      const usrType = isInternalUser();
      var req = {};
      req["form"] = byId ? "id" : "code";
      req["code"] = byId ? productFolderId : (rootUrl && rootUrl.substr(rootUrl.indexOf("/") + 1));
      req["type"] = type;
      if (usrType) {
        req["uuid"] = uuid;
        req["emailId"] = emailId;
      }
      if (!usrType && fetchDatafromContactId) {
        req["contactid"] = contactid;
        req["uuid"] = "";
      } else if (!usrType) {
        req["uuid"] = uuid;
        req["contactid"] = "";
      }
      const url = usrType ? fetchFileWithMSId() : fetchFileTreeContentUrl();
      http
        .post(url, JSON.stringify(req))
        .then(response => {
          const data = response.data;
          if (response.status !== 200) {
            return reject(data);
          } else {
            const fileTreeContent = getState().fileTree.fileTreeContent;
            dispatch(
              receiveFileTreeContent({
                data,
                currentTree: fileTreeContent,
                isRootResponse: isRootRequest,
                pk: parentKey
              })
            );
            return resolve();
          }
        })
        .catch(function (error) {
          dispatch(requestFileTreeError({ error }));
        });
    });
  };
}

export function getFileDataById({
  folderId,
  isRootRequest,
  rootUrl,
  fetchDatafromContactId,
  contactid,
  uuid,
  emailId,
  type
}) {
  const usrType = isInternalUser();
  var req = {};
  req["form"] = isRootRequest ? "code" : "id";
  req["code"] = isRootRequest ? (rootUrl && rootUrl.substr(rootUrl.indexOf("/") + 1)) : folderId;
  req["type"] = type;
  if (usrType) {
    req["uuid"] = uuid;
    req["emailId"] = emailId;
  }
  if (!usrType && fetchDatafromContactId) {
    req["contactid"] = contactid;
    req["uuid"] = "";
  } else if (!usrType) {
    req["uuid"] = uuid;
    req["contactid"] = "";
  }

  const url = usrType ? fetchFileWithMSId() : fetchFileTreeContentUrl();
  const request = http.post(url, JSON.stringify(req))

  request
    .then(response => {
      const data = response.data;
      if (response.status !== 200) {
        throw new Error("Error fetching folder content");
      } else {
        return data;
      }
    })
  return request
}

export function updateTreeContent({
  folderId,
  data,
  isRootRequest,
  parentKey
}) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(requestFileTreeContent());
      const fileTreeContent = getState().fileTree.fileTreeContent;
      dispatch(
        receiveFileTreeContent({
          data: data && data.value && data.value.data,
          currentTree: fileTreeContent,
          isRootResponse: isRootRequest,
          pk: parentKey
        })
      );
    });
  };
}