import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from "../actions/login";
function auth(
  state = {
    isFetching: false,
    isAuthenticated: localStorage.getItem("session_id") ? true : false,
    token: localStorage.getItem("session_id"),
    user: localStorage.getItem("user"),
    isSignOut: localStorage.getItem("signOut") ? true : false,
  },
  action
) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        errorMessage: "",
        token: action.token,
        user: action.user,
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message,
      });
    default:
      return state;
  }
}

// We combine the reducers here so that they
// can be left split apart above

export default auth;
