import {
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAILURE,
  OP_REQUEST,
  OP_SUCCESS,
  OP_FAILURE,
} from "../actions/userProfile";

export function userProfile(
  state = {
    isFetching: false,
    profileData: "",
    opData: "",
    userId: "",
  },
  action
) {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
        profileData: "",
        userId: "",
      });
    case USER_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        profileData: action.profileData,
        userId: action.userId,
      });
    case USER_PROFILE_FAILURE:
      return Object.assign({}, state, {
        isFetching: true,
        isProfileError: true,
        profileError: action.message,
      });
    case OP_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
        opData: "",
      });
    case OP_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        opData: action.opData,
      });
    case OP_FAILURE:
      return Object.assign({}, state, {
        isFetching: true,
        isOPError: true,
        OPError: action.message,
      });
    default:
      return state;
  }
}

export default userProfile;
