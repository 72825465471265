import {
  INTERNAL_PRODUCT_REQUEST,
  INTERNAL_PRODUCT_SUCCESS,
  INTERNAL_PRODUCT_FAILURE,
} from "../actions/internalEmployeeProducts";

export function internalEmployeeProducts(
  state = {
    isFetching: false,
    accountId: "",
    internalProducts: [],
  },
  action
) {
  switch (action.type) {
    case INTERNAL_PRODUCT_SUCCESS:
      const data = state.internalProducts;
      const index =
        data &&
        data.findIndex(
          (item) => item && item.accountId === action.data.accountId
        );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        isFetching: false,
        internalProducts: data,
      };

    default:
      return state;
  }
}

export default internalEmployeeProducts;
