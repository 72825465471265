import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../components/FileCabinet/InvoicesTab/Table/Table";
import { getInvoiceMonths } from "../actions/getMonths";
class InvoiceTableContainer extends Component {
  state = { invoiceMonths: [] };

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setInvoiceMonths(nextprops);
  }

  componentDidMount() {
    this.setInvoiceMonths(this.props);
  }

  setInvoiceMonths(props) {
    const { isFetching, invoiceMonths, isError } = props;
    if (!isError) {
      if (!isFetching) {
        if (invoiceMonths) {
          if (invoiceMonths && invoiceMonths !== this.state.invoiceMonths) {
            this.setState({ invoiceMonths });
          }
        } else {
          this.props.dispatch(getInvoiceMonths());
        }
      }
    }
  }

  render() {
    const { invoiceMonths } = this.state;
    const { handleMonthSubmit } = this.props;
    return (
      <Table
        invoiceMonths={invoiceMonths}
        handleMonthSubmit={handleMonthSubmit}
      />
    );
  }
}

const mapStateToProps = ({ getMonths, auth }) => {
  return { ...getMonths, auth };
};
export default connect(mapStateToProps)(InvoiceTableContainer);
