import {
  CLIENTACCOUNTLIST_CONTENT_REQUEST,
  CLIENTACCOUNTLIST_CONTENT_SUCCESS,
  CLIENTACCOUNTLIST_CONTENT_FAILURE,
} from "../actions/clientAccountListContent";

export function clientAccountListContent(
  state = {
    isFetching: false,
    clientAccountListContent: "",
  },
  action
) {
  switch (action.type) {
    case CLIENTACCOUNTLIST_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        clientAccountListContent: "",
      });
    case CLIENTACCOUNTLIST_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        clientAccountListContent: action.data,
      });
    case CLIENTACCOUNTLIST_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default clientAccountListContent;
