
import React from "react";
import Loader from "react-loader-spinner";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    color: (props) => props.color || "auto",
    marginLeft: (props) => props.marginLeft || "auto",
   
  },
}));
const LoaderSpin = ({height, width,color,marginLeft}) => {
    const classes = useStyles({
      width,
      height,
      marginLeft,
      color
    });
  return (
    <Loader
    className={classes.root}
      height={20}
      width={20}
      type="TailSpin"
      color={color}
   />

  );
};
export default LoaderSpin;
