import {
  FILECABINET_CONTENT_REQUEST,
  FILECABINET_CONTENT_SUCCESS,
  FILECABINET_CONTENT_FAILURE,
  FILECABINET_DOCUMENTLIBRARY_REQUEST,
  FILECABINET_DOCUMENTLIBRARY_SUCCESS,
  FILECABINET_DOCUMENTLIBRARY_CHILD_SUCCESS,
  FILECABINET_DOCUMENTLIBRARY_PARENT_SUCCESS,
} from "../actions/fileCabinetContent";

export function fileCabinetContent(
  state = {
    isFetching: false,
    fileCabinetContent: "",
    documentLibraryContent: "",
    documentLibraryParentContent: null,
    documentLibraryChildContent: null,
  },
  action
) {
  switch (action.type) {
    case FILECABINET_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        fileCabinetContent: "",
      });
    case FILECABINET_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        fileCabinetContent: action.data,
      });
    case FILECABINET_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });

    case FILECABINET_DOCUMENTLIBRARY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        documentLibraryContent: "",
      });

    case FILECABINET_DOCUMENTLIBRARY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        documentLibraryContent: action.data,
      });

    case FILECABINET_DOCUMENTLIBRARY_CHILD_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        documentLibraryChildContent: action.childData,
      });

    case FILECABINET_DOCUMENTLIBRARY_PARENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        documentLibraryParentContent: action.documentLibraryContent,
      });
    default:
      return state;
  }
}

export default fileCabinetContent;
