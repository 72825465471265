import {
    GET_BAS_DOC_REQUEST,
    GET_BAS_DOC_SUCCESS,
    GET_BAS_DOC_FAILURE,
} from "../actions/getBASDocuments";

export function getBASDocuments(
    state = {
        isFetching: false,
        basData: "",
        isError:""
    },
    action
) {
    switch (action.type) {
        case GET_BAS_DOC_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                basData: "",
            });
        case GET_BAS_DOC_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                basData: action.data,
            });

        case GET_BAS_DOC_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isError: true,
                error: action.message,
            });
        default:
            return state;
    }
}

export default getBASDocuments;
