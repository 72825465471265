import React from "react";
import InputForm from "../Shared/InputForm/InputForm";
import Label from "../../constants/labels";
import {
  getEnviormentPropByName,
  getUIURLs,
} from "../../constants/microservice-helpers";
import Skeleton from '@material-ui/lab/Skeleton';
import Content from "../Shared/Content/Content";
import MsIdAuth from "./MsIdAuth/MsIdAuth";

class OptumIdLogin extends InputForm {
  state = {
    actionurl: "",
    clientid: "",
    responsetype: "",
    redirecturi: "",
    scope: "",
    stateVal: "",
    nonce: "",
  };

  componentDidMount() {
    const { whiteLabel, setApplicationContext } = this.props;
    this.setState({
      actionurl:
        process.env.REACT_APP_OID_FLAG === "true"
          ? getEnviormentPropByName("REACT_APP_OPTUMID_ACTION_URL")
          : getEnviormentPropByName("REACT_APP_OHID_ACTION_URL"),
    });
    this.setState({
      clientid:
        process.env.REACT_APP_OID_FLAG === "true"
          ? getEnviormentPropByName("REACT_APP_OPTUMID_CLIENT_ID")
          : getEnviormentPropByName("REACT_APP_OHID_CLIENT_ID"),
    });
    this.setState({
      responsetype:
        process.env.REACT_APP_OID_FLAG === "true"
          ? getEnviormentPropByName("REACT_APP_OPTUMID_CLIENT_RESPONSE_TYPE")
          : getEnviormentPropByName("REACT_APP_OHID_CLIENT_RESPONSE_TYPE"),
    });
    this.setState({
      stateVal:
        process.env.REACT_APP_OID_FLAG === "true"
          ? getEnviormentPropByName("REACT_APP_OPTUMID_STATE_VALUES")
          : getEnviormentPropByName("REACT_APP_OHID_STATE_VALUES"),
    });
    if (whiteLabel) {
      this.setState({
        redirecturi: setApplicationContext && setApplicationContext != undefined
          ? getEnviormentPropByName("REACT_APP_WHITELABEL_URL") +
          setApplicationContext.basename +
          getEnviormentPropByName("REACT_APP_OPTUMID_REDIRECT_URI")
          : getEnviormentPropByName("REACT_APP_WHITELABEL_URL") +
          getEnviormentPropByName("REACT_APP_OPTUMID_REDIRECT_URI")
      });
    } else {
      this.setState({
        redirecturi: getUIURLs(getEnviormentPropByName("REACT_APP_OPTUMID_REDIRECT_URI")),
      });
    }
    this.setState({
      scope:
        process.env.REACT_APP_OID_FLAG === "true"
          ? getEnviormentPropByName("REACT_APP_OPTUMID_SCOPE")
          : getEnviormentPropByName("REACT_APP_OHID_SCOPE"),
    });
    this.setState({
      nonce:
        process.env.REACT_APP_OID_FLAG === "true"
          ? getEnviormentPropByName("REACT_APP_OPTUMID_NONCE")
          : getEnviormentPropByName("REACT_APP_OHID_NONCE"),
    });
  }

  render() {
    const {
      actionurl,
      clientid,
      responsetype,
      stateVal,
      redirecturi,
      scope,
      nonce,
    } = this.state;
    const { whiteLabel, setApplicationContext, isOptumIDFetching, optumIdcontentData } = this.props;
    return (
      <form method="post" action={actionurl || ""}>
        <input type="hidden" name="client_id" value={clientid || ""} />
        <input type="hidden" name="response_type" value={responsetype || ""} />
        <input type="hidden" name="redirect_uri" value={redirecturi || ""} />
        <input type="hidden" name="scope" value={scope || ""} />
        <input type="hidden" name="state" value={stateVal || ""} />
        <input type="hidden" name="nonce" value={nonce || " "} />
        <fieldset className="row">
          <div className="login-container">
            <div className="login-content">
              {isOptumIDFetching && !whiteLabel && (
                <h4>
                  <Skeleton variant="text" width={350} height={30} />
                </h4>
              )}
              {isOptumIDFetching && whiteLabel && (
                <h4>
                  <Skeleton variant="text" width={120} height={30} />
                </h4>
              )}
              {!isOptumIDFetching && optumIdcontentData && optumIdcontentData.title && (
                <h4>
                  <Content html={optumIdcontentData.title} />
                </h4>
              )}
              <div className={optumIdcontentData || isOptumIDFetching ? "" : "mt42"}>
                {this.renderButton(Label.OptumSignIn, "OID Sign in")}
              </div>
              <MsIdAuth
                whiteLabel={whiteLabel}
                setApplicationContext={setApplicationContext}
                optumIdcontentData={optumIdcontentData}
                isOptumIDFetching={isOptumIDFetching}
              />
            </div>
            <figure className="login-figure">
              <img src="/assets/images/image_2x.png" alt="" />{" "}
            </figure>
          </div>
        </fieldset>
      </form>
    );
  }
}

export default OptumIdLogin;
