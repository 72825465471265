import React, { Component } from "react";
import Content from "../Shared/Content/Content";
import { isExternal } from "../../constants/service";

class Profile extends Component {
  render() {
    const { contentData, primaryAccount, userProfile, user } = this.props;
    const profileDetail = userProfile.profileData;

    if (isExternal(user)) {
      return (
        <React.Fragment>
          {contentData && (
            <div>
              <h1>
                {contentData && contentData.title && (
                  <Content html={contentData.title} />
                )}
              </h1>
              {contentData && contentData.body && (
                <Content html={contentData.body} />
              )}

              <p />
              <table className="w50">
                <tbody>
                  <tr>
                    <td className="w35">First name:</td>
                    <td>
                      {primaryAccount && primaryAccount["u_contact.first_name"]}
                    </td>
                  </tr>
                  <tr>
                    <td className="w35">Last name:</td>
                    <td>
                      {primaryAccount && primaryAccount["u_contact.last_name"]}
                    </td>
                  </tr>
                  <tr>
                    <td className="w35">Organization:</td>
                    <td>
                      {primaryAccount && primaryAccount["u_account.name"]}
                    </td>
                  </tr>
                  <tr>
                    <td className="w35">Phone:</td>
                    <td>
                      {primaryAccount && primaryAccount["u_contact.phone"]}
                    </td>
                  </tr>
                  <tr>
                    <td className="w35">Email:</td>
                    <td>
                      {primaryAccount && primaryAccount["u_contact.email"]}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p />
            </div>
          )}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {contentData && (
          <div>
            <h1>
              {contentData && contentData.title && (
                <Content html={contentData.title} />
              )}
            </h1>
            {contentData && contentData.body && (
              <Content html={contentData.body} />
            )}

            <p />
            <table className="w50">
              <tbody>
                <tr>
                  <td className="w35">First name:</td>
                  <td>{profileDetail && profileDetail["given_name"]}</td>
                </tr>
                <tr>
                  <td className="w35">Last name:</td>
                  <td>{profileDetail && profileDetail["family_name"]}</td>
                </tr>
                <tr>
                  <td className="w35">Organization:</td>
                  <td>{profileDetail && profileDetail["department"]}</td>
                </tr>
                <tr>
                  <td className="w35">Phone:</td>
                  <td>{profileDetail && profileDetail["phone"]}</td>
                </tr>
                <tr>
                  <td className="w35">Email:</td>
                  <td>{profileDetail && profileDetail["email"]}</td>
                </tr>
              </tbody>
            </table>
            <p />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Profile;
