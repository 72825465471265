import React, { Component } from "react";
import { connect } from "react-redux";
import { getClientRequestsMessageStating } from "../actions/clientRequestErrorContent";
import Request from "../components/Requests/Request";

class ClientRequestsErrorContentContainer extends Component {
  state = {
    clientRequestMessage: ""
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setClientRequestsError(nextprops);
  }

  componentDidMount() {
    this.setClientRequestsError(this.props);
  }

  setClientRequestsError(props) {
    const { isFetching, clientRequestErrorContent, isError } = props;
    if (!isError) {
      if (!isFetching) {
        if (clientRequestErrorContent) {
          if (
            clientRequestErrorContent &&
            clientRequestErrorContent !== this.state.clientRequestMessage
          ) {
            this.setState({ clientRequestMessage: clientRequestErrorContent });
          }
        } else {
          this.props.dispatch(getClientRequestsMessageStating());
        }
      }
    }
  }

  render() {
    const contentData = this.state.clientRequestMessage[0];
    return <Request contentData={contentData} isLaunchVisible={false} />;
  }
}

const mapStateToProps = ({ clientRequestErrorContent, auth }) => {
  return { ...clientRequestErrorContent, auth };
};

export default connect(mapStateToProps)(ClientRequestsErrorContentContainer);
