import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { getMessageBoxContent } from "../actions/messageBox";
import MessageBox from "../components/Shared/MessageBox/MessageBox";
import { renderHTML } from "../constants/service";
import { getFilesFromBytes } from "../actions/filesFromBytes";

class MessageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageData: {}
    };
    this.containerRef = React.createRef();
  }

  overrideLinkHandler = e => {
    const { dispatch } = this.props;
    if (
      e.target.tagName === "A" &&
      e.target.getAttribute("class") === "internalLink"
    ) {
      e.preventDefault();
      this.props.history.push(e.target.getAttribute("href"));
    } else if (
      e.target.tagName === "A" &&
      e.target.getAttribute("href") &&
      e.target.getAttribute("class") === "downloadLink"
    ) {
      e.preventDefault();
      const hrefData = e.target.getAttribute("href");
      const fileName = hrefData.substr(hrefData.lastIndexOf("/") + 1);
      const req = {};
      req["filePath"] = hrefData;
      dispatch(getFilesFromBytes(req, fileName));
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getMessageData(nextProps);
    this.addListener();
  }

  addListener() {
    ReactDOM.findDOMNode(this).addEventListener(
      "click",
      this.overrideLinkHandler
    );
  }

  componentDidMount() {
    this.getMessageData(this.props);
    this.addListener();
  }

  getMessageData(nextProps) {
    const { pageType, pageId } = nextProps;
    if (pageId) {
      const { messageData } = nextProps;
      if (messageData && !messageData.isFetching) {
        const messageContent = this.getMessageContent(pageId, messageData.data);
        if (messageContent) {
          if (messageContent.pageId !== this.state.messageData.pageId) {
            this.setState({
              messageData: messageContent
            });
          }
        } else {
          this.props.dispatch(getMessageBoxContent(pageId, pageType));
        }
      }
    }
  }

  getMessageContent(pageId, messageContentData) {
    return messageContentData.find(item => {
      return item.pageId === pageId;
    });
  }

  render() {
    const { messageData } = this.state;
    if (
      messageData &&
      messageData.messageContent &&
      messageData.messageContent.length > 0
    ) {
      var messages = messageData.messageContent;
    }
    return (
      <div ref={this.containerRef}>
        {messages &&
          messages.length > 0 &&
          messages.map(item => (
            <MessageBox
              key={item.field_title}
              data={renderHTML(item.field_box_content)}
              title={item.field_title}
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = ({ messageData, auth }) => {
  return {
    messageData,
    auth
  };
};

export default connect(mapStateToProps)(withRouter(MessageContainer));
