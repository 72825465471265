import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getFilesFromBytes,
  getFilesFromBytesB64
} from "../../../actions/filesFromBytes";
import globalConstants from "../../../constants/global-constants";

class Offerings extends Component {
  constructor(props) {
    super(props);
    this.state = { byteInfoData: {} };
  }

  getByteData(code, name) {
    const { filesFromBytes, dispatch } = this.props;
    if (code && filesFromBytes) {
      const byteInfo = this.getData(name, filesFromBytes.reponseB64);
      if (!byteInfo) {
        const req = {};
        req["filePath"] = code;
        dispatch(getFilesFromBytesB64(req, name));
      } else if (byteInfo && byteInfo.bytes) {
        return byteInfo.bytes;
      } else {
        return null;
      }
    }
    return null;
  }

  getData(name, imagesData) {
    return imagesData.find(item => {
      return item[globalConstants.EMAIL] === name;
    });
  }

  handleDownload = data => {
    if (data) {
      const { dispatch } = this.props;
      const fileName = data.substr(data.lastIndexOf("/") + 1);
      const req = {};
      req["filePath"] = data;
      dispatch(getFilesFromBytes(req, fileName));
    }
  };

  render() {
    const { item, proMetaData } = this.props;
    return (
      <React.Fragment ref={this.containerRef}>
        <li name="myProduct" className="tableRowOdd">
          <div className="col-sm-3 posStatic">
            <figure>
              {this.getByteData(
                proMetaData["field_product_logo"],
                item.u_service_product || item.pname
              ) && (
                  <img
                    src={
                      "data:image/png;base64, " +
                      this.getByteData(
                        proMetaData["field_product_logo"],
                        item.u_service_product || item.pname
                      )
                    }
                    alt=""
                    width="200"
                    height="70"
                    className="mCS_img_loaded"
                  />
                )}
            </figure>
          </div>
          <div className="col-sm-6">
            <p>
              <span name="Pocontent">
                {item["u_service_product.u_description"] || item["pcontent"]}
              </span>
            </p>
          </div>
          <div className="col-sm-3 aHnoU">
            <a
              href="# "
              friendlyname={proMetaData.title + " - Product Overview"}
              rel="noopener noreferrer"
              onClick={e => {
                e.preventDefault();
                this.handleDownload(proMetaData["field_product_offering"]);
              }}
            >
              <span
                className="block marAuto marB10 c oi-document"
                style={{ fontSize: 65 }}
              />
            </a>
            <a
              friendlyname={proMetaData.title + " - Product Overview"}
              rel="noopener noreferrer"
              href="# "
              onClick={e => {
                e.preventDefault();
                this.handleDownload(proMetaData["field_product_offering"]);
              }}
            >
              <span className="button button-reverse block noCap">
                Product overview
              </span>
            </a>
            <div align="center" />
          </div>

          <span className="block marAuto marB10 c" name="Pourl" />
        </li>
      </React.Fragment>
    );
  }
}

const mapsStateToProps = ({ auth, filesFromBytes }) => {
  return { auth, filesFromBytes };
};
export default connect(mapsStateToProps)(Offerings);
