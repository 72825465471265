import { fetchBcbsUser } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
export const GET_BCBS_USER_SUCCESS = "GET_BCBS_USER_SUCCESS";

function requestBcbsUser(accountId) {
  const bcbsUser = {};
  bcbsUser["accountId"] = accountId;
  bcbsUser["isFetching"] = true;
  bcbsUser["isError"] = false;
  return {
    type: GET_BCBS_USER_SUCCESS,
    data: bcbsUser
  };
}

function receiveBcbsUser(res, accountId) {
  const bcbsUser = {};
  bcbsUser["isbcbs"] = res;
  bcbsUser["accountId"] = accountId;
  bcbsUser["isFetching"] = false;
  bcbsUser["isError"] = false;
  return {
    type: GET_BCBS_USER_SUCCESS,
    data: bcbsUser
  };
}

function bcbsUserFailure(message, accountId) {
  const bcbsUser = {};
  bcbsUser["accountId"] = accountId;
  bcbsUser["isFetching"] = false;
  bcbsUser["isError"] = true;
  return {
    type: GET_BCBS_USER_SUCCESS,
    data: bcbsUser,
    message
  };
}

export function getBcbsUsers(accountId) {
  return dispatch => {
    dispatch(requestBcbsUser(accountId));
    const url = fetchBcbsUser(accountId);
    http
      .get(url)
      .then(response => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(bcbsUserFailure(data.message, accountId));
          return Promise.reject(data);
        } else {
          dispatch(receiveBcbsUser(data, accountId));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(bcbsUserFailure(error, accountId));
      });
  };
}
