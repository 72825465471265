import {
  GET_INVOICE_DOC_REQUEST,
  GET_INVOICE_DOC_SUCCESS,
  GET_INVOICE_DOC_FAILURE,
} from "../actions/getInvoiceDocuments";

export function getInvoiceDocuments(
  state = {
    isFetching: false,
    invoicesData: "",
  },
  action
) {
  switch (action.type) {
    case GET_INVOICE_DOC_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        invoicesData: "",
      });
    case GET_INVOICE_DOC_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        invoicesData: action.data,
      });

    case GET_INVOICE_DOC_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default getInvoiceDocuments;
