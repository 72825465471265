import React from "react";
import Content from "../../Shared/Content/Content";
import InvoiceDataContainer from "../../../containers/InvoiceDataContainer";
const Invoice = ({
  invoiceContent,
  dropdownData,
  isDetailVisibleProp,
  setPageVisiblity
}) => {
  return (
    <div className="tab">
      <div className="container30">
        <div id="myProducts" className="tab-content">
          <div className="noProduct">
            <Content html={invoiceContent.body} />
            <InvoiceDataContainer
              dropdownData={dropdownData}
              isDetailVisibleProp={isDetailVisibleProp}
              setPageVisiblity={setPageVisiblity}
            />
            <Content html={invoiceContent.field_body_secondary} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
