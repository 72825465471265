import { GET_GENERATED_REPORT_LINK_SUCCESS } from "../actions/generateReportLink";

export function getGeneratedReportLink(
  state = {
    isFetching: false,
    link: [],
  },
  action
) {
  switch (action.type) {
    case GET_GENERATED_REPORT_LINK_SUCCESS:
      const data = state.link;
      const index =
        data &&
        data.findIndex(
          (item) =>
            item &&
            item.accountId === action.data.accountId &&
            item.linkId === action.data.linkId
        );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        isFetching: false,
        link: data,
      };
    default:
      return state;
  }
}

export default getGeneratedReportLink;
