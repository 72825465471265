import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({ name, checked = false, onChange, value }) => (
  <input
    type="checkbox"
    name={name}
    checked={checked}
    onChange={onChange}
    value={value}
  />
);

Checkbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default Checkbox;
