import React, { Component } from "react";
import Loader from "react-loader-spinner";
import "./loader.scss";
class CircularLoader extends Component {
  state = {};
  render() {
    const { color } = this.props;

    return (
      <div className="loaderSpin">
        <Loader
          type="TailSpin"
          color={color}
          height={50}
          width={50}
          align="center"
          //  timeout={3000} //3 secs
        />
        <p>Please wait while we connect...</p>
      </div>
    );
  }
}

export default CircularLoader;
