import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import {
  getGovernmentProgramContentPromotional,
} from "../actions/governmentProgramContent";
import GovernmentPrograms from "../components/GovernmentPrograms/GovernmentPrograms";
import { isInternal } from "../constants/service";
import GovernmentProgramRelatedLinkContainer from "./GovernmentProgramRelatedLinksContainer";
import globalConstants from "../constants/global-constants";
import routePath from "../constants/routes";

class GovernmentProgramContainer extends Component {
  state = {
    governmentProgramContent: "",
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setGovernmentProgramContent(nextprops);
  }

  componentDidMount() {
    document.title = "Client Portal - Government programs";
    this.setGovernmentProgramContent(this.props);
  }

  setGovernmentProgramContent(props) {
    const {
      isFetching,
      governmentProgramContent,
      isError,
      user,
      selectedAccount,
      selectedMode,
      auth,
    } = props;
    if (isInternal(user)) {
      this.configureForInternalUser(
        isError,
        isFetching,
        governmentProgramContent,
        selectedMode,
        auth
      );
    } else {
      this.configureForAccounts(
        isError,
        isFetching,
        governmentProgramContent,
        selectedAccount,
        auth
      );
    }
  }

  configureForAccounts(
    isError,
    isFetching,
    governmentProgramContent,
    selectedAccount
  ) {
    if (selectedAccount) {
      const pageType =
        selectedAccount[globalConstants.NAVIGATION_GOVERNMENT_PROGRAM_TAB];

      const governmentProgramInfo = this.configGovtPrgram(
        selectedAccount[globalConstants.NAVIGATION_GOVERNMENT_PROGRAM_TAB],
        governmentProgramContent.governmentProgramContent
      );
      if (governmentProgramInfo) {
        this.setState({
          governmentProgramContent: governmentProgramInfo,
        });
      } else {
        this.props.dispatch(getGovernmentProgramContentPromotional(pageType));
      }
    }
  }

  configGovtPrgram(pageType, governmentProgramContent) {
    return (
      governmentProgramContent &&
      governmentProgramContent.length > 0 &&
      governmentProgramContent.find((item) => {
        return item && item.pageType === pageType;
      })
    );
  }

  configureForInternalUser(
    isError,
    isFetching,
    governmentProgramContent,
    selectedMode
  ) {
    const govtProgramInfo = this.configGovtPrgram(
      selectedMode,
      governmentProgramContent.governmentProgramContent
    );
    if (govtProgramInfo) {
      this.setState({
        governmentProgramContent: govtProgramInfo,
      });
    } else {
      this.props.dispatch(getGovernmentProgramContentPromotional(selectedMode));
    }
  }

  render() {
    const { governmentProgramContent } = this.state;
    const { location } = this.props;
    let isVisible = true;
    if (
      location.pathname.match("/home/govermentPrograms/") ||
      location.pathname.match("/home/govermentPrograms/relatedLinks/")
    ) {
      isVisible = false;
    }

    return (
      <React.Fragment>
        {isVisible && (
          <section>
            <GovernmentPrograms
              contentData={
                governmentProgramContent && governmentProgramContent.value
              }
            />
          </section>
        )}
        {!isVisible && (
          <Switch>
            <Route
              path={routePath.relatedLinkGPWithType}
              render={(props) => (
                <GovernmentProgramRelatedLinkContainer {...props} />
              )}
            />
          </Switch>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { governmentProgramContent, userAccount, user, auth } = state;
  return {
    governmentProgramContent,
    userAccount,
    user,
    selectedAccount: userAccount.selectedAccount,
    selectedMode: userAccount.selectedMode,
    auth,
  };
};

export default connect(mapStateToProps)(GovernmentProgramContainer);
