import React, { Component } from "react";
import { connect } from "react-redux";
import { getNoAccessContent } from "../actions/noAccessContent";
import NoAccess from "../components/NoAccess/NoAccess";

class NoAccessContentContainer extends Component {
  state = {
    noAccess: "",
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setNoAccess(nextprops);
  }

  componentDidMount() {
    document.title = "Client Portal - No Access";
    this.setNoAccess(this.props);
  }

  setNoAccess(props) {
    const { isFetching, noAccessContent, isError } = props;
    if (!isError) {
      if (!isFetching) {
        if (noAccessContent) {
          if (noAccessContent && noAccessContent !== this.state.noAccess) {
            this.setState({ noAccess: noAccessContent });
          }
        } else {
          this.props.dispatch(getNoAccessContent());
        }
      }
    }
  }

  render() {
    const contentData = this.state.noAccess[0];
    return <NoAccess contentData={contentData} />;
  }
}

const mapStateToProps = ({ noAccessContent, auth }) => {
  return { ...noAccessContent, auth };
};

export default connect(mapStateToProps)(NoAccessContentContainer);
