import { fetchFileNames } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const FILES_REQUEST = "FILES_REQUEST";
export const FILES_SUCCESS = "FILES_SUCCESS";
export const FILES_FAILURE = "FILES_FAILURE";
export const CLEAR_FILESTRUCUTRE = "CLEAR_FILESTRUCUTRE";

export function requestFiles() {
  return {
    type: FILES_REQUEST,
    isFetched: false
  };
}

export function fileSuccess(data) {
  return {
    type: FILES_SUCCESS,
    isFetched: true,
    data
  };
}

export function filesFailure(message) {
  return {
    type: FILES_FAILURE,
    isFetched: false,
    isError: true,
    message
  };
}

export function clearUpFileStrucutre() {
  return {
    type: CLEAR_FILESTRUCUTRE
  };
}


export function getallFiles(
  isRootRequest,
  rootUrl,
  folderId,
  productFolderId,
  isAtRootLevel,
  parentKey,
  byId
) {
  return dispatch => {
    dispatch(requestFiles());
    const url = fetchFileNames(isRootRequest && isRootRequest.rootUrl)

    http
      .get(url)
      .then(response => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(filesFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(fileSuccess(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(filesFailure(error));
      });
  };
}
