import {
  ACCOUNT_PROFILE_CONTENT_REQUEST,
  ACCOUNT_PROFILE_CONTENT_SUCCESS,
  ACCOUNT_PROFILE_CONTENT_FAILURE,
} from "../actions/accountProfileContent";

export function accountProfileContent(
  state = {
    isFetching: false,
    accountProfileContent: "",
  },
  action
) {
  switch (action.type) {
    case ACCOUNT_PROFILE_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        accountProfileContent: "",
      });
    case ACCOUNT_PROFILE_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        accountProfileContent: action.data,
      });
    case ACCOUNT_PROFILE_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default accountProfileContent;
