import React from "react";

const NotFound = () => {
  return (
    <section className="marL20">
      <h1>404 No page found</h1>
      <p className="error">This page could not be found.</p>
    </section>
  );
};

export default NotFound;
