import React from "react";
const Input = ({ name, type, label, error, className, ...rest }) => {
  return (
    // <div className="form-group">
    <React.Fragment>
      <label className="col-sm-4">{label}</label>
      <div className="col-sm-8">
        <input
          type={type}
          {...rest}
          name={name}
          className={className}
          id={name}
        />
        {error && (
          <div className="errorMsg error padL13 pad0 padB10">{error}</div>
        )}
      </div>
    </React.Fragment>
    // </div>
  );
};

export default Input;
