import {
  USER_ACCOUNT_REQUEST,
  USER_ACCOUNT_SUCCESS,
  USER_ACCOUNT_FAILURE,
  SET_SELECTED_ACCOUNT,
  SET_SELECTED_MODE,
  USER_INTERNAL_SUCCESS,
  FETCHED_DATA_FROM,
} from "../actions/userAccount";

function userAccount(
  state = {
    isFetching: false,
    content: "",
    selectedMode: "PARTICIPANT",
    userType: "",
    fetchDatafromContactId: false,
  },
  action
) {
  switch (action.type) {
    case USER_ACCOUNT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isPrimaryAccountSelected: false,
        account: "",
      });
    case USER_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isPrimaryAccountSelected: false,
        account: action.data,
      });
    case USER_INTERNAL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isPrimaryAccountSelected: true,
        isError: false,
      });
    case SET_SELECTED_ACCOUNT:
      return Object.assign({}, state, {
        isFetching: true,
        isPrimaryAccountSelected: true,
        selectedAccount: action.selectedAccount,
      });
    case USER_ACCOUNT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        isPrimaryAccountSelected: true,
        error: action.message,
      });
    case SET_SELECTED_MODE:
      return Object.assign({}, state, {
        selectedMode: action.mode,
      });
    case FETCHED_DATA_FROM:
      return Object.assign({}, state, {
        fetchDatafromContactId: action.fetchDatafromContactId,
      });

    default:
      return state;
  }
}

export default userAccount;
