import React, { Component } from "react";
import ClinicalPublication from "../components/ClinicalServices/ClinicalPublication/ClinicalPublication";
import globalConstants from "../constants/global-constants";

var URLSearchParams = require("url-search-params");

class PublicationsDetailContainer extends Component {
  state = {};
  render() {
    const {
      location,
      match: { params }
    } = this.props;
    const parameter = new URLSearchParams(location.search);
    const tags = parameter.get(globalConstants.TYPE);
    return <ClinicalPublication type={params.type} subType={tags} />;
  }
}
export default PublicationsDetailContainer;
