import { makeStyles } from "@material-ui/core";

export const RenderCellExpandStyle = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    lineHeight: theme.typography.h6.lineHeight,
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    "& .cellValue": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));
