import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const HEADER_LOGO_REQUEST = "HEADER_LOGO_REQUEST";
export const HEADER_LOGO_SUCCESS = "HEADER_LOGO_SUCCESS";
export const HEADER_LOGO_FAILURE = "HEADER_LOGO_FAILURE";

function requestHeaderLogo() {
  return {
    type: HEADER_LOGO_REQUEST,
    isFetched: false
  };
}

function receiveHeaderLogo(logo) {
  return {
    type: HEADER_LOGO_SUCCESS,
    isFetched: true,
    logo
  };
}

function headerLogoFailure(message) {
  return {
    type: HEADER_LOGO_FAILURE,
    isFetched: false,
    isError: true,
    message
  };
}

export function getHeaderLogo() {
  return dispatch => {
    dispatch(requestHeaderLogo());
    const url = fetchGeneralContentUrl("/cms/getHeaderLogo");
    http
      .get(url)
      .then(response => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(headerLogoFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveHeaderLogo(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(headerLogoFailure(error));
      });
  };
}
