import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const CLIENTREQUEST_MESSAGE_CONTENT_REQUEST =
  "CLIENTREQUEST_MESSAGE_CONTENT_REQUEST";
export const CLIENTREQUEST_MESSAGE_CONTENT_SUCCESS =
  "CLIENTREQUEST_MESSAGE_CONTENT_SUCCESS";
export const CLIENTREQUEST_MESSAGE_CONTENT_FAILURE =
  "CLIENTREQUEST_MESSAGE_CONTENT_FAILURE";

export function requestClientRequestMessageContent() {
  return {
    type: CLIENTREQUEST_MESSAGE_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function receiveClientRequestMessageContent(message) {
  return {
    type: CLIENTREQUEST_MESSAGE_CONTENT_SUCCESS,
    isFetched: true,
    message,
  };
}

export function clientRequestMessageContentFailure(message) {
  return {
    type: CLIENTREQUEST_MESSAGE_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getClientRequestsMessageStating() {
  return (dispatch) => {
    dispatch(requestClientRequestMessageContent());
    const url = fetchGeneralContentUrl(
      "/cms/getNodeByCode/page/ClientRequestsMessageStating"
    );
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(clientRequestMessageContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveClientRequestMessageContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(clientRequestMessageContentFailure(error));
      });
  };
}
