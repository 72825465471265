import React, { Component } from "react";
import NVD3Chart from "react-nvd3";
import * as _ from "underscore";

class PieChart extends Component {
  state = {};
  render() {
    const { dashboardData } = this.props;
    const value = _.countBy(dashboardData, "serviceType");
    const pieChartData = [];
    let count = 0;
    const color = [
      "#422C88",
      "#627D32",
      "#078576",
      "#4A9960",
      "#F5C533",
      "#8CC1FF",
      "#17565F"
    ];
    for (var label in value) {
      const data = {};
      if (label && label !== "null") {
        const legend = value[label];
        const legendPerc = Math.round(
          (value[label] / dashboardData.length) * 100
        );
        data["label"] = label + " = " + legend + " (" + legendPerc + "%)";
      } else {
        data["label"] = "(empty)";
      }
      data["y"] = (value[label] / dashboardData.length) * 100;
      if (color[count]) {
        data["color"] = color[count];
        count = count + 1;
      } else {
        count = 0;
        data["color"] = color[count];
      }
      pieChartData.push(data);
    }

    pieChartData.sort(function (a, b) {
      const a1 = parseInt(a["y"]);
      const b1 = parseInt(b["y"]);
      const countSortResult = b1 - a1;

      if (countSortResult === 0) {
        let a2 = a["label"];
        let b2 = b["label"];
        a2 = a2.toLowerCase();
        b2 = b2.toLowerCase();
        if (a2 < b2) {
          return -1;
        }
        return a2 > b2 ? 1 : 0;
      } else {
        return countSortResult;
      }
    });

    return (
      <div className="pie-chart">
        <h6>Cases by Service Type</h6>
        <NVD3Chart
          id="pieChart"
          type="pieChart"
          datum={pieChartData}
          legendPosition="bottom"
          enableHover={false}
          height={300}
          showLabels={false}
          tooltip={{ enabled: false }}
          x="label"
          y="value"
        />
        <div className="tk-pie-chart-container">
          <div className="tk-pie-chart" />
        </div>
      </div>
    );
  }
}

export default PieChart;
