import {
  getProfileDetail,
  getMSProfileDetail,
  getOptumProvisioning,
} from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
import { onLogoutUser } from "./logout";
import { setSessionsStorage } from "./oidAuthentication";
export const USER_INTERNAL_SUCCESS = "USER_INTERNAL_SUCCESS";
export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAILURE = "USER_PROFILE_FAILURE";
export const OP_REQUEST = "OP_REQUEST";
export const OP_SUCCESS = "OP_SUCCESS";
export const OP_FAILURE = "OP_FAILURE";

export function requestUserProfile() {
  return {
    type: USER_PROFILE_REQUEST,
  };
}

export function receiveUserProfile(profileData) {
  return {
    type: USER_PROFILE_SUCCESS,
    profileData,
    userId: profileData.sub,
  };
}

export function userProfileFailure(message) {
  return {
    type: USER_PROFILE_FAILURE,
    isError: true,
    message,
  };
}

export function requestOP() {
  return {
    type: OP_REQUEST,
  };
}

export function receiveOP(opData) {
  return {
    type: OP_SUCCESS,
    opData,
  };
}

export function OPFailure(message) {
  return {
    type: OP_FAILURE,
    isError: true,
    message,
  };
}

export function getUserProfileData() {
  return (dispatch) => {
    dispatch(requestUserProfile());
    const url = getProfileDetail();
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(receiveUserProfile(data));
          dispatch(getOptumProvisioningData());
        } else if (response.status === 500) {
          dispatch(onLogoutUser());
        } else {
          dispatch(userProfileFailure(data.message));
          return Promise.reject(data);
        }
        return data;
      })
      .catch(function (error) {
        dispatch(userProfileFailure(error));
        dispatch(onLogoutUser());
      });
  };
}

export function getOptumProvisioningData() {
  return (dispatch) => {
    dispatch(requestOP());
    const url = getOptumProvisioning();
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(receiveOP(data));
        } else if (response.status === 500) {
          dispatch(onLogoutUser());
        } else {
          dispatch(OPFailure(data.message));
          return Promise.reject(data);
        }
        return data;
      })
      .catch(function (error) {
        dispatch(OPFailure(error));
        dispatch(onLogoutUser());
      });
  };
}

export function getMSUserProfileData() {
  return (dispatch) => {
    dispatch(requestUserProfile());
    const url = getMSProfileDetail();
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(receiveUserProfile(data));
        } else if (response.status === 500) {
          dispatch(onLogoutUser());
        } else {
          dispatch(userProfileFailure(data.message));
          return Promise.reject(data);
        }
        return data;
      })
      .catch(function (error) {
        dispatch(userProfileFailure(error));
        dispatch(onLogoutUser());
      });
  };
}
