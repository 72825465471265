import React, { Component } from "react";
import Label from "../../constants/labels";
import { getBasicFilesFromBytesB64 } from "../../actions/filesFromBytes";
import { connect } from "react-redux";
import routePath from "../../constants/routes";
import { ComboHeader } from "@pcs/specialty-ui";
import Route from "../../constants/routes";
import { isInternal, isExternal } from "../../constants/service";
import { setSelectedMode } from "../../actions/userAccount";
import { withStyles } from "@material-ui/core";
import globalConstant from "../../constants/global-constants";
import routes from "../../constants/routes";
import globalConstants from "../../constants/global-constants";
import { getAccountMetaDataContent } from "../../actions/accountsMetaData";
import { getFilesFromBytesB64 } from "../../actions/filesFromBytes";
import { NavLink } from "react-router-dom";
import { OptumLogo } from "./Optumlogo";
import onClickOutside from "react-onclickoutside";
import { withRouter } from "react-router-dom";
import {
  getCPLogoContent,
  getWellDyneContent,
  getServeYouContent,
} from "../../actions/cpHeaderLogo";
const styles = (theme) => ({
  main: {
    "& .logo": {
      maxWidth: "100%",
      width: "173px",
      height: "50px",
      bottom: "24px",
      left: "76px",
      top: "24px",
    },
    "& .makeStyles-comboHeader-2 .logo-img-container": {
      padding: "24px 20px 24px 20px",
    },
    "& .link-container ": {
      top: "24px",
    },
    "& .MuiContainer-maxWidthLg": {
      maxWidth: "1440px",
    },
    " & .MuiTypography-body1": {
      maxWidth: "570px",
      fontFamily: theme.typography.body1.fontFamily,
      color: "grey",
    },
    "& .MuiButtonBase-root ": {
      cursor: "default",
    },
    "& .MuiLink-root ": {
      color: theme.customPalette.color.blue1,
      fontSize: "16px",
      fontFamily: theme.typography.body1.fontFamily,
    },
    "& .makeStyles-productName-4": {
      padding: "16px",
    },
    "& .MuiTypography-colorPrimary": {
      lineHeight: theme.typography.h4.lineHeight,
    },
    "& .MuiTypography-h5": {
      fontSize: theme.typography.h5.fontSize,
      fontFamily: theme.typography.h5.fontFamily,
      fontWeight: theme.typography.h5.fontWeight,
      color: theme.palette.primary.main,
    },
    "& svg": {
      color: theme.customPalette.color.blue1,
      right: "25",
      left: "25%",
      top: "35.79%",
      bottom: "33.33%",
    },
    "& .makeStyles-headerBand-5": {
      height: "64px",
    },
    "& .MuiMenuItem-root": {
      minHeight: "auto",
    },
    "& .firstLevelButton": {
      color: theme.palette.grey[700],
      fontSize: theme.typography.subtitle1.fontSize,
      fontFamily: theme.typography.subtitle1.fontFamily,
      lineHeight: theme.typography.body1.lineHeight,
    },
    "& .makeStyles-navHeader-10 .DsgMenuItem": {
      margin: "10px 15px",
    },
    "& .dropdownClass": {
      "& .parentItem": {
        "& li:first-child": {
          border: "0px !important",
        },
      },
    },
    "& .firstLevelButton:hover": {
      color: theme.customPalette.color.blue1,
    },
    "& .icon-new-window": {
      fill: theme.typography.subtitle1.color,
    },
  },
});
export const routeCallback = (path, history) => () => {
  history.push(path);
};
class GlobalHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDropdownOpen: false,
      participantDropdownOpen: false,
      userName: "",
      isInternal: "",
    };
  }
  state = {
    cpLogoData: "",
    wellDyneData: "",
    serveYouData: "",
    logoByteInfoData: "",
    RoboOption: "",
    account: [],
    selectedAccount: "",
    accountMetaData: {},
  };
  getFollowUsData(props) {
    const { whiteLabel } = props;
    if (whiteLabel && !whiteLabel.whiteLabelVal && whiteLabel.data === null) {
      this.fetchCPLogo(props);
    }
    if (whiteLabel.whiteLabelVal) {
      if (whiteLabel.data && whiteLabel.data.name === "welldyne") {
        this.fetchWellDyneLogo(props);
      }
      if (whiteLabel.data && whiteLabel.data.name === "serveyou") {
        this.fetchServeYouLogo(props);
      }
    }
  }
  fetchCPLogo(props) {
    const { cpHeaderLogo } = props;
    if (cpHeaderLogo && !cpHeaderLogo.isCPLogoError) {
      if (!cpHeaderLogo.isCPLogoFetching) {
        if (cpHeaderLogo.cpLogoData) {
          if (cpHeaderLogo.cpLogoData !== this.state.cpLogoData) {
            if (cpHeaderLogo.cpLogoData.length > 0) {
              this.getLogoByteData(
                props,
                cpHeaderLogo.cpLogoData[0] &&
                  cpHeaderLogo.cpLogoData[0].field_image,
                "CPData"
              );
            }
            this.setState({ cpLogoData: cpHeaderLogo.cpLogoData });
          } else {
            this.getLogoByteData(
              props,
              cpHeaderLogo.cpLogoData[0] &&
                cpHeaderLogo.cpLogoData[0].field_image
            );
          }
        } else {
          this.props.dispatch(getCPLogoContent());
        }
      }
    }
  }
  fetchServeYouLogo(props) {
    const { cpHeaderLogo } = props;
    if (cpHeaderLogo && !cpHeaderLogo.isServeYouError) {
      if (!cpHeaderLogo.isServeYouFetching) {
        if (cpHeaderLogo.serveYouData) {
          if (cpHeaderLogo.serveYouData !== this.state.serveYouData) {
            this.setState({ serveYouData: cpHeaderLogo.serveYouData });
            if (cpHeaderLogo.serveYouData.length > 0) {
              this.getLogoByteData(
                props,
                cpHeaderLogo.serveYouData[0] &&
                  cpHeaderLogo.serveYouData[0].field_image,
                "serveYou"
              );
            }
          } else {
            this.getLogoByteData(
              props,
              cpHeaderLogo.serveYouData[0] &&
                cpHeaderLogo.serveYouData[0].field_image
            );
          }
        } else {
          this.props.dispatch(getServeYouContent());
        }
      }
    }
  }
  fetchWellDyneLogo(props) {
    const { cpHeaderLogo } = props;
    if (cpHeaderLogo && !cpHeaderLogo.isWellDyneError) {
      if (!cpHeaderLogo.isWellDyneFetching) {
        if (cpHeaderLogo.wellDyneData) {
          if (cpHeaderLogo.wellDyneData !== this.state.wellDyneData) {
            this.setState({ wellDyneData: cpHeaderLogo.wellDyneData });
            if (cpHeaderLogo.wellDyneData.length > 0) {
              this.getLogoByteData(
                props,
                cpHeaderLogo.wellDyneData[0] &&
                  cpHeaderLogo.wellDyneData[0].field_image,
                "wellDyne"
              );
            }
          } else {
            this.getLogoByteData(
              props,
              cpHeaderLogo.wellDyneData[0] &&
                cpHeaderLogo.wellDyneData[0].field_image
            );
          }
        } else {
          this.props.dispatch(getWellDyneContent());
        }
      }
    }
  }
  getLogoByteData(props, code, name) {
    const { filesFromBytes, dispatch } = props;
    if (filesFromBytes) {
      const byteInfo = this.getData(name, filesFromBytes.reponseB64);
      if (!byteInfo) {
        const req = {};
        req["filePath"] = code;
        dispatch(getBasicFilesFromBytesB64(req, name));
      } else if (byteInfo && byteInfo.bytes) {
        this.setState({ logoByteInfoData: byteInfo });
      }
    }
  }
  getByteData(code, name) {
    const { filesFromBytes, dispatch } = this.props;
    if (code && filesFromBytes) {
      const byteInfo = this.getData(name, filesFromBytes.reponseB64);
      if (!byteInfo) {
        const req = {};
        req["filePath"] = code;
        dispatch(getFilesFromBytesB64(req, name));
      } else if (byteInfo && byteInfo.bytes) {
        return byteInfo.bytes;
      } else {
        return null;
      }
    }
    return null;
  }
  getData(name, imagesData) {
    return imagesData.find((item) => {
      return item[globalConstants.EMAIL] === name;
    });
  }
  componentDidMount() {
    this.accountConfiguration(this.props);
    this.setAccountLogo(this.props);
    const { selectedAccount } = this.props;
    this.getRoboOptionFromAccount(selectedAccount);
    this.getFollowUsData(this.props);
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setAccountLogo(props);
    this.setName(props);
    this.accountConfiguration(props);
    const { user } = props;
    if (user) {
      this.setState({ isInternal: isInternal(user) });
    }
    const { selectedAccount } = props;
    if (selectedAccount) {
      this.getRoboOptionFromAccount(selectedAccount);
    }
    this.getFollowUsData(props);
  }
  accountConfiguration(nextProps) {
    if (nextProps.userAccount.account && nextProps.selectedAccount) {
      const { account, selectedAccount } = nextProps;
      this.setState({ account, selectedAccount });
    }
  }
  change = (e) => {
    e.preventDefault();
    this.setState({
      participantDropdownOpen: !this.state.participantDropdownOpen,
    });
  };
  selectChange = async (val) => {
    this.props.dispatch(setSelectedMode(val));
    this.setState({
      participantDropdownOpen: !this.state.participantDropdownOpen,
    });
  };
  handleClickOutside = () => {
    this.setState({ userDropdownOpen: false });
    this.setState({ participantDropdownOpen: false });
  };
  setAccountLogo(nextProps) {
    const { userAccount } = nextProps;
    const selectedAccount = userAccount.selectedAccount;
    if (selectedAccount) {
      if (selectedAccount && selectedAccount.u_account) {
        const { accountsMetaData } = nextProps;
        if (accountsMetaData) {
          const accountMetaData = this.getAccountMetaData(
            selectedAccount.u_account,
            accountsMetaData.data
          );
          if (accountMetaData) {
            this.setState({
              accountMetaData,
            });
          } else {
            this.props.dispatch(
              getAccountMetaDataContent(selectedAccount.u_account)
            );
          }
        }
      }
    }
  }
  getAccountMetaData(accountID, accountsMetaData) {
    return (
      accountsMetaData &&
      accountsMetaData.length > 0 &&
      accountsMetaData.find((item) => {
        return item && item.accountId === accountID;
      })
    );
  }
  getRoboOptionFromAccount(selectedAccount) {
    var options = null;
    var baseLineContentAccess = false;
    var fileCabinetAccess = false;
    var applicationTabAccess = false;
    var requestTabAccess = false;
    var accessToBU = false;
    if (selectedAccount) {
      baseLineContentAccess = true;
      fileCabinetAccess = this.getInvoiceCabinet(selectedAccount);
      applicationTabAccess =
        this.getClientApplicationTabVisibility(selectedAccount);
      requestTabAccess = this.getClientRequestVisibility(selectedAccount);
      accessToBU = this.getBusinessUnitAccess(selectedAccount);
      options = this.getOptionName(
        baseLineContentAccess,
        fileCabinetAccess,
        applicationTabAccess,
        requestTabAccess,
        accessToBU
      );
      this.setState({ RoboOption: options });
      return options;
    }
  }
  getOptionName(
    baseLineContentAccess,
    fileCabinetAccess,
    applicationTabAccess,
    requestTabAccess,
    accessToBU
  ) {
    var result = "";
    if (
      baseLineContentAccess &&
      requestTabAccess &&
      applicationTabAccess &&
      accessToBU &&
      fileCabinetAccess
    ) {
      return "WebHelp1";
    } else if (
      baseLineContentAccess &&
      requestTabAccess &&
      applicationTabAccess &&
      accessToBU
    ) {
      return "WebHelp2";
    } else if (
      baseLineContentAccess &&
      requestTabAccess &&
      applicationTabAccess &&
      fileCabinetAccess
    ) {
      return "WebHelp3";
    } else if (
      baseLineContentAccess &&
      requestTabAccess &&
      applicationTabAccess
    ) {
      return "WebHelp4";
    } else if (
      baseLineContentAccess &&
      requestTabAccess &&
      accessToBU &&
      fileCabinetAccess
    ) {
      return "WebHelp5";
    } else if (baseLineContentAccess && requestTabAccess && accessToBU) {
      return "WebHelp6";
    } else if (baseLineContentAccess && requestTabAccess && fileCabinetAccess) {
      return "WebHelp7";
    } else if (baseLineContentAccess && requestTabAccess) {
      return "WebHelp8";
    } else if (
      baseLineContentAccess &&
      applicationTabAccess &&
      accessToBU &&
      fileCabinetAccess
    ) {
      return "WebHelp9";
    } else if (baseLineContentAccess && applicationTabAccess && accessToBU) {
      return "WebHelp10";
    } else if (
      baseLineContentAccess &&
      applicationTabAccess &&
      fileCabinetAccess
    ) {
      return "WebHelp11";
    } else if (baseLineContentAccess && applicationTabAccess) {
      return "WebHelp12";
    } else if (baseLineContentAccess && accessToBU && fileCabinetAccess) {
      return "WebHelp13";
    } else if (baseLineContentAccess && accessToBU) {
      return "WebHelp14";
    } else if (baseLineContentAccess && fileCabinetAccess) {
      return "WebHelp15";
    } else if (baseLineContentAccess) {
      return "WebHelp16";
    }
    return result;
  }
  getBusinessUnitAccess(selectedAccount) {
    var spec = false;
    var hd = false;
    var cs = false;
    var gp = false;
    spec = this.getSpeciality(selectedAccount);
    if (spec) {
      return true;
    }
    hd = this.getMailtab(selectedAccount);
    if (hd) {
      return true;
    }
    cs = this.getClinicalServices(selectedAccount);
    if (cs) {
      return true;
    }
    gp = this.getMediPartD(selectedAccount);
    if (gp) {
      return true;
    }
    return false;
  }
  getInvoiceCabinet(selectedAccount) {
    if (
      selectedAccount &&
      selectedAccount[globalConstants.NAVIGATION_FILE_CABINET_TAB]
    ) {
      if (
        selectedAccount[globalConstants.NAVIGATION_FILE_CABINET_TAB] ===
        globalConstants.YES
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  getClientRequestVisibility(selectedAccount) {
    if (
      selectedAccount &&
      selectedAccount[globalConstants.NAVIGATION_REQUEST_TAB]
    ) {
      if (
        selectedAccount[globalConstants.NAVIGATION_REQUEST_TAB] ===
          globalConstants.SHOW ||
        selectedAccount[globalConstants.NAVIGATION_REQUEST_TAB] ===
          globalConstants.SHOW_DASHBOARD
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  getClientApplicationTabVisibility(selectedAccount) {
    if (
      selectedAccount &&
      selectedAccount[globalConstants.NAVIGATION_APPLICATION_TAB]
    ) {
      if (
        selectedAccount[globalConstants.NAVIGATION_APPLICATION_TAB] ===
        globalConstants.SHOW
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  getClinicalServices(selectedAccount) {
    if (
      selectedAccount &&
      selectedAccount[globalConstants.NAVIGATION_CLINICAL_SERVICE_TAB] &&
      selectedAccount[globalConstants.NAVIGATION_CLINICAL_SERVICE_TAB] !== "" &&
      selectedAccount[globalConstants.NAVIGATION_CLINICAL_SERVICE_TAB] !==
        globalConstants.NO_ACCESS
    ) {
      return true;
    } else {
      return false;
    }
  }
  getSpeciality(selectedAccount) {
    if (
      selectedAccount &&
      selectedAccount[globalConstants.NAVIGATION_SPECIALTY_TAB] &&
      selectedAccount[globalConstants.NAVIGATION_SPECIALTY_TAB] !== "" &&
      selectedAccount[globalConstants.NAVIGATION_SPECIALTY_TAB] !==
        globalConstants.NO_ACCESS
    ) {
      return true;
    } else {
      return false;
    }
  }
  getMediPartD(selectedAccount) {
    if (
      selectedAccount &&
      selectedAccount[globalConstants.NAVIGATION_GOVERNMENT_PROGRAM_TAB] &&
      selectedAccount[globalConstants.NAVIGATION_GOVERNMENT_PROGRAM_TAB] !==
        "" &&
      selectedAccount[globalConstants.NAVIGATION_GOVERNMENT_PROGRAM_TAB] !==
        globalConstants.NO_ACCESS
    ) {
      return true;
    } else {
      return false;
    }
  }
  getMailtab(selectedAccount) {
    if (
      selectedAccount &&
      selectedAccount[globalConstants.NAVIGATION_HOME_DELIVERY_TAB] &&
      selectedAccount[globalConstants.NAVIGATION_HOME_DELIVERY_TAB] !== "" &&
      selectedAccount[globalConstants.NAVIGATION_HOME_DELIVERY_TAB] !==
        globalConstants.NO_ACCESS
    ) {
      return true;
    } else {
      return false;
    }
  }
  navigationContentTabVisible(nodename) {
    const { selectedAccount } = this.props;
    if (
      selectedAccount &&
      selectedAccount[nodename] &&
      selectedAccount[nodename] !== globalConstant.NO_ACCESS
    ) {
      return true;
    }
    return false;
  }
  navigationContentVisible(nodename) {
    const { selectedAccount } = this.props;
    if (
      selectedAccount &&
      selectedAccount[nodename] &&
      (selectedAccount[nodename] === globalConstant.SHOW ||
        selectedAccount[nodename] === globalConstant.SHOW_DASHBOARD)
    ) {
      return true;
    }
    return false;
  }
  validateRoutes() {
    const { isWhiteLabel } = this.props;
    let routes = [];
    if (
      !isWhiteLabel &&
      this.navigationContentVisible(globalConstant.NAVIGATION_REQUEST_TAB)
    ) {
      routes.push({
        url: "#",
        title: Label.Requests,
        target: "",
        type: "internal",
        onClick: routeCallback(Route.requests, this.props.history),
      });
    }
    if (
      this.navigationContentVisible(globalConstant.NAVIGATION_APPLICATION_TAB)
    ) {
      routes.push({
        url: "#",
        title: Label.Applications,
        target: "",
        type: "internal",
        onClick: routeCallback(Route.applications, this.props.history),
      });
    }
    if (
      !isWhiteLabel &&
      this.navigationContentTabVisible(
        globalConstant.NAVIGATION_CLINICAL_SERVICE_TAB
      )
    ) {
      routes.push({
        url: "#",
        title: Label.ClinicalServices,
        target: "",
        type: "internal",
        onClick: routeCallback(Route.clinicalServices, this.props.history),
      });
    }
    if (
      !isWhiteLabel &&
      this.navigationContentTabVisible(
        globalConstant.NAVIGATION_HOME_DELIVERY_TAB
      )
    ) {
      routes.push({
        url: "#",
        title: Label.HomeDelivery,
        target: "",
        type: "internal",
        onClick: routeCallback(Route.homedelivery, this.props.history),
      });
    }
    if (
      !isWhiteLabel &&
      this.navigationContentTabVisible(globalConstant.NAVIGATION_SPECIALTY_TAB)
    ) {
      routes.push({
        url: "#",
        title: Label.Specialty,
        target: "",
        type: "internal",
        onClick: routeCallback(Route.specialty, this.props.history),
      });
    }
    if (
      !isWhiteLabel &&
      this.navigationContentTabVisible(
        globalConstant.NAVIGATION_GOVERNMENT_PROGRAM_TAB
      )
    ) {
      routes.push({
        url: "#",
        title: Label.GovernmentPrograms,
        target: "",
        type: "internal",
        onClick: routeCallback(Route.govermentPrograms, this.props.history),
      });
    }
    if (!isWhiteLabel) {
      routes.push({
        url: "#",
        title: Label.FileCabinet,
        target: "",
        type: "internal",
        onClick: routeCallback(Route.filecabinet, this.props.history),
      });
    }
    return routes;
  }
  setName(props) {
    const { selectedAccount, user, userProfile } = props;
    if (selectedAccount) {
      let userName;
      if (isExternal(user)) {
        userName = selectedAccount["u_contact.first_name"];
      } else {
        if (
          userProfile &&
          userProfile.profileData &&
          userProfile.profileData.given_name
        ) {
          userName = userProfile.profileData.given_name;
        }
      }
      if (userName !== this.state.userName) {
        this.setState({ userName });
      }
    }
  }
  render() {
    const { classes } = this.props;
    const { isInternal, userName, account, selectedAccount } = this.state;
    const { whiteLabel, auth, isItemVisibleForLaunch, userAccount } =
      this.props;
    const { onItemchange } = this.props;
    const { RoboOption } = this.state;
    const { logoByteInfoData } = this.state;
    var accountLogo = "";
    const { accountMetaData } = this.state;
    const routesForPromoDropdown = [
      routePath.clinicalServices,
      routePath.homedelivery,
      routePath.govermentPrograms,
      routePath.specialty,
    ];
    const showPromosDropdown =
      routesForPromoDropdown.indexOf(this.props.location.pathname) !== -1;
    let menuItemArray = [];
    let logo = " ";
    let productName = [];
    let homeRoute = "";
    let loginRoute = "";
    if (!window.location.search) {
      homeRoute = Route.home;
      loginRoute = Route.login;
    } else if (window.location.search === "?oid=true") {
      homeRoute = Route.home + "?oid=true";
      loginRoute = Route.login + "?oid=true";
    } else {
      homeRoute = Route.home;
      loginRoute = Route.home;
    }
    let content = [];
    if (window.location.pathname.includes("/home/sso/launch")) {
      return null;
    } else {
      if (this.props.selectedAccount) {
        const { isWhiteLabel } = this.props;
        content = this.validateRoutes(isWhiteLabel);
      }
    }
    if (
      whiteLabel &&
      whiteLabel.whiteLabelVal &&
      this.props.auth.isAuthenticated
    ) {
      content.push({
        url: "#",
        id: "WhiteLabelHome",
        title: "Home",
        target: "",
        type: "internal",
        onClick: routeCallback(Route.home, this.props.history),
      });
    } else {
      if (this.props.auth.isAuthenticated) {
        if (!isInternal) {
          if (RoboOption) {
            content.push({
              url:
                process.env.REACT_APP_ROBOHELP_URL + RoboOption + "/start.htm",
              title: "Help",
              type: "external",
              target: "_blank",
            });
          }
        } else {
          content.push({
            url: process.env.REACT_APP_ROBOHELP_URL + "WebHelp1/start.htm",
            title: "Help",
            type: "external",
            target: "_blank",
          });
        }
      }
    }
    if (
      auth.isAuthenticated &&
      userAccount &&
      userAccount.account &&
      userAccount.account.length > 1
    ) {
      let dropDown = [];
      if (userAccount.account && userAccount.account.length > 0) {
        userAccount.account.map((item, index) =>
          dropDown.push({
            title: item["u_account.name"],
            id: item["u_account"],
            onClick: onItemchange(userAccount.account),
          })
        );
        if (selectedAccount) {
          menuItemArray.push({
            title: "Viewing:" + selectedAccount["u_account.name"],
            url: "#",
            id: "Viewing",
            children: dropDown,
          });
        }
      }
    }
    if (auth.isAuthenticated) {
      if (
        accountMetaData &&
        accountMetaData.accountMetaData &&
        accountMetaData.accountMetaData.length > 0
      ) {
        accountLogo = accountMetaData.accountMetaData[0];
        if (
          accountLogo &&
          accountLogo["field_account_logo"] &&
          this.getByteData(
            accountLogo["field_account_logo"],
            accountLogo["field_code"]
          )
        ) {
          let accountlogo = (
            <img
              src={
                "data:image/png;base64, " +
                this.getByteData(
                  accountLogo["field_account_logo"],
                  accountLogo["field_code"]
                )
              }
              alt=""
            />
          );
          logo = accountlogo;
        }
      }
    }
    if (auth.isAuthenticated && showPromosDropdown) {
      if (isInternal) {
        let showpromoDropdown = [
          {
            title: "Participant",
            id: "PARTICIPANT",
            onClick: this.selectChange.bind(this, "PARTICIPANT"),
          },
          {
            title: "Promotional",
            id: "PROMOTIONAL",
            onClick: this.selectChange.bind(this, "PROMOTIONAL"),
          },
        ];
        if (userAccount) {
          menuItemArray.push({
            title:
              userAccount["selectedMode"].charAt(0).toUpperCase() +
              userAccount["selectedMode"].slice(1).toLowerCase(),
            url: "#",
            id: "participantPromtionalDB",
            children: showpromoDropdown,
          });
        }
      }
    }
    if (!userName && this.props.auth.isAuthenticated) {
      menuItemArray.push({
        title: Label.SignOut,
        url: "#",
        target: "_self",
        onClick: (e) => {
          e.preventDefault();
          this.props.logoutClick();
        },
      });
    }
    if (!whiteLabel.whiteLabelVal) {
      let logoimage = (
        <NavLink
          link-id={"OptumRx"}
          to={auth && auth.isAuthenticated ? homeRoute : loginRoute}
        >
          <OptumLogo />
        </NavLink>
      );
      logo = logoimage;
      if (isItemVisibleForLaunch) {
        productName.push(Label.ClientPortal);
      } else {
        productName.push(Label.IDA);
      }
    }
    if (whiteLabel.whiteLabelVal) {
      if (whiteLabel.data && whiteLabel.data.logo) {
        let logoimage = (
          <NavLink
            link-id={"WhiteLabellogo"}
            to={auth && auth.isAuthenticated ? homeRoute : loginRoute}
          >
            <img
              src={
                logoByteInfoData && logoByteInfoData.bytes
                  ? `data:image/png;base64, ${logoByteInfoData.bytes}`
                  : ""
              }
              className={!logoByteInfoData ? "noLogo" : "logo"}
              alt="logo"
            />
          </NavLink>
        );
        logo = logoimage;
      } else {
        return "";
      }
      if (isItemVisibleForLaunch) {
        productName.push(Label.ClientPortal);
      } else {
        productName.push(Label.IDA);
      }
    }
    if (this.props.auth.isAuthenticated && userName) {
      menuItemArray.push({
        title: userName,
        url: "#",
        id: "userInfo",
        target: "",
        type: "internal",
        children: [
          {
            url: "#",
            title: Label.Profile,
            id: "Profile",
            target: "",
            type: "internal",
            onClick: routeCallback(routes.profile, this.props.history),
          },
          {
            title: Label.SignOut,
            url: "#",
            target: "_self",
            id: "Signout",
            onClick: (e) => {
              e.preventDefault();
              this.props.logoutClick();
            },
          },
        ],
      });
    }
    return (
      <div className={classes.main}>
        <ComboHeader
          headerNavItems={menuItemArray}
          mainNavItems={content}
          logo={logo}
          logoTarget="_blank"
          productName={productName}
        ></ComboHeader>
      </div>
    );
  }
}
const mapStateToProps = ({
  selectedAccount,
  userAccount,
  user,
  userProfile,
  whiteLabel,
  cpHeaderLogo,
  filesFromBytes,
  accountsMetaData,
  auth,
}) => {
  return {
    ...selectedAccount,
    userAccount,
    user,
    userProfile,
    whiteLabel,
    cpHeaderLogo,
    filesFromBytes,
    accountsMetaData,
    auth,
  };
};
export default withStyles(styles)(
  connect(mapStateToProps)(withRouter(onClickOutside(GlobalHeader)))
);
