import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { getFileCabinetContent } from "../actions/fileCabinetContent";
import FileCabinet from "../components/FileCabinet/FileCabinet";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import InvoiceAndFinancialReportContainer from "./InvoiceAndFinancialReportContainer";
import DocumentLibraryContainer from "./DocumentLibraryContainer";
import BASContainer from "./BASContainer";
import globalConstants from "../constants/global-constants";
import Label from "../constants/labels";
import { pageLinkAction } from "../actions/tealium";
import user from "../reducers/user";
// import userAccount from "../reducers/userAccount";

const grps = [
  process.env.REACT_APP_EOB_OPS_ADMIN,
  process.env.REACT_APP_EOB_SP_USR_ADMIN,
  process.env.REACT_APP_TB_SP_USR_ADMIN
];

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class FileCabinetContentContainer extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const parameter = new URLSearchParams(location && location.search);
    const defaultValue =
      parameter && parameter.get("value") && parameter.get("value") === "1"
        ? 1
        : 0;
    this.state = {
      fileCabinet: "",
      value: defaultValue,
      noAccess: "",
      isDetailVisibleProp: false,
      usrGrps: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, value) {
    const { isDetailVisibleProp } = this.state;
    if (event.target.innerText) {
      this.props.dispatch(pageLinkAction(event.target.innerText, "", ""));
    }
    if (isDetailVisibleProp) {
      this.setState({ value, isDetailVisibleProp: !isDetailVisibleProp });
    } else {
      this.setState({ value, isDetailVisibleProp });
    }
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setFileCabinet(nextprops);
    this.access_EOB_TB();
  }

  componentDidMount() {
    document.title = "Client Portal - File cabinet";
    this.access_EOB_TB();
    this.setFileCabinet(this.props);
  }

  setPageVisiblity(isDetailVisible) {
    this.setState({ isDetailVisibleProp: isDetailVisible });
  }

  setFileCabinet(props) {
    const { fileCabinetContent } = props;
    if (!fileCabinetContent.isError) {
      if (!fileCabinetContent.isFetching) {
        if (fileCabinetContent.fileCabinetContent) {
          if (
            fileCabinetContent.fileCabinetContent &&
            fileCabinetContent.fileCabinetContent !== this.state.fileCabinet
          ) {
            this.setState({
              fileCabinet: fileCabinetContent.fileCabinetContent,
            });
            if (
              fileCabinetContent &&
              fileCabinetContent.fileCabinetContent.length > 0 &&
              fileCabinetContent.fileCabinetContent[0]
            ) {
              this.setState({
                noAccess:
                  fileCabinetContent.fileCabinetContent[0].field_body_secondary,
              });
            }
          }
        } else {
          this.props.dispatch(getFileCabinetContent());
        }
      }
    }
  }

  isAccessOnTabs(nodename) {
    const { userAccount } = this.props;
    if (
      userAccount.selectedAccount &&
      userAccount.selectedAccount[nodename] &&
      userAccount.selectedAccount[nodename].toLowerCase().includes(globalConstants.yes)
    ) {
      return true;
    }
    return false;
  }

  access_EOB_TB() {
    const { userProfile } = this.props;
    const arr = [];
    if (
      userProfile.profileData &&
      userProfile.profileData.roles &&
      userProfile.profileData.roles.length > 0
    ) {
      grps.forEach((grp) => {
        userProfile.profileData.roles.find((item) => {
          if (item && grp && (item.toLowerCase().includes(grp.toLowerCase()))) {
            arr.push(grp);
          }
        });
      });
      if (arr && arr.length > 0) {
        // console.log("res inn", arr)
        this.setState({
          usrGrps: arr
        })
        return true;
      }
      else return false;
    }
    return false;
  }


  render() {
    const { userAccount, userProfile } = this.props;
    const { value, isDetailVisibleProp } = this.state;
    const contentData = this.state.fileCabinet[0];
    // console.log("usrgrps ", this.state.usrGrps)
    if (!contentData) {
      return null;
    }

    if (this.isAccessOnTabs(globalConstants.NAVIGATION_FILE_CABINET_TAB)) {
      return (
        <React.Fragment>
          <FileCabinet contentData={contentData} />
          <div className="tabWrapper row--">
            <StyledTabs value={value} onChange={this.handleChange}>
              {this.isAccessOnTabs(
                globalConstants.NAVIGATION_FILE_CABINET_TAB
              ) && (
                  <StyledTab
                    label={Label.INVOICEANDFINANCIALREPORTS}
                    link-id={"Invoices and Financial Reports"}
                  />
                )}
              <StyledTab
                label={Label.DOCUMENTLIBRARY}
                link-id={"Document Library"}
              />
              {/* {((this.state.usrGrps && this.state.usrGrps.length > 0) ||
                (userAccount && userAccount.selectedAccount &&
                  (userAccount.selectedAccount[globalConstants.EOBFLAG] === "true" || userAccount.selectedAccount[globalConstants.TBFLAG] === "true"))) &&
                <StyledTab
                  label={Label.BENEFITSARCHIVESEARCH}
                  link-id={"Benefits archive search"}
                />
              } */}
            </StyledTabs>

            {value === 0 &&
              this.isAccessOnTabs(
                globalConstants.NAVIGATION_FILE_CABINET_TAB
              ) && (
                <TabContainer>
                  <InvoiceAndFinancialReportContainer
                    isDetailVisibleProp={isDetailVisibleProp}
                    setPageVisiblity={this.setPageVisiblity.bind(this)}
                  />
                </TabContainer>
              )}
            {value === 1 && (
              <TabContainer>
                <DocumentLibraryContainer fetchDatafromContactId={userAccount.fetchDatafromContactId} />
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer>
                <BASContainer
                  fetchDatafromContactId={userAccount.fetchDatafromContactId}
                  selectedAccount={userAccount && userAccount.selectedAccount}
                  usrGrps={this.state.usrGrps}
                />
              </TabContainer>
            )}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <FileCabinet contentData={contentData} />
          <div className="tabWrapper row--">
            <StyledTabs value={value} onChange={this.handleChange}>
              <StyledTab
                label={Label.DOCUMENTLIBRARY}
                link-id={"Document Library"}
              />
              {userAccount && userAccount.selectedAccount && (userAccount.selectedAccount[globalConstants.EOBFLAG] === "true" || userAccount.selectedAccount[globalConstants.TBFLAG] === "true") &&
                <StyledTab
                  label={Label.BENEFITSARCHIVESEARCH}
                  link-id={"Benefits archive search"}
                />
              }
            </StyledTabs>

            {value === 0 &&
              <TabContainer>
                <DocumentLibraryContainer fetchDatafromContactId={userAccount.fetchDatafromContactId} />
              </TabContainer>
            }
            {value === 1 && (
              <TabContainer>
                <BASContainer
                  fetchDatafromContactId={userAccount.fetchDatafromContactId}
                  selectedAccount={userAccount && userAccount.selectedAccount}
                  usrGrps={this.state.usrGrps}
                />
              </TabContainer>
            )}
          </div>
        </React.Fragment>
      );
    }
  }
}

const StyledTab = withStyles({
  root: {
    background: "#282a2e",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: "solid 1px transparent",
    color: "#ffffff",
    fontFamily: '"frutiger-light", Arial, sans-serif',
    fontSize: "14px",
    marginRight: "5px",
    opacity: "1",
    textTransform: "none",
    position: "relative",
    bottom: "-1px",

    height: "auto",
    minHeight: "auto",
    minWidth: "230px",
    maxWidth: "none",
    padding: "10px 30px 9px",

    "&:hover": {
      background: "#ffffff",
      border: "solid 1px #282a2e",
      borderBottom: "solid 1px #ffffff",
      color: "#282a2e",
    },
  },
  selected: {
    background: "#ffffff",
    border: "solid 1px #282a2e",
    borderBottom: "solid 1px #ffffff",
    color: "#282a2e",
  },
  wrapper: {
    fontSize: "14px",
  },
})(Tab);

const StyledTabs = withStyles({
  fixed: {
    borderBottom: "solid 1px #282a2e",
    overflow: "visible !important",
    padding: "14px 30px 0",
  },
  indicator: {
    height: 0,
  },
})(Tabs);

const mapStateToProps = ({ fileCabinetContent, userAccount, auth, userProfile }) => {
  return {
    fileCabinetContent,
    userAccount,
    // selectedAccount: userAccount.selectedAccount,
    auth,
    userProfile
  };
};

export default connect(mapStateToProps)(
  withRouter(FileCabinetContentContainer)
);
