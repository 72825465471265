import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import Collapse from "@material-ui/core/Collapse";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import "./announcement.scss";
import globalConstants from "../../../constants/global-constants";
import moment from "moment";
import { getFilesFromBytes } from "../../../actions/filesFromBytes";

class Announcement extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = { collapseOpen: false };
  }

  componentDidMount() {
    this.addListener();
  }

  componentWillUnmount() {
    this.addListener();
  }

  updateViewAll = () => {
    this.setState((prevState) => {
      return {
        collapseOpen: !prevState.collapseOpen,
      };
    });
  };

  overrideLinkHandler = (e) => {
    if (
      e.target.tagName === "A" &&
      e.target.getAttribute("class") === "internalLink"
    ) {
      e.preventDefault();
      this.props.history.push(e.target.getAttribute("href"));
    } else if (
      e.target.tagName === "A" &&
      e.target.getAttribute("href") &&
      e.target.getAttribute("class") === "downloadLink"
    ) {
      e.preventDefault();
      const hrefData = e.target.getAttribute("href");
      const fileName = hrefData.substr(hrefData.lastIndexOf("/") + 1);
      const req = {};
      req["filePath"] = hrefData;
      this.props.dispatch(getFilesFromBytes(req, fileName));
    }
  };

  addListener() {
    ReactDOM.findDOMNode(this).addEventListener(
      "click",
      this.overrideLinkHandler
    );
  }

  getFormattedDate(date) {
    if (date) {
      const formattedDate = moment(date).format(globalConstants.ANNOUNCEMENTDATE);
      return formattedDate;
    }
    return null;
  }

  render() {
    const { item } = this.props;
    return (
      <div className="announcementCard" ref={this.containerRef}>
        {item && (
          <div>
            <div className="date">{this.getFormattedDate(item.field_date)}</div>
            <h2 className="title">{renderHTML(item.title)}</h2>
            <div className="brief">
              {renderHTML(item.body)}
              <Collapse in={this.state.collapseOpen} collapsedHeight="1px">
                {renderHTML(item.field_body_secondary)}
              </Collapse>
            </div>

            <div className="moreBtn">
              {item.field_body_secondary && !this.state.collapseOpen ? (
                <a
                  href="javascript:void(0)"
                  link-id="learn_more"
                  onClick={this.updateViewAll}
                >
                  Learn more <NavigateNextIcon />
                </a>
              ) : (
                  ""
                )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStateToProps)(withRouter(Announcement));
