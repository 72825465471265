import Axios from "axios";
import _ from "lodash";
import {
  getAPIURLs,
  getMSAccessFromRefreshToken,
  getOIDAccessFromRefreshToken,
  logoutuser,
  sessionUrl,
} from "../constants/microservice-helpers";
import store from "../store/store";
import { notifyNetworkErr } from "../actions/networkError";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const accepTypeURl = [
  { url: "getBillingEntity" },
  { url: "getclients" },
  { url: "getInvoiceDoc" },
  { url: "getclients" },
];

let isRefreshing = false;
let refreshSubscribers = [];
let count = 0;
let openModal = false;

Axios.interceptors.request.use(
  function (config) {
    const accessToken = sessionStorage.getItem("session_id");
    // const accessToken = cookies.get('access_token');
    const type = sessionStorage.getItem("type");

    if (isAcceptTypeUrl(config)) {
      config.headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: accessToken,
        AuthorizationType: type,
      };
    } else {
      if (config.method === "post") {
        config.headers = {
          "Content-Type": "application/json",
          Authorization: accessToken,
          AuthorizationType: type,
        };
      } else if (
        config.method === "get" &&
        (config.url.indexOf("windows.net/cbs") >= 1 ||
          config.url.indexOf("-cbs.optumrx.com") >= 1)
      ) {
        config.headers = {};
      } else {
        config.headers = {
          Authorization: accessToken,
          AuthorizationType: type,
        };
      }
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
Axios.interceptors.response.use(
  (response) => {
    const accessToken = sessionStorage.getItem("session_id");
    // const accessToken = cookies.get('access_token');
    const type = sessionStorage.getItem("type");

    if (response.config.url.indexOf("getNodeByCode/page/AccountProfile") >= 1) {
      response.config.headers = {
        "Cache-Control": "no-cache, no-store",
        Expires: 0,
        Pragma: "no-cache",
        Authorization: accessToken,
        AuthorizationType: type,
      };
      response.headers = {
        "Cache-Control": "no-cache, no-store",
        Expires: 0,
        Pragma: "no-cache",
        Authorization: accessToken,
        AuthorizationType: type,
      };
    }
    const cmsUrl = getAPIURLs(process.env.REACT_APP_CONTENTSERVICE_URL);
    if (response.config.url.indexOf(cmsUrl) !== -1) {
      const data = response.data;
      if (data) {
        return response;
      } else {
        response.data = [];
        return response;
      }
    }
    return response;
  },

  (error) => {
    if (error && error.response) {
      const { config, response } = error;
      const originalRequest = config;

      if (response && response.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;
          const req = makeRequestWithOldRefreshToken();
          const type = sessionStorage.getItem("type");
          let url;
          if (type === "msid") {
            url = getMSAccessFromRefreshToken();
          } else {
            url = getOIDAccessFromRefreshToken();
          }
          refreshSignature();
          if (req.refreshToken && req.refreshToken != null) {
            Axios.post(url, JSON.stringify(req)).then((newToken) => {
              sessionStorage.setItem("session_id", newToken.data.sessionId);
              isRefreshing = false;
              if (newToken && newToken.data) {
                onRrefreshed(newToken.data.sessionId);
              } else {
                if (process.env.REACT_APP_OID_FLAG == "true") {
                  window.location = onLogoutUser();
                  sessionStorage.clear();
                  localStorage.clear();
                  // dispatch(userLogout());
                  return false;
                } else {
                  // window.location =
                  onLogoutUser();
                  // sessionStorage.clear();
                  return false;
                }
              }
            });
          }
          }
        count = count + 1;
        if (count >= 0 && count < 101) {
          const retryOrigReq = new Promise((resolve, reject) => {
            subscribeTokenRefresh((token) => {
              // replace the expired token and retry
              originalRequest.headers["Authorization"] = token;
              resolve(Axios(originalRequest));
            });
          });
          return retryOrigReq;
        } else {
          if (process.env.REACT_APP_OID_FLAG == "true") {
            window.location = onLogoutUser();
            count = 0;
            sessionStorage.clear();
            localStorage.clear();
          } else {
            onLogoutUser();
            count = 0;
          }
        }
      } else {
        if (
          originalRequest.url.indexOf("/oid/getOIDTokenFromRefreshToken") >=
            1 ||
          originalRequest.url.indexOf("/msid/getMSTokenFromRefreshToken") >= 1
        ) {
          if (process.env.REACT_APP_OID_FLAG == "true") {
            window.location = onLogoutUser();
            sessionStorage.clear();
            localStorage.clear();
            // dispatch(userLogout());
          } else {
            onLogoutUser();
          }
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }
    } else if (error.message === "Network Error") {
      const { dispatch } = store;
      dispatch(notifyNetworkErr(error.config));
      return Promise.reject(error);
    }
  }
);

function refreshSignature() {
  const type = sessionStorage.getItem("type");
  if (type === "msid") {
    const signatureUrl = sessionUrl();
    Axios.get(signatureUrl).then((data) => {
      console.log("Signature Refreshed!!");
    });
  }
}

// logging out user after getting 500 in refresh token service
function onLogoutUser() {
  let customObject = JSON.parse(sessionStorage.getItem("pxCustomData"));
  if (window && window.pxSendCustomData && customObject) {
    customObject["user.action"] = "logout";
    const currentTime = new Date().getTime();
    customObject["user.sessionTime"] =
      currentTime - customObject["user.loginTime"];
    delete customObject["user.loginTime"];
    customObject["user.loginStatus"] = "not logged in";
    customObject["user.logoutTime"] = new Date().getTime();
    sessionStorage.setItem("pxCustomData", JSON.stringify(customObject));
    window.pxSendCustomData(customObject);
  }
  setTimeout(() => {
    // if (
    //   window.Rakanto &&
    //   (!window.Rakanto.q || (window.Rakanto.q && window.Rakanto.q.length === 0))
    // ) {
    const type = sessionStorage.getItem("type");
    const dynamicUrl = checkRedirectLogoutURL(window.location.href);
    if (type && type === "msid") {
      return (
        process.env.REACT_APP_MSID_LOGOUT_URL +
        `/idp/startSLO.ping?TargetResource=${window.location.origin + dynamicUrl}`
      );
    } else {
      if (
        process.env.REACT_APP_OID_FLAG &&
        process.env.REACT_APP_OID_FLAG == "true"
      ) {
        return (
          process.env.REACT_APP_OPTUMID_LOGOUT_URL +
          `/tb/oidc/logout?post_logout_redirect_uri=${
            window.location.origin + dynamicUrl
          }`
        );
      } else {
        functionCallIdToken(dynamicUrl);
      }
    }
    // }
  }, 1500);
}

function logoutUrlOID() {
  const type = sessionStorage.getItem("type");
  const dynamicUrl = checkRedirectLogoutURL(window.location.href);
  if (type && type === "msid") {
    return (
      process.env.REACT_APP_MSID_LOGOUT_URL +
      `/idp/startSLO.ping?TargetResource=${window.location.origin + dynamicUrl}`
    );
  } else {
    return (
      process.env.REACT_APP_OPTUMID_LOGOUT_URL +
      `/tb/oidc/logout?post_logout_redirect_uri=${
        window.location.origin + dynamicUrl
      }`
    );
  }
}

function logoutUrlOHID() {
  const type = sessionStorage.getItem("type");
  const dynamicUrl = checkRedirectLogoutURL(window.location.href);
  if (type && type === "msid") {
    return (
      process.env.REACT_APP_MSID_LOGOUT_URL +
      `/idp/startSLO.ping?TargetResource=${window.location.origin + dynamicUrl}`
    );
  } else {
    functionCallIdToken(dynamicUrl);
  }
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

function checkRedirectLogoutURL(location) {
  if (location) {
    if (
      location.split("/")[3] &&
      location.split("/")[3].toLocaleUpperCase() === "HOME"
    ) {
      return "/";
    } else if (
      location.split("/")[3] &&
      location.split("/")[3].toLocaleUpperCase() === "MEMBERIDGENERATOR"
    ) {
      return "/";
    } else if (
      location.split("/")[3] &&
      location.split("/")[3].toLocaleUpperCase() === "MS"
    ) {
      return "/";
    } else if (
      location.split("/")[3] &&
      location.split("/")[3].toLocaleUpperCase() === "OID"
    ) {
      return "/";
    } else {
      return "/" + window.location.href.split("/")[3];
    }
  }
}

async function functionCallIdToken(dynamicUrl) {
  const url = logoutuser();
  const sessionId = sessionStorage.getItem("session_id");
  const requestHeader = {
    authorization: { sessionId },
  };

  const { status, data: token } = await Axios.get(url, {
    headers: requestHeader,
  });
  try {
    if (status === 200 && token) {
      window.location =
        process.env.REACT_APP_OHID_LOGOUT_URL +
        `?id_token_hint=${token}&post_logout_redirect_uri=${
          window.location.origin + dynamicUrl
        }/&state=clientportal${getRandomInt(1, 99)}`;
      sessionStorage.clear();
      localStorage.clear();
    } else {
      window.location = process.env.REACT_APP_UI_URL_REGION_CENTRAL + "/login";
      sessionStorage.clear();
      localStorage.clear();
    }
  } catch (error) {
    window.location = process.env.REACT_APP_UI_URL_REGION_CENTRAL + "/login";
    sessionStorage.clear();
    localStorage.clear();
  }
}

function makeRequestWithOldRefreshToken() {
  const refreshToken = sessionStorage.getItem("session_id");
  const req = {};
  req["refreshToken"] = refreshToken;
  return req;
}

// Subscribing the token
function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

// After geeting the new token
function onRrefreshed(sessionId) {
  // sessionStorage.setItem("access_token", token);
  sessionStorage.setItem("session_id", sessionId);

  // cookies.set('access_token', token,{HttpOnly: true});
  refreshSubscribers.map((cb) => cb(sessionId));
}

// Check for AccessType Authorization
function isAcceptTypeUrl(data) {
  let isData = false;
  _.find(accepTypeURl, (o) => {
    return (isData = data.url.indexOf(o.url) >= 1 ? true : false);
  });
  return isData;
}

export default {
  get: Axios.get,
  post: Axios.post,
};
