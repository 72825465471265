export const whiteLabels = [
  {
    name: "welldyne",
    logo: "/assets/images/whitelabellogo/wellldyne.png",
    basename: "/welldyne"
  },
  {
    name: "serveyou",
    logo: "/assets/images/whitelabellogo/serveYou.png",
    basename: "/serveyou"
  }
];
