import {
  CS_RELATEDLINK_REQUEST,
  CS_RELATEDLINK_SUCCESS,
  CS_RELATEDLINK_FAILURE
} from "../actions/csRelatedLinksMetaData";

export function csRelatedLinksMetaData(
  state = {
    isFetching: false,
    data: []
  },
  action
) {
  switch (action.type) {
    case CS_RELATEDLINK_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case CS_RELATEDLINK_SUCCESS:
      let data=[];
      if (state.data) {
         data = [...state.data, action.data]
      }
       return Object.assign({}, state, {
        isFetching: false,
        data: data
      });
    case CS_RELATEDLINK_FAILURE:
      let dataFail=[];
      if (state.data) {
        dataFail = [...state.data, action.data]
      }
       return Object.assign({}, state, {
        isFetching: false,
        isClinicalUpdatesError: true,
        error: action.message,
        data: dataFail
      });
    default:
      return state;
  }
}

export default csRelatedLinksMetaData;
