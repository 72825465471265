import React, { Component } from "react";
import { getFileTreeContent } from "../actions/fileTree";
import { connect } from "react-redux";
import FileTree from "../components/Shared/FileTree/FileTree";

const TYPE = "document";

class DocumentLibraryContainer extends Component {
  state = {};

  render() {
    // const productFolderId = this.props.userAccount.selectedAccount["u_account.name"].split(" ").join("");
    const str = this.props.userAccount.selectedAccount["u_account.name"];
    const splittedStr = str.split(" ").join("");
    const encodedStr = encodeURIComponent(splittedStr);
    const productFolderId = encodedStr;
    return (
      <FileTree
        title={""}
        fileTreeContent={this.props.fileTreeContent}
        error={this.props.error && this.props.errorMessage}
        fetchDatafromContactId={this.props && this.props.fetchDatafromContactId}
        getFileTreeContent={getFileTreeContent}
        productFolderId={str}
        type={TYPE}
      />
    );
  }
}

const mapStateToProps = ({ fileCabinetContent, fileTree, userAccount }) => {
  return { ...fileCabinetContent, ...fileTree, userAccount };
};

export default connect(mapStateToProps)(DocumentLibraryContainer);
