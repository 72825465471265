export const PAGE_LOAD_NAME = "PAGE_LOAD_NAME";
export const PAGE_LINK_NAME = "PAGE_LINK_NAME";
export const VIDEO_ACTION = "VIDEO_ACTION";
export const INVOICE_FILTER = "INVOICE_FILTER";

export function pageLoadAction(payload) {
  return {
    type: PAGE_LOAD_NAME,
    payload,
  };
}

export function pageLinkAction(linkName, linkType, exitLinkName) {
  return {
    type: PAGE_LINK_NAME,
    linkName,
    linkType,
    exitLinkName,
  };
}

export function videoAction(status, length, name, event, videoTimeWatched) {
  return {
    type: VIDEO_ACTION,
    status,
    length,
    name,
    event,
    videoTimeWatched,
  };
}

export function invoiceActions(searchTerm, searchFilters, searchResults) {
  return {
    type: INVOICE_FILTER,
    searchTerm,
    searchFilters,
    searchResults,
  };
}
