import React, { Component } from "react";
import { Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import globalConstants from "../../../constants/global-constants";
import { clearDocumentRetrieval } from "../../../actions/getDocumentRetrieval";
import { connect } from "react-redux";
const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    boxShadow: "none",

    "& .MuiTablePagination-root": {
      "& .MuiTablePagination-spacer": {
        display: "none",
      },
    },
  },
  table: {
    minWidth: 1020,

    "& tr:nth-child(even)": {
      background: "#f5f5f5",
    },
  },
  tableWrapper: {
    overflowX: "auto",
    border: "1px solid #ccc",

    "& .MuiTableCell-head": {
      lineHeight: "normal",
      background: "#f5f5f5",
      color: "#000",

      "&:not(.MuiTableCell-paddingCheckbox)": {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
    "& .MuiCheckbox-colorPrimary.Mui-checked": {
      color: "#0879ff",
    },
    "& .MuiTableCell-root": {
      padding: "5px 21px",

      "& .MuiCheckbox-root": {
        padding: 0,
        marginLeft: 10,

        "& + span": {
          whiteSpace: "nowrap",
          fontSize: 10,
        },
      },
    },
  },
  dialogTitleRoot: {
    paddingBottom: 15,
    "& .closeButton": {
      padding: 0,
    },
  },
});

const DialogContent = withStyles((theme) => ({
  dialogTitleRoot: {
    padding: 0,
    "& .closeButton": {
      padding: 0,
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    padding: 0,
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography>
      <Box
        className={classes.dialogTitleRoot}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={-4}
      >
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="Close"
            className="closeButton"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
    </MuiDialogTitle>
  );
});

class PdfViewer extends Component {
  componentWillUnmount() {
    this.props && this.props.dispatch(clearDocumentRetrieval());
  }

  render() {
    const { generatedLink, handleClose, open, pdfFileName } = this.props;

    return (
      <React.Fragment>
        <Dialog
          className="reports"
          open={open}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {pdfFileName}
          </DialogTitle>
          <DialogContent>
            <React.Fragment>
              <main className="divider rPad">
                <div style={{ width: "880px", height: "700px" }}>
                  <div className="row">
                    {generatedLink && generatedLink.isFetching ? (
                      <Box pl={2} mt={3} fontSize={18} fontWeight={600}>
                        {"Loading..."}
                      </Box>
                    ) : generatedLink &&
                      generatedLink.generatedLink ===
                        globalConstants.UNABLETOGETTEXT ? (
                      <Box
                        className="cux-icon-warning_filled error txtSub marB0"
                        marginTop={2}
                      >
                        {globalConstants.SERVICEERRORMSG}
                      </Box>
                    ) : (
                      <iframe
                        name="theFrame"
                        title="theFrame"
                        width="880px"
                        height="700px"
                        className="marT30"
                        src={`data:application/pdf;base64,${
                          generatedLink && generatedLink.generatedLink
                        }`}
                        type="application/pdf"
                      />
                    )}
                  </div>
                </div>
              </main>
            </React.Fragment>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ getDocumentRetrievalLink }) => {
  return { getDocumentRetrievalLink };
};
export default connect(mapStateToProps)(PdfViewer);
