import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const PRELOGIN_FOOTER_CONTENT_REQUEST =
  "PRELOGIN_FOOTER_CONTENT_REQUEST";
export const PRELOGIN_FOOTER_CONTENT_SUCCESS =
  "PRELOGIN_FOOTER_CONTENT_SUCCESS";
export const PRELOGIN_FOOTER_CONTENT_FAILURE =
  "PRELOGIN_FOOTER_CONTENT_FAILURE";
export const PRELOGIN_FOOTER_IMAGE_REQUEST = "PRELOGIN_FOOTER_IMAGE_REQUEST";
export const PRELOGIN_FOOTER_IMAGE_SUCCESS = "PRELOGIN_FOOTER_IMAGE_SUCCESS";
export const PRELOGIN_FOOTER_IMAGE_FAILURE = "PRELOGIN_FOOTER_IMAGE_FAILURE";

export function requestPreLoginFooterContent() {
  return {
    type: PRELOGIN_FOOTER_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function receivePreLoginFooterContent(preLoginFooterData) {
  return {
    type: PRELOGIN_FOOTER_CONTENT_SUCCESS,
    isFetched: true,
    preLoginFooterData,
  };
}

export function preLoginFooterContentFailure(message) {
  return {
    type: PRELOGIN_FOOTER_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function requestPreLoginImage() {
  return {
    type: PRELOGIN_FOOTER_IMAGE_REQUEST,
    isImageFetched: false,
  };
}

export function receivePreLoginImage(preLoginFooterImage) {
  return {
    type: PRELOGIN_FOOTER_IMAGE_SUCCESS,
    isImageFetched: true,
    preLoginFooterImage,
  };
}

export function preLoginImageFailure(message) {
  return {
    type: PRELOGIN_FOOTER_IMAGE_FAILURE,
    isImageFetched: false,
    isImageError: true,
    message,
  };
}

export function getPreLoginFooterContent() {
  return (dispatch) => {
    dispatch(requestPreLoginFooterContent());
    const url = fetchGeneralContentUrl("/cms/preloginfooter");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(preLoginFooterContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receivePreLoginFooterContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(preLoginFooterContentFailure(error));
      });
  };
}

export function getPreLoginFooterImage() {
  return (dispatch) => {
    dispatch(requestPreLoginImage());
    const url = fetchGeneralContentUrl("/cms/preLoginFooterImage");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(preLoginImageFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receivePreLoginImage(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(preLoginImageFailure(error));
      });
  };
}
