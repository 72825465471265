import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getPreLoginFooterContent,
  getPreLoginFooterImage
} from "../actions/preLoginFooter";
import { getBasicFilesFromBytesB64 } from "../actions/filesFromBytes";
import PreLoginFooter from "../components/PreLoginFooter/PreLoginFooter";
import globalConstants from "../constants/global-constants";

class PreLoginFooterContainer extends Component {
  state = { preLoginFooter: "", preLoginFooterImage: "", byteInfoData: {} };

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setFooterLogo(nextprops);
    this.setPreLoginFooter(nextprops);
  }

  componentDidMount() {
    this.setFooterLogo(this.props);
    this.setPreLoginFooter(this.props);
  }

  setPreLoginFooter(props) {
    const { isFetching, preLoginFooter, isError } = props;
    if (!isError) {
      if (!isFetching) {
        if (preLoginFooter) {
          if (preLoginFooter && preLoginFooter !== this.state.preLoginFooter) {
            this.setState({ preLoginFooter });
          }
        } else {
          this.props.dispatch(getPreLoginFooterContent());
        }
      }
    }
  }

  setFooterLogo(props) {
    const { isImageFetching, preLoginFooterImage, isImageError } = props;
    if (!isImageError) {
      if (!isImageFetching) {
        if (preLoginFooterImage) {
          if (
            preLoginFooterImage &&
            preLoginFooterImage !== this.state.preLoginFooterImage
          ) {
            // this.setState({ preLoginFooterImage });
            if (preLoginFooterImage && preLoginFooterImage.length > 0) {
              this.getByteData(
                props,
                preLoginFooterImage[0].field_image,
                preLoginFooterImage
              );
            }
          }
        } else {
          this.props.dispatch(getPreLoginFooterImage());
        }
      }
    }
  }

  getByteData(props, code, preLoginFooterImage) {
    const { filesFromBytes, dispatch } = props;
    if (filesFromBytes) {
      const byteInfo = this.getData(
        "PreLoginFooerLogo",
        filesFromBytes.reponseB64
      );
      if (!byteInfo) {
        const req = {};
        req["filePath"] = code;
        dispatch(getBasicFilesFromBytesB64(req, "PreLoginFooerLogo"));
      } else if (byteInfo && byteInfo.bytes) {
        this.setState({ byteInfoData: byteInfo });
      }
    }
  }

  getData(name, Imagesdata) {
    return Imagesdata.find(item => {
      return item[globalConstants.EMAIL] === name;
    });
  }

  render() {
    const { isFetching } = this.props;
    const contentData = this.state.preLoginFooter[0];
    if (
      this.props.preLoginFooterImage &&
      this.props.preLoginFooterImage &&
      this.props.preLoginFooterImage.length > 0
    ) {
      var hrefData = this.props.preLoginFooterImage[0].field_href;
    }
    const { byteInfoData } = this.state;
    return (
      <PreLoginFooter
        contentData={contentData}
        byteInfoData={byteInfoData}
        hrefData={hrefData}
        isFetching={isFetching}
      />
    );
  }
}

const mapStateToProps = ({ preLoginFooterContent, filesFromBytes }) => {
  return { ...preLoginFooterContent, filesFromBytes };
};

export default connect(mapStateToProps)(PreLoginFooterContainer);
