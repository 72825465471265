import React, { Component } from "react";
import { connect } from "react-redux";
import { getVideo } from "../actions/video";
import VideoPlayer from "../components/Shared/Video/Video";
import { getFilesFromBytesB64 } from "../actions/filesFromBytes";
import globalConstants from "../constants/global-constants";

class VideoContainer extends Component {
  state = {
    videoMetaData: {},
    byteInfoData: ""
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getVideoData(nextProps);
  }

  componentDidMount() {
    this.getVideoData(this.props);
  }

  getVideoData(nextProps) {
    const { pageType, pageId } = nextProps;
    if (pageId) {
      const { videoMetaData } = nextProps;
      if (videoMetaData && !videoMetaData.isFetching) {
        const videoData = this.getVideoContent(pageId, videoMetaData.data);
        if (!videoData) {
          this.props.dispatch(getVideo(pageId, pageType));
        } else {
          if (videoData.pageId !== this.state.videoMetaData.pageId) {
            this.setState({
              videoMetaData: videoData
            });
          }
        }
      }
    }
  }

  getByteData(props, code, name) {
    const { filesFromBytes, dispatch } = props;
    if (code && filesFromBytes) {
      const byteInfo = this.getData(name, filesFromBytes.reponseB64);
      if (!byteInfo) {
        const req = {};
        req["filePath"] = code;
        dispatch(getFilesFromBytesB64(req, name));
      } else if (byteInfo && byteInfo.bytes) {
        return byteInfo.bytes;
      } else {
        return null;
      }
    }
  }

  getData(name, imagesData) {
    return imagesData.find(item => {
      return item[globalConstants.EMAIL] === name;
    });
  }

  getVideoContent(pageId, videoData) {
    return videoData.find(item => {
      return item.pageId === pageId;
    });
  }

  render() {
    const { videoMetaData } = this.state;
    const { whiteLabel } = this.props;
    if (
      whiteLabel &&
      !whiteLabel.whiteLabelVal &&
      videoMetaData &&
      videoMetaData.videoMetaData &&
      videoMetaData.videoMetaData.length > 0
    ) {
      const videos = videoMetaData.videoMetaData;
      return (
        <div>
          {videos.map((item, index) => (
            <React.Fragment key={index}>
              {item.field_is_visible && item.field_is_visible === "1" && (
                <React.Fragment key={item.field_title}>
                  {this.getByteData(
                    this.props,
                    item.field_video_thumb,
                    item.field_title
                  ) && (
                    <VideoPlayer
                      key={item.field_title}
                      title={item.field_title}
                      poster={
                        "data:image/png;base64, " +
                        this.getByteData(
                          this.props,
                          item.field_video_thumb,
                          item.field_title
                        )
                      }
                      type={"video/" + item.field_video_type}
                      videoUrlLow={item.field_video_url}
                      videoUrlHigh={item.field_video_url_high}
                      isVisible={item.field_is_visible}
                      isVisibleForWhiteLabel={
                        item.field_visible_for_white_label
                      }
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </div>
      );
    } else if (
      whiteLabel &&
      whiteLabel.whiteLabelVal &&
      videoMetaData &&
      videoMetaData.videoMetaData &&
      videoMetaData.videoMetaData.length > 0
    ) {
      const videos = videoMetaData.videoMetaData;
      return (
        <div>
          {videos.map((item, index) => (
            <React.Fragment key={index}>
              {item.field_visible_for_white_label &&
                item.field_visible_for_white_label === "1" && (
                  <React.Fragment key={item.field_title}>
                    {this.getByteData(
                      this.props,
                      item.field_video_thumb,
                      item.field_title
                    ) && (
                      <VideoPlayer
                        key={item.field_title}
                        title={item.field_title}
                        poster={
                          "data:image/png;base64, " +
                          this.getByteData(
                            this.props,
                            item.field_video_thumb,
                            item.field_title
                          )
                        }
                        type={"video/" + item.field_video_type}
                        videoUrlLow={item.field_video_url}
                        videoUrlHigh={item.field_video_url_high}
                      />
                    )}
                  </React.Fragment>
                )}
            </React.Fragment>
          ))}
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = ({
  videoMetaData,
  auth,
  filesFromBytes,
  whiteLabel
}) => {
  return {
    videoMetaData,
    auth,
    filesFromBytes,
    whiteLabel
  };
};

export default connect(mapStateToProps)(VideoContainer);
