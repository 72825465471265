import { Grid } from "@material-ui/core";
import React from "react";
import ChipWithIcon from "../Chip/Chip";

const ChipsArray = ({ chipsData, handleSelection, size, selectedChips, spacing }) => {
  {
    return (
      <Grid container direction="row" spacing={spacing}>
        {chipsData.map((chip) => {
          const selected = selectedChips.includes(chip);
          return (
            <Grid item xs={12} md={3}>
              <ChipWithIcon
                value={chip}
                label={chip}
                selected={selected}
                size={size}
                handleSelection={handleSelection}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
};

export default ChipsArray;
