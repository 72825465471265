import { ACCOUNT_PRODUCT_QUICKLAUNCH_SUCCESS } from "../actions/accountProductQuickLaunch";

export function accountProductQuickLaunch(
  state = {
    isFetching: false,
    accountId: "",
    productQuickLaunch: [],
  },
  action
) {
  switch (action.type) {
    case ACCOUNT_PRODUCT_QUICKLAUNCH_SUCCESS:
      const data = state.productQuickLaunch;
      const index =
        data &&
        data.findIndex(
          (item) => item && item.accountId === action.data.accountId
        );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        isFetching: false,
        productQuickLaunch: data,
      };

    default:
      return state;
  }
}

export default accountProductQuickLaunch;
