import { makeStyles, createStyles } from "@material-ui/core";

export const FormularyTextFieldStyle = makeStyles((theme) =>
  createStyles({
    textstyle: {
      height: "44px",
      width: "490px",
    },
  })
);
