import { REQUEST_PRODUCT_DOCUMENT_DATA, RECEIVED_PRODUCT_DOCUMENT_DATA } from "../actions/productDocumentation";
function productDocumentation(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case REQUEST_PRODUCT_DOCUMENT_DATA:
      return Object.assign({}, state, {
      });
    case RECEIVED_PRODUCT_DOCUMENT_DATA:
      return Object.assign({}, state, {
        isLoading: false,
      });
 
    default:
      return state;
  }
}

// We combine the reducers here so that they
// can be left split apart above

export default productDocumentation;
