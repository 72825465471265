import {
  YOUTUBE_FAILURE,
  YOUTUBE_REQUEST,
  YOUTUBE_SUCCESS,
  TWITTER_FAILURE,
  TWITTER_REQUEST,
  TWITTER_SUCCESS,
  LINKEDIN_FAILURE,
  LINKEDIN_REQUEST,
  LINKEDIN_SUCCESS,
} from "../actions/followUsContent";

export function followUsContent(
  state = {
    isFetching: false,
    isYouTubeFetching: false,
    isTwitterFetching: false,
    isLinkedInFetching: false,
    followUs: "",
    youTubeData: "",
    twitterData: "",
    linkedInData: "",
  },
  action
) {
  switch (action.type) {
    // Youtube
    case YOUTUBE_REQUEST:
      return Object.assign({}, state, {
        isYouTubeFetching: true,
        youTubeData: "",
      });
    case YOUTUBE_SUCCESS:
      return Object.assign({}, state, {
        isYouTubeFetching: false,
        youTubeData: action.youTubeData,
      });
    case YOUTUBE_FAILURE:
      return Object.assign({}, state, {
        isYouTubeFetching: false,
        isYouTubeError: true,
        error: action.message,
      });

    // Twitter
    case TWITTER_REQUEST:
      return Object.assign({}, state, {
        isTwitterFetching: true,
        twitterData: "",
      });
    case TWITTER_SUCCESS:
      return Object.assign({}, state, {
        isTwitterFetching: false,
        twitterData: action.twitterData,
      });
    case TWITTER_FAILURE:
      return Object.assign({}, state, {
        isTwitterFetching: false,
        isTwitterError: true,
        error: action.message,
      });

    //Linkedin
    case LINKEDIN_REQUEST:
      return Object.assign({}, state, {
        isLinkedInFetching: true,
        linkedInData: "",
      });
    case LINKEDIN_SUCCESS:
      return Object.assign({}, state, {
        isLinkedInFetching: false,
        linkedInData: action.linkedInData,
      });
    case LINKEDIN_FAILURE:
      return Object.assign({}, state, {
        isLinkedInFetching: false,
        isLinkedInError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default followUsContent;
