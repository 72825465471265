import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

class IdleTimeOutModal extends Component {
  state = {};

  getTime(sec) {
    var pad = function (num, size) {
        return ("000" + num).slice(size * -1);
      },
      time = parseFloat(sec).toFixed(3),
      minutes = Math.floor(time / 60) % 60,
      seconds = Math.floor(time - minutes * 60);
    if (minutes > 0) {
      return pad(minutes, 2) + ":" + pad(seconds, 2) + " min";
    } else {
      return pad(seconds, 2) + " sec";
    }
  }

  render() {
    const { showModal, handleLogout, handleContinue, countDown } = this.props;
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        className="session"
        open={showModal}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="customized-dialog-title">Session Timeout</DialogTitle>
        <DialogContent>
          {countDown === 0 ? (
            <p>Session is expired. Please try to login again.</p>
          ) : (
            <p>
              Your session is about to be time out in{" "}
              <b> {this.getTime(countDown)}.</b> <br /> Click OK to continue
              your current session.
            </p>
          )}
          <div className="marT20 marB10">
            <button className="button button-secondary " onClick={handleLogout}>
              {countDown === 0 ? "Login" : "Logout"}
            </button>
            {countDown === 0 ? (
              ""
            ) : (
              <button
                className="button button-primary cap"
                onClick={handleContinue}
              >
                OK
              </button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default IdleTimeOutModal;
