import { makeStyles, createStyles } from "@material-ui/core";

export const FormularyDrugSearchStyles = makeStyles((theme) =>
  createStyles({
    header3: {
      paddingBottom: "16px",
      color: "#5A5A5A",
    },
    header1: {
      color: "#5A5A5A",
    },
    textstyle: {
      height: "44px",
      width: "500px",
    },
    textstyle2: {
      height: "44px",
      width: "490px",
    },
    margin: {
      borderBottom: "1px solid",
      borderColor: "#c4cfd4",
      paddingTop: "50px",
    },
    flex: {
      display: "flex",
      flexWrap: "nowrap",
      direction: "row",
    },
    mgright: {
      marginRight: "0px",
    },
    autocompleteErrorText: {
      marginTop: "24px",
      fontSize:"12px",
    },
    resultText: {
      textDecoration: "underline",
      fontWeight: 700,
      wordBreak: "break-all"
    }
  })
);
