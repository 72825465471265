import { makeStyles, createStyles } from "@material-ui/core";

export const FormDialogErrorStyles = makeStyles((theme) =>
  createStyles({
    main: {
      "& .MuiDialog-paperWidthSm": {
        borderRadius: "12px",
        boxShadow: "0px 4px 8px" + theme.customPalette.color.grey2,
      },
    },
    DialogActions: {
      display: "grid",
      justifyContent: "center",
    },
  })
);
