import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import "./FollowUs.scss";

const FollowUs = ({
  twitterData,
  linkedInData,
  youTubeData,
  youTubeByteInfoData,
  linkedInByteInfoData,
  twitterByteInfoData,
  isLinkedInFetching,
  isYouTubeFetching,
  isTwitterFetching
}) => {
  return (
    <section>
      <ul
        className="row inlineBlock w100 padT15 padB15 social"
        style={{ minHeight: 70 }}
      >
        <li>
          <label>Follow us :</label>
        </li>
        <li>
          {!isLinkedInFetching && linkedInByteInfoData && (
            <a
              link-id="LinkedIn"
              href={
                linkedInData &&
                linkedInData.length > 0 &&
                linkedInData[0].field_href
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={
                  linkedInByteInfoData && linkedInByteInfoData.bytes
                    ? `data:image/png;base64, ${linkedInByteInfoData.bytes}`
                    : ""
                }
                alt="LinkedIn"
              />
            </a>
          )}
          {isLinkedInFetching && (
            <Skeleton variant="circle" width={40} height={40} />
          )}
        </li>
        <li>
          {!isTwitterFetching && twitterByteInfoData && (
            <a
              link-id="Twitter"
              href={
                twitterData &&
                twitterData.length > 0 &&
                twitterData[0].field_href
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={
                  twitterByteInfoData && twitterByteInfoData.bytes
                    ? `data:image/png;base64, ${twitterByteInfoData.bytes}`
                    : ""
                }
                alt="Twitter"
              />
            </a>
          )}
          {isTwitterFetching && (
            <Skeleton variant="circle" width={40} height={40} />
          )}
        </li>
        <li>
          {!isYouTubeFetching && youTubeByteInfoData && (
            <a
              link-id="Youtube"
              href={
                youTubeData &&
                youTubeData.length > 0 &&
                youTubeData[0].field_href
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={
                  youTubeByteInfoData && youTubeByteInfoData.bytes
                    ? `data:image/png;base64, ${youTubeByteInfoData.bytes}`
                    : ""
                }
                alt="Youtube"
              />
            </a>
          )}
          {isYouTubeFetching && (
            <Skeleton variant="circle" width={40} height={40} />
          )}
        </li>
      </ul>
    </section>
  );
};

export default FollowUs;
