import {
    NETWORK_CONTENT_SUCCESS,
    NETWORK_CONTENT_REQUEST,
    NETWORK_CONTENT_FAILURE,
} from "../actions/networkError";

function networkContent(
    state = {
        isFetching: false,
        networkContent: ""
    },
    action
) {
    switch (action.type) {
        case NETWORK_CONTENT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                networkContent: ""
            });
        case NETWORK_CONTENT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                networkContent: action.logo
            });
        case NETWORK_CONTENT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isError: true,
                error: action.message
            });
        default:
            return state;
    }
}

export default networkContent;
