import React, { Component } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import globalConstants from "../../../constants/global-constants";
import { getFilesFromBytesB64 } from "../../../actions/filesFromBytes";
import ErrorModal from "../../ErrorModal/ErrorModal";
class Product extends Component {
  constructor() {
    super();
    this.state = {
      error: false,
      openModal: false,
      errorMsg: "",
    };
  }

  componentDidMount() {
    this.setData(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setData(newProps);
  }

  setData(props) {
    const { userProfile } = props;
    if (userProfile && userProfile.opData) {
      this.makeRequest(userProfile.opData, userProfile.profileData);
    }
  }

  makeRequest(opData, profileData) {
    var {
      firstName,
      lastName,
      email,
      userID,
      clientName,
      carrierID,
      role,
      userLocation,
    } = this.findPALiteRequestData(profileData, opData);
    if (
      firstName &&
      lastName &&
      email &&
      userID &&
      clientName &&
      carrierID &&
      role &&
      userLocation
    ) {
      const req = {};
      req["userID"] = userID;
      req["firstName"] = firstName;
      req["lastName"] = lastName;
      req["email"] = email;
      req["clientName"] = clientName;
      req["carrierID"] = carrierID;
      req["role"] = role;
      req["userLocation"] = userLocation;
      this.setState({ error: false });
      return req;
    } else {
      /*  const req = {};
       req["User ID"] = userID;
       req["First Name"] = firstName;
       req["Last Name"] = lastName;
       req["Email"] = email;
       req["Client Name"] = clientName;
       req["Carrier ID"] = carrierID;
       req["Role"] = role;
       req["User Location"] = userLocation;
       const errData = this.checkProperties(req);
       const errorMsg = errData + " is missing for SSO." */
      const errorMsg =
        "Access denied. Please contact your OptumRx Client management account team for assistance.";
      this.setState({ error: true, errorMsg });
    }
    return null;
  }

  checkProperties(obj) {
    const arr = [];
    for (var key in obj) {
      if (obj[key] === null || obj[key] === "") {
        arr.push(key);
      }
    }
    if (arr && arr.length > 0) {
      return arr.join(", ");
    }
    return null;
  }

  findPALiteRequestData(profileData, opData) {
    var firstName = profileData.given_name;
    var lastName = profileData.family_name;
    var email = opData.workEmail;
    var userID = "";
    var clientName = "";
    var carrierID = "";
    var role = "";
    var userLocation = "";
    if (opData && opData.userIdentifiers && opData.userIdentifiers.length > 0) {
      userID = this.findDataByID(opData.userIdentifiers, "Optum ID");
      clientName = this.findDataByID(opData.userIdentifiers, "u_client");
      carrierID = this.findDataByID(opData.userIdentifiers, "u_carrier");
      role = this.findDataByID(opData.userIdentifiers, "u_pa_roles");
      userLocation = this.findDataByID(
        opData.userIdentifiers,
        "u_pa_user_location"
      );
    }
    return {
      firstName,
      lastName,
      email,
      userID,
      clientName,
      carrierID,
      role,
      userLocation,
    };
  }

  findDataByID(identifiers, id) {
    const ids = identifiers.find((i) => i && i["name"] === id);
    if (ids) {
      return ids.value;
    } else {
      return null;
    }
  }

  handleClicked(item) {
    const { selectedAccount } = this.props;
    // sessionStorage.removeItem("acc_id");
    // sessionStorage.removeItem("QLApp");
    var win;
    if (item["pname"] === "RxClaim(IDA)") {
      window.open("/product/launch/?url=" + item["purl"], "_blank");
    } else if (item["pname"] === "PAPortal") {
      if (!this.state.error) {
        win = window.open("/home/sso/launch/paportal", "_blank");
        win.focus();
      } else {
        this.setState({ openModal: true });
      }
      // } else if (item["pname"] === "RxTrack" || item["pname"] === "RxTrack IDW" || (item["pname"] === "RxTrack Confidential")) {
      //   win = window.open(`/home/rxtrackSSO/launch/${item.pname}`, "_blank");
      //   win.focus();
    } else {
      if (item["productName"] && item["productName"].includes("RxClaim")) {
        // if (item["pname"] === "RxClaim Web RXDV1" || item["pname"] === "RxClaim Web RXCL1TST" || item["pqlname"] === "RxClaim Web") {
        sessionStorage.setItem("acc_id", selectedAccount && selectedAccount.u_account);
        sessionStorage.setItem("QLApp", item["pname"]);
      }
      window.open(item["purl"], "_blank");
    }
  }

  handleClose() {
    this.setState({ openModal: false });
  }

  getByteData(code, name) {
    const { filesFromBytes, dispatch } = this.props;
    if (code && filesFromBytes) {
      const byteInfo = this.getData(name, filesFromBytes.reponseB64);
      if (!byteInfo) {
        const req = {};
        req["filePath"] = code;
        dispatch(getFilesFromBytesB64(req, name));
      } else if (byteInfo && byteInfo.bytes) {
        return byteInfo.bytes;
      } else {
        return null;
      }
    }
    return null;
  }

  getData(name, imagesData) {
    return imagesData.find((item) => {
      return item[globalConstants.EMAIL] === name;
    });
  }

  render() {
    const { item, proMetaData } = this.props;
    const { openModal, errorMsg } = this.state;
    if (!item) {
      return null;
    }
    return (
      <React.Fragment>
        <li name="myProduct" className="tableRowOdd">
          <span name="Pocontent3" />
          <div className="col-sm-3 posStatic">
            <figure>
              {this.getByteData(
                proMetaData["field_product_logo"],
                item.pname
              ) && (
                  <img
                    src={
                      "data:image/png;base64, " +
                      this.getByteData(
                        proMetaData["field_product_logo"],
                        item.pname
                      )
                    }
                    alt=""
                    width="200"
                    height="70"
                    className="mCS_img_loaded"
                  />
                )}
            </figure>
          </div>
          <div className="col-sm-6">
            <p>
              <span name="Pcontent">{item.pcontent}</span>
            </p>
          </div>
          <div className="col-sm-3 aHnoU">
            {item && item["purl"] && (
              <Link
                to="#"
                rel="noopener noreferrer"
                name="Purl"
                className="block marB10"
                link-id={item["analyticsTrackingName"] + "- Launch"}
                onClick={
                  () => this.handleClicked(item)
                  // item["pname"] === "RxClaim(IDA)"
                  //   ? "/product/launch/?url=" + item["purl"]
                  //   : item["pname"] === "RxTrack"
                  //   ? "/home/rxtrackSSO/launch/" + item.pname
                  //   : item["pname"] === "RxTrack Confidential"
                  //   ? "/home/rxtrackSSO/launch/" + item.pname
                  //   : item["purl"]

                  // item["pname"] === "RxClaim(IDA)"
                  //   ? "/product/launch/?url=" + item["purl"]
                  //   : item["purl"]

                  // item["pname"] === "RxClaim(IDA)"
                  //   ? "/product/launch/?url=" + item["purl"]
                  //   : // : item["pname"] === "PA Lite"
                  //     // ? "/home/landing/palite/launch"
                  //     item["purl"]
                }
              >
                <span className="button block noCap">Launch</span>
              </Link>
            )}
            {item &&
              item["pDetailsVisiblityFlag"] &&
              !item["pDetailsVisiblityFlag"].toLowerCase().includes("no") &&
              proMetaData["field_product_folder_id_dev"] && (
                <NavLink
                  className="block marB10"
                  exact
                  link-id={item["analyticsTrackingName"] + "- Product Details"}
                  to={
                    "/home/applications/myproduct/productdetail/" +
                    item["productCompleteName"] +
                    "/" +
                    proMetaData["field_product_folder_id_dev"] +
                    "?page=ProductDetail&folderId=" +
                    proMetaData["field_product_folder_id_dev"]
                  }
                >
                  <span className="button button-reverse block noCap">
                    Product details
                  </span>
                </NavLink>
              )}
            {proMetaData &&
              proMetaData["field_training_button_visibility"] === "Yes" &&
              proMetaData["field_product_trainingfolderidd"] && (
                <NavLink
                  link-id={item["analyticsTrackingName"] + "- Training"}
                  to={
                    "/home/applications/myproduct/productdetail/" +
                    item["productCompleteName"] +
                    "/" +
                    proMetaData["field_product_trainingfolderidd"] +
                    "?page=TrainingDetail&byId=true"
                  }
                >
                  <span className="button button-secondary button-reverse block noCap">
                    Training
                  </span>
                </NavLink>
              )}
          </div>
        </li>

        {openModal && (
          <ErrorModal
            title={"Unable to Access PA Portal"}
            responseMessage={errorMsg}
            openModal={openModal}
            handleClose={this.handleClose.bind(this)}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ filesFromBytes, paLite, userProfile }) => {
  return { filesFromBytes, paLite, userProfile };
};

export default connect(mapStateToProps)(withRouter(Product));
