import { WHITE_LABEL_VALUE, WHITE_LABEL_CLIENT } from "../actions/whiteLabel";

export function whiteLabel(
  state = {
    whiteLabelVal: false,
    data: "",
  },
  action
) {
  switch (action.type) {
    case WHITE_LABEL_VALUE:
      return Object.assign({}, state, {
        ...state,
        whiteLabelVal: action.val,
        data: null,
      });

    case WHITE_LABEL_CLIENT:
      return Object.assign({}, state, {
        ...state,
        whiteLabelVal: action.val,
        data: action.data,
      });
    default:
      return state;
  }
}

export default whiteLabel;
