import { makeStyles, createStyles } from "@material-ui/core";

export const footerStyles = makeStyles((theme) =>
  createStyles({
    main: {
      "& .MuiTypography-body1": {
        fontSize: theme.typography.subtitle1.fontSize,
        color: theme.palette.grey[900],
        fontFamily: theme.typography.fontFamily,
      },
      "& ul li a span": {
        fontSize: theme.typography.subtitle1.fontSize,
        color: theme.palette.grey[900],
      },
    },
    footerDivider: {
      border: "none",
      height: "1px",
      margin: "0",
      flexShrink: "0",
      backgroundColor: theme.customPalette.color.grey1,
      marginBottom: "20px",
      marginTop: "auto",
    },
  })
);
