import React, { Component } from "react";
import { connect } from "react-redux";
import { getAccountTeam } from "../actions/accountTeam";
import WorkTeam from "../components/LandingPage/WorkTeam/WorkTeam";
import { isInternal } from "../constants/service";

class WorkTeamContainer extends Component {
  state = {
    accountWorkTeam: {},
    title: "",
    isVisible: "",
    isVisibleForWhiteLabel: "",
    accountTeamImage: {},
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getAccountsTeam(nextProps);
  }

  componentDidMount() {
    this.getAccountsTeam(this.props);
  }

  getAccountsTeam(nextProps) {
    const { selectedAccount, user } = nextProps;
    if (selectedAccount && selectedAccount.u_account) {
      const { landingContent } = nextProps;
      if (landingContent) {
        const data = landingContent.landingContent;
        if (data && data.length > 0) {
          this.setState({
            title: data[0].field_workteam_title,
            isVisible: data[0].field_workteam_visibility,
            isVisibleForWhiteLabel: data[0].field_is_wt_visible_white_label,
          });
        }
      }
      const { accountTeams } = nextProps;
      if (accountTeams) {
        const accountWorkTeam = this.getAccountWorkTeam(
          selectedAccount.u_account,
          accountTeams.workTeam
        );
        if (accountWorkTeam) {
          this.setState({
            accountWorkTeam,
          });
        } else {
          if (!isInternal(user)) {
            this.props.dispatch(getAccountTeam(selectedAccount.u_account));
          } else {
            this.setState({
              accountWorkTeam: {},
            });
          }
        }
      }
    }
  }

  getAccountWorkTeam(accountID, accountTeams) {
    return (
      accountTeams &&
      accountTeams.length > 0 &&
      accountTeams.find((item) => {
        return item && item.accountId === accountID;
      })
    );
  }

  render() {
    const {
      accountWorkTeam,
      title,
      isVisible,
      isVisibleForWhiteLabel,
    } = this.state;
    const { whiteLabel } = this.props;

    var accountTeam = [];
    if (
      accountWorkTeam &&
      accountWorkTeam.accountTeam &&
      accountWorkTeam.accountTeam.length > 0
    ) {
      accountTeam = accountWorkTeam.accountTeam;
    }
    if (
      whiteLabel &&
      whiteLabel.whiteLabelVal &&
      isVisibleForWhiteLabel === "1" &&
      title
    ) {
      return <WorkTeam accountTeam={accountTeam} title={title} />;
    } else if (
      whiteLabel &&
      !whiteLabel.whiteLabelVal &&
      title &&
      isVisible === "1"
    ) {
      return <WorkTeam accountTeam={accountTeam} title={title} />;
    }
    return null;
  }
}

const mapStateToProps = ({
  accountTeams,
  userAccount,
  landingContent,
  user,
  auth,
  whiteLabel,
}) => {
  return {
    accountTeams,
    selectedAccount: userAccount.selectedAccount,
    landingContent,
    user,
    auth,
    whiteLabel,
  };
};

export default connect(mapStateToProps)(WorkTeamContainer);
