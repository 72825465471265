import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Content from "../Shared/Content/Content";
import InputForm from "../Shared/InputForm/InputForm";
import routePath from "../../constants/routes";
import FollowUsContainer from "../../containers/FollowUsContainer";
import PreLoginFooterContainer from "../../containers/PreLoginFooterContainer";
import OptumIdLogin from "./OptumIdLogin";
import "./login-page.scss";
import { getFilesFromBytes } from "../../actions/filesFromBytes";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";

class LoginPage extends InputForm {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    this.addListener();
    this.checkAuthetication(this.props);
  }

  componentWillUnmount() {
    this.addListener();
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.checkAuthetication(nextprops);
  }

  overrideLinkHandler = e => {
    if (
      e.target.tagName === "A" &&
      e.target.getAttribute("class") === "internalLink"
    ) {
      e.preventDefault();
      this.props.history.push(e.target.getAttribute("href"));
    } else if (
      e.target.tagName === "A" &&
      e.target.getAttribute("href") &&
      e.target.getAttribute("class") === "downloadLink"
    ) {
      e.preventDefault();
      const hrefData = e.target.getAttribute("href");
      const fileName = hrefData.substr(hrefData.lastIndexOf("/") + 1);
      const req = {};
      req["filePath"] = hrefData;
      this.props.dispatch(getFilesFromBytes(req, fileName));
    }
  };

  addListener() {
    ReactDOM.findDOMNode(this).addEventListener(
      "click",
      this.overrideLinkHandler
    );
  }

  checkAuthetication(props) {
    const { isAuthenticated } = props.auth;
    if (isAuthenticated) {
      this.props.history.push(routePath.home);
    }
  }

  render() {
    const {
      contentData,
      optumIdcontentData,
      whiteLabel,
      isFetching,
      isOptumIDFetching,
      setApplicationContext
    } = this.props;

    return (
      <React.Fragment>
        <main className="divider rPad">
          <div className="container" ref={this.containerRef}>
            <div id="loginContainer" className="body-text">
              <div
                data-testid="login-id"
                className="login-brief"
                style={{ minHeight: whiteLabel ? 95 : 152 }}
              >
                {isFetching && (
                  <h1>
                    <Skeleton variant="text" width={450} height={40} />
                  </h1>
                )}
                {isFetching && whiteLabel && (
                  <p>
                    <Skeleton variant="text" width={600} height={20} />
                  </p>
                )}
                {isFetching && !whiteLabel && (
                  <div>
                    <p>
                      <Skeleton variant="text" width={880} height={20} />
                      <Skeleton variant="text" width={880} height={20} />
                      <Skeleton variant="text" width={200} height={20} />
                    </p>
                    <p className="Skeleton">
                      <Skeleton variant="text" width={880} height={20} />
                    </p>
                  </div>
                )}
                {contentData && contentData.title && (
                  <h1>
                    <Content html={contentData.title} />
                  </h1>
                )}
                {contentData && contentData.body && (
                  <Content html={contentData.body} />
                )}
              </div>
              <div className="login-wrap">
                <div
                  data-testid="optumLogin"
                  className="login-frm-div bi-sec-form"
                >
                  <OptumIdLogin
                    optumIdcontentData={optumIdcontentData}
                    isOptumIDFetching={isOptumIDFetching}
                    whiteLabel={whiteLabel}
                    setApplicationContext={setApplicationContext}
                  />
                </div>
              </div>
              {!whiteLabel &&
                contentData &&
                contentData.field_body_secondary && (
                  <Content html={contentData.field_body_secondary} />
                )}
            </div>
            {!whiteLabel && <FollowUsContainer />}
            {!whiteLabel && <PreLoginFooterContainer />}
          </div>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ filesFromBytes }) => {
  return { filesFromBytes };
};
export default connect(mapStateToProps)(withRouter(LoginPage));