import React from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField } from "@material-ui/core";
import { FormularyTextFieldStyle } from "./FormularyTextFieldStyle";

const FormularyTextField = ({
  variant,
  placeholder,
  value,
  name,
  onChange,
  error,
  helperText,
  disabled,
  autoFocus,
  inputRef,
  onClick,
}) => {
  const classes = FormularyTextFieldStyle();
  return (
    <TextField
      variant={variant}
      fullWidth
      placeholder={placeholder}
      value={value}
      name={name}
      onChange={onChange}
      error={error}
      helperText={helperText}
      className={classes.textstyle}
      disabled={disabled}
      autoFocus={autoFocus}
      inputRef={inputRef}
      onClick={onClick}
    ></TextField>
  );
};

export default FormularyTextField;
