import {
  CONTACTUS_CONTENT_REQUEST,
  CONTACTUS_CONTENT_SUCCESS,
  CONTACTUS_CONTENT_FAILURE,
  FEEDBACK_PAGE_CONTENT_REQUEST,
  FEEDBACK_PAGE_CONTENT_SUCCESS,
  FEEDBACK_PAGE_CONTENT_FAILURE,
  THANK_YOU_PAGE_CONTENT_REQUEST,
  THANK_YOU_PAGE_CONTENT_SUCCESS,
  THANK_YOU_PAGE_CONTENT_FAILURE,
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS
} from "../actions/contactUsContent";

function contactUsContent(
  state = {
    isFetching: false,
    contactUsContent: "",
    feedbackPageContent: "",
    thankYouPageContent: "",
    emailData: ""
  },
  action
) {
  switch (action.type) {
    case CONTACTUS_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        contactUsContent: ""
      });
    case CONTACTUS_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        contactUsContent: action.data
      });
    case CONTACTUS_CONTENT_FAILURE:
    case FEEDBACK_PAGE_CONTENT_FAILURE:
    case THANK_YOU_PAGE_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message
      });
    case FEEDBACK_PAGE_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        feedbackPageContent: ""
      });
    case FEEDBACK_PAGE_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        feedbackPageContent: action.data
      });
    case THANK_YOU_PAGE_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        thankYouPageContent: ""
      });
    case THANK_YOU_PAGE_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        thankYouPageContent: action.data
      });

    case SEND_EMAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        emailData: ""
      });
    case SEND_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        emailData: action.emailData
      });
    case SEND_EMAIL_FAILURE:
    default:
      return state;
  }
}

export default contactUsContent;
