import { downloadfromAzure } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const DOWNLOAD_AZURE_REQUEST = "GET_DOWNLOAD_AZURE_REQUEST";
export const DOWNLOAD_AZURE_SUCCESS = "GET_DOWNLOAD_AZURE_SUCCESS";
export const DOWNLOAD_AZURE_FAILURE = "GET_DOWNLOAD_AZURE_FAILURE";

export function requestDownloadAzure() {
  return {
    type: DOWNLOAD_AZURE_REQUEST,
    isFetched: false,
  };
}

export function downloadAzureSuccess(data) {
  return {
    type: DOWNLOAD_AZURE_SUCCESS,
    isFetched: true,
    data,
  };
}

export function downloadAzureFailure(message) {
  return {
    type: DOWNLOAD_AZURE_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getdownloadfromAzure() {
  return (dispatch) => {
    dispatch(requestDownloadAzure());
    const url = downloadfromAzure();
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(downloadAzureFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(downloadAzureSuccess(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(downloadAzureFailure(error));
      });
  };
}
