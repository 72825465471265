import React from "react";

const Button = ({ value, type, className, friendlyname }) => {
  return (
    <input
      type={type}
      defaultValue={value}
      link-id={friendlyname}
      className={className}
    />
  );
};

export default Button;
