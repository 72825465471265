import { GOVERNMENT_PROGRAM_CONTENT_SUCCESS } from "../actions/governmentProgramContent";

export function governmentProgramContent(
  state = { isFetching: false, governmentProgramContent: [] },
  action
) {
  switch (action.type) {
    case GOVERNMENT_PROGRAM_CONTENT_SUCCESS:
      const data = state.governmentProgramContent;
      const index =
        data &&
        data.findIndex(
          (item) => item && item.pageType === action.data.pageType
        );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        isFetching: false,
        governmentProgramContent: data,
      };

    default:
      return state;
  }
}

export default governmentProgramContent;
