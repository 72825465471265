import React, { Component } from "react";
import { connect } from "react-redux";
import Announcement from "../components/LandingPage/Announcement/Announcement";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getAnnouncementData } from "../actions/Announcement";

let index;
class AnnouncementContainer extends Component {
  state = {
    showMoreItems: false,
    title: "",
    isVisible: "0",
    isVisibleForWhiteLabel: "",
    announcementData: [],
  };

  componentDidMount() {
    this.getAnnouncements(this.props);
    this.setAnnouncementData(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getAnnouncements(newProps);
    this.setAnnouncementData(this.props);
  }

  updateShowMore = () => {
    this.setState((prevState) => {
      return {
        showMoreItems: !prevState.showMoreItems,
      };
    });
  };

  getAnnouncements(newProps) {
    const { landingContent } = newProps;
    if (landingContent) {
      const data = landingContent.landingContent;
      if (data && data.length > 0) {
        this.setState({
          title: data[0].field_announcements_title,
          isVisible: data[0].field_announcements_visibility,
          isVisibleForWhiteLabel: data[0].field_is_ann_visible_white_label,
        });
      }
    }
  }

  setAnnouncementData(props) {
    const { isFetching, announcementData, isError } = props.getAnnouncements;
    if (!isError) {
      if (!isFetching) {
        if (announcementData) {
          if (
            announcementData &&
            announcementData !== this.state.announcementData
          ) {
            this.setState({ announcementData });
          }
        } else {
          this.props.dispatch(getAnnouncementData());
        }
      }
    }
  }

  render() {
    const {
      showMoreItems,
      title,
      isVisible,
      announcementData,
      isVisibleForWhiteLabel,
    } = this.state;
    const { whiteLabel } = this.props;
    showMoreItems ? (index = announcementData.length) : (index = 3);
    if (
      whiteLabel &&
      whiteLabel.whiteLabelVal &&
      isVisibleForWhiteLabel === "1" &&
      title
    ) {
      return (
        <React.Fragment>
          {isVisible === "1" &&
            announcementData &&
            announcementData.length > 0 && (
              <div className="announcementContainer">
                <h3
                  className="accounting-heading"
                  name="heading"
                  styles={{ display: "block" }}
                >
                  <span name="InsideBottom_heading">{title}</span>
                </h3>
                {announcementData.slice(0, index).map((item) => (
                  <React.Fragment key={item.nid}>
                    <Announcement item={item} />
                  </React.Fragment>
                ))}
                {announcementData.length > 3 && (
                  <div className="moreBtn">
                    {!showMoreItems ? (
                      <a
                        href="javascript:void(0)"
                        onClick={this.updateShowMore}
                        link-id="show_more_announcements"
                      >
                        Show more <ExpandMoreIcon />
                      </a>
                    ) : (
                      <a
                        href="javascript:void(0)"
                        onClick={this.updateShowMore}
                        link-id="show_less_announcements"
                      >
                        Show less <ExpandLessIcon />
                      </a>
                    )}
                  </div>
                )}
              </div>
            )}
        </React.Fragment>
      );
    } else if (
      whiteLabel &&
      !whiteLabel.whiteLabelVal &&
      title &&
      isVisible === "1"
    ) {
      return (
        <React.Fragment>
          {isVisible === "1" &&
            announcementData &&
            announcementData.length > 0 && (
              <div className="announcementContainer">
                <h3
                  className="accounting-heading"
                  name="heading"
                  styles={{ display: "block" }}
                >
                  <span name="InsideBottom_heading">{title}</span>
                </h3>
                {announcementData.slice(0, index).map((item) => (
                  <React.Fragment key={item.nid}>
                    <Announcement item={item} />
                  </React.Fragment>
                ))}
                {announcementData.length > 3 && (
                  <div className="moreBtn">
                    {!showMoreItems ? (
                      <a
                        href="javascript:void(0)"
                        onClick={this.updateShowMore}
                        link-id="show_more_announcements"
                      >
                        Show more <ExpandMoreIcon />
                      </a>
                    ) : (
                      <a
                        href="javascript:void(0)"
                        onClick={this.updateShowMore}
                        link-id="show_less_announcements"
                      >
                        Show less <ExpandLessIcon />
                      </a>
                    )}
                  </div>
                )}
              </div>
            )}
        </React.Fragment>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ landingContent, getAnnouncements, whiteLabel }) => {
  return { landingContent, getAnnouncements, whiteLabel };
};

export default connect(mapStateToProps)(AnnouncementContainer);
