import React from "react";
import InputForm from "../../Shared/InputForm/InputForm";
import {
  getEnviormentPropByName,
  getUIURLs,
} from "../../../constants/microservice-helpers";
import Skeleton from '@material-ui/lab/Skeleton';
import labels from "../../../constants/labels";
import Content from "../../Shared/Content/Content";
import ReactDOM from "react-dom";

class MsIdAuth extends InputForm {
  constructor(props) {
    super(props);
    this.state = {
      actionurl: "",
      clientid: "",
      responsetype: "",
      acrvalue: "",
      redirecturi: "",
      scope: "",
    };
  }

  componentDidMount() {
    const { whiteLabel, setApplicationContext } = this.props;

    this.addListener();

    this.setState({
      actionurl: getEnviormentPropByName("REACT_APP_MSID_OAUTH_URL"),
    });
    this.setState({
      clientid: getEnviormentPropByName("REACT_APP_MSID_CLIENT_ID"),
    });
    this.setState({
      responsetype: getEnviormentPropByName(
        "REACT_APP_MSID_CLIENT_RESPONSE_TYPE"
      ),
    });
    this.setState({
      acrvalue: getEnviormentPropByName("REACT_APP_MSID_ACR_VALUES"),
    });
    if (whiteLabel) {
      this.setState({
        redirecturi: setApplicationContext && setApplicationContext !== undefined
          ? getEnviormentPropByName("REACT_APP_WHITELABEL_URL") +
          setApplicationContext.basename +
          getEnviormentPropByName("REACT_APP_MSID_REDIRECT_URI")
          : getEnviormentPropByName("REACT_APP_WHITELABEL_URL") +
          getEnviormentPropByName("REACT_APP_MSID_REDIRECT_URI")
      });
    } else {
      this.setState({
        redirecturi: getUIURLs(getEnviormentPropByName("REACT_APP_MSID_REDIRECT_URI")),
      });

    }
    this.setState({
      scope: getEnviormentPropByName("REACT_APP_MSID_SCOPE"),
    });
  }

  componentWillUnmount() {
    this.addListener();
  }
  overrideLinkHandler = (e) => {
    if (
      e.target.tagName === "A" &&
      e.target.getAttribute("class") === "internalLink"
    ) {
      var form = document.getElementById("form-id");
      form.submit();
    }
  };

  addListener() {
    ReactDOM.findDOMNode(this).addEventListener(
      "click",
      this.overrideLinkHandler
    );
  }


  render() {
    const {
      actionurl,
      clientid,
      responsetype,
      acrvalue,
      redirecturi,
      scope,
    } = this.state;
    const { optumIdcontentData, isOptumIDFetching } = this.props;
    return (
      <React.Fragment>
        <form id="form-id" method="post" action={actionurl || ""}>
          <div>
            <input type="hidden" name="client_id" value={clientid || ""} />
            <input
              type="hidden"
              name="response_type"
              value={responsetype || ""}
            />
            <input type="hidden" name="acr_values" value={acrvalue || ""} />
            <input
              type="hidden"
              name="redirect_uri"
              value={redirecturi || ""}
            />
            <input type="hidden" name="scope" value={scope || ""} />

            <input className="hideSubmit" type="submit" value="" />
            {isOptumIDFetching && (
              <div className="mt15">
                <Skeleton
                  className="Skeleton"
                  variant="text"
                  width={350}
                  height={20}
                />
              </div>
            )}
            <fieldset className="link-btn">
              {optumIdcontentData && optumIdcontentData.body && (
                <div>
                  <Content html={optumIdcontentData.body} />
                </div>
              )}
            </fieldset>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default MsIdAuth;

