import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const YOUTUBE_REQUEST = "YOUTUBE_REQUEST";
export const YOUTUBE_SUCCESS = "YOUTUBE_SUCCESS";
export const YOUTUBE_FAILURE = "YOUTUBE_FAILURE";

export const TWITTER_REQUEST = "TWITTER_REQUEST";
export const TWITTER_SUCCESS = "TWITTER_SUCCESS";
export const TWITTER_FAILURE = "TWITTER_FAILURE";

export const LINKEDIN_REQUEST = "LINKEDIN_REQUEST";
export const LINKEDIN_SUCCESS = "LINKEDIN_SUCCESS";
export const LINKEDIN_FAILURE = "LINKEDIN_FAILURE";

// Youtube images
export function requestYoutube() {
  return {
    type: YOUTUBE_REQUEST,
    isYouTubeFetched: false,
  };
}

export function receiveYoutube(youTubeData) {
  return {
    type: YOUTUBE_SUCCESS,
    isYouTubeFetched: true,
    youTubeData,
  };
}

export function youtubeFailure(message) {
  return {
    type: YOUTUBE_FAILURE,
    isYouTubeFetched: false,
    isYoutubeError: true,
    message,
  };
}

// Twitter images
export function requestTwitter() {
  return {
    type: TWITTER_REQUEST,
    isTwitterFetched: false,
  };
}

export function receiveTwitter(twitterData) {
  return {
    type: TWITTER_SUCCESS,
    isTwitterFetched: true,
    twitterData,
  };
}

export function twitterFailure(message) {
  return {
    type: TWITTER_FAILURE,
    isTwitterFetched: false,
    isTwitterError: true,
    message,
  };
}

// Linkedin images
export function requestLinkedIn() {
  return {
    type: LINKEDIN_REQUEST,
    isLinkedInFetched: false,
  };
}

export function receiveLinkedIn(linkedInData) {
  return {
    type: LINKEDIN_SUCCESS,
    isLinkedInFetched: true,
    linkedInData,
  };
}

export function linkedInFailure(message) {
  return {
    type: LINKEDIN_FAILURE,
    isLinkedInFetched: false,
    isLinkedInError: true,
    message,
  };
}

// Youtube
export function getYoutubeContent() {
  return (dispatch) => {
    dispatch(requestYoutube());
    const url = fetchGeneralContentUrl("/cms/getFollowUsYoutube");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(youtubeFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveYoutube(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(linkedInFailure(error));
      });
  };
}

// LikedIn
export function getLinkedInContent() {
  return (dispatch) => {
    dispatch(requestLinkedIn());
    const url = fetchGeneralContentUrl("/cms/getFollowUsLinkedin");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(linkedInFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveLinkedIn(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(linkedInFailure(error));
      });
  };
}

// Twitter
export function getTwitterContent() {
  return (dispatch) => {
    dispatch(requestTwitter());
    const url = fetchGeneralContentUrl("/cms/getFollowUsTwitter");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(twitterFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveTwitter(data));
        }
        return data;
      })
      .catch(function (error) {
        dispatch(twitterFailure(error));
      });
  };
}
