import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
export const ACCOUNT_TEAM_IMAGES_REQUEST = "ACCOUNT_TEAM_IMAGES_REQUEST";
export const ACCOUNT_TEAM_IMAGES_UPDATE = "ACCOUNT_TEAM_IMAGES_UPDATE";

function requestAccountTeamImages(email) {
  const accountTeamImages = {};
  accountTeamImages["isFetching"] = true;
  accountTeamImages["value"] = {};
  accountTeamImages["email"] = email;
  return {
    type: ACCOUNT_TEAM_IMAGES_REQUEST,
    data: accountTeamImages
  };
}

function receiveAccountTeamImages(data, email) {
  const accountTeamImages = {};
  accountTeamImages["value"] = data;
  accountTeamImages["isFetching"] = false;
  accountTeamImages["email"] = email;
  return {
    type: ACCOUNT_TEAM_IMAGES_UPDATE,
    data: accountTeamImages
  };
}

function accountTeamImagesFailure(message, email) {
  const accountTeamImages = {};
  accountTeamImages["value"] = null;
  accountTeamImages["isFetching"] = false;
  accountTeamImages["email"] = email;
  return {
    type: ACCOUNT_TEAM_IMAGES_UPDATE,
    message,
    data: accountTeamImages
  };
}

export function getAccountTeamImages(email) {
  const requestParam = {};
  requestParam["code"] = email;
  if (requestParam) {
    return dispatch => {
      dispatch(requestAccountTeamImages(email));
      const url = fetchGeneralContentUrl("/cms/getAccountManagerByCode");
      http
        .post(url, JSON.stringify(requestParam))
        .then(response => {
          const data = response.data;
          if (response.status !== 200) {
            dispatch(accountTeamImagesFailure(data.message, email));
            return Promise.reject(data);
          } else {
            if (data && data.length > 0) {
              dispatch(receiveAccountTeamImages(data[0], email));
            }
          }
          return data;
        })
        .catch(function (error) {
          dispatch(accountTeamImagesFailure(error, email));
        });
    };
  }
  return null;
}
