import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const SPECIALTY_SUCCESS = "SPECIALTY_SUCCESS";

export function requestSpecialty(pageType) {
  const specialtyResponse = {};
  specialtyResponse["pageType"] = pageType;
  specialtyResponse["isFetching"] = true;
  specialtyResponse["iserror"] = false;
  return {
    type: SPECIALTY_SUCCESS,
    data: specialtyResponse,
  };
}

export function receiveSpecialty(data, pageType) {
  const specialtyResponse = {};
  specialtyResponse["pageType"] = pageType;
  specialtyResponse["value"] = data;
  specialtyResponse["isFetching"] = false;
  specialtyResponse["iserror"] = false;

  return {
    type: SPECIALTY_SUCCESS,
    data: specialtyResponse,
  };
}

export function specialtyFailure(message, pageType) {
  const specialtyResponse = {};
  specialtyResponse["pageType"] = pageType;
  specialtyResponse["isFetching"] = false;
  specialtyResponse["iserror"] = true;
  return {
    type: SPECIALTY_SUCCESS,
    data: specialtyResponse,
    message,
  };
}

export function getSpecialtyPart(pageType) {
  if (pageType) {
    var endPoint = "";
    return (dispatch) => {
      dispatch(requestSpecialty(pageType));
      if (pageType.toUpperCase() === "PARTICIPANT") {
        endPoint = "/cms/getNodeByCode/t2/SpecialtyPart";
      } else if (pageType.toUpperCase() === "PROMOTIONAL") {
        endPoint = "/cms/getNodeByCode/t2/SpecialtyPro";
      }else{
        endPoint="";
      }
      const url = fetchGeneralContentUrl(endPoint);
      http
        .get(url)
        .then((response) => {
          const data = response.data;
          if (response.status === 200) {
            dispatch(receiveSpecialty(data, pageType));
          } else {
            dispatch(specialtyFailure(data.message, pageType));
            return Promise.reject(data);
          }
          return null;
        })
        .catch(function (error) {
          dispatch(specialtyFailure(error, pageType));
        });
    };
  }
  return null;
}
