import React, { Component } from "react";
import { Box } from "@material-ui/core";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import Content from "../../components/Shared/Content/Content";
import WorkTeamContainer from "../../containers/WorkTeamContainer";
import ResourceAndNewsContainer from "../../containers/ResourceAndNewsContainer";
import VideoContainer from "../../containers/VideoContainer";
import MessageContainer from "../../containers/MessageContainer";
import pageTypeEnum from "../../constants/pageType";
import AccountProductQuickLaunchContainer from "../../containers/AccountProductQuickLaunchContainer";
import ReportContainer from "../../containers/ReportContainer";
import AnnouncementContainer from "../../containers/AnnouncementContainer";
import { getFilesFromBytes } from "../../actions/filesFromBytes";
import FormularyLookupContainer from "../../containers/FormularyLookupContainer";
class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  overrideLinkHandler = (e) => {
    if (
      e.target.tagName === "A" &&
      e.target.getAttribute("class") === "internalLink"
    ) {
      e.preventDefault();
      this.props.history.push(e.target.getAttribute("href"));
    } else if (
      e.target.tagName === "A" &&
      e.target.getAttribute("href") &&
      e.target.getAttribute("class") === "downloadLink"
    ) {
      e.preventDefault();
      const hrefData = e.target.getAttribute("href");
      const fileName = hrefData.substr(hrefData.lastIndexOf("/") + 1);
      const req = {};
      req["filePath"] = hrefData;
      this.props.dispatch(getFilesFromBytes(req, fileName));
    }
  };

  componentDidMount() {
    this.addListener();
  }

  addListener() {
    ReactDOM.findDOMNode(this).addEventListener(
      "click",
      this.overrideLinkHandler
    );
  }

  componentWillUnmount() {
    this.addListener();
  }

  render() {
    const { selectedAccount, data, bcbsUser, user, accountList } = this.props;
    if (!data) {
      return null;
    }
    return (
      <div className="row-">
        <div className="col-sm-9 leftBlock">
          <article>
            <div className="row" ref={this.containerRef}>
              {data && (
                <div>
                  <h1>{data.title && <Content html={data.title} />}</h1>
                  {data.body && <Content html={data.body} />}
                </div>
              )}
            </div>
          </article>
          <React.Fragment>
            {selectedAccount &&
            selectedAccount["u_account.u_acct_mgmt_team_model"] &&
            selectedAccount[
              "u_account.u_acct_mgmt_team_model"
            ].toUpperCase() === "YES" ? (
              <>
                <Box mt={-4}>
                  <MessageContainer
                    pageType={pageTypeEnum.HOME_PAGE}
                    pageId={data.nid}
                  />
                </Box>
                <hr />
                <AnnouncementContainer />
              </>
            ) : (
              <WorkTeamContainer selectedAccount={selectedAccount} />
            )}
          </React.Fragment>
        </div>
        <div className="col-sm-4 rightBlock">
          {accountList &&
            accountList.map(
              (accountName) =>
                selectedAccount &&
                selectedAccount["u_account.name"] &&
                selectedAccount["u_account.name"] === accountName && (
                  <FormularyLookupContainer />
                )
            )}
          <AccountProductQuickLaunchContainer />
          {bcbsUser && bcbsUser.isbcbs && <ReportContainer />}
          <VideoContainer pageType={pageTypeEnum.HOME_PAGE} pageId={data.nid} />
          <ResourceAndNewsContainer />
          {/* <MessageContainer
            pageType={pageTypeEnum.HOME_PAGE}
            pageId={data.nid}
          /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, whiteLabel }) => {
  return {
    auth,
    whiteLabel,
  };
};
export default connect(mapStateToProps)(withRouter(LandingPage));
