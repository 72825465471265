import React, { Component } from "react";
import LoginPage from "../components/Login/LoginPage";
import { connect } from "react-redux";
import {
  getSignInContent,
  getOptumIdSignInContent
} from "../actions/signInContent";

class LoginContainer extends Component {
  state = {
    signInContent: "",
    optumIdSignInContent: ""
  };
  componentWillUpdate(nextprops) {
    this.getSignInContentData(nextprops);
  }

  componentDidMount() {
    document.title = "Client Portal - Login";
    this.getSignInContentData(this.props);
  }

  getSignInContentData(props) {
    const {
      isFetching,
      signInContent,
      isError,
      isOptumIdError,
      isOptumIDFetching,
      optumIdSignInContent,
      whiteLabel
    } = props;
    this.setClienSignIncontent(isError, isFetching, signInContent, whiteLabel);
    this.setOptumIDSignInContent(
      isOptumIdError,
      isOptumIDFetching,
      optumIdSignInContent,
      whiteLabel
    );
  }

  setOptumIDSignInContent(
    isOptumIdError,
    isOptumIDFetching,
    optumIdSignInContent,
    whiteLabel
  ) {
    if (!isOptumIdError) {
      if (!isOptumIDFetching) {
        if (optumIdSignInContent) {
          if (
            optumIdSignInContent &&
            optumIdSignInContent !== this.state.optumIdSignInContent
          ) {
            this.setState({ optumIdSignInContent });
          }
        } else {
          this.props.dispatch(getOptumIdSignInContent(whiteLabel));
        }
      }
    }
  }

  setClienSignIncontent(isError, isFetching, signInContent, whiteLabel) {
    if (!isError) {
      if (!isFetching) {
        if (signInContent) {
          if (signInContent && signInContent !== this.state.signInContent) {
            this.setState({ signInContent });
          }
        } else {
          this.props.dispatch(getSignInContent(whiteLabel));
        }
      }
    }
  }

  render() {
    const contentData = this.state.signInContent[0];
    const optumIdcontentData = this.state.optumIdSignInContent[0];
    const {
      loginClick,
      auth,
      whiteLabel,
      isFetching,
      isOptumIDFetching,
      setApplicationContext,
      ...props
    } = this.props;
    return (
      <React.Fragment>
        <LoginPage
          {...props}
          loginClick={loginClick}
          auth={auth}
          contentData={contentData}
          isOptumIDFetching={isOptumIDFetching}
          optumIdcontentData={optumIdcontentData}
          whiteLabel={whiteLabel}
          isFetching={isFetching}
          setApplicationContext={setApplicationContext}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ signInContent }) => {
  return signInContent;
};

export default connect(mapStateToProps)(LoginContainer);
