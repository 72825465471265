import React, { Component } from "react";
import SideFilter from "../components/FileCabinet/InvoicesTab/SideFilter/SideFilter";
import InvoiceDetailTable from "../components/FileCabinet/InvoicesTab/InvoiceDetailTable/InvoiceDetailTable";
import moment from "moment";
import { invoiceActions } from "../actions/tealium";

class InvoiceDetailContainer extends Component {
  state = {
    billingEnity: [],
    invoiceInfo: [],
    filtererdInfo: [],
  };

  componentDidMount() {
    this.setInvoiceData(this.props);
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    this.setInvoiceData(newprops);
  }

  setInvoiceData(props) {
    const { invoiceInfo } = props;
    if (invoiceInfo !== this.state.invoiceInfo) {
      this.setState({
        invoiceInfo,
        filtererdInfo: invoiceInfo,
      });
    }
  }

  filterFromBillingEnity(billingEnity) {
    this.setState({
      billingEnity,
    });
    this.filterListData(billingEnity);
  }

  filterListData(billingEnity) {
    const { invoiceInfo } = this.state;
    const { selectedValue, fromDate, toDate, invoicetext } = this.props;

    if (
      invoiceInfo &&
      invoiceInfo.length > 0 &&
      billingEnity &&
      billingEnity.length > 0
    ) {
      let filterData = invoiceInfo.filter(function (item) {
        return billingEnity.includes(item.billingEntity["billingEntityId"]);
      });
      const fromFormatedData =
        fromDate && fromDate !== "Invalid date"
          ? moment(fromDate).format("MMDDYYYY")
          : "";
      const toFormatedData =
        toDate && toDate !== "Invalid date"
          ? moment(toDate).format("MMDDYYYY")
          : "";

      this.setState({ filtererdInfo: filterData });
      this.setAnalytics(
        invoicetext,
        fromFormatedData,
        toFormatedData,
        selectedValue,
        filterData.length,
        billingEnity
      );
    } else {
      this.setState({ filtererdInfo: invoiceInfo });
    }
  }

  setAnalytics(
    invoicetext,
    fromDate,
    toDate,
    selectedValue,
    searchItem,
    billingEnity
  ) {
    const searchTerm = invoicetext ? invoicetext : "";
    if (toDate && fromDate && selectedValue) {
      var searchFilters =
        selectedValue + " | Start Date: " + fromDate + " | End Date: " + toDate;
    } else {
      searchFilters = selectedValue;
    }
    const updatedSearchFilter = billingEnity.map(
      (item) => " | Billing entity: " + item
    );
    searchFilters = searchFilters + updatedSearchFilter;
    const searchItems = searchItem ? searchItem : "zero";
    this.props.dispatch(invoiceActions(searchTerm, searchFilters, searchItems));
  }

  render() {
    const {
      entityItem,
      isInvoiceLoadComplete,
      dispatch,
      user,
      sideFilterValue,
      currentDate,
      restrictDate
    } = this.props;
    const { filtererdInfo } = this.state;

    return (
      <React.Fragment>
        <div className="tbl-wrapper marB20 sep sep25 row-">
          <SideFilter
            entityItem={entityItem}
            sideFilterValue={sideFilterValue}
            filterFromBillingEnity={this.filterFromBillingEnity.bind(this)}
            currentDate={currentDate}
            restrictDate={restrictDate}
          />

          <InvoiceDetailTable
            invoiceInfo={filtererdInfo}
            isInvoiceLoadComplete={isInvoiceLoadComplete}
            dispatch={dispatch}
            user={user}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default InvoiceDetailContainer;
