import React, { Component } from "react";
import Input from "../Input/Input";
import Button from "../Button/Button";
import globalConstants from "../../../constants/global-constants";

class InputForm extends Component {
  state = {
    data: {},
    errors: {}
  };

  renderInput(name, label, type) {
    const { data, errors } = this.state;
    return (
      <Input
        type={type}
        name={name}
        label={label}
        value={data[name] || ""}
        onChange={this.handleChange}
        error={errors[name]}
        // className="form-control"
      />
    );
  }

  renderButton(label, friendlyname) {
    return (
      <Button
        type={globalConstants.SUBMIT}
        value={label}
        friendlyname={friendlyname}
        className="button-secondary noCap"
      />
    );
  }
}

export default InputForm;
