import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const HOMEDELIVERY_SUCCESS = "HOMEDELIVERY_SUCCESS";

export function requestHomedelivery(pageType) {
  const homedeliveryRespose = {};
  homedeliveryRespose["pageType"] = pageType;
  homedeliveryRespose["isFetching"] = true;
  homedeliveryRespose["isError"] = false;
  return {
    type: HOMEDELIVERY_SUCCESS,
    data: homedeliveryRespose,
  };
}

export function receiveHomedelivery(data, pageType) {
  const homedeliveryRespose = {};
  homedeliveryRespose["pageType"] = pageType;
  homedeliveryRespose["value"] = data;
  homedeliveryRespose["isFetching"] = false;
  homedeliveryRespose["isError"] = false;
  return {
    type: HOMEDELIVERY_SUCCESS,
    data: homedeliveryRespose,
  };
}

export function homedeliveryFailure(message, pageType) {
  const homedeliveryRespose = {};
  homedeliveryRespose["pageType"] = pageType;
  homedeliveryRespose["isFetching"] = false;
  homedeliveryRespose["isError"] = true;
  return {
    type: HOMEDELIVERY_SUCCESS,
    data: homedeliveryRespose,
    message,
  };
}

export function getHomeDeliveryPart(pageType) {
  if (pageType) {
    return (dispatch) => {
      var endPoint = "";
      dispatch(requestHomedelivery(pageType));
      if (pageType.toUpperCase() === "PARTICIPANT") {
        endPoint = "/cms/getNodeByCode/t2/HomeDeliveryPart";
      } else if (pageType.toUpperCase() === "PROMOTIONAL") {
        endPoint = "/cms/getNodeByCode/t2/HomeDeliveryPro";
      }
      const url = fetchGeneralContentUrl(endPoint);

      http
        .get(url)
        .then((response) => {
          const data = response.data;
          if (response.status === 200) {
            dispatch(receiveHomedelivery(data, pageType));
            return null;
          } else {
            dispatch(homedeliveryFailure(data.message, pageType));
            return Promise.reject(data);
          }
        })
        .catch(function (error) {
          dispatch(homedeliveryFailure(error, pageType));
        });
    };
  }
  return null;
}
