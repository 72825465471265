import { USER_TYPE } from "../actions/user";

function user(state = { userType: "", isType: "" }, action) {
  switch (action.type) {
    case USER_TYPE:
      return Object.assign({}, state, {
        userType: action.userType,
        isType: action.isType
      });
    default:
      return state;
  }
}

export default user;
