import React from "react";
import { NavLink } from "react-router-dom";
import routePath from "../../../../constants/routes";
const FormularyManagementLN = ({ type, subType }) => {
  return (
    <React.Fragment>
      <strong
        className={type === "FormularyManagement" && !subType ? "selected" : ""}
      >
        <NavLink
          link-id=" Formulary management"
          to={routePath.relatedLink_FormularyManagement}
        >
          Formulary management
        </NavLink>
      </strong>
      <ul>
        <li
          className={
            type === "FormularyManagement" && subType === "MediSpanWeeklyReport"
              ? "selected"
              : ""
          }
        >
          <NavLink
            link-id="MediSpan weekly report"
            to={
              routePath.relatedLink_FormularyManagement +
              "?type=MediSpanWeeklyReport"
            }
          >
            MediSpan weekly report
          </NavLink>
        </li>

        <li
          className={
            type === "FormularyManagement" && subType === "OptumRxFormularies"
              ? "selected"
              : ""
          }
        >
          <NavLink
            link-id="OptumRx formularies"
            to={
              routePath.relatedLink_FormularyManagement +
              "?type=OptumRxFormularies"
            }
          >
            OptumRx formularies
          </NavLink>
        </li>
        <li
          className={
            type === "FormularyManagement" && subType === "PriceIncreases"
              ? "selected"
              : ""
          }
        >
          <NavLink
            link-id="Drug manufacturer planned price increases in California"
            to={
              routePath.relatedLink_FormularyManagement + "?type=PriceIncreases"
            }
          >
            Drug manufacturer planned price increases in California
          </NavLink>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default FormularyManagementLN;
