import { fetchDashboardUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";

export function requestDashboardData(accountId) {
  const dashboardRespnose = {};
  dashboardRespnose["accountId"] = accountId;
  dashboardRespnose["isFetching"] = true;
  dashboardRespnose["isError"] = false;
  return {
    type: DASHBOARD_SUCCESS,
    data: dashboardRespnose,
  };
}

export function receiveDashboardData(data, accountId) {
  const dashboardRespnose = {};
  dashboardRespnose["accountId"] = accountId;
  dashboardRespnose["dashboardData"] = data;
  dashboardRespnose["isFetching"] = false;
  dashboardRespnose["isError"] = false;
  return {
    type: DASHBOARD_SUCCESS,
    data: dashboardRespnose,
  };
}

export function dashboardDataFailure(message, accountId) {
  const dashboardRespnose = {};
  dashboardRespnose["accountId"] = accountId;
  dashboardRespnose["isFetching"] = false;
  dashboardRespnose["isError"] = true;
  return {
    type: DASHBOARD_SUCCESS,
    accountId: accountId,
    data: dashboardRespnose,
    message,
  };
}

export function getDashboardData(request, accountId) {
  if (request) {
    return (dispatch) => {
      dispatch(requestDashboardData(accountId));
      const url = fetchDashboardUrl();
      http
        .post(url, JSON.stringify(request))
        .then((response) => {
          const data = response.data;
          if (response.status === 200) {
            dispatch(receiveDashboardData(data, accountId));
            return data;
          } else {
            dispatch(dashboardDataFailure(data.message, accountId));
            return Promise.reject(data);
          }
        })
        .catch(function (error) {
          dispatch(dashboardDataFailure(error, accountId));
        });
    };
  }
  return null;
}
