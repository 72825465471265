import { combineReducers } from "redux";
import authLog from "./login";
import resourceAndNews from "./resourceAndNews";
import signInContent from "./signInContent";
import headerLogo from "./headerLogo";
import accountsMetaData from "./accountsMetaData";
import followUsContent from "./followUsContent";
import preLoginFooterContent from "./preLoginFooter";
import copyRightContent from "./copyRight";
import landingContent from "./landingContent";
import clientAccountListContent from "./clientAccountListContent";
import userAccount from "./userAccount";
import accountTeams from "./accountTeams";
import AEM from "./AEM";
import dashboard from "./dashboard";
import myProduct from "./myProduct";
import productDocumentation from "./productDocumentation";
import productOffering from "./productOffering";
import videoMetaData from "./video";
import messageData from "./messageBox";
import termsOfUseContent from "./termsOfUseContent";
import privacyPolicyContent from "./privacyPolicyContent";
import contactUsContent from "./contactUsContent";
import signOutContent from "./signOutContent";
import noAccessContent from "./noAccessContent";
import getDocumentRetrievalLink from "./getDocumentRetrieval";
import accountProductQuickLaunch from "./accountProductQuickLaunch";
import accountProfileContent from "./accountProfileContent";
import clientRequestContent from "./clientRequestContent";
import clientRequestErrorContent from "./clientRequestErrorContent";
import applicationContent from "./applicationContent";
import productsMetaData from "./productMetaData";
import homeDelivery from "./homeDelivery";
import specialty from "./specialty";
import clinicalServices from "./clinicalServices";
import fileCabinetContent from "./fileCabinetContent";
import clinicalPublicationAEM from "./clinicalPublicationAEM";
import clinicalPublicationMetaData from "./clinicalPublicationMetaData";
import csRelatedLinksMetaData from "./csRelatedLinksMetaData";
import formularyList from "./relatedLinkFormularyList";
import accountTeamImages from "./accountTeamImages";
import governmentProgramContent from "./governmentProgramContent";
import user from "./user";
import getAnnouncements from "./Announcement";
import internalEmployeeProducts from "./internalEmployeeProducts";
import invoiceContent from "./invoiceContent";
import governmentRelatedLinksMetaData from "./governmentRelatedLinksMetaData";
import getClients from "./getClients";
import getBillingEntity from "./getBillingEntity";
import getMonths from "./getMonths";
import getBcbsUser from "./bcbsUser";
import getInvoiceDocuments from "./getInvoiceDocuments";
import fileTree from "./fileTree";
import fetchAllFiles from "./getAllFiles";
import getDownloadInvoice from "./downloadInvoice";
import memberIdContent from "./memberIDGenerator";
import networkError from "./networkError";
import networkContent from "./networkContent";
import userProfile from "./userProfile";
import auth from "./oidAuthentication";
import filesFromBytes from "./filesFromBytes";
import getReportLinks from "./reportLinks";
import getGeneratedReportLink from "./generateReportLink";
import fetchDocumentUrl from "./getDocumentUrl";
import fetchDownloadfromAzure from "./getDownloadAzure";
import whiteLabel from "./whiteLabel";
import tealiumDataLayer from "./tealium";
import rxTrackSSO from "./rxTrackSSO";
import rxClaimSSO from "./rxClaimSSO";
import requestSSOData from "./ssoRequest";
import cpHeaderLogo from "./cpHeaderLogo";
import paLite from "./paLite";
import getBASDocuments from "./getBASDocuments";
import drugSearchContent from "./drugSearchContent";
import drugSearchFormularyContent from "./drugSearchFormularyContent"
export const appReducer = combineReducers({
  authLog,
  auth,
  resourceAndNews,
  userAccount,
  signInContent,
  followUsContent,
  landingContent,
  clientAccountListContent,
  videoMetaData,
  messageData,
  preLoginFooterContent,
  headerLogo,
  copyRightContent,
  accountTeams,
  accountsMetaData,
  termsOfUseContent,
  privacyPolicyContent,
  contactUsContent,
  signOutContent,
  noAccessContent,
  accountProductQuickLaunch,
  accountProfileContent,
  clientRequestContent,
  clientRequestErrorContent,
  applicationContent,
  productsMetaData,
  homeDelivery,
  getAnnouncements,
  specialty,
  clinicalServices,
  fileCabinetContent,
  clinicalPublicationAEM,
  clinicalPublicationMetaData,
  csRelatedLinksMetaData,
  formularyList,
  accountTeamImages,
  user,
  invoiceContent,
  AEM,
  dashboard,
  myProduct,
  productDocumentation,
  productOffering,
  governmentProgramContent,
  internalEmployeeProducts,
  governmentRelatedLinksMetaData,
  getClients,
  getInvoiceDocuments,
  getBillingEntity,
  getMonths,
  getBcbsUser,
  fileTree,
  fetchAllFiles,
  getDownloadInvoice,
  fetchDocumentUrl,
  fetchDownloadfromAzure,
  memberIdContent,
  networkError,
  networkContent,
  userProfile,
  filesFromBytes,
  getReportLinks,
  getGeneratedReportLink,
  getDocumentRetrievalLink,
  whiteLabel,
  tealiumDataLayer,
  rxTrackSSO,
  requestSSOData,
  paLite,
  rxClaimSSO,
  cpHeaderLogo,
  getBASDocuments,
  drugSearchContent,
  drugSearchFormularyContent
});
