import {
  MEMBER_ID_GEN_CONTENT_REQUEST,
  MEMBER_ID_GEN_CONTENT_SUCCESS,
  MEMBER_ID_GEN_CONTENT_FAILURE,
  SSN_ID_REQUEST,
  SSN_ID_SUCCESS,
  SSN_ID_FAILURE,
} from "../actions/memberIdGenerator";

export function memberIdContent(
  state = {
    isFetching: false,
    memberIdContent: "",
    generatedSSNId: "",
  },
  action
) {
  switch (action.type) {
    case MEMBER_ID_GEN_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        memberIdContent: "",
      });
    case MEMBER_ID_GEN_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        memberIdContent: action.memContent,
      });
    case MEMBER_ID_GEN_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    case SSN_ID_REQUEST:
      return Object.assign({}, state, {
        isSSNFetching: true,
        generatedSSNId: "",
      });
    case SSN_ID_SUCCESS:
      return Object.assign({}, state, {
        isSSNFetching: false,
        generatedSSNId: action.ssnId,
      });
    case SSN_ID_FAILURE:
      return Object.assign({}, state, {
        isSSNFetching: false,
        isSSNError: true,
        error: action.ssnMessage,
      });
    default:
      return state;
  }
}

export default memberIdContent;
