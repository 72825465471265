import {
  HEADER_LOGO_REQUEST,
  HEADER_LOGO_SUCCESS,
  HEADER_LOGO_FAILURE
} from "../actions/headerLogo";

function headerLogo(
  state = {
    isFetching: false,
    headerLogo: ""
  },
  action
) {
  switch (action.type) {
    case HEADER_LOGO_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        headerLogo: ""
      });
    case HEADER_LOGO_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        headerLogo: action.logo
      });
    case HEADER_LOGO_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message
      });
    default:
      return state;
  }
}

export default headerLogo;
