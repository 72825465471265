import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import "./CustomFooter.scss";

const defaultFooterStyles = {};

class CustomFooter extends React.Component {
  render() {
    const { count, component, goToPage } = this.props;
    return (
      <TableFooter>
        <TableRow>
          <TableCell className="CustomFooter">
            <div className="CustomFooterCount">
              {count} records
            </div>
            <div className="CustomFooterPagination">
              {component}
            </div>
            <div className="CustomFooterGoToPage">
              {goToPage}
            </div>
          </TableCell>
        </TableRow>
      </TableFooter>
    );
  }
}

export default withStyles(defaultFooterStyles, { name: "CustomFooter" })(
  CustomFooter
);
