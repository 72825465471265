import React, { Component } from "react";
import routePath from "../../../constants/routes";
import { NavLink } from "react-router-dom";
class RelatedLinksComponent extends Component {
  state = {};
  render() {
    return (
      <article>
        <div className="list">
          <h6>Related links</h6>

          <div className="content">
            <p dir="ltr" />
            <ul>
              <li>
                <NavLink link-id={"P&T Committee support"} to={routePath.relatedLink_PTCommitteeSupport}>
                  P&amp;T Committee support
                </NavLink>
              </li>
              <li>
                <NavLink link-id={"Utilization management"} to={routePath.relatedLink_UtilizationManagement}>
                  Utilization management
                </NavLink>
              </li>
              <li>
                <NavLink link-id={"Formulary management"} to={routePath.relatedLink_FormularyManagement}>
                  Formulary management
                </NavLink>
              </li>
            </ul>
            <p />
          </div>
        </div>
      </article>
    );
  }
}

export default RelatedLinksComponent;
