const FormularyLookupStyles = (theme) => ({
  main: {
    border: "1px solid",
    borderColor: theme.customPalette.color.grey4,
    marginBottom: "20px",
    borderRadius: "3px",
  },
  iconstyles: {
    "& svg": {
      fontSize: "10px",
      color: theme.customPalette.color.black4,
    },
  },
});

export default FormularyLookupStyles;
