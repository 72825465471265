import {
  GET_RELATEDLINK_FORMULARY_LIST_REQUEST,
  GET_RELATEDLINK_FORMULARY_LIST_SUCCESS,
  GET_RELATEDLINK_FORMULARY_LIST_FAILURE,
} from "../actions/relatedLinkFormularyList";

export function formularyList(
  state = {
    isFetching: false,
    formularyListContent: "",
  },
  action
) {
  switch (action.type) {
    case GET_RELATEDLINK_FORMULARY_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        formularyListContent: "",
      });

    case GET_RELATEDLINK_FORMULARY_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        formularyListContent: action.data,
      });

    case GET_RELATEDLINK_FORMULARY_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });

    default:
      return state;
  }
}

export default formularyList;
