import React from "react";
import { connect } from "react-redux";
import { getAccountProductQuickLauch } from "../actions/accountProductQuickLaunch";
import ApplicationQuickLaunch from "../components/LandingPage/ApplicationQuickLaunch/ApplicationQuickLaunch";
import globalConstants from "../constants/global-constants";

class AccountProductQuickLaunchContainer extends React.Component {
  state = {
    productQuickLaunch: {},
    title: "",
    isVisible: "",
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getAccountProductQuickLaunch(nextProps);
  }

  componentDidMount() {
    this.getAccountProductQuickLaunch(this.props);
  }

  getAccountProductQuickLaunch(nextProps) {
    const {
      selectedAccount,
      landingContent,
      accountProductQuickLaunch,
    } = nextProps;
    if (selectedAccount) {
      if (selectedAccount && selectedAccount.u_account) {
        if (landingContent) {
          const data = landingContent.landingContent;
          if (data && data.length > 0) {
            this.setState({
              title: data[0].field_quicklaunch_title,
              isVisible: data[0].field_quicklaunch_visibility,
            });
          }
        }
        if (accountProductQuickLaunch) {
          const productQuickLaunch = this.configQuickLaunchAndProduct(
            selectedAccount.u_account,
            accountProductQuickLaunch.productQuickLaunch
          );
          if (productQuickLaunch) {
            this.setState({
              productQuickLaunch,
            });
          } else {
            this.props.dispatch(
              getAccountProductQuickLauch(
                selectedAccount[globalConstants.U_ACCOUNT_NAME],
                selectedAccount.u_account
              )
            );
          }
        }
      }
    }
  }

  configQuickLaunchAndProduct(accountID, accountTeams) {
    return (
      accountTeams &&
      accountTeams.length > 0 &&
      accountTeams.find((item) => {
        return item && item.accountId === accountID;
      })
    );
  }

  render() {
    const { productQuickLaunch, title, isVisible } = this.state;
    const {selectedAccount} = this.props;
    let quickLaunchList = [];
    if (
      productQuickLaunch &&
      productQuickLaunch.productAndquickLaunch &&
      productQuickLaunch.productAndquickLaunch.quickLaunchList &&
      productQuickLaunch.productAndquickLaunch.quickLaunchList.length > 0
    ) {
      quickLaunchList =
        productQuickLaunch.productAndquickLaunch.quickLaunchList;
    }
    if (title && isVisible === "1") {
      return (
        <React.Fragment>
          <ApplicationQuickLaunch
            title={title}
            quickLaunchList={quickLaunchList}
            selectedAccount={selectedAccount}
          />
        </React.Fragment>
      );
    }
    return null;
  }
}

const mapStateToProps = ({
  accountProductQuickLaunch,
  userAccount,
  landingContent,
  auth,
}) => {
  return {
    accountProductQuickLaunch,
    selectedAccount: userAccount && userAccount.selectedAccount,
    landingContent,
    auth,
  };
};

export default connect(mapStateToProps)(AccountProductQuickLaunchContainer);
