import React, { Component } from "react";
import { connect } from "react-redux";
import { getSpecialtyPart } from "../actions/specialty";
import Specialty from "../components/Specialty/Specialty";
import { isInternal } from "../constants/service";
import globalConstants from "../constants/global-constants";

class SpecialtyContainer extends Component {
  state = {
    specialty: "",
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getSpecialtyData(newProps);
  }

  componentDidMount() {
    document.title = "Client Portal - Specialty";
    this.getSpecialtyData(this.props);
  }

  getSpecialtyData(props) {
    const { selectedAccount, selectedMode, user, specialty } = props;
    if (isInternal(user)) {
      this.configureForInternalUser(specialty, selectedMode);
    } else {
      this.configuredForAccounts(selectedAccount, specialty);
    }
  }

  configureForInternalUser(specialty, selectedMode) {
    if (!specialty.isError && !specialty.isFetching) {
      const specialtyInfo = this.configSpecialty(
        selectedMode,
        specialty.specialty
      );
      if (specialtyInfo) {
        this.setState({
          specialty: specialtyInfo,
        });
      } else {
        this.props.dispatch(getSpecialtyPart(selectedMode))
      }
    }
  }

  configuredForAccounts(selectedAccount, specialty) {
    if (selectedAccount) {
      const pageType =
        selectedAccount[globalConstants.NAVIGATION_SPECIALTY_TAB];
      if (specialty) {
        const specialtyInfo = this.configSpecialty(
          selectedAccount[globalConstants.NAVIGATION_SPECIALTY_TAB],
          specialty.specialty
        );
        if (specialtyInfo) {
          this.setState({
            specialty: specialtyInfo,
          });
        } else {
          this.props.dispatch(getSpecialtyPart(pageType))
        }
      }
    }
  }

  configSpecialty(pageType, specialty) {
    return (
      specialty &&
      specialty.length > 0 &&
      specialty.find((item) => {
        return item && item.pageType === pageType;
      })
    );
  }

  render() {
    const { specialty } = this.state;
    var specialtyData = [];
    if (specialty && specialty.value && specialty.value.length > 0) {
      specialtyData = specialty.value;
      return <Specialty contentData={specialtyData} />;
    }
    return null;
  }
}

const mapStateToProps = ({ userAccount, specialty, user }) => {
  return {
    selectedMode: userAccount.selectedMode,
    selectedAccount: userAccount.selectedAccount,
    specialty,
    user,
  };
};

export default connect(mapStateToProps)(SpecialtyContainer);
