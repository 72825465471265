import React from "react";

const UnAuthorized = () => {
  return (
    <div className="row">
      <h1>UnAuthorized access</h1>
      <div className="errorMsg error " dir="ltr">
        <p>
          <span>Important Note: </span>You don't have access to this Page.
        </p>

        <p>
          Please contact your client management account team for assistance.
        </p>
      </div>
    </div>
  );
};

export default UnAuthorized;
