import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const MEMBER_ID_GEN_CONTENT_REQUEST = "MEMBER_ID_GEN_CONTENT_REQUEST";
export const MEMBER_ID_GEN_CONTENT_SUCCESS = "MEMBER_ID_GEN_CONTENT_SUCCESS";
export const MEMBER_ID_GEN_CONTENT_FAILURE = "MEMBER_ID_GEN_CONTENT_FAILURE";
export const SSN_ID_REQUEST = "SSN_ID_REQUEST";
export const SSN_ID_SUCCESS = "SSN_ID_SUCCESS";
export const SSN_ID_FAILURE = "SSN_ID_FAILURE";

export function requestMemIdContent() {
  return {
    type: MEMBER_ID_GEN_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function requestSSNId() {
  return {
    type: SSN_ID_REQUEST,
    isFetched: false,
  };
}

export function receiveMemIdContent(data) {
  return {
    type: MEMBER_ID_GEN_CONTENT_SUCCESS,
    isFetched: true,
    memContent: data,
  };
}

export function receiveSSNId(data) {
  return {
    type: SSN_ID_SUCCESS,
    isFetched: true,
    ssnId: data,
  };
}

export function memIdContentFailure(message) {
  return {
    type: MEMBER_ID_GEN_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function ssnIdFailure(ssnMessage) {
  return {
    type: SSN_ID_FAILURE,
    isFetched: false,
    isError: true,
    ssnMessage,
  };
}

export function getMemIdContent() {
  return (dispatch) => {
    dispatch(requestMemIdContent());
    const url = fetchGeneralContentUrl("/cms/getmemberidcontent");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(memIdContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveMemIdContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(memIdContentFailure(error));
      });
  };
}

export function getGeneratedSSNID(req) {
  return (dispatch) => {
    dispatch(requestSSNId());
    const url = fetchGeneralContentUrl("/memberidgenerator/getIdForMember");
    http
      .post(url, JSON.stringify(req))
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(ssnIdFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveSSNId(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(ssnIdFailure(error));
      });
  };
}
