import React from "react";
import {
  getEnviormentPropByName,
  getUIURLs,
} from "../../constants/microservice-helpers";

var URLSearchParams = require("url-search-params");
class OIDSSO extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      actionurl: "",
      clientid: "",
      responsetype: "",
      redirecturi: "",
      scope: "",
      stateVal: "",
      nonce: "",
    };
  }

  handleSubmit(evt) {
    evt.preventDefault();
    this.props.onSubmit();
  }

  componentDidMount() {
    const { whiteLabel, setApplicationContext } = this.props;
    this.setAlpsToken();
    this.setState({
      actionurl:
        process.env.REACT_APP_OID_FLAG === "true"
          ? getEnviormentPropByName("REACT_APP_OPTUMID_ACTION_URL")
          : getEnviormentPropByName("REACT_APP_OHID_ACTION_URL"),
    });
    this.setState({
      clientid:
        process.env.REACT_APP_OID_FLAG === "true"
          ? getEnviormentPropByName("REACT_APP_OPTUMID_CLIENT_ID")
          : getEnviormentPropByName("REACT_APP_OHID_CLIENT_ID"),
    });
    this.setState({
      responsetype:
        process.env.REACT_APP_OID_FLAG === "true"
          ? getEnviormentPropByName("REACT_APP_OPTUMID_CLIENT_RESPONSE_TYPE")
          : getEnviormentPropByName("REACT_APP_OHID_CLIENT_RESPONSE_TYPE"),
    });
    this.setState({
      stateVal:
        process.env.REACT_APP_OID_FLAG === "true"
          ? getEnviormentPropByName("REACT_APP_OPTUMID_STATE_VALUES")
          : getEnviormentPropByName("REACT_APP_OHID_STATE_VALUES"),
    });
    if (whiteLabel) {
      this.setState({
        redirecturi:
          setApplicationContext && setApplicationContext != undefined
            ? getEnviormentPropByName("REACT_APP_WHITELABEL_URL") +
            setApplicationContext.basename +
            getEnviormentPropByName("REACT_APP_OPTUMID_REDIRECT_URI")
            : getEnviormentPropByName("REACT_APP_WHITELABEL_URL") +
            getEnviormentPropByName("REACT_APP_OPTUMID_REDIRECT_URI")
      });
    } else {
      this.setState({
        redirecturi: getUIURLs(getEnviormentPropByName("REACT_APP_OPTUMID_REDIRECT_URI"))
      });
    }
    this.setState({
      scope:
        process.env.REACT_APP_OID_FLAG === "true"
          ? getEnviormentPropByName("REACT_APP_OPTUMID_SCOPE")
          : getEnviormentPropByName("REACT_APP_OHID_SCOPE"),
    });
    this.setState({
      nonce:
        process.env.REACT_APP_OID_FLAG === "true"
          ? getEnviormentPropByName("REACT_APP_OPTUMID_NONCE")
          : getEnviormentPropByName("REACT_APP_OHID_NONCE"),
    });
  }

  setAlpsToken() {
    const { location } = this.props;
    const parameter = new URLSearchParams(location.search);
    const pingtoken = parameter.get("_alpsWsTkn");
    if (pingtoken) {
      sessionStorage.setItem("_alpsWsTkn", pingtoken);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.addListener(props);
  }

  addListener(props) {
    if (this && this.state && this.state.actionurl) {
      if (props) {
        this.containerRef.current.submit();
      }
    }
  }

  render() {
    const {
      actionurl,
      clientid,
      responsetype,
      stateVal,
      redirecturi,
      scope,
      nonce,
    } = this.state;
    return (
      <form method="post" ref={this.containerRef} action={actionurl || ""}>
        <input type="hidden" name="client_id" value={clientid || ""} />
        <input type="hidden" name="response_type" value={responsetype || ""} />
        <input type="hidden" name="redirect_uri" value={redirecturi || ""} />
        <input type="hidden" name="scope" value={scope || ""} />
        <input type="hidden" name="state" value={stateVal || ""} />
        <input type="hidden" name="nonce" value={nonce || " "} />
      </form>
    );
  }
}

export default OIDSSO;
