import { getRxClaimSSO } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
import globalConstants from "../constants/global-constants";

export const RxClaim_SSO_REQUEST = "RxClaim_SSO_REQUEST";
export const RxClaim_SSO_SUCCESS = "RxClaim_SSO_SUCCESS";
export const RxClaim_SSO_FAILURE = "RxClaim_SSO_FAILURE";

function requestRxClaimSSO() {
    return {
        type: RxClaim_SSO_REQUEST,
        isFetching: true,
    };
}

function receiveRxClaimSSO(ssoData) {
    return {
        type: RxClaim_SSO_SUCCESS,
        isFetching: false,
        ssoData,
    };
}

function RxClaimSSOFailure(ssoData) {
    return {
        type: RxClaim_SSO_FAILURE,
        isFetching: false,
        ssoData,
    };
}

export function onRxClaimSSO(req) {
    return (dispatch) => {
        dispatch(requestRxClaimSSO());
        const url = getRxClaimSSO();
        http
            .post(url, JSON.stringify(req))
            .then((response) => {
                const data = response.data;
                if (response.status === 200) {
                    dispatch(receiveRxClaimSSO(data));
                } else if (response.status === 500) {
                    dispatch(RxClaimSSOFailure(globalConstants.UNABLETOGETTEXT));
                } else {
                    dispatch(RxClaimSSOFailure(globalConstants.UNABLETOGETTEXT));
                    return Promise.reject(data);
                }
                return data;
            })
            .catch(function (error) {
                dispatch(RxClaimSSOFailure(error));
            });
    };
}
