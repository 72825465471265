import {
  GET_CLINICAL_PUBLICATION_REQUEST,
  GET_CLINICAL_PUBLICATION_UPDATE
} from "../actions/clinicalPublicationAEM";

export function clinicalPublicationAEM(
  state = {
    isFetching: false,
    data: []
  },
  action
) {
  switch (action.type) {
    case GET_CLINICAL_PUBLICATION_REQUEST:
      let data=[];
      if (state.data) {
         data = [...state.data, action.data]
      }
      return Object.assign({}, state, {
        data: data
      });
    case GET_CLINICAL_PUBLICATION_UPDATE:
      const aemArray =
        state.data &&
        state.data.filter(item => {
          if (item["key"] !== action.data.key) {
            return item;
          }
          return null;
        });
        let dataUpdate=[];
        if (aemArray) {
          dataUpdate= [...aemArray, action.data]
        }
      return {
        ...state,
        data: dataUpdate
      };
    default:
      return state;
  }
}

export default clinicalPublicationAEM;
