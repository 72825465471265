import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import Wrapper from "../../Shared/Wrapper/Wrapper";
import { AdvancedFiltersStyles } from "./AdvancedFiltersStyles";
import IndeterminateCheckbox from "../../Shared/IndeterminateCheckbox/IndeterminateCheckbox";
import RadioButtonsGroup from "../../Shared/RadioGroup/RadioButtonsGroup";
import ChipsArray from "../../Shared/ChipsArray/ChipsArray";
import { theme } from "../../../theme";


import useAdvancedFilter from "./useAdvancedFilters";

const AdvancedFilters = ({
    isPartD,
    drugFilterCMSData,
    // drugsData,
    tiers,
    submitClickCount,
    handleAdvanceFilter
}) => {

    const styles = AdvancedFiltersStyles();
    // const disabled = drugsData.length === 0;

    const {
        title1,
        cms_drug_type,
        cms_drug_types,
        others,
        tier: tierHeader,
        subtitle1,
        tierErrorMessage,
      } = drugFilterCMSData;

    
    const initialTier = [tiers[0]];
    const initialDrugType = cms_drug_types[0];
    const initialSelectedChips = [];
      
    // advanced filter init state
    // const initialState = {
    //     tier: [tiers[0]],
    //     drugType: cms_drug_types[0],
    //     selectedChips: [],
    //     submitClickCount: submitClickCount,
    // }

    const {
        advancedFilterData,
        handleTierUpdate,
        handleDrugTypeUpdate,
        handleChipsArrayUpdate,
        tierArrayToObject,
    } = useAdvancedFilter(
        isPartD,
        initialTier,
        initialDrugType,
        initialSelectedChips,
        submitClickCount,
        handleAdvanceFilter,
    )

    const { drugType, selectedChips } = advancedFilterData;
    
    let chipsToShow;
    if (isPartD) {
        chipsToShow = others.filter((chip) => chip !== 'Specialty');
    } else {
        chipsToShow = others;
    }

    return (
         <Wrapper wrapperStyle={styles.wrapper}>
            <Box style={{ padding: "25px" }}>
                <Box sx={{ my: "12px" }}>
                    <Typography variant="h3" className={styles.title}>
                        {title1}
                    </Typography>
                </Box>
                <Box sx={{ my: "12px" }}>
                    <Typography variant="subtitle2" className={styles.title}>
                        {subtitle1}
                    </Typography>
                </Box>
                <Grid container direction="row" spacing={2}>

                    <Grid item xs={12} md={3}> 
                        <IndeterminateCheckbox
                            formLabel={tierHeader}
                            childList={tiers.slice(1)}
                            parent={tiers[0]}
                            handlechange={handleTierUpdate}
                            color={theme.palette.primary.main}
                            childLeftPadding="16px"
                            mandatory="true"
                            errorMessage={tierErrorMessage}
                            initialObject={tierArrayToObject(
                                advancedFilterData.tier,
                                tiers.slice(1),
                                tiers[0]
                            )}
                        /> 
                    </Grid> 
                    <Grid item xs={12} md={3}>
                        <RadioButtonsGroup
                            formLabel={cms_drug_type}
                            radioOptions={cms_drug_types}
                            selectedValue={drugType}
                            setSelectedValue={handleDrugTypeUpdate}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} className={styles.othersContainer}>
                        <ChipsArray
                            spacing={3}
                            chipsData={chipsToShow}
                            size="medium"
                            selectedChips={selectedChips}
                            handleSelection={handleChipsArrayUpdate}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Wrapper>
    )
}

export default React.memo(AdvancedFilters);