export default {
  LANDING: "Landing",
  LOGIN: "Login",
  TERMSOFUSE: "TermsOfUse",
  PRIVACYPOLICY: "PrivacyPolicy",
  CONTACTUSMAIN: "ContactUsClientSupport",
  CONTACTUSMAIL: "ContactUsMailing",
  SIGNOUT: "SignOut",
  USERNAME: "username",
  PASSWORD: "password",
  TEXT: "text",
  BUTTON: "button",
  SUBMIT: "Submit",
  RESET: "Reset",
  CLIENT_ACCESS_CODE: "Client Access Code",
  SSN_MEMBER_ID: "SSN/Member ID",
  APPLICATION_QUICK_LAUNCH: "Application quick launch",
  RESOURCE_LIBRARY_AND_NEWS: "Resource library and news",
  NO_ACCESS: "No Access",
  SHOW: "Show",
  YES: "Yes",
  SHOW_DASHBOARD: "Show Dashboard",
  HIDE: "Hide",
  NAVIGATION_REQUEST_TAB: "u_account.u_client_requests_tab",
  NAVIGATION_APPLICATION_TAB: "u_account.u_client_applications_tab",
  NAVIGATION_CLINICAL_SERVICE_TAB: "u_account.u_cic_clinical_tab",
  NAVIGATION_HOME_DELIVERY_TAB: "u_account.u_cic_mail_tab",
  NAVIGATION_GOVERNMENT_PROGRAM_TAB: "u_account.u_cic_med_d_tab",
  NAVIGATION_SPECIALTY_TAB: "u_account.u_cic_specialty_tab",
  NAVIGATION_FILE_CABINET_TAB: "u_contact.u_access_to_cic_invoice_tab",
  NAVIGATION_MY_PRODUCT_TAB: "u_account.u_my_products_tab",
  NAVIGATION_PRODUCT_OFFERING_TAB: "u_account.u_product_offerings_tab",
  U_CONTACT_FIRST_NAME: "u_contact.first_name",
  U_CONTACT_LAST_NAME: "u_contact.last_name",
  U_ACCOUNT_SERVICENOW_ACCESS: "u_servicenow_access",
  U_ACCOUNT_NAME: "u_account.name",
  U_ACCOUNT: "u_account",
  TRUE: "true",
  FIELD_CODE: "field_code",
  PRODUCT_COMPLETE_NAME: "productCompleteName",
  U_SERVICE_PRODUCT: "u_service_product",
  EMAIL: "email",
  PRODUCT_TYPE_MP: "myproduct",
  PRODUCT_TYPE_QL: "quicklaunch",
  USER: "user",
  TYPE: "type",
  PARTICIPANT: "PARTICIPANT",
  Promotional: "Promotional",
  Participant: "Participant",
  EOBFLAG: "u_eob_access",
  TBFLAG: "u_tb_access",
  yes: "yes",
  SMALLDATEFORMAT: "mm/dd/yyyy",
  CAPSDATEFORMATWITHHIPHEN: "MM-DD-YYYY",
  WSFORMATDATE: "YYYYMMDD",
  FORMATDATEWITHSLASH: "MM/DD/YYYY",
  SERVICEERRORMSG: "Unable to get the data. Please try again after sometime.",
  UNABLETOGETTEXT: "Unable to get the data",
  ANNOUNCEMENTDATE: "MMMM DD, YYYY",
  SESSIONTIME: 1000 * 60 * 25,
  COOKIETIME: 60 * 24,
  WARNINGTIME: 300,
  FILESIZEMSG:
    "The invoice file that you are trying to download exceeds the allowable limit, please contact your account team for assistance. We are currently working to address the situation.",
  FILENOTFOUNDMSG:
    "The invoice file that you are trying to download doesn't exists in our system, please contact your account team for assistance.",
  FILENOTFOUNDINDOC:
    "The file that you are trying to download doesn't exists in our system, please contact your account team for assistance.",
  EOB: "EOB",
  TB: "TB",
  EOB_OPS_ADMIN: "OCP_EOB_OpsAdmin_nonprod",
  EOB_SP_USR_ADMIN: "OCP_EOB_SuperUser_nonprod",
  TB_SP_USR_ADMIN: "OCP_TB_SuperUser_nonprod"
};
