import React, { Component } from "react";
import { connect } from "react-redux";
import ClientRequestsContentContainer from "./ClientRequestsContentContainer";
import ClientRequestsErrorContentContainer from "./ClientRequestsErrorContentContainer";
import globalConstants from "../constants/global-constants";

class RequestContainer extends Component {
  state = {
    selectedAccount: {},
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getVisibilty(newProps);
  }

  componentDidMount() {
    document.title = "Client Portal - Requests";
    this.getVisibilty(this.props);
  }

  getVisibilty(props) {
    const { userAccount } = props;
    if (userAccount && userAccount.selectedAccount) {
      this.setState({ selectedAccount: userAccount.selectedAccount });
    }
  }

  render() {
    const { selectedAccount } = this.state;
    const { userAccount } = this.props;
    // const fetchDatafromContactId =
    //   userAccount && userAccount.fetchDatafromContactId;
    if (
      selectedAccount &&
      selectedAccount[globalConstants.U_ACCOUNT_SERVICENOW_ACCESS]
    ) {
      const data = selectedAccount[globalConstants.U_ACCOUNT_SERVICENOW_ACCESS]
        .toLowerCase()
        .includes(globalConstants.yes);
      if (data) {
        return (
          <ClientRequestsContentContainer selectedAccount={selectedAccount} />
        );
      } else {
        return <ClientRequestsErrorContentContainer />;
      }
    }

    return null;
  }
}

const mapStateToProps = ({ userAccount, user }) => {
  return { userAccount, user };
};

export default connect(mapStateToProps)(RequestContainer);
