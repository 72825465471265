import { fetchBillingEntity } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const GETBILLING_ENTITY_REQUEST = "GETBILLING_ENTITY_REQUEST";
export const GETBILLING_ENTITY_SUCCESS = "GETBILLING_ENTITY_SUCCESS";
export const GETBILLING_ENTITY_FAILURE = "GETBILLING_ENTITY_FAILURE";

export function requestBillingEntity() {
  return {
    type: GETBILLING_ENTITY_REQUEST,
    isFetched: false,
  };
}

export function receiveBillingEntity(data) {
  return {
    type: GETBILLING_ENTITY_SUCCESS,
    isFetched: true,
    data,
  };
}

export function billingEntityFailure(message) {
  return {
    type: GETBILLING_ENTITY_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getBillingEntityData(username, selectedClient, typeId) {
  const req = {};
  req["idType"] = typeId;
  req["clientId"] = selectedClient;
  req["userId"] = username;
  return (dispatch) => {
    dispatch(requestBillingEntity());
    const url = fetchBillingEntity(username, selectedClient);
    http
      .post(url, JSON.stringify(req))
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(billingEntityFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveBillingEntity(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(billingEntityFailure(error));
      });
  };
}
