import React, { Component } from "react";
import PublicationContentContainer from "../../../../containers/PublicationContentContainer";
import PublicationAEMContainer from "../../../../containers/PublicationAEMContainer";
import RxNewsContent from "./RxNewsContent";

class PublicationContent extends Component {
  state = {};
  getKey(type, subType) {
    if (subType) {
      return type + "_" + subType;
    }
    return type;
  }

  render() {
    const { type, subType } = this.props;
    const key = this.getKey(type, subType);
    if (type === "RxNews" && !subType) {
      return <RxNewsContent />;
    }
    return (
      <React.Fragment>
        <PublicationContentContainer
          type={type}
          subType={subType}
          publicationkey={key}
        />
        <p dir="ltr" />
        <p dir="ltr" />
        <PublicationAEMContainer
          type={type}
          subType={subType}
          publicationkey={key}
        />
      </React.Fragment>
    );
  }
}

export default PublicationContent;
