import {
  fetchAccountProductQuickLauch,
  fetchAccountProductInternalUser,
} from "../constants/microservice-helpers";
import userTypeEnum from "../constants/UserType";
import http from "../constants/token-interceptors";

export const ACCOUNT_PRODUCT_QUICKLAUNCH_SUCCESS =
  "ACCOUNT_PRODUCT_QUICKLAUNCH_SUCCESS";

export function requestAccountProductQuickLaunch(accountId) {
  const productAndquickLaunchResponse = {};
  productAndquickLaunchResponse["accountId"] = accountId;
  productAndquickLaunchResponse["isFetching"] = true;
  productAndquickLaunchResponse["isError"] = false;
  return {
    type: ACCOUNT_PRODUCT_QUICKLAUNCH_SUCCESS,
    accountId: accountId,
    data: productAndquickLaunchResponse,
  };
}

export function receiveAccountProductQuickLaunch(data, accountId) {
  const productAndquickLaunchResponse = {};
  productAndquickLaunchResponse["accountId"] = accountId;
  productAndquickLaunchResponse["productAndquickLaunch"] = data;
  productAndquickLaunchResponse["isFetching"] = false;
  productAndquickLaunchResponse["isError"] = false;

  return {
    type: ACCOUNT_PRODUCT_QUICKLAUNCH_SUCCESS,
    data: productAndquickLaunchResponse,
  };
}

export function accountProductquickLaunchFailure(message, accountId) {
  const productAndquickLaunchResponse = {};
  productAndquickLaunchResponse["accountId"] = accountId;
  productAndquickLaunchResponse["isFetching"] = false;
  productAndquickLaunchResponse["productAndquickLaunch"] = [];
  productAndquickLaunchResponse["isError"] = true;

  return {
    type: ACCOUNT_PRODUCT_QUICKLAUNCH_SUCCESS,
    accountId: accountId,
    data: productAndquickLaunchResponse,
    message,
  };
}

export function getAccountProductQuickLauch(accountname, accountId) {
  if (accountname && accountId) {
    const req = {};
    req["accountId"] = accountId;
    req["accountName"] = accountname;
    return (dispatch) => {
      dispatch(requestAccountProductQuickLaunch(accountId));
      const url = fetchAccountProductQuickLauch();
      http
        .post(url, JSON.stringify(req))
        .then((response) => {
          const data = response.data;
          if (response.status === 200) {
            dispatch(receiveAccountProductQuickLaunch(data, accountId));
            return data;
          } else {
            dispatch(accountProductquickLaunchFailure(data.message, accountId));
            return Promise.reject(data);
          }
        })
        .catch(function (error) {
          dispatch(accountProductquickLaunchFailure(error, accountId));
        });
    };
  }
  return null;
}

export function getAccountProductForInternalUser() {
  return (dispatch) => {
    dispatch(
      requestAccountProductQuickLaunch(userTypeEnum.INTERNAL_DEFAULT_TYPE)
    );
    const url = fetchAccountProductInternalUser();
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(
            receiveAccountProductQuickLaunch(
              data,
              userTypeEnum.INTERNAL_DEFAULT_TYPE
            )
          );
          return data;
        } else {
          dispatch(
            accountProductquickLaunchFailure(
              data.message,
              userTypeEnum.INTERNAL_DEFAULT_TYPE
            )
          );
          return Promise.reject(data);
        }
      })
      .catch(function (error) {
        dispatch(
          accountProductquickLaunchFailure(
            error,
            userTypeEnum.INTERNAL_DEFAULT_TYPE
          )
        );
      });
  };
}
