import React, { Component } from "react";
import { connect } from "react-redux";
import FormularyLookup from "../components/FormularyLookup/FormularyLookup";

class FormularyLookupContainer extends Component {
  state = { title: "", contentlist: "" };

  componentDidMount() {
    this.getFormularyLookupContent(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getFormularyLookupContent(newProps);
  }

  getFormularyLookupContent(newProps) {
    const { landingContent } = newProps;
    if (landingContent) {
      const data = landingContent.landingContent;
      if (data && data.length > 0) {
        this.setState({
          title: data[0].field_formulary_lookup_tool,
          contentlist: data[0].field_formulary_drug_search,
        });
      }
    }
  }

  render() {
    const { title, contentlist } = this.state;
    return  title != "" && contentlist != "" ? <FormularyLookup title={title} contentlist={contentlist} />: <></>;
  }
}

const mapStateToProps = ({ landingContent }) => {
  return { landingContent };
};

export default connect(mapStateToProps)(FormularyLookupContainer);
