import {
  CP_METADATA_REQUEST,
  CP_METADATA_SUCCESS,
  CP_METADATA_FAILURE
} from "../actions/clinicalPublicationMetaData";

export function clinicalPublicationMetaData(
  state = {
    isFetching: false,
    data: []
  },
  action
) {
  switch (action.type) {
    case CP_METADATA_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case CP_METADATA_SUCCESS:
      let data=[];
      if (state.data) {
         data = [...state.data, action.data]
      }
       return Object.assign({}, state, {
        isFetching: false,
        data: data
      });
    case CP_METADATA_FAILURE:
      let dataFl=[];
      if (state.data) {
        dataFl = [...state.data, action.data]
      }
      return Object.assign({}, state, {
        isFetching: false,
        isClinicalUpdatesError: true,
        error: action.message,
        data: dataFl
      });
    default:
      return state;
  }
}

export default clinicalPublicationMetaData;
