import {
  CLIENTREQUESTS_CONTENT_REQUEST,
  CLIENTREQUESTS_CONTENT_SUCCESS,
  CLIENTREQUESTS_CONTENT_FAILURE,
  CLIENTREQUESTS_LAUNCH_URL,
  CLIENTREQUESTS_EXTERNAL_SSO_DATA_REQUEST,
  CLIENTREQUESTS_EXTERNAL_SSO_DATA_SUCCESS,
  CLIENTREQUESTS_EXTERNAL_SSO_DATA_FAILURE,
} from "../actions/clientRequestContent";

export function clientRequestContent(
  state = {
    isFetching: false,
    clientRequestContent: "",
    launchUrl: "",
    externalSSOData: "",
  },
  action
) {
  switch (action.type) {
    case CLIENTREQUESTS_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        clientRequestContent: "",
      });
    case CLIENTREQUESTS_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        clientRequestContent: action.data,
      });
    case CLIENTREQUESTS_LAUNCH_URL:
      return {
        isFetching: false,
        launchUrl: action.launchUrl,
      };
    case CLIENTREQUESTS_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    case CLIENTREQUESTS_EXTERNAL_SSO_DATA_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        externalSSOData: "",
      });
    case CLIENTREQUESTS_EXTERNAL_SSO_DATA_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        externalSSOData: action.ssoData,
      });
    case CLIENTREQUESTS_EXTERNAL_SSO_DATA_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        externalSSOData: action.ssoData,
        isError: true,
      });
    default:
      return state;
  }
}

export default clientRequestContent;
