export default {
  Login: {
    Username: {
      Required: "User name is required."
    },
    Password: {
      Required: "Password is required."
    },
    CombinationCredentialErrorMessage: `The user name and password combination entered does not match
                  our records.<br />Please try again or contact your OptumRx client management
                  account team for assistance.`
  },
  ContactForm: {
    FirstName: {
      Required: "First name is required"
    },
    LastName: {
      Required: "Last name is required"
    },
    EmailAddress: {
      Required: "Email address is required",
      INVALID: "Email address is not valid"
    }
  },
  MemberIDForm: {
    ClientAccessCode: {
      Required: "Field is required."
    },
    MemberId: {
      Required: "Field is required."
    }
  }
};
