export const REQUEST_AEM_DATA = "REQUEST_AEM_DATA";
export const RECEIVED_AEM_DATA = "RECEIVED_AEM_DATA";



function requestAEM(aem) {
    return {
      type: REQUEST_AEM_DATA,
      loading: true,
      payload:aem
    };
  }
  
  function receivedAEM(aem) {
    return {
      type: RECEIVED_AEM_DATA,
      loading: false,
      payload: aem,
    };
  }




  export function onRequestAEM() {
    return dispatch => {
      // TODO: Fetching the AEM data.
      dispatch(requestAEM());
    };
  }

  export function onReceivedAEM(accountsData) {
    return dispatch => {
      // We dispatch request accounts when the accounts data is received
      dispatch(receivedAEM(accountsData));
    
    };
  }
  