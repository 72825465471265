import globalConstants from "../constants/global-constants";
import { fetchPALITEUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
export const PALITE_REQUEST = "PALITE_REQUEST";
export const PALITE_SUCCESS = "PALITE_SUCCESS";
export const PALITE_FAILURE = "PALITE_FAILURE";

function requestPALiteData() {
  return {
    type: PALITE_REQUEST,
    isFetching: true,
    isError: false,
  };
}

function pALiteDataSuccess(data) {
  return {
    type: PALITE_SUCCESS,
    isFetching: false,
    isError: false,
    data,
  };
}

function pALiteDataFailure() {
  return {
    type: PALITE_FAILURE,
    isFetching: false,
    isError: true,
    data: globalConstants.UNABLETOGETTEXT,
  };
}

export function fetchPALiteData(req) {
  return (dispatch) => {
    dispatch(requestPALiteData());
    const url = fetchPALITEUrl();
    http
      .post(url, JSON.stringify(req))
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(pALiteDataSuccess(data));
          return data;
        } else {
          dispatch(pALiteDataFailure());
          return Promise.reject(data);
        }
      })
      .catch(function (error) {
        dispatch(pALiteDataFailure());
      });
  };
}
