import React, { Component } from "react";
import { connect } from "react-redux";
import InvoiceFilterContainer from "./InvoiceFilterContainer";
import InvoiceTableContainer from "./InvoiceTableContainer";
import InvoiceDetailContainer from "./InvoiceDetailContainer";
import { getBillingEntityData } from "../actions/getBillingEntity";
import { getInvoiceDocumentData } from "../actions/getInvoiceDocuments";
import moment from "moment";
import globalConstants from "../constants/global-constants";
// import { isInternal, isExternal } from "../constants/service";

let pattern = /^[a-zA-Z!@#$%^&*()_+=\[\]{};':"\\|,<>?]*$/;
let numPattern = /^[0-9\/\.\-]*$/;

const currentDate = moment();
const restrictDate = moment(new Date()).subtract(366, "days");
class InvoiceDataContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: "",
      toDate: "",
      invoicetext: "",
      selectedValue: "",
      isDetailVisible: false,
      isErrorVisible: false,
      isInvoiceNumErrorVisible: false,
      isDateErrorVisible: false,
      invalidFromDate: false,
      invalidToDate: false,
      isFromDateValid: false,
      fromDateMax: false,
      toDateMin: false,
      isToDateValid: false,
      isInvalidDateErrorVisible: false,
      billingInfo: [],
      invoiceInfo: [],
      isInvoiceLoadComplete: false,
      sideFilterValue: "",
    };
    this.handleChangefromData = this.handleChangefromData.bind(this);
    this.handleChangetoData = this.handleChangetoData.bind(this);
    this.handleFromDateRaw = this.handleFromDateRaw.bind(this);
    this.handleToDateRaw = this.handleToDateRaw.bind(this);
    this.handleToDateBlur = this.handleToDateBlur.bind(this);
    this.handleFromDateBlur = this.handleFromDateBlur.bind(this);
    this.handleMonthSubmit = this.handleMonthSubmit.bind(this);
  }

  componentDidMount() {
    const { dropdownData } = this.props;
    if (dropdownData && dropdownData.length > 0) {
      this.setState({ selectedValue: dropdownData[0].id });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { dropdownData } = this.props;
    this.configureBillingEnity(newProps);
    this.configureInvoiceDocumentData(newProps);
    const { isDetailVisibleProp } = newProps;
    if (isDetailVisibleProp) {
      this.setState({ isDetailVisible: true });
    }
    // else {
    //   this.setState({
    //     isDetailVisible: false,
    //     fromDate: "",
    //     toDate: "",
    //     invoicetext: "",
    //     selectedValue: dropdownData[0].id
    //   });
    // }
  }

  configureBillingEnity(props) {
    const { getBillingEntity } = props;
    if (getBillingEntity && !getBillingEntity.isFetching) {
      this.setState({
        billingInfo: getBillingEntity.billingEntity,
      });
    }
  }

  configureInvoiceDocumentData(props) {
    const { getInvoiceDocuments } = props;
    if (getInvoiceDocuments && !getInvoiceDocuments.isFetching) {
      this.setState({
        invoiceInfo: getInvoiceDocuments.invoicesData,
        isInvoiceLoadComplete: true,
      });
    }
  }

  handleChangefromData(date) {
    this.setState({
      fromDate: date,
      isDateErrorVisible: false,
      isInvalidDateErrorVisible: false,
      isFromDateValid: false,
      fromDateMax: false,
      invalidFromDate: false
    });
    if (date && moment(date).isBefore(moment(restrictDate), 'day')) {
      this.setState({ isFromDateValid: true });
    }
  }

  handleChangetoData(date) {
    this.setState({
      toDate: date,
      isDateErrorVisible: false,
      isInvalidDateErrorVisible: false,
      isToDateValid: false,
      invalidToDate: false,
      toDateMin: false
    });
  }
  handleToDateBlur = (e) => {
    const date = e.target.value;
    const restrictDt = moment(restrictDate._d).format(
      globalConstants.FORMATDATEWITHSLASH
    );
    const currentDt = moment(currentDate._d).format(
      globalConstants.FORMATDATEWITHSLASH
    );
    this.setState({
      invalidToDate: false
    });

    if (date && (moment(currentDt).isSameOrBefore(date) || isNaN(moment(date).month()) || isNaN(moment(date).date()))) {
      this.setState({
        toDate: currentDate._d,
        isToDateValid: false
      });
    }
    if (!this.state.fromDate && moment(date).isSameOrBefore(restrictDt)) {
      this.setState({
        toDate: restrictDate._d,
        toDateMin: false
      });
    }
    else if (moment(this.state.fromDate).isAfter(date) && this.state.toDateMin) {
      this.setState({
        toDate: currentDate._d,
        toDateMin: false
      });
    }
  }

  handleFromDateBlur = (e) => {
    const date = e.target.value;
    const restrictDt = moment(restrictDate._d).format(
      globalConstants.FORMATDATEWITHSLASH
    );
    this.setState({
      invalidFromDate: false
    });

    if (date && moment(restrictDt).isAfter(date)) {
      this.setState({
        fromDate: restrictDate._d,
        isFromDateValid: false,
      });
    }
    if (date && (moment(date).isAfter(currentDate) || isNaN(moment(date).month()) || isNaN(moment(date).date()))) {
      this.setState({
        fromDate: currentDate._d,
        fromDateMax: false
      });
    }
  }

  handleToDateRaw = (e) => {
    const date = e.target.value;
    const restrictDt = moment(new Date()).subtract(366, "days").format("MM/DD/YYYY");
    const currentDt = currentDate.format("MM/DD/YYYY");
    let solidus = (date.match(/\//ig) || []).length;
    let dot = (date.match(/\./ig) || []).length;
    let hyphen = (date.match(/\-/ig) || []).length;
    let seperator = solidus === 2 ? "/" : (dot === 2 ? "." : hyphen === 2 ? "-" : "");
    let year = date.substring(date.lastIndexOf(seperator) + 1);
    this.setState({
      isToDateValid: false,
      toDateMin: false,
      invalidToDate: false
    });
    if (!date.match(numPattern)) {
      this.setState({
        invalidToDate: true
      });
    }
    if (date.match(numPattern)) {
      if ((solidus === 2 || dot === 2 || hyphen === 2) && year.length === 4) {
        if (moment(currentDt).isBefore(date) || isNaN(moment(date).month()) || isNaN(moment(date).date())) {
          this.setState({
            isToDateValid: true,
            toDateMin: false
          });
        }
        if (!this.state.fromDate && moment(date).isBefore(restrictDt))
          this.setState({
            isToDateValid: false,
            toDateMin: true
          });
        else if (moment(date).isBefore(this.state.fromDate))
          this.setState({
            isToDateValid: false,
            toDateMin: true
          });
      } else if ((solidus === 2 || dot === 2 || hyphen === 2) && year.length > 4) {
        this.setState({
          toDateMin: false,
          isToDateValid: true
        });
      } else if (solidus > 2 || dot > 2 || hyphen > 2) {
        this.setState({
          invalidToDate: true
        });
      }
    }

  }

  handleFromDateRaw = (e) => {
    const date = e.target.value;
    const restrictDt = moment(new Date()).subtract(366, "days").format("MM/DD/YYYY");
    let solidus = (date.match(/\//ig) || []).length;
    let dot = (date.match(/\./ig) || []).length;
    let hyphen = (date.match(/\-/ig) || []).length;
    let seperator = solidus === 2 ? "/" : (dot === 2 ? "." : hyphen === 2 ? "-" : "");
    let year = date.substring(date.lastIndexOf(seperator) + 1);


    this.setState({
      isFromDateValid: false,
      fromDateMax: false,
      invalidFromDate: false
    });
    if (!date.match(numPattern)) {
      this.setState({
        invalidFromDate: true
      });
    }
    if (date.match(numPattern)) {
      if ((solidus === 2 || dot === 2 || hyphen === 2) && year.length === 4) {
        if (moment(restrictDt).isAfter(date)) {
          this.setState({
            isFromDateValid: true,
            fromDateMax: false
          });
        }
        else if (moment(date).isAfter(currentDate) || isNaN(moment(date).month()) || isNaN(moment(date).date())) {
          this.setState({
            fromDateMax: true,
            isFromDateValid: false
          });
        }
      }
      else if ((solidus === 2 || dot === 2 || hyphen === 2) && year.length > 4) {
        this.setState({
          fromDateMax: true,
          isFromDateValid: false
        });
      }
      else if (solidus > 2 || dot > 2 || hyphen > 2) {
        this.setState({
          invalidFromDate: true
        });
      }
    }

  }
  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      isErrorVisible: false,
      fromDate: "",
      toDate: "",
      isInvoiceNumErrorVisible: false,
      isDateErrorVisible: false,
      isInvalidDateErrorVisible: false,
    });
  };

  handleClientChange = (name) => (event) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      this.setState({
        selectedValue,
        isErrorVisible: false,
        isInvoiceNumErrorVisible: false,
        isDateErrorVisible: false,
        isInvalidDateErrorVisible: false,
      });
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    const { toDate, fromDate } = this.state;
    this.configureDataOnSearch(toDate, fromDate);
  };

  handleMonthSubmit(toDate, fromDate) {
    let toDateObj = "";
    let fromDateObj = "";
    if (
      toDate &&
      fromDate &&
      toDate === globalConstants.SMALLDATEFORMAT &&
      fromDate === globalConstants.SMALLDATEFORMAT
    ) {
      toDate = "";
      fromDate = "";
      this.setState({
        toDate: "",
        fromDate: "",
      });
    } else {
      toDateObj = moment(toDate, globalConstants.CAPSDATEFORMATWITHHIPHEN);
      fromDateObj = moment(fromDate, globalConstants.CAPSDATEFORMATWITHHIPHEN);
      this.setState({
        toDate: toDateObj.toDate(),
        fromDate: fromDateObj.toDate(),
      });
    }
    this.configureDataOnSearch(toDate, fromDate);
  }

  configureDataOnSearch(toDate, fromDate) {
    this.setState({
      isInvoiceLoadComplete: false,
      isErrorVisible: false,
      isInvoiceNumErrorVisible: false,
      isDateErrorVisible: false,
      isInvalidDateErrorVisible: false,
      isFromDateValid: false,
      invalidFromDate: false,
      invalidToDate: false,
      isToDateValid: false,
    });
    if (this.validateFilterCombination(toDate, fromDate)) {
      this.setState({ isErrorVisible: false });
      this.setState({ isDetailVisible: true });
      this.props.setPageVisiblity(true);
      this.setState({ isDateErrorVisible: false });
      // Get name
      const { getClients } = this.props;
      if (getClients && getClients.clients && getClients.clients.length > 0) {
        const userName = getClients.userName;
        const typeId = getClients.typeId;
        const { selectedValue, invoicetext } = this.state;
        const sideFilterValue = this.passDataIntoSideFilter(
          selectedValue,
          invoicetext,
          toDate,
          fromDate
        );
        this.setState({ sideFilterValue });
        this.getBillingEntityInformation(userName, selectedValue, typeId);
        this.getInvoicesDocData(
          toDate,
          fromDate,
          invoicetext,
          selectedValue,
          userName,
          typeId
        );
      }
    } else {
      this.setState({ isInvoiceLoadComplete: true });
    }
  }

  passDataIntoSideFilter(selectedValue, invoicetext, toDate, fromDate) {
    const sideFilterValue = {};
    sideFilterValue["selectedValue"] = selectedValue;
    sideFilterValue["invoicetext"] = invoicetext;
    if (toDate) {
      const toDateDisplay = moment(toDate).format(
        globalConstants.FORMATDATEWITHSLASH
      );
      sideFilterValue["toDate"] = toDateDisplay;
    } else {
      sideFilterValue["toDate"] = "";
    }
    if (fromDate) {
      const fromDateDisplay = moment(fromDate).format(
        globalConstants.FORMATDATEWITHSLASH
      );
      sideFilterValue["fromDate"] = fromDateDisplay;
    } else {
      sideFilterValue["fromDate"] = "";
    }
    return sideFilterValue;
  }

  validateFilterCombination(toDate, fromDate) {
    const { invoicetext, selectedValue } = this.state;
    if (!invoicetext && selectedValue && selectedValue === "*") {
      this.setState({ isErrorVisible: true });
      return false;
    } else if (invoicetext && isNaN(invoicetext)) {
      this.setState({ invoicetext: "" });
      this.setState({ isInvoiceNumErrorVisible: true });
      return false;
    } else if (
      (toDate && this.validityCheckDate(moment(toDate).format("MM-DD-YYYY"))) ||
      (fromDate &&
        this.validityCheckDate(moment(fromDate).format("MM-DD-YYYY")))
    ) {
      this.setState({ isInvalidDateErrorVisible: true });
      return false;
    } else if (fromDate && toDate && !moment(fromDate).isSameOrBefore(toDate)) {
      this.setState({ isDateErrorVisible: true });
      return false;
    } else if (fromDate && moment(moment(fromDate).format("MM-DD-YYYY")).isBefore(moment(restrictDate._d).format("MM-DD-YYYY"))) {
      this.setState({ isFromDateValid: true });
      return false;
    } else if (toDate && moment(toDate).isAfter(new Date())) {
      this.setState({ isToDateValid: true });
      return false;
    } else {
      return true;
    }
  }


  validityCheckDate(toDateData) {
    return !moment(
      moment(toDateData, "MM-DD-YYYY", true).format("MM-DD-YYYY"),
      "MM-DD-YYYY",
      true
    ).isValid();
  }

  getInvoicesDocData(
    toDate,
    fromDate,
    invoicetext,
    selectedValue,
    userName,
    typeId
  ) {
    if (invoicetext && !toDate && !fromDate) {
      toDate = currentDate;
      fromDate = restrictDate;
    }
    if (toDate) {
      var wsFormatToDate = moment(toDate).format(globalConstants.WSFORMATDATE);
    }
    if (fromDate) {
      var wsFormatFromDate = moment(fromDate).format(
        globalConstants.WSFORMATDATE
      );
    }
    let request = this.makeRequestForInvoice(
      userName,
      selectedValue,
      invoicetext,
      toDate,
      fromDate,
      wsFormatFromDate,
      wsFormatToDate,
      typeId
    );
    const { getInvoiceDocuments } = this.props;
    if (!getInvoiceDocuments || !getInvoiceDocuments.isFetching) {
      this.props.dispatch(getInvoiceDocumentData(request));
    }
  }

  makeRequestForInvoice(
    userName,
    selectedValue,
    invoicetext,
    toDate,
    fromDate,
    wsFormatFromDate,
    wsFormatToDate,
    typeId
  ) {
    let request = {};
    request["authentication"] = null;
    request["authId"] = userName;
    if (selectedValue === "*") {
      request["clientID"] = "";
    } else {
      request["clientID"] = selectedValue;
    }
    request["billingEntityIDs"] = [];
    request["invoiceNum"] = invoicetext;
    if (toDate && fromDate) {
      request["fromDate"] = wsFormatFromDate;
      request["toDate"] = wsFormatToDate;
    } else {
      request["fromDate"] = "";
      request["toDate"] = "";
    }
    request["returnMaximumResultSetInd"] = true;
    request["maximumResultSet"] = 3000;
    request["additionalField"] = [];
    request["idType"] = typeId;
    return request;
  }

  getBillingEntityInformation(userName, selectedValue, typeId) {
    const { getBillingEntity } = this.props;
    if (!getBillingEntity || !getBillingEntity.isFetching) {
      this.props.dispatch(
        getBillingEntityData(userName, selectedValue, typeId)
      );
    }
  }

  render() {
    const { dropdownData, user } = this.props;
    const {
      toDate,
      fromDate,
      invoicetext,
      selectedValue,
      isDetailVisible,
      isErrorVisible,
      isDateErrorVisible,
      isFromDateValid,
      invalidFromDate,
      invalidToDate,
      isToDateValid,
      fromDateMax,
      toDateMin,
      billingInfo,
      invoiceInfo,
      isInvoiceLoadComplete,
      isInvoiceNumErrorVisible,
      isInvalidDateErrorVisible,
      sideFilterValue,
    } = this.state;

    const toDateDisplay = moment(toDate).format(
      globalConstants.FORMATDATEWITHSLASH
    );
    const fromDateDisplay = moment(fromDate).format(
      globalConstants.FORMATDATEWITHSLASH
    );

    if (
      billingInfo &&
      billingInfo.getBillingEntityItem &&
      billingInfo.getBillingEntityItem.length > 0
    ) {
      var entityItem = billingInfo.getBillingEntityItem;
    }
    return (
      <React.Fragment>
        <div className="row- form ">
          <div id="errorMsgDiv" style={{ minHeight: 45, marginTop: -10, marginBottom: -15 }} className="padL15 col-sm-12 error ">
            {isInvoiceNumErrorVisible && (
              <p className="marB5">Only numbers are allowed in Invoice field.</p>
            )}
            {(isDateErrorVisible || (fromDate && toDateMin)) && (
              <p>To Date should be greater than From Date.</p>
            )}
            {isInvalidDateErrorVisible && (
              <p>Please enter a valid 4 digit year.</p>
            )}
            {(invalidFromDate || invalidToDate) && (
              <p>Please enter a valid Date.</p>
            )}
            {(isFromDateValid || (toDateMin && !fromDate)) && (
              <p>The reports generated on or after {restrictDate.format("MM-DD-YYYY")} can only be searched.</p>
            )}
            {(isToDateValid || fromDateMax) && (
              <p>The reports generated today or earlier can only be searched.</p>
            )}
            {isErrorVisible && (
              <p>
                To view invoices and financial reports, please select a client
                from the list OR enter an invoice number to search all clients.
              </p>
            )}
          </div>
          <InvoiceFilterContainer
            dropdownData={dropdownData}
            handleChangefromData={this.handleChangefromData}
            handleChangetoData={this.handleChangetoData}
            handleToDateRaw={this.handleToDateRaw}
            handleFromDateRaw={this.handleFromDateRaw}
            handleToDateBlur={this.handleToDateBlur}
            handleFromDateBlur={this.handleFromDateBlur}
            onInputChange={this.onInputChange}
            handleClientChange={this.handleClientChange}
            invoicetext={invoicetext}
            fromDateMax={fromDateMax}
            toDateMin={toDateMin}
            selectedValue={selectedValue}
            fromDate={fromDate}
            toDate={toDate}
            onSearch={this.handleSearch}
            isErrorVisible={isErrorVisible}
            isDateErrorVisible={isDateErrorVisible}
            isFromDateValid={isFromDateValid}
            invalidFromDate={invalidFromDate}
            invalidToDate={invalidToDate}
            isToDateValid={isToDateValid}
            isInvoiceNumErrorVisible={isInvoiceNumErrorVisible}
            isInvalidDateErrorVisible={isInvalidDateErrorVisible}
            currentDate={currentDate}
            restrictDate={restrictDate}
          />
        </div>
        {!isDetailVisible && (
          <InvoiceTableContainer handleMonthSubmit={this.handleMonthSubmit} />
        )}
        {isDetailVisible && (
          <InvoiceDetailContainer
            invoicetext={invoicetext}
            selectedValue={selectedValue}
            fromDate={fromDateDisplay}
            toDate={toDateDisplay}
            entityItem={entityItem}
            invoiceInfo={invoiceInfo}
            isInvoiceLoadComplete={isInvoiceLoadComplete}
            dispatch={this.props.dispatch}
            user={user}
            sideFilterValue={sideFilterValue}
            currentDate={currentDate}
            restrictDate={restrictDate}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  getBillingEntity,
  getInvoiceDocuments,
  user,
  userProfile,
  auth,
  getClients,
}) => {
  return {
    getBillingEntity,
    getInvoiceDocuments,
    user,
    userProfile,
    auth,
    getClients,
  };
};

export default connect(mapStateToProps)(InvoiceDataContainer);
