import React, { Component } from "react";
import PropTypes from "prop-types";

class Pagination extends Component {
  render() {
    const { pageSize, itemCount, currentPage, onPageChange } = this.props;
    const pageCount = itemCount / pageSize;
    return (
      <aside className="pagination">
        {currentPage !== 1 && (
          <a
            className="prev"
            href="# "
            onClick={() => onPageChange(currentPage - 1)}
          >
            {"<"} <span>Prev</span>
          </a>
        )}
        <span className="current">
          {" "}
          {currentPage + " of " + Math.ceil(pageCount)}{" "}
        </span>
        {currentPage !== Math.ceil(pageCount) && (
          <a
            className="next"
            href="# "
            onClick={() => onPageChange(currentPage + 1)}
          >
            <span>Next </span>
            {">"}
          </a>
        )}
      </aside>
    );
  }
}

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  itemCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default Pagination;
