import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import Print from "../Shared/Print/Print";
import FollowUsContainer from "../../containers/FollowUsContainer";
import PreLoginFooterContainer from "../../containers/PreLoginFooterContainer";
import Content from "../Shared/Content/Content";
import "./TermsOfUse.scss";

class TermsOfUse extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  overrideLinkHandler = (e) => {
    const { auth } = this.props;
    if (
      (e.target.tagName === "A" &&
        e.target.getAttribute("class") === "internalLink1") ||
      (e.target.tagName === "A" &&
        e.target.getAttribute("class") === "internalLink")
    ) {
      e.preventDefault();
      if (auth && auth.isAuthenticated) {
        this.props.history.push(e.target.getAttribute("href"));
      } else {
        this.props.history.push("/privacyPolicy");
      }
    }
  };

  componentDidMount() {
    this.addListener();
  }

  addListener() {
    ReactDOM.findDOMNode(this).addEventListener(
      "click",
      this.overrideLinkHandler
    );
  }

  componentWillUnmount() {
    this.addListener();
  }

  render() {
    const { contentData, whiteLabelVal } = this.props;
    const { isAuthenticated } = this.props.auth;
    return (
      <main className={isAuthenticated ? "rPad" : "divider rPad"}>
        <div className="container">
          <div className="row">
            <h1>
              {contentData && contentData.title && (
                <Content html={contentData.title} />
              )}
            </h1>
            <Print onClick={() => window.print()} />
            {contentData && contentData.body && (
              <article className="marT10 terms-article-body">
                <Content html={contentData.body} />
              </article>
            )}
          </div>
          {!isAuthenticated && !whiteLabelVal && <FollowUsContainer />}
          {!isAuthenticated && !whiteLabelVal && <PreLoginFooterContainer />}
        </div>
      </main>
    );
  }
}

export default withRouter(TermsOfUse);
