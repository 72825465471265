import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
export const DRUG_SEARCH_CONTENT_REQUEST = "DRUG_SEARCH_CONTENT_REQUEST";
export const DRUG_SEARCH_CONTENT_SUCCESS = "DRUG_SEARCH_CONTENT_SUCCESS";
export const DRUG_SEARCH_CONTENT_FAILURE = "DRUG_SEARCH_CONTENT_FAILURE";

export function requestDrugSearchContent() {
  return {
    type: DRUG_SEARCH_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function receiveDrugSearchContent(data) {
  return {
    type: DRUG_SEARCH_CONTENT_SUCCESS,
    isFetched: true,
    data,
  };
}

export function drugSearchContentFailure(message) {
  return {
    type: DRUG_SEARCH_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getDrugsSearchFieldsData() {
  return (dispatch) => {
    dispatch(requestDrugSearchContent());
    const url = fetchGeneralContentUrl(
      "/cms/getTextObjectFromByCode/drugsContent"
    );
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(drugSearchContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveDrugSearchContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(drugSearchContentFailure(error));
      });
  };
}
