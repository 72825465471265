import React from "react";
import { connect } from "react-redux";
import { getProductOfferingData } from "../actions/productOffering";
import { Scrollbars } from "react-custom-scrollbars";
import ProductOffering from "../components/Applications/ProductOffering/ProductOffering";
import Content from "../components/Shared/Content/Content";
import { isInternal } from "../constants/service";
import InternalProductContainer from "./InternalProductContainer";
import globalConstants from "../constants/global-constants";

class ProductOfferingContainer extends React.Component {
  state = {
    productOffering: {},
    productOfferingLoading: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getProductOffering(nextProps);
  }

  componentDidMount() {
    this.getProductOffering(this.props);
  }

  getProductOffering(nextProps) {
    const { selectedAccount, user } = nextProps;
    if (!isInternal(user)) {
      if (selectedAccount) {
        if (selectedAccount && selectedAccount.u_account) {
          const { productOffering } = nextProps;
          if (productOffering) {
            const productOfferingInfo = this.configProductOffering(
              selectedAccount.u_account,
              productOffering.productOffering
            );
            if (productOfferingInfo) {
              if (productOfferingInfo.productOffering) {
                this.setState({
                  productOffering: productOfferingInfo,
                  productOfferingLoading: true,
                });
              }
            } else {
              this.props.dispatch(
                getProductOfferingData(
                  selectedAccount[globalConstants.U_ACCOUNT_NAME],
                  selectedAccount.u_account
                )
              );
            }
          }
        }
      }
    }
  }

  configProductOffering(accountID, accountTeams) {
    return (
      accountTeams &&
      accountTeams.length > 0 &&
      accountTeams.find((item) => {
        return item && item.accountId === accountID;
      })
    );
  }

  render() {
    const { productOffering, productOfferingLoading } = this.state;
    const { noDataError, user } = this.props;
    if (isInternal(user)) {
      return <InternalProductContainer type="ProductOffering" />;
    } else {
      if (!productOfferingLoading) {
        return (
          <React.Fragment>
            <div align="center">Loading data...</div>
          </React.Fragment>
        );
      } else {
        if (
          productOffering &&
          productOffering.productOffering &&
          productOffering.productOffering.length > 0
        ) {
          var productOfferingList = productOffering.productOffering;
          return (
            <React.Fragment>
              <Scrollbars style={{ width: 1410, height: 500, marginTop: 16 }}>
                {productOfferingList && (
                  <ProductOffering productOfferingList={productOfferingList} />
                )}
              </Scrollbars>
            </React.Fragment>
          );
        }
        return (
          <div className="tab">
            <div className="container30">
              <div id="productOffering" className="tab-content">
                <div id="prodoffdivmsg" className="noProduct">
                  <Content html={noDataError} />
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

const mapStateToProps = ({ productOffering, userAccount, user, auth }) => {
  return {
    productOffering,
    selectedAccount: userAccount.selectedAccount,
    user,
    auth,
  };
};

export default connect(mapStateToProps)(ProductOfferingContainer);
