import { fetchAnnouncements } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const GET_ANNOUNCEMENT_REQUEST = "GET_ANNOUNCEMENT_REQUEST";
export const GET_ANNOUNCEMENT_SUCCESS = "GET_ANNOUNCEMENT_SUCCESS";
export const GET_ANNOUNCEMENT_FAILURE = "GET_ANNOUNCEMENT_FAILURE";

export function requestAnnouncementData() {
  return {
    type: GET_ANNOUNCEMENT_REQUEST,
    isFetched: false,
  };
}

export function receiveAnnouncementData(data) {
  return {
    type: GET_ANNOUNCEMENT_SUCCESS,
    isFetched: true,
    data,
  };
}

export function AnnouncementDataFailure(message) {
  return {
    type: GET_ANNOUNCEMENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getAnnouncementData() {
  return (dispatch) => {
    dispatch(requestAnnouncementData());
    const url = fetchAnnouncements();
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(AnnouncementDataFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveAnnouncementData(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(AnnouncementDataFailure(error));
      });
  };
}
