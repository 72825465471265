import React from "react";
import { connect } from "react-redux";
import globalConstants from "../../constants/global-constants";
import { fetchPALiteData } from "../../actions/paLite";
import CircularLoader from "../Loader/Loader";
import { getSSOData } from "../../actions/ssoRequest";

class SingleSignOn extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      sso: "",
      isLoading: true,
      error: "",
    };
  }

  componentDidMount() {
    this.setData(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setData(newProps);
  }

  makeRequest(opData, profileData) {
    var {
      firstName,
      lastName,
      email,
      userID,
      clientName,
      carrierID,
      role,
      userLocation,
    } = this.findPALiteRequestData(profileData, opData);
    if (
      firstName &&
      lastName &&
      email &&
      userID &&
      clientName &&
      carrierID &&
      role &&
      userLocation
    ) {
      const req = {};
      req["userID"] = userID;
      req["firstName"] = firstName;
      req["lastName"] = lastName;
      req["email"] = email;
      req["clientName"] = clientName;
      req["carrierID"] = carrierID;
      req["role"] = role;
      req["userLocation"] = userLocation;
      this.setState({ error: "" });
      return req;
    } else {
      const error =
        "Access denied. Please contact your OptumRx Client management account team for assistance.";
      this.setState({ error, isLoading: false });
      return null;
    }
  }

  findPALiteRequestData(profileData, opData) {
    var firstName = profileData.given_name;
    var lastName = profileData.family_name;
    var email = opData.workEmail;
    var userID = "";
    var clientName = "";
    var carrierID = "";
    var role = "";
    var userLocation = "";
    if (opData && opData.userIdentifiers && opData.userIdentifiers.length > 0) {
      userID = this.findDataByID(opData.userIdentifiers, "Optum ID");
      clientName = this.findDataByID(opData.userIdentifiers, "u_client");
      carrierID = this.findDataByID(opData.userIdentifiers, "u_carrier");
      role = this.findDataByID(opData.userIdentifiers, "u_pa_roles");
      userLocation = this.findDataByID(
        opData.userIdentifiers,
        "u_pa_user_location"
      );
    }
    return {
      firstName,
      lastName,
      email,
      userID,
      clientName,
      carrierID,
      role,
      userLocation,
    };
  }

  findDataByID(identifiers, id) {
    const ids = identifiers.find((i) => i && i["name"] === id);
    if (ids) {
      return ids.value;
    } else {
      return null;
    }
  }

  setData(props) {
    const {
      paLite,
      requestSSOData,
      userProfile,
      match: { params },
    } = props;
    if (params && params.type === "servicenow") {
      this.setSSORequests(requestSSOData, props);
    } else if (params && params.type === "paportal") {
      this.setPAportalData(userProfile, paLite, props);
    }
  }

  setPAportalData(userProfile, paLite, props) {
    if (userProfile && userProfile.opData) {
      const req = this.makeRequest(userProfile.opData, userProfile.profileData);
      if (!paLite.isFetching) {
        if (paLite.paLiteData) {
          this.setState({
            sso: paLite.paLiteData,
            isLoading: false,
          });
          this.hitEventListener(paLite.paLiteData, props);
        } else {
          if (req) {
            this.props.dispatch(fetchPALiteData(req));
          }
        }
      }
    }
  }

  setSSORequests(requestSSOData, props) {
    if (!requestSSOData.isFetching) {
      if (requestSSOData.sso) {
        this.setState({
          sso: requestSSOData.sso,
          isLoading: false,
        });
        this.hitEventListener(requestSSOData.sso, props);
      } else {
        this.props.dispatch(getSSOData());
      }
    }
  }

  hitEventListener(sso, props) {
    if (sso) {
      this.addListener(sso);
    }
  }

  addListener(sso) {
    setTimeout(() => {
      if (sso && sso !== globalConstants.UNABLETOGETTEXT) {
        this.containerRef &&
          this.containerRef.current &&
          this.containerRef.current.submit();
      }
    }, 100);
  }

  render() {
    const { sso, isLoading, error } = this.state;
    const {
      match: { params },
    } = this.props;
    if (this.props.isLoaderVisible) {
      return <CircularLoader color={"#e87722"} />;
    }
    if (isLoading) {
      return <CircularLoader color={"#e87722"} />;
    } else {
      if (error) {
        return (
          <React.Fragment>
            {params && params.type === "servicenow" ? (
              <h1>Unable to access Service now</h1>
            ) : (
                <h1>Unable to access PA Portal</h1>
              )}
            <div>
              <span class="error">
                {params && params.type === "servicenow" ? (
                  <p dir="ltr">
                    Unable to access Service now. Please try again after
                    sometime.
                  </p>
                ) : (
                    <p dir="ltr">
                      Unable to access PA Portal. Please try again after sometime.
                    </p>
                  )}
              </span>
            </div>
          </React.Fragment>
        );
      } else if (sso === globalConstants.UNABLETOGETTEXT) {
        return (
          <React.Fragment>
            <h1>Service error</h1>
            <div>
              <span class="error">
                <p dir="ltr">
                  There is some error with the services. Please try after
                  sometime.
                </p>
              </span>
            </div>
          </React.Fragment>
        );
      } else if (sso) {
        return (
          <>
            <CircularLoader color={"#e87722"} />
            <form
              method="post"
              ref={this.containerRef}
              action={sso.actionUrl || ""}
            >
              <input type="hidden" name="PARTNER" value={sso.partnerId || ""} />
              <input
                type="hidden"
                name="TARGET"
                value={sso.targetResource || ""}
              />
              <input
                type="hidden"
                name="opentoken"
                value={sso.openToken || ""}
              />
            </form>
          </>
        );
      } else {
        return <CircularLoader color={"#e87722"} />;
      }
    }
  }
}

const mapStateToProps = ({ paLite, userProfile, requestSSOData }) => {
  return {
    paLite,
    userProfile,
    requestSSOData,
    isLoaderVisible: paLite.paLiteData || requestSSOData.sso ? false : true,
  };
};

export default connect(mapStateToProps)(SingleSignOn);
