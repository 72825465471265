import {
  ACCOUNT_TEAM_IMAGES_REQUEST,
  ACCOUNT_TEAM_IMAGES_UPDATE,
} from "../actions/accountTeamImages";

export function accountTeamImages(
  state = {
    isFetching: false,
    data: [],
  },
  action
) {
  switch (action.type) {
    case ACCOUNT_TEAM_IMAGES_REQUEST:
      return Object.assign({}, state, {
        data: [...state.data, action.data],
      });
    case ACCOUNT_TEAM_IMAGES_UPDATE:
      const imagesArray = state.data.filter((item) => {
        if (item["email"] !== action.data.email) {
          return item;
        }
        return null;
      });
      return {
        ...state,
        data: [...imagesArray, action.data],
      };
    default:
      return state;
  }
}

export default accountTeamImages;
