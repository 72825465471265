import React, { Component } from "react";
import { connect } from "react-redux";
import Filter from "../components/FileCabinet/InvoicesTab/Filters/Filter";

class InvoiceFilterContainer extends Component {
  state = {};

  render() {
    const {
      dropdownData,
      handleChangefromData,
      handleChangetoData,
      handleFromDateRaw,
      handleToDateRaw,
      handleToDateBlur,
      handleFromDateBlur,
      onInputChange,
      fromDateMax,
      toDateMin,
      handleClientChange,
      invoicetext,
      selectedValue,
      fromDate,
      toDate,
      onSearch,
      isToDateValid,
      isErrorVisible,
      invalidFromDate,
      invalidToDate,
      isFromDateValid,
      isDateErrorVisible,
      isInvoiceNumErrorVisible,
      isInvalidDateErrorVisible,
      currentDate,
      restrictDate
    } = this.props;
    return (
      <Filter
        dropdownData={dropdownData}
        handleChangefromData={handleChangefromData}
        handleChangetoData={handleChangetoData}
        handleFromDateRaw={handleFromDateRaw}
        handleToDateRaw={handleToDateRaw}
        handleFromDateBlur={handleFromDateBlur}
        handleToDateBlur={handleToDateBlur}
        onInputChange={onInputChange}
        fromDateMax={fromDateMax}
        toDateMin={toDateMin}
        handleClientChange={handleClientChange}
        invoicetext={invoicetext}
        selectedValue={selectedValue}
        fromDate={fromDate}
        toDate={toDate}
        onSearch={onSearch}
        isErrorVisible={isErrorVisible}
        isFromDateValid={isFromDateValid}
        invalidFromDate={invalidFromDate}
        invalidToDate={invalidToDate}
        isDateErrorVisible={isDateErrorVisible}
        isToDateValid={isToDateValid}
        isInvoiceNumErrorVisible={isInvoiceNumErrorVisible}
        isInvalidDateErrorVisible={isInvalidDateErrorVisible}
        currentDate={currentDate}
        restrictDate={restrictDate}
      />
    );
  }
}

const mapStateToProps = ({ getClients }) => {
  return { getClients };
};

export default connect(mapStateToProps)(InvoiceFilterContainer);
