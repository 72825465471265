import {
    CPLOGO_REQUEST,
    CPLOGO_SUCCESS,
    CPLOGO_FAILURE,
    SERVEYOU_REQUEST,
    SERVEYOU_SUCCESS,
    SERVEYOU_FAILURE,
    WELLDYNE_REQUEST,
    WELLDYNE_SUCCESS,
    WELLDYNE_FAILURE
} from "../actions/cpHeaderLogo";

export function cpHeaderLogo(
    state = {
        isCPLogoFetching: false,
        isServeYouFetching: false,
        isWellDyneFetching: false,
        cpLogoData: "",
        serveYouData: "",
        wellDyneData: "",
    },
    action
) {
    switch (action.type) {

        case CPLOGO_REQUEST:
            return Object.assign({}, state, {
                isCPLogoFetching: true,
                cpLogoData: "",
            });
        case CPLOGO_SUCCESS:
            return Object.assign({}, state, {
                isCPLogoFetching: false,
                cpLogoData: action.cpLogoData,
            });
        case CPLOGO_FAILURE:
            return Object.assign({}, state, {
                isCPLogoFetching: false,
                isCPLogoError: true,
                error: action.message,
            });

        case SERVEYOU_REQUEST:
            return Object.assign({}, state, {
                isServeYouFetching: true,
                serveYouData: "",
            });
        case SERVEYOU_SUCCESS:
            return Object.assign({}, state, {
                isServeYouFetching: false,
                serveYouData: action.serveYouData,
            });
        case SERVEYOU_FAILURE:
            return Object.assign({}, state, {
                isServeYouFetching: false,
                isServeYouError: true,
                error: action.message,
            });

        case WELLDYNE_REQUEST:
            return Object.assign({}, state, {
                isWellDyneFetching: true,
                wellDyneData: "",
            });
        case WELLDYNE_SUCCESS:
            return Object.assign({}, state, {
                isWellDyneFetching: false,
                wellDyneData: action.wellDyneData,
            });
        case WELLDYNE_FAILURE:
            return Object.assign({}, state, {
                isWellDyneFetching: false,
                isWellDyneError: true,
                error: action.message,
            });
        default:
            return state;
    }
}

export default cpHeaderLogo;
