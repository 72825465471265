import React, { Component } from "react";
import { connect } from "react-redux";
import BASTab from "../components/FileCabinet/BASTab";
import BASDetailTable from "../components/FileCabinet/BASDetailTable";
import { getBASDocumentData } from "../actions/getBASDocuments";
import { fetchBASDocuments } from "../constants/microservice-helpers";
import { PTEOB, PTEOBTB, PTTB } from "../constants/searchCriteria_EOB";
import {
  searchCriteriaEob,
  searchCriteriaTB
} from "../constants/searchCriteria_EOB";

import globalConstants from "../constants/global-constants";

class BASContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchResult: false,
      filterObject: null,
      basData: "",
      options: "",
      selectedAccount: "",
      PType: "",
      pageSize: 25,
      page: 0,
      request: null,
    };
    this.handleSearch = this.handleSearch.bind(this);
  }
  componentDidMount() {
    const { selectedAccount } = this.props;
    this.setOptionsList(selectedAccount);
  }
  UNSAFE_componentWillReceiveProps(nextprops) {
    const { selectedAccount } = nextprops;
    this.setOptionsList(selectedAccount);
  }

  componentDidUpdate() {
    // const { getBASDocuments } = this.props;
    const { getBASDocuments, selectedAccount } = this.props;
    if (
      this.props.getBASDocuments &&
      this.props.getBASDocuments.basData &&
      this.props.getBASDocuments.basData !== this.state.basData
    ) {
      this.setState({
        basData: getBASDocuments.basData,
      });
    }
    if (
      selectedAccount &&
      selectedAccount !== "" &&
      selectedAccount !== this.state.selectedAccount
    ) {
      this.setOptionsList(selectedAccount);
    }
  }
  hasGrpAccess(usrGrps, grp) {
    let res = "";
    usrGrps && usrGrps.find((item) => {
      if (item.toLowerCase().includes(grp.toLowerCase())) {
        res = grp;
      }
    });
    if (res) {
      return true;
    }
    return false;
  }
  setOptionsList(selectedAccount) {
    const { usrGrps } = this.props;
    this.setState({
      selectedAccount: selectedAccount,
      options: PTEOBTB
    })
    if ((selectedAccount && selectedAccount[globalConstants.EOBFLAG] === "true" &&
      selectedAccount[globalConstants.TBFLAG] === "true") ||
      ((this.hasGrpAccess(usrGrps, globalConstants.EOB_OPS_ADMIN) || (this.hasGrpAccess(usrGrps, globalConstants.EOB_SP_USR_ADMIN))) &&
        this.hasGrpAccess(usrGrps, globalConstants.TB_SP_USR_ADMIN))
    ) {
      this.setState({
        options: PTEOBTB,
        PType: globalConstants.EOB,
        searchCriteriaFieldsList: searchCriteriaEob
      })
    } else if ((selectedAccount &&
      ((selectedAccount[globalConstants.EOBFLAG] === "true") &&
        this.hasGrpAccess(usrGrps, globalConstants.TB_SP_USR_ADMIN)) ||
      ((selectedAccount[globalConstants.TBFLAG] === "true") &&
        (this.hasGrpAccess(usrGrps, globalConstants.EOB_OPS_ADMIN) || (this.hasGrpAccess(usrGrps, globalConstants.EOB_SP_USR_ADMIN)))
      ))) {
      console.log("testt");
      this.setState({
        options: PTEOBTB,
        PType: globalConstants.EOB,
        searchCriteriaFieldsList: searchCriteriaEob
      })
    } else if ((selectedAccount && selectedAccount[globalConstants.EOBFLAG] === "true") ||
      (this.hasGrpAccess(usrGrps, globalConstants.EOB_OPS_ADMIN) || (this.hasGrpAccess(usrGrps, globalConstants.EOB_SP_USR_ADMIN)))
    ) {
      this.setState({
        options: PTEOB,
        PType: globalConstants.EOB,
        searchCriteriaFieldsList: searchCriteriaEob
      })
    } else if ((selectedAccount && selectedAccount[globalConstants.TBFLAG] === "true") ||
      (this.hasGrpAccess(usrGrps, globalConstants.TB_SP_USR_ADMIN))
    ) {
      this.setState({
        options: PTTB,
        PType: globalConstants.TB,
        searchCriteriaFieldsList: searchCriteriaTB
      })
    }
  }

  handleSearch = (e, filterObject, request) => {
    e.preventDefault();
    this.setState({
      isSearchResult: true,
      filterObject,
      request,
      page: 0,
      pageSize: 25,
    });
    this.handleSearchDocs(e, request);
  };

  handleSearchDocs = (e, request) => {
    const { getBASDocuments } = this.props;
    if (!getBASDocuments.isError) {
      if (!getBASDocuments.isFetching) {
        if (
          getBASDocuments.basData &&
          getBASDocuments.basData !== this.state.basData
        ) {
          this.state.basData = getBASDocuments.basData;
          this.setState({
            basData: getBASDocuments.basData,
          });
        } else {
          this.props.dispatch(getBASDocumentData(request));
        }
      }
    }
  };

  handlePageSize = (pageSize) => {
    const { request } = this.state;
    this.setState({ pageSize });
    const newRequest = { ...request, limit: pageSize };
    this.setState({ newRequest });
    this.props.dispatch(getBASDocumentData(newRequest));
  };

  handlePageChange = (page) => {
    const { request } = this.state;
    this.setState({ page });
    const newRequest = {
      ...request,
      offSet: page * this.state.pageSize,
    };
    this.setState({ newRequest });
    this.props.dispatch(getBASDocumentData(newRequest));
  };

  handleDownloadZip = (e) => {
    e.preventDefault();
  };

  handleJobSummary = (e) => {
    e.preventDefault();
  };

  handleReprint = (e) => {
    e.preventDefault();
  };

  render() {
    const {
      isSearchResult,
      filterObject,
      basData,
      page,
      pageSize,
    } = this.state;
    return (
      <>
        <BASTab
          onSearch={this.handleSearch}
          filterObject={filterObject}
          productTypeList={this.state.options}
          PType={this.state.PType}
          searchCriteriaFieldsList={this.state.searchCriteriaFieldsList}
        />
        {isSearchResult &&
          filterObject.searchValue[0] &&
          filterObject.searchValue[0].SearchInputVal.length > 0 && (
            <BASDetailTable
              isLoading={this.props.getBASDocuments.isFetching}
              pageSize={pageSize}
              productTypeList={this.state.options}
              page={page}
              handlePageSize={this.handlePageSize}
              handlePageChange={this.handlePageChange}
              filterObject={filterObject}
              handleDownloadZip={this.handleDownloadZip}
              handleReprint={this.handleReprint}
              handleJobSummary={this.handleJobSummary}
              basData={basData}
            />
          )}
      </>
    );
  }
}

const mapsStateToProps = ({ getBASDocuments }) => {
  return { getBASDocuments };
};
export default connect(mapsStateToProps)(BASContainer);

// export default BASContainer;
