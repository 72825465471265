import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const GOVERNMENT_PROGRAM_CONTENT_SUCCESS =
  "GOVERNMENT_PROGRAM_CONTENT_SUCCESS";

export function requestGovernmentProgramContent(pageType) {
  const govtProgramResponse = {};
  govtProgramResponse["pageType"] = pageType;
  govtProgramResponse["isFetching"] = true;
  govtProgramResponse["isError"] = false;
  return {
    type: GOVERNMENT_PROGRAM_CONTENT_SUCCESS,
    data: govtProgramResponse,
  };
}

export function receiveGovernmentProgramContent(data, pageType) {
  const govtProgramResponse = {};
  govtProgramResponse["pageType"] = pageType;
  govtProgramResponse["value"] = data;
  govtProgramResponse["isFetching"] = false;
  govtProgramResponse["isError"] = false;

  return {
    type: GOVERNMENT_PROGRAM_CONTENT_SUCCESS,
    data: govtProgramResponse,
  };
}

export function governmentProgramContentFailure(message, pageType) {
  const govtProgramResponse = {};
  govtProgramResponse["pageType"] = pageType;
  govtProgramResponse["isFetching"] = false;
  govtProgramResponse["isError"] = true;
  return {
    type: GOVERNMENT_PROGRAM_CONTENT_SUCCESS,
    data: govtProgramResponse,
    message,
  };
}

export function getGovernmentProgramContentPromotional(pageType) {
  return (dispatch) => {
    dispatch(requestGovernmentProgramContent(pageType));
    const url = fetchGeneralContentUrl(
      "/cms/getNodeByCode/t2/GovernmentProgramsPro"
    );
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(receiveGovernmentProgramContent(data, pageType));
          return data;
        } else {
          dispatch(governmentProgramContentFailure(data.message, pageType));
          return Promise.reject(data);
        }
      })
      .catch(function (error) {
        dispatch(governmentProgramContentFailure(error, pageType));
      });
  };
}

export function getGovernmentProgramContentParticipant(pageType) {
  return (dispatch) => {
    dispatch(requestGovernmentProgramContent(pageType));
    const url = fetchGeneralContentUrl(
      "/cms/getNodeByCode/t2/GovernmentProgramsPart"
    );
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(receiveGovernmentProgramContent(data, pageType));
          return data;
        } else {
          dispatch(governmentProgramContentFailure(data.message, pageType));
          return Promise.reject(data);
        }
      })
      .catch(function (error) {
        dispatch(governmentProgramContentFailure(error, pageType));
      });
  };
}
