export const REQUEST_PRODUCT_DOCUMENT_DATA = "REQUEST_PRODUCT_DOCUMENT_DATA";
export const RECEIVED_PRODUCT_DOCUMENT_DATA = "RECEIVED_PRODUCT_DOCUMENT_DATA";

function requestProductDocumentData(Id) {
  return {
    type: REQUEST_PRODUCT_DOCUMENT_DATA,
    loading: true,
    payload: Id
  };
}

function receivedProductDocumentData() {
  return {
    type: RECEIVED_PRODUCT_DOCUMENT_DATA,
    loading: false
  };
}

export function onRequestProductDocumentData(Id) {
  return dispatch => {
    //TODO: Fetching the My Product data.
    dispatch(requestProductDocumentData(Id));
  };
}

export function onReceivedProductDocumentData() {
  return dispatch => {
    dispatch(receivedProductDocumentData());
  };
}
