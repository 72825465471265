import React from "react";
import PieChart from "./PieChart/PieChart";
import BarChart from "./BarChart/BarChart";
import Table from "./Table/Table";
import "./Dashboard.scss";

const Dashboard = ({ dashboardData }) => {
  if (!dashboardData) {
    return null;
  }
  return (
    <div className="dashboard-wrap">
      {dashboardData && dashboardData.length > 0 && (
        <PieChart dashboardData={dashboardData} />
      )}
      {dashboardData && dashboardData.length > 0 && (
        <BarChart dashboardData={dashboardData} />
      )}
      {dashboardData && dashboardData.length > 0 && (
        <Table dashboardData={dashboardData} />
      )}
    </div>
  );
};

export default Dashboard;
