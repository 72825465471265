import {
  SIGNOUT_CONTENT_REQUEST,
  SIGNOUT_CONTENT_SUCCESS,
  SIGNOUT_CONTENT_FAILURE
} from "../actions/signOutContent";

function signOutContent(
  state = {
    isFetching: false,
    signOutContent: ""
  },
  action
) {
  switch (action.type) {
    case SIGNOUT_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        signOutContent: ""
      });
    case SIGNOUT_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        signOutContent: action.data
      });
    case SIGNOUT_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message
      });
    default:
      return state;
  }
}

export default signOutContent;
