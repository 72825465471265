import React from "react";
import PropTypes from "prop-types";
import HyperLink from "../../Shared/HyperLink/HyperLink";

const ResourceAndNews = ({ resources, news, title }) => {
  return (
    <article>
      <h6>{title}</h6>
      <div className="content">
        <div className="list">
          <ul>
            {resources &&
              resources.length > 0 &&
              resources.slice(0, 3).map(resourceItem => (
                <li key={resourceItem.title}>
                  <HyperLink
                    link={resourceItem.link}
                    name={resourceItem.title}
                    isExternal="yes"
                  />
                </li>
              ))}
            {news &&
              news.length > 0 &&
              news.slice(0, 1).map(newsItem => (
                <li key={newsItem.title}>
                  <HyperLink
                    link={process.env.REACT_APP_AEM_URL + newsItem.link}
                    name={newsItem.title}
                    isExternal="yes"
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </article>
  );
};

ResourceAndNews.propTypes = {
  resources: PropTypes.any,
  news: PropTypes.any
};

export default ResourceAndNews;
