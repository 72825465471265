export default {
  "/login": {
    pageName: "optum:rx-client:Sign in",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/welldyne/login": {
    pageName: "optum:rx-client:Welldyne - Sign in",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/serveyou/login": {
    pageName: "optum:rx-client:Serveyou - Sign in",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home": {
    pageName: "optum:rx-client:Home",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/profile": {
    pageName: "optum:rx-client:Account Profile",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },

  "/home/landing": {
    pageName: "optum:rx-client:Home",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/requests": {
    pageName: "optum:rx-client:Requests",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications": {
    pageName: "optum:rx-client:Client Applications:My Products",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/clinicalServices": {
    pageName: "optum:rx-client:Clinical Services",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/homedelivery": {
    pageName: "optum:rx-client:Home delivery",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/specialty": {
    pageName: "optum:rx-client:Specialty",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/govermentPrograms": {
    pageName: "optum:rx-client:Government programs",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/filecabinet": {
    pageName: "optum:rx-client:File Cabinet",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/termsOfUse": {
    pageName: "optum:rx-client:Terms of use",
    siteSectionL1: "Terms of use",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/termsOfUse": {
    pageName: "optum:rx-client:Terms of use - Authenticate",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/privacyPolicy": {
    pageName: "optum:rx-client:Privacy policy",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/privacyPolicy": {
    pageName: "optum:rx-client:Privacy policy - Authenticate",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/memberIdGenerator": {
    pageName: "optum:rx-client:Member ID Generator",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/Alliance Savings Program/AllianceSavingsProgram": {
    pageName:
      "optum:rx-client:Client Application:My Products:Alliance Savings Program",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/Enhanced Savings Program/EnhancedSavingsProgram": {
    pageName:
      "optum:rx-client:Client Application:My Products:Enhanced Savings Program",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/Home Delivery - IRIS/678678": {
    pageName:
      "optum:rx-client:Client Application:My Products:Home Delivery - IRIS/678678",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/Medicare Part D/MedicarePartD": {
    pageName: "optum:rx-client:Client Application:My Products:Medicare Part D",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/Mobile App for Members/MobileAppforMembers": {
    pageName:
      "optum:rx-client:Client Application:My Products:Mobile App for Members",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/Online Reporting (OLR)/OnlineReporting(OLR)": {
    pageName: "optum:rx-client:Client Application:My Products:Online Reporting",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "Online Reporting",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/Orchard/orchard": {
    pageName: "optum:rx-client:Client Application:My Products:Orchard",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "Orchard",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/PBS/PBS": {
    pageName: "optum:rx-client:Client Application:My Products:PBS",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/RxAuth/RxAuth": {
    pageName: "optum:rx-client:Client Application:My Products:RxAuth",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/RxBuilder/RxBuilder": {
    pageName: "optum:rx-client:Client Application:My Products:RxBuilder",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/RxClaim/RxClaim": {
    pageName: "optum:rx-client:Client Application:My Products:RxClaim",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/RxClaim (IDA)/RxClaim(IDA)": {
    pageName:
      "optum:rx-client:Client Application:My Products:Internet Direct Access",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/RxExchange/RxExchange": {
    pageName: "optum:rx-client:Client Application:My Products:RxExchange",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/RxExpress/RxExpress": {
    pageName: "optum:rx-client:Client Application:My Products:RxExpress",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/RxInteract/RxInterAct": {
    pageName: "optum:rx-client:Client Application:My Products:RxInterAct",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/RxMax/RxMax": {
    pageName: "optum:rx-client:Client Application:My Products:RxMax",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/RxPortal for Members/RxPortalforMembers": {
    pageName:
      "optum:rx-client:Client Application:My Products:RxPortal for Members",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/RxTrack/RxTrack": {
    pageName: "optum:rx-client:Client Application:My Products:RxTrack",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/RxView/RxView": {
    pageName: "optum:rx-client:Client Application:My Products:RxView",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/RxVision/RxVision": {
    pageName: "optum:rx-client:Client Application:My Products:RxVision",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/applications/myproduct/productdetail/Web Services/WebServices": {
    pageName: "optum:rx-client:Client Application:My Products:Web Services",
    siteSectionL1: "Client Application",
    siteSectionL2: "My Products",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/clinicalServices/clinicalPublication/RxNews": {
    pageName: "optum:rx-client:Clinical services:RxNews",
    siteSectionL1: "Clinical services",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/clinicalServices/clinicalPublication/RxOutlook": {
    pageName: "optum:rx-client:Clinical services:RxOutlook",
    siteSectionL1: "Clinical services",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/clinicalServices/clinicalPublication/RxHighlight": {
    pageName: "optum:rx-client:Clinical services:RxHighlights",
    siteSectionL1: "Clinical services",
    siteSectionL2: "RxHighlights",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/clinicalServices/relatedLinks/P&TCommitteeSupport": {
    pageName:
      "optum:rx-client:Clinical services:Related Links:P & T Committee support",
    siteSectionL1: "Clinical services",
    siteSectionL2: "Related Links",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/clinicalServices/relatedLinks/UtilizationManagement": {
    pageName:
      "optum:rx-client:Clinical services:Related Links:Utilization management",
    siteSectionL1: "Clinical services",
    siteSectionL2: "Related Links",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/clinicalServices/relatedLinks/FormularyManagement": {
    pageName:
      "optum:rx-client:Clinical services:Related Links:Formulary management",
    siteSectionL1: "Clinical services",
    siteSectionL2: "Related Links",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/govermentPrograms/relatedLinks/GPRelatedLinksEmployerGroupWaiverPlans": {
    pageName:
      "optum:rx-client:Government Programs:Related Links:Employer Group Waiver Plans",
    siteSectionL1: "Government Programs",
    siteSectionL2: "Related Links",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/govermentPrograms/relatedLinks/GPRelatedLinksHealthInsuranceExchanges": {
    pageName:
      "optum:rx-client:Government Programs:Related Links:Health Insurance Exchanges",
    siteSectionL1: "Government Programs",
    siteSectionL2: "Related Links",
    siteSectionL3: "",
    siteSectionL4: "",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/govermentPrograms/relatedLinks/GPRelatedLinksManagedMedicaid": {
    pageName:
      "optum:rx-client:Government Programs:Related Links:Managed Medicaid",
    siteSectionL1: "Government Programs",
    siteSectionL2: "Related Links",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/govermentPrograms/relatedLinks/GPRelatedLinksMedicarePartD": {
    pageName:
      "optum:rx-client:Government Programs:Related Links:Medicare Part D",
    siteSectionL1: "Government Programs",
    siteSectionL2: "Related Links",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  },
  "/home/formularylookup": {
    pageName: "Formulary Drug Search",
    siteSectionL1: "Home",
    siteSectionL2: "Formulary Lookup",
    siteSectionL3: "",
    siteSectionL4: "",
    website: "rx-client",
    businessUnit: "optum",
    language: "en",
    newsArticle: "",
  }
};
