import {
  CLIENTREQUEST_MESSAGE_CONTENT_REQUEST,
  CLIENTREQUEST_MESSAGE_CONTENT_SUCCESS,
  CLIENTREQUEST_MESSAGE_CONTENT_FAILURE,
} from "../actions/clientRequestErrorContent";

export function clientRequestErrorContent(
  state = {
    isFetching: false,
    clientRequestContent: "",
    clientRequestErrorContent: "",
  },
  action
) {
  switch (action.type) {
    case CLIENTREQUEST_MESSAGE_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        clientRequestErrorContent: "",
      });
    case CLIENTREQUEST_MESSAGE_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        clientRequestErrorContent: action.message,
      });
    case CLIENTREQUEST_MESSAGE_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default clientRequestErrorContent;
