import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const FILECABINET_CONTENT_REQUEST = "FILECABINET_CONTENT_REQUEST";
export const FILECABINET_CONTENT_SUCCESS = "FILECABINET_CONTENT_SUCCESS";
export const FILECABINET_CONTENT_FAILURE = "FILECABINET_CONTENT_FAILURE";

export const FILECABINET_DOCUMENTLIBRARY_REQUEST =
  "FILECABINET_DOCUMENTLIBRARY_REQUEST";
export const FILECABINET_DOCUMENTLIBRARY_SUCCESS =
  "FILECABINET_DOCUMENTLIBRARY_SUCCESS";
export const FILECABINET_DOCUMENTLIBRARY_PARENT_SUCCESS =
  "FILECABINET_DOCUMENTLIBRARY_PARENT_SUCCESS";
export const FILECABINET_DOCUMENTLIBRARY_CHILD_SUCCESS =
  "FILECABINET_DOCUMENTLIBRARY_CHILD_SUCCESS";
export const FILECABINET_DOCUMENTLIBRARY_FAILURE =
  "FILECABINET_DOCUMENTLIBRARY_FAILURE";

export function requestFileCabinetContent() {
  return {
    type: FILECABINET_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function receiveFileCabinetContent(data) {
  return {
    type: FILECABINET_CONTENT_SUCCESS,
    isFetched: true,
    data,
  };
}

export function fileCabinetContentFailure(message) {
  return {
    type: FILECABINET_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getFileCabinetContent() {
  return (dispatch) => {
    dispatch(requestFileCabinetContent());
    const url = fetchGeneralContentUrl("/cms/getNodeByCode/page/FileCabinet");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(fileCabinetContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveFileCabinetContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(fileCabinetContentFailure(error));
      });
  };
}
