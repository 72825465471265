import React, { Component } from "react";
import { connect } from "react-redux";
import { getProductMetaData } from "../actions/productMetaData";
import Offerings from "../components/Applications/Offerings/Offering";
import globalConstants from "../constants/global-constants";

class ProductOfferingContainer extends Component {
  state = {
    proMetaData: {}
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getProductData(newProps);
  }

  componentDidMount() {
    this.getProductData(this.props);
  }

  getProductData(props) {
    const { productOfferingData, productsMetaData } = props;
    const InternalAndExternal =
      productOfferingData[globalConstants.U_SERVICE_PRODUCT] ||
      productOfferingData[globalConstants.PRODUCT_COMPLETE_NAME];
    if (productOfferingData && InternalAndExternal && productsMetaData) {
      const productInfo = this.getProduct(
        InternalAndExternal,
        productsMetaData.data
      );
      if (!productInfo) {
        this.props.dispatch(getProductMetaData(InternalAndExternal));
      } else if (
        productInfo &&
        productInfo.value &&
        productInfo.value[globalConstants.FIELD_CODE]
      ) {
        this.setState({ proMetaData: productInfo });
      }
    }
  }

  getProduct(productName, productMetaData) {
    return productMetaData.find(item => {
      return item["name"] === productName;
    });
  }

  render() {
    const { productOfferingData } = this.props;
    const { proMetaData } = this.state;
    return (
      <React.Fragment>
        {productOfferingData &&
          proMetaData &&
          proMetaData.value &&
          proMetaData.value[globalConstants.FIELD_CODE] && (
            <Offerings
              item={productOfferingData}
              proMetaData={proMetaData.value}
            />
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ productsMetaData, auth, filesFromBytes }) => {
  return { productsMetaData, auth, filesFromBytes };
};

export default connect(mapStateToProps)(ProductOfferingContainer);
