import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  withStyles,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Route from "../../constants/routes";
import { FiberManualRecord } from "@material-ui/icons";
import styles from "./FormularyLookupStyles";
import { useTheme } from "@material-ui/styles";

function FormularyLookup(props) {
  const { classes, title, contentlist } = props;
  let formularylookup = Route.formularylookup;
  const theme = useTheme();
  return (
    <div className={classes.main}>
      {title && (
        <div>
          <Box
            padding="15px"
            borderBottom="1px solid"
            sx={{ ...theme.formularylookup }}
          >
            {title}
          </Box>
          <List>
            <ListItem>
              <ListItemIcon className={classes.iconstyles}>
                <FiberManualRecord />
                <NavLink link-id="formularylookup" to={formularylookup}>
                  <Box paddingLeft="7px" marginTop="-4px">
                    {contentlist}
                  </Box>
                </NavLink>
              </ListItemIcon>
            </ListItem>
          </List>
        </div>
      )}
    </div>
  );
}
export default withStyles(styles)(FormularyLookup);
