import React from "react";
import Content from "../Shared/Content/Content";
import Button from "@material-ui/core/Button";
import Select from "react-select";
import "./BASTab.scss";
import { Box } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Link from "../../constants/outside-links";
import {
  searchCriteriaEob,
  searchCriteriaTB,
} from "../../constants/searchCriteria_EOB";
import _ from "lodash";
import globalConstants from "../../constants/global-constants";

const SEARCHCLASS = "mb-0 searchFieldLabelsSpacing question body-text-small ";
const CRITERIACLASS =
  "mb-0 criteriaFieldLabelsSpacing question body-text-small ";

const options = [
  { label: "Explanation of benefits", value: "Explanation of benefits" },
  { label: "Transition benefits", value: "Transition benefits" },
];

// const PTList = ["Explanation of benefits", "Transition benefits"];
const exemptionList = [
  "Last Name",
  "First Name",
  "Claim date",
  "Claim date start",
  "Claim date end",
  "Stage 1, 2, 3, 4, 5",
  "Format S, L, B, A",
  "Language code – ENG, SPA, CHI",
];
const EOB = "Explanation of benefits";
const TB = "Transition benefits";
let statesArr = "";
// let searchFieldList = ""
let request = {
  queryType: "",
  operation: "AND",
  limit: 25,
  offSet: 0,
  filters: [
    {
      name: searchCriteriaEob[0].id,
      operation: "eq",
      value: "",
    },
  ],
};

class BASTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prodtype: "",
      options: options,
      stateVal: "Equals",
      SearchCriteria: [
        {
          searchField: searchCriteriaEob[0].name,
          statesList: searchCriteriaEob[0].states,
          stageValueList: searchCriteriaEob[0].stageValue,
          formatValueList: searchCriteriaEob[0].formatValue,
          languageValueList: searchCriteriaEob[0].languageValue,
          stateVal: "",
          SearchInputVal: "",
          searchId: searchCriteriaEob[0].id,
        },
      ],
      //   searchCriteriaFieldsList: searchCriteriaEob,
      searchCriteriaFieldsList: "",
      filterObject: {
        productType: "",
        searchValue: "",
      },
      errors: {},
    };

    this.handleSearchField = this.handleSearchField.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleChangeFreq = this.handleChangeFreq.bind(this);
  }
  initialState = {
    searchField: "",
    stateVal: "",
    SearchInputVal: "",
  };
  componentDidMount() {
    const { PType } = this.props;
    this.setState({
      prodtype: PType,
    });
    request.queryType = "";
    request.filters[0].operation = "eq";
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    const { productTypeList } = newProps;
    // let searchFieldList = "";
    if (this.state.searchCriteriaFieldsList === "" && productTypeList) {
      if (productTypeList[0].id === globalConstants.EOB) {
        // searchFieldList =
        // 	searchCriteriaEob &&
        // 	searchCriteriaEob.length > 0 &&
        // 	searchCriteriaEob.map((i) => (
        // 		<option key={i.name} value={i.name}>
        // 			{i.name}
        // 		</option>
        // 	));
        this.setState({ searchCriteriaFieldsList: searchCriteriaEob });
      }
      if (productTypeList[0].id === globalConstants.TB) {
        // searchFieldList =
        // 	searchCriteriaTB &&
        // 	searchCriteriaTB.length > 0 &&
        // 	searchCriteriaTB.map((i) => (
        // 		<option key={i.name} value={i.name}>
        // 			{i.name}
        // 		</option>
        // 	));
        this.setState({ searchCriteriaFieldsList: searchCriteriaTB });
      }
    }
  }

  handleSearchField(e, idx) {
    const { name, value } = e.target;
    const { searchCriteriaFieldsList } = this.state;
    const SearchCriteria = [...this.state.SearchCriteria];
    SearchCriteria[idx].searchField = value;
    SearchCriteria[idx].stateVal = SearchCriteria[idx].statesList[0];
    this.state.SearchCriteria = SearchCriteria;
    this.setState({ SearchCriteria: SearchCriteria });
    statesArr =
      searchCriteriaFieldsList &&
      searchCriteriaFieldsList.length > 0 &&
      searchCriteriaFieldsList.find((item) => item.name === value);
    if (request.filters[idx]) {
      request.filters[idx].name = statesArr.id;
      request.filters[idx].operation = "eq";
    }
    // this.state.statesList.push(statesArr.states);
    this.state.SearchCriteria[idx].statesList = statesArr.states;
    this.state.SearchCriteria[idx].stageValueList = statesArr.stageValue;
    this.state.SearchCriteria[idx].formatValueList = statesArr.formatValue;
    this.state.SearchCriteria[idx].languageValueList = statesArr.languageValue;
  }
  changeToCamelCase(value) {
    return value.toLowerCase().replace(/\s+(.)/g, function (match, group1) {
      return group1.toUpperCase();
    });
  }

  handleStateChange = (e, idx) => {
    const val = e.target.value;
    const SearchCriteria = [...this.state.SearchCriteria];
    SearchCriteria[idx].stateVal = val;
    this.state.SearchCriteria = SearchCriteria;
    if (val === "Equals") {
      request.filters[idx].operation = "eq";
    } else if (val === "begins with") {
      request.filters[idx].operation = "startsWith";
    } else if (val === "less than") {
      request.filters[idx].operation = "lt";
    } else if (val === "less than equals") {
      request.filters[idx].operation = "le";
    } else if (val === "greater than") {
      request.filters[idx].operation = "gt";
    } else if (val === "greater than equals") {
      request.filters[idx].operation = "ge";
    } else {
      request.filters[idx].operation = this.changeToCamelCase(val);
    }
    this.setState({ SearchCriteria: SearchCriteria });
  };

  handleAddSearch = () => {
    const initialState = {
      searchField:
        this.state.searchCriteriaFieldsList &&
        this.state.searchCriteriaFieldsList.length > 0 &&
        this.state.searchCriteriaFieldsList[0].name,
      stateVal: "",
      SearchInputVal: "",
      statesList:
        this.state.searchCriteriaFieldsList &&
        this.state.searchCriteriaFieldsList.length > 0 &&
        this.state.searchCriteriaFieldsList[0].states,
    };
    const reqFilter = {
      name: searchCriteriaEob[0].id,
      operation: "eq",
      value: "",
    };
    const newData = _.cloneDeep(initialState);
    this.state.SearchCriteria.push(newData);
    this.setState({
      SearchCriteria: this.state.SearchCriteria,
    });
    request.filters.push(_.cloneDeep(reqFilter));
  };

  isRequiredCarrierId(SearchCriteria, errors) {
    if (SearchCriteria && SearchCriteria[0].SearchInputVal.trim() === "") {
      errors.carrierID = "CarrierID **";
    }
  }

  handleSubmit = (e, request) => {
    // e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (request.queryType === "") {
      request.queryType = this.props.PType;
    }
    if (errors) {
      return errors;
    }
    this.props.onSearch(e, this.state.filterObject, request);
  };

  validate = () => {
    const errors = {};
    const { SearchCriteria } = this.state;
    this.isRequiredCarrierId(SearchCriteria, errors);
    return Object.keys(errors).length === 0 ? null : errors;
  };

  validateProperty = (value) => {
    if (value.trim() === "" || value.length > 0) {
      return "CarrierID**";
    }
  };

  onChange = (e, idx) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(e.target.value);
    const { name, value } = e.target;
    const nameValue = name;
    const replaceValue = nameValue.replace(
      this.state.SearchCriteria[0].searchField,
      "CarrierID"
    );
    if (errorMessage) {
      errors[replaceValue] = errorMessage;
    } else {
      delete errors[replaceValue];
    }
    let SearchCriteria = [...this.state.SearchCriteria];
    SearchCriteria[idx].SearchInputVal = value;
    this.state.SearchCriteria = SearchCriteria;
    if (
      name === "Claim date start" ||
      name === "Claim date end" ||
      name === "Date" ||
      name === "Date range" ||
      name === "Mailed from" ||
      name === "Mailed to" ||
      name === "Received to" ||
      name === "Received from"
    ) {
      request.filters[idx].value = value.replace(/-/g, "");
    } else {
      request.filters[idx].value = value;
    }
    this.setState({
      SearchCriteria: SearchCriteria,
      filterObject: {
        productType: this.state.filterObject.productType,
        searchValue: SearchCriteria,
      },
      errors,
    });
  };
  handleChangeFreq = (e) => {
    if (e.target.value === EOB) {
      this.setState({
        searchCriteriaFieldsList: searchCriteriaEob,
      });
      request.queryType = globalConstants.EOB;
    } else {
      this.setState({ searchCriteriaFieldsList: searchCriteriaTB });
      request.queryType = globalConstants.TB;
    }
    const reqFilter = {
      name: searchCriteriaEob[0].id,
      operation: "eq",
      value: "",
    };
    const initialState = {
      searchField:
        this.state.searchCriteriaFieldsList &&
        this.state.searchCriteriaFieldsList.length > 0 &&
        this.state.searchCriteriaFieldsList[0].name,
      stateVal: "",
      SearchInputVal: "",
      statesList:
        this.state.searchCriteriaFieldsList &&
        this.state.searchCriteriaFieldsList.length > 0 &&
        this.state.searchCriteriaFieldsList[0].states,
      stageValueList:
        this.state.searchCriteriaFieldsList &&
        this.state.searchCriteriaFieldsList.length > 0 &&
        this.state.searchCriteriaFieldsList[0].stageValue,
      formatValueList:
        this.state.searchCriteriaFieldsList &&
        this.state.searchCriteriaFieldsList.length > 0 &&
        this.state.searchCriteriaFieldsList[0].formatValue,
      languageValueList:
        this.state.searchCriteriaFieldsList &&
        this.state.searchCriteriaFieldsList.length > 0 &&
        this.state.searchCriteriaFieldsList[0].languageValue,
    };
    const newData = _.cloneDeep(initialState);
    this.state.SearchCriteria.length = 0;
    this.state.SearchCriteria.push(newData);
    request.filters.length = 0;
    request.filters.push(_.cloneDeep(reqFilter));
    this.setState({
      prodtype: e.target.value,
      SearchCriteria: this.state.SearchCriteria,
      filterObject: {
        searchValue: this.state.filterObject.searchValue,
        productType: e.target.value,
      },
      errors: "",
    });
  };

  handleRemoveSpecificSearch = (index) => {
    let searchCriteria = [...this.state.SearchCriteria];
    if (searchCriteria.length > 1) {
      searchCriteria.splice(index, 1);
      request.filters.splice(index, 1);
    }
    this.setState({
      SearchCriteria: searchCriteria,
    });
  };
  checkSearchForCriteria = (ind) => {
    const value = this.state.SearchCriteria[ind].searchField;
    const res = exemptionList.find((item) => item === value);
    return res ? true : false;
  };

  render() {
    const {
      prodtype,
      searchField,
      stateVal,
      searchCriteriaFieldsList,
      SearchCriteria,
      errors,
    } = this.state;

    const { onSearch, filterObject, productTypeList } = this.props;
    const searchFieldList =
      searchCriteriaFieldsList &&
      searchCriteriaFieldsList.length > 0 &&
      searchCriteriaFieldsList.map((i) => (
        <option key={i.name} value={i.name}>
          {i.name}
        </option>
      ));
    const PT =
      productTypeList &&
      productTypeList.length > 0 &&
      productTypeList.map((i) => (
        <option key={i.label} value={i.value}>
          {i.label}
        </option>
      ));
    const states = statesArr.states;
    return (
      <React.Fragment>
        <div className="bas-container">
          <div className="ml40">
            <h3 className="mb5 mt25">
              <b>Benefits archive search</b>
            </h3>
            {/* <p>Fields with an asterisk <font color='red'> * </font>are required.</p> */}
            {errors.carrierID === "CarrierID **" &&
            errors.CarrierID !== "CarrierID**" ? (
              <p className="fieldValidationmsg pad0">
                Fill out at least one search field to get results.
              </p>
            ) : (
              <p>Fill out at least one search field to get results.</p>
            )}
            <div>
              <label className="body-text-small">Product type</label>
              <div>
                <select
                  className="select-dropdown "
                  value={this.state.prodtype}
                  onChange={(e) => this.handleChangeFreq(e)}
                >
                  {PT}
                </select>
              </div>
              <div className="marB20">
                {SearchCriteria &&
                  SearchCriteria.length > 0 &&
                  SearchCriteria.map((el, index) => (
                    <div key={index}>
                      <div className="QuestionHeader InputFieldLabels">
                        <label
                          className={
                            index === 0
                              ? `${SEARCHCLASS}`
                              : `${SEARCHCLASS} mr145`
                          }
                        >
                          Search Using
                        </label>
                        <label
                          className={
                            index === 0
                              ? `${CRITERIACLASS}`
                              : `${CRITERIACLASS} mr126`
                          }
                        >
                          Criteria
                        </label>
                        <Box position="relative">
                          {index === 0 &&
                            errors.carrierID === "CarrierID **" &&
                            errors.CarrierID !== "CarrierID**" && (
                              <Box className="error errorIcon" display="flex">
                                <ErrorIcon size={15} />{" "}
                                <Box
                                  className={
                                    index === 0
                                      ? "mb-0 question body-text-small validationmsg"
                                      : "mb-0 question body-text-small questionFont "
                                  }
                                >
                                  One field is required.
                                </Box>
                              </Box>
                            )}
                          <p
                            className={
                              index === 0
                                ? "mb-0 question body-text-small"
                                : "mb-0 question body-text-small "
                            }
                          >
                            {searchCriteriaFieldsList &&
                              searchCriteriaFieldsList.length > 0 &&
                              (el.searchField !== ""
                                ? el.searchField
                                : searchCriteriaFieldsList[0].name)}
                          </p>
                        </Box>
                      </div>
                      <div className="mt InputFieldLabels">
                        <select
                          value={el.searchField}
                          className="select-dropdown mb-0 mr-30"
                          onChange={(e) => this.handleSearchField(e, index)}
                        >
                          {searchFieldList}
                        </select>
                        <div>
                          <select
                            // className="select-dropdown  width150"
                            className={
                              this.checkSearchForCriteria(index)
                                ? "select-dropdown  width150 greyBorder"
                                : "select-dropdown  width150"
                            }
                            value={el.stateVal}
                            onChange={(e) => this.handleStateChange(e, index)}
                            // disabled="true"
                            disabled={this.checkSearchForCriteria(index)}
                          >
                            {el.statesList &&
                              el.statesList.length > 0 &&
                              el.statesList.map((e, key) => {
                                return <option key={key}>{e}</option>;
                              })}
                          </select>
                        </div>
                        <Box>
                          {el.searchField === "Stage 1, 2, 3, 4, 5" ||
                          el.searchField === "Format S, L, B, A" ||
                          el.searchField === "Language code – ENG, SPA, CHI" ||
                          el.searchField === "Claim date start" ||
                          el.searchField === "Claim date end" ||
                          el.searchField === "Date range" ||
                          el.searchField === "Date" ||
                          el.searchField === "Mailed from" ||
                          el.searchField === "Mailed to" ||
                          el.searchField === "Received from" ||
                          el.searchField === "Received to" ? (
                            <Box>
                              {el.searchField === "Claim date start" ||
                              el.searchField === "Claim date end" ||
                              el.searchField === "Date range" ||
                              el.searchField === "Date" ||
                              el.searchField === "Mailed from" ||
                              el.searchField === "Mailed to" ||
                              el.searchField === "Received from" ||
                              el.searchField === "Received to" ? (
                                <Box>
                                  <input
                                    style={{
                                      fontSize: "13px",
                                      fontFamily: "inherit",
                                    }}
                                    name={el.searchField}
                                    value={el.SearchInputVal || ""}
                                    className="BusinessXSMInput mb-0"
                                    onChange={(e) => this.onChange(e, index)}
                                    type="date"
                                    required
                                  />{" "}
                                  <Box className="hintMsg">mm/dd/yyyy</Box>
                                </Box>
                              ) : (
                                <select
                                  className={
                                    this.checkSearchForCriteria(index)
                                      ? "select-dropdown  width150 "
                                      : "select-dropdown  width150"
                                  }
                                  onChange={(e) => this.onChange(e, index)}
                                >
                                  {el.searchField === "Stage 1, 2, 3, 4, 5" &&
                                    el.stageValueList &&
                                    el.stageValueList.length > 0 &&
                                    el.stageValueList.map((e, key) => {
                                      return <option key={key}>{e}</option>;
                                    })}
                                  {el.searchField === "Format S, L, B, A" &&
                                    el.formatValueList &&
                                    el.formatValueList.length > 0 &&
                                    el.formatValueList.map((e, key) => {
                                      return <option key={key}>{e}</option>;
                                    })}
                                  {el.searchField ===
                                    "Language code – ENG, SPA, CHI" &&
                                    el.languageValueList &&
                                    el.languageValueList.length > 0 &&
                                    el.languageValueList.map((e, key) => {
                                      return <option key={key}>{e}</option>;
                                    })}
                                </select>
                              )}
                            </Box>
                          ) : (
                            <input
                              name={el.searchField}
                              value={el.SearchInputVal || ""}
                              className="BusinessXSMInput mb-0"
                              onChange={(e) => this.onChange(e, index)}
                              type="text"
                            />
                          )}
                          {el.searchField === "Benefit Year" ? (
                            <Box className="hintMsg">yyyy</Box>
                          ) : (
                            ""
                          )}
                          {index === 0 &&
                          errors.carrierID === "CarrierID **" &&
                          errors.CarrierID !== "CarrierID**" ? (
                            <Box className="errorBottomMsg">
                              Please enter value.
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                        {index !== 0 && (
                          <Button
                            className="padTB0 removeIcon"
                            onClick={() =>
                              this.handleRemoveSpecificSearch(index)
                            }
                          >
                            <RemoveCircleOutlineIcon
                              className="removeIcon"
                              // onClick={() => this.handleRemoveSpecificSearch(index)}
                            />
                          </Button>
                        )}
                        {SearchCriteria &&
                          SearchCriteria.length > 0 &&
                          SearchCriteria.length - 1 === index && (
                            <div className={index === 0 ? "marL30" : ""}>
                              <a
                                name="search"
                                className="button button-primary block marB10 height noCap marL30"
                                onClick={(e) => this.handleSubmit(e, request)}
                              >
                                Search
                                {/* <span className="pad15"><b>Search</b></span> */}
                              </a>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
              </div>
              <div>
                <Button
                  className="textTransform addVendorsContacts addButton mr75 mb30pl0"
                  onClick={() => this.handleAddSearch()}
                >
                  <p className=" mb-0 iconButtonSpacing color">
                    <b>+ Add another search field</b>
                  </p>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BASTab;
