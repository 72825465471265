export default {
  login: "/login",
  termsOfUse: "/termsOfUse",
  memberIdGenerator: "/memberIdGenerator",
  memberIdGeneratorAuth: "/home/memberIdGenerator",
  privacyPolicy: "/privacyPolicy",
  termsOfUseAuth: "/home/termsOfUse",
  privacyPolicyAuth: "/home/privacyPolicy",
  profile: "/home/profile",
  home: "/home",
  landing: "/home/landing",
  requests: "/home/requests",
  dashboard: "/home/dashboard",
  formularylookup: "/home/formularylookup",
  applications: "/home/applications",
  clinicalServices: "/home/clinicalServices",
  homedelivery: "/home/homedelivery",
  specialty: "/home/specialty",
  govermentPrograms: "/home/govermentPrograms",
  filecabinet: "/home/filecabinet",
  contactus: "/home/contactus",
  feedback: "/home/contactus/feedback",
  noaccess: "/home/noaccess",
  signout: "/signout",
  unauthorized: "/home/unauthorized",
  myProduct: "/home/applications/myproduct",
  productOffering: "/home/applications/productoffering",
  productdetail: "/home/applications/myproduct/productdetail",
  productdetailWithNameAndId:
    "/home/applications/myproduct/productdetail/:productname/:productDocID",
  launch: "/product/launch/",
  rxTrackSSOLaunch: "/home/rxtrackSSO/launch/:product",
  serviceNowkSSOLaunch: "/home/requests/:serviceNowId/launch",
  ssolaunch: "/home/sso/launch/:type",

  clinicalPublication: "/home/clinicalServices/clinicalPublication/",
  relatedLinkCS: "/home/clinicalServices/relatedLinks/",
  clinicalPublicationWithType:
    "/home/clinicalServices/clinicalPublication/:type",
  relatedLinksCSWithType: "/home/clinicalServices/relatedLinks/:type",
  clinicalPublication_RxNews:
    "/home/clinicalServices/clinicalPublication/RxNews",
  clinicalPublication_RxOutlook:
    "/home/clinicalServices/clinicalPublication/RxOutlook",
  clinicalPublication_RxHighlight:
    "/home/clinicalServices/clinicalPublication/RxHighlight",
  relatedLink_PTCommitteeSupport:
    "/home/clinicalServices/relatedLinks/P&TCommitteeSupport",
  relatedLink_UtilizationManagement:
    "/home/clinicalServices/relatedLinks/UtilizationManagement",
  relatedLink_FormularyManagement:
    "/home/clinicalServices/relatedLinks/FormularyManagement",
  relatedLink_EmployerGroupWaiverPlans:
    "/home/govermentPrograms/relatedLinks/GPRelatedLinksEmployerGroupWaiverPlans",
  relatedLink_HealthInsuranceExchanges:
    "/home/govermentPrograms/relatedLinks/GPRelatedLinksHealthInsuranceExchanges",
  relatedLink_ManagedMedicaid:
    "/home/govermentPrograms/relatedLinks/GPRelatedLinksManagedMedicaid",
  relatedLink_MedicarePartD:
    "/home/govermentPrograms/relatedLinks/GPRelatedLinksMedicarePartD",
  oidResponseCode: "/oidresponsecode",
  responseCode: "/:type/responsecode/",
  oidSSO: "/oid/sso",
  msidAuth: "/msid/auth",
  relatedLinkGPWithType: "/home/govermentPrograms/relatedLinks/:type",
  PrestoSSOAuth: "/home/rxClaimSSO",
  PrestoSSO:
    "/resource-server/login?resume=/as/GFryH/resume/as/authorization.png",
};
