import { fetchGeneralContentUrl, fetchAEMContentUrl} from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
import {getCPDataFromAEMResponse} from '../utils/aem-content';

export const SIGNIN_CONTENT_REQUEST = "SIGNIN_CONTENT_REQUEST";
export const SIGNIN_CONTENT_SUCCESS = "SIGNIN_CONTENT_SUCCESS";
export const SIGNIN_CONTENT_FAILURE = "SIGNIN_CONTENT_FAILURE";
export const OPTUMID_SIGNIN_CONTENT_REQUEST = "OPTUMID_SIGNIN_CONTENT_REQUEST";
export const OPTUMID_SIGNIN_CONTENT_SUCCESS = "OPTUMID_SIGNIN_CONTENT_SUCCESS";
export const OPTUMID_SIGNIN_CONTENT_FAILURE = "OPTUMID_SIGNIN_CONTENT_FAILURE";

export function requestSignInContent() {
  return {
    type: SIGNIN_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function requestOptumIdSignInContent() {
  return {
    type: OPTUMID_SIGNIN_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function receiveSignInContent(signInData) {
  return {
    type: SIGNIN_CONTENT_SUCCESS,
    isFetched: true,
    signInData,
  };
}

export function receiveOptumIdSignInContent(optumIdsignInData) {
  return {
    type: OPTUMID_SIGNIN_CONTENT_SUCCESS,
    isFetched: true,
    optumIdsignInData,
  };
}

export function signInContentFailure(message) {
  return {
    type: SIGNIN_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function optumIdSignInContentFailure(message) {
  return {
    type: OPTUMID_SIGNIN_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getSignInContent(whiteLabel) {
  return (dispatch) => {
    dispatch(requestSignInContent());
    // const endPoint = whiteLabel
    //   ? "/cms/whitelabellogincontent"
    //   : "/cms/logincontent";
    // console.log('endpoint', endPoint);

    const aemEndPoint = whiteLabel ? '/cms/whitelabellogincontent' : '/cms/getsignin';
    const url = fetchAEMContentUrl(aemEndPoint);
    http
      .get(url)
      .then((response) => {
        const resp = response.data;
        const data = getCPDataFromAEMResponse(resp, 'signin', 'signin');
    
        if (response.status !== 200) {
          dispatch(signInContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveSignInContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(signInContentFailure(error));
      });
  };
}

export function getOptumIdSignInContent(whiteLabel) {
  return (dispatch) => {
    dispatch(requestOptumIdSignInContent());
    var endPt;
    if (whiteLabel) {
      endPt = "/cms/wlOidLoginContent";
    } else {
      endPt = (process.env.REACT_APP_OID_FLAG === "true" ? "/cms/oidlogincontent" : "/cms/ohidlogincontent");
    }
    const url = fetchGeneralContentUrl(endPt);
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(optumIdSignInContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveOptumIdSignInContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(optumIdSignInContentFailure(error));
      });
  };
}
