import React, { useMemo } from "react";
import ClientDataGrid from "../../Shared/ClientDataGrid/ClientDataGrid";
import DrugResultsStyle from "./DrugResultStyle";
import { GridToolbarExport } from "@mui/x-data-grid";
import { RenderCellExpand } from "../../Shared/ClientDataGrid/RenderCellExpand";

function DrugResult({
  drugResults,
  toolbarExportText,
  filterCriteria,
  noRowsMessage,
  shouldDisplayNDC,
  isPartD
}) {
  const styles = DrugResultsStyle();
  const headers = [
    {
      field: "brandName",
      headerName: "Drug name (brand)",
      width: 300,
      renderCell: RenderCellExpand,
    },
    {
      field: "genericName",
      headerName: "Drug name (generic)",
      width: 300,
      renderCell: RenderCellExpand,
    },
    {
      field: "boldedDrugFlag",
      headerName: "Drug type",
      width: 200,
    },
    {
      field: "tierDesc",
      headerName: "Formulary status",
      width: 200,
      renderCell: RenderCellExpand,
    },
    {
      field: "hasPriorAuthorization",
      headerName: "PA required",
      width: 200,
    },
    {
      field: "specialtyFlag",
      headerName: "Specialty",
      width: 200,
    },
    {
      field: "stepFlag",
      headerName: "Step therapy",
      width: 200,
    },
    {
      field: "hasQuantityLimit",
      headerName: "Quantity limits",
      width: 300,
      renderCell: RenderCellExpand,
    },
  ];

  if (isPartD) {
    const specialityFlagIndex = headers.findIndex(header => header.field === 'specialtyFlag');
    if (specialityFlagIndex !== -1) {
      headers.splice(specialityFlagIndex, 1)
    }
  }

  // const drugHeaders = useMemo(() => headers, [shouldDisplayNDC]);
  return (
    <ClientDataGrid
      pageSize={10}
      rows={drugResults}
      columns={headers}
      rowsPerPageOptions={[10, 20, 30, 40, 50]}
      disableColumnMenu={true}
      checkboxSelection={true}
      sortingOrder={["asc", "desc"]}
      initialSortOrder={[{ field: "brandName", sort: "asc" }]}
      getCellClassName={(params) => {
        if (
          (params.row.boldedDrugFlag === "Brand" &&
            params.field === "brandName") ||
          (params.row.boldedDrugFlag === "Generic" &&
            params.field === "genericName")
        ) {
          return styles.brandName;
        }
        return styles.cell;
      }}
      GridToolBarComponent={GridToolbarExport}
      disableSelectionOnClick={true}
      toolbarExportText={toolbarExportText}
      filterCriteria={filterCriteria}
      noRowsMessage={noRowsMessage}
    />
  );
}

export default React.memo(DrugResult);
