import React, { Component } from "react";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import {
  getRelatedLinkMetaDataPart,
  getRelatedLinkMetaDataPro
} from "../actions/governmentRelatedLinksMetaData";
import { getFilesFromBytes } from "../actions/filesFromBytes";
import globalConstants from "../constants/global-constants";

class GPRelatedLinkDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { governmentRelatedLinksMetaData: "", key: "" };
    this.containerRef = React.createRef();
  }

  overrideLinkHandler = e => {
    const { dispatch } = this.props;
    if (
      e.target.tagName === "A" &&
      e.target.getAttribute("class") === "internalLink"
    ) {
      e.preventDefault();
      this.props.history.push(e.target.getAttribute("href"));
    } else if (
      e.target.tagName === "A" &&
      e.target.getAttribute("href") &&
      e.target.getAttribute("class") === "downloadLink"
    ) {
      e.preventDefault();
      const hrefData = e.target.getAttribute("href");
      const fileName = hrefData.substr(hrefData.lastIndexOf("/") + 1);
      const req = {};
      req["filePath"] = hrefData;
      dispatch(getFilesFromBytes(req, fileName));
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getRelatedLinkCMS(nextProps);
    this.addListener();
  }

  componentDidMount() {
    this.getRelatedLinkCMS(this.props);
    this.addListener();
  }
  addListener() {
    ReactDOM.findDOMNode(this).addEventListener(
      "click",
      this.overrideLinkHandler
    );
  }
  getRelatedLinkCMS(nextProps) {
    const {
      subType,
      type,
      governmentRelatedLinksMetaData,
      relatedkey,
      selectedAccount
    } = nextProps;
    const pageType =
      selectedAccount[globalConstants.NAVIGATION_GOVERNMENT_PROGRAM_TAB];
    if (type || subType) {
      if (
        governmentRelatedLinksMetaData &&
        !governmentRelatedLinksMetaData.isFetching
      ) {
        const data = this.configData(
          relatedkey,
          governmentRelatedLinksMetaData.data,
          pageType
        );
        if (data) {
          if (data.key !== this.state.governmentRelatedLinksMetaData.key) {
            this.setState({
              governmentRelatedLinksMetaData: data
            });
            if (data.value) {
              const response = data.value;
              if (response && response.title) {
                this.props.getTitle(response.title);
              }
            }
          }
        } else {
          if (pageType === globalConstants.Participant) {
            this.props.dispatch(
              getRelatedLinkMetaDataPart(pageType, type, subType, relatedkey)
            );
          } else {
            this.props.dispatch(
              getRelatedLinkMetaDataPro(pageType, type, subType, relatedkey)
            );
          }
        }
      }
    }
  }

  configData(key, data, pageType) {
    if (data && data.length > 0) {
      return data.find(item => {
        return item.key === key && item.pageType === pageType;
      });
    }
    return null;
  }

  render() {
    const { governmentRelatedLinksMetaData } = this.state;
    if (
      governmentRelatedLinksMetaData &&
      governmentRelatedLinksMetaData.value
    ) {
      const response = governmentRelatedLinksMetaData.value;
      const html = response.body;
      return <div ref={this.containerRef}>{renderHTML(html)}</div>;
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = ({
  governmentRelatedLinksMetaData,
  userAccount,
  auth
}) => {
  return {
    governmentRelatedLinksMetaData,
    selectedAccount: userAccount.selectedAccount,
    auth
  };
};

export default connect(mapStateToProps)(
  withRouter(GPRelatedLinkDetailContainer)
);
