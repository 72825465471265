import React from "react";

const FormularyList = ({ response }) => {
  return (
    <React.Fragment>
      <ul className="liDoc" dir="ltr">
        {response &&
          response.length > 0 &&
          response.map((item) => (
            <li key={item.title}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={item.link}
                link-id="benefits-formulary"
              >
                <span className="oi-document">&nbsp;</span>
                {item.title}
              </a>
            </li>
          ))}
      </ul>
    </React.Fragment>
  );
};

export default FormularyList;
