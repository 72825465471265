import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import routePath from "../../constants/routes";
import Content from "../Shared/Content/Content";
import FollowUsContainer from "../../containers/FollowUsContainer";
import PreLoginFooterContainer from "../../containers/PreLoginFooterContainer";

class SignOut extends Component {
  state = {};

  render() {
    const { contentData } = this.props;
    return (
      <main className="divider rPad">
        <div className="container">
          <div className="row">
            <div className="logout" style={{ minHeight: 400 }}>
              <p className="marT20" dir="ltr">
                {contentData && contentData.body && (
                  <Content html={contentData.body} />
                )}
                <NavLink to={routePath.login} link-id="Sign in again">
                  Sign in again
                </NavLink>
              </p>
            </div>
          </div>
          <FollowUsContainer />
          <PreLoginFooterContainer />
        </div>
      </main>
    );
  }
}

export default SignOut;
