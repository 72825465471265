import {
  PALITE_SUCCESS,
  PALITE_REQUEST,
  PALITE_FAILURE,
} from "../actions/paLite";

function paLite(
  state = {
    isFetching: false,
    paLiteData: "",
  },
  action
) {
  switch (action.type) {
    case PALITE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        paLiteData: "",
      });
    case PALITE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        paLiteData: action.data,
      });
    case PALITE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        paLiteData: action.data,
      });

    default:
      return state;
  }
}

export default paLite;
