import React from "react";
import Content from "../../Shared/Content/Content";
import { renderHTML } from "../../../constants/service";
const GovernmentProgramContent = ({ data }) => {
  let html = "";
  if (data && data.body) {
    html = renderHTML(data.body);
  }

  return (
    <React.Fragment>
      <h1>{data && data.title && <Content html={data.title} />}</h1>
      {data && data.body && <Content html={html} />}
    </React.Fragment>
  );
};

export default GovernmentProgramContent;
