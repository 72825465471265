import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
export const CLIENTACCOUNTLIST_CONTENT_REQUEST =
  "CLIENTACCOUNTLIST_CONTENT_REQUEST";
export const CLIENTACCOUNTLIST_CONTENT_SUCCESS =
  "CLIENTACCOUNTLIST_CONTENT_SUCCESS";
export const CLIENTACCOUNTLIST_CONTENT_FAILURE =
  "CLIENTACCOUNTLIST_CONTENT_FAILURE";

export function requestClientAccountListContent() {
  return {
    type: CLIENTACCOUNTLIST_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function receiveClientAccountListContent(data) {
  return {
    type: CLIENTACCOUNTLIST_CONTENT_SUCCESS,
    isFetched: true,
    data,
  };
}

export function clientAccountListContentFailure(message) {
  return {
    type: CLIENTACCOUNTLIST_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getClientAccountListContent() {
  return (dispatch) => {
    dispatch(requestClientAccountListContent());
    const url = fetchGeneralContentUrl(
      "/cms/getTextObjectFromByCode/clientAccountList"
    );
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(clientAccountListContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveClientAccountListContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(clientAccountListContentFailure(error));
      });
  };
}
