import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
export const APPLICATION_CONTENT_REQUEST = "APPLICATION_CONTENT_REQUEST";
export const APPLICATION_CONTENT_SUCCESS = "APPLICATION_CONTENT_SUCCESS";
export const APPLICATION_CONTENT_FAILURE = "APPLICATION_CONTENT_FAILURE";

export function requestApplicationContent() {
  return {
    type: APPLICATION_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function receiveApplicationContent(data) {
  return {
    type: APPLICATION_CONTENT_SUCCESS,
    isFetched: true,
    data,
  };
}

export function ApplicationContentFailure(message) {
  return {
    type: APPLICATION_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getApplicationContent(whiteLabel) {
  return (dispatch) => {
    dispatch(requestApplicationContent());
    const endPoint = whiteLabel.whiteLabelVal
      ? "/cms/wlclientapplications"
      : "/cms/getNodeByCode/page/ClientApplications";
    const url = fetchGeneralContentUrl(endPoint);
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(ApplicationContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveApplicationContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(ApplicationContentFailure(error));
      });
  };
}
