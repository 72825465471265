import { makeStyles, createStyles } from "@material-ui/core";

export const FormularySelectStyles = makeStyles((theme) =>
  createStyles({
    textstyle: {
      height: "44px",
      width: "490px",
    },
    errorMessage: {
      color: 'red'
    }
  })
);
