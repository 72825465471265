import React from 'react';
 
export const OptumLogo = () => {
  return (
    <svg width="173" height="50px" viewBox="0 0 173 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path id="path-1" d="M0 0L115.210656 0 115.210656 32.8518656 0 32.8518656z" />
        <path id="path-3" d="M0 0L7.39154983 0 7.39154983 7.52739688 0 7.52739688z" />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-87 -309)">
          <g transform="translate(87 309)">
            <g>
              <mask id="mask-2" fill="#fff">
                <use xlinkHref="#path-1" />
              </mask>
              <path
                fill="#FF612B"
                d="M58.37 6.169h5.214v4.949H58.37v6.886c0 1.757 1.13 2.51 2.808 2.51.693 0 1.495-.107 2.406-.394v4.913c-1.24.503-2.662.646-4.011.646-4.63 0-7.51-2.402-7.51-7.173V1.256h6.307v4.913zM39.521 20.587c-2.552 0-4.594-2.045-4.594-4.807 0-2.725 2.042-4.77 4.594-4.77 2.516 0 4.558 2.045 4.558 4.77 0 2.762-2.042 4.807-4.558 4.807zm1.46-14.705a8.866 8.866 0 00-6.235 2.51l-.038-2.223h-6.125v26.683h6.344v-9.468c1.605 1.47 3.719 2.33 6.053 2.33 5.286 0 9.443-4.41 9.443-9.934 0-5.486-4.157-9.898-9.443-9.898zm74.23 7.137v12.373h-6.308v-11.44c0-1.865-1.203-2.977-2.662-2.977-1.458 0-2.662 1.112-2.662 2.977v11.44h-6.306v-11.44c0-1.865-1.204-2.977-2.662-2.977-1.459 0-2.662 1.112-2.662 2.977v11.44h-6.307V6.169h6.198l.036 2.295c1.24-1.758 3.063-2.582 5.178-2.582 2.661 0 4.557 1.22 5.578 3.12 1.239-2.08 3.573-3.12 5.98-3.12 4.265 0 6.599 3.156 6.599 7.137zM77.184 6.17h6.342v11.907c0 4.95-4.01 7.747-9.114 7.747-5.104 0-9.115-2.797-9.115-7.747V6.169h6.344v11.727c0 1.686 1.13 2.763 2.771 2.763s2.772-1.077 2.772-2.763V6.17zm-63.84 13.557c-3.682 0-6.636-2.941-6.636-6.886 0-3.946 2.954-6.887 6.636-6.887 3.683 0 6.636 2.941 6.636 6.887 0 3.945-2.953 6.886-6.636 6.886zm0-19.726C5.87 0 0 5.702 0 12.84c0 7.137 5.87 12.84 13.344 12.84 7.474 0 13.344-5.703 13.344-12.84C26.688 5.702 20.818 0 13.344 0z"
                mask="url(#mask-2)"
              />
            </g>
            <path
              fill="#6F625A"
              d="M134.383 2.78h-6.028v9.212h5.887c3.546 0 5.212-2.187 5.212-4.624 0-2.652-1.808-4.588-5.07-4.588zm-8.865 22.652V.343h9.149c4.574 0 7.624 2.83 7.624 6.953 0 3.154-2.163 5.77-5.39 6.738l7.092 11.398h-3.298l-6.773-11.039h-5.567v11.039h-2.837z"
            />
            <path
              fill="#6F625A"
              d="M143.741908 25.4321008L151.010822 15.7189624 144.416064 6.93718962 147.713443 6.93718962 152.642573 13.6393692 157.60671 6.93718962 160.727928 6.93718962 154.203182 15.6116728 161.543239 25.4321008 158.245859 25.4321008 152.571431 17.6547418 146.82699 25.4321008z"
            />
            <g transform="translate(162.608 5.992)">
              <mask id="mask-4" fill="#fff">
                <use xlinkHref="#path-3" />
              </mask>
              <path
                fill="#6F625A"
                d="M3.799 2.553h-.674v.965h.636c.356 0 .524-.227.524-.473 0-.284-.168-.492-.486-.492zm-1.441 2.97V1.967H3.93c.636 0 1.104.379 1.104 1.04 0 .398-.244.738-.618.946l.917 1.57h-.842l-.805-1.419h-.561v1.42h-.767zM3.686 6.79c1.647 0 2.919-1.286 2.919-3.026 0-1.74-1.272-3.027-2.92-3.027C2.06.737.806 2.024.806 3.764c0 1.74 1.254 3.026 2.88 3.026zm0 .737C1.646 7.527 0 5.883 0 3.764 0 1.646 1.646 0 3.686 0s3.706 1.646 3.706 3.764c0 2.119-1.666 3.763-3.706 3.763z"
                mask="url(#mask-4)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};