import {
  APPLICATION_CONTENT_REQUEST,
  APPLICATION_CONTENT_SUCCESS,
  APPLICATION_CONTENT_FAILURE,
} from "../actions/applicationContent";

export function applicationContent(
  state = {
    isFetching: false,
    applicationContent: "",
  },
  action
) {
  switch (action.type) {
    case APPLICATION_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        applicationContent: "",
      });
    case APPLICATION_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        applicationContent: action.data,
      });
    case APPLICATION_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default applicationContent;
