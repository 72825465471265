import {
  getOidAuthentication,
  getMSIDAuthentication,
} from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
import Cookies from "universal-cookie";

import { onLogoutUser } from "./logout";
export const OID_LOGIN_REQUEST = "OID_LOGIN_REQUEST";
export const OID_LOGIN_SUCCESS = "OID_LOGIN_SUCCESS";
export const OID_LOGIN_FAILURE = "OID_LOGIN_FAILURE";

const cookies = new Cookies();

export function requestOidLogin() {
  return {
    type: OID_LOGIN_REQUEST,
    isFetching: true,
  };
}

export function receiveOidLogin(tokenData, type) {
  if (tokenData) {
    setSessionsStorage(
      tokenData.accessToken,
      tokenData.refreshToken,
      tokenData.idToken,
      type,
      tokenData.sessionId
    );
    return {
      type: OID_LOGIN_SUCCESS,
      isFetching: false,
      accessToken: tokenData.accessToken,
    };
  }
  return null;
}

export function setSessionsStorage(
  accessToken,
  refreshToken,
  idToken,
  type,
  sessionId
) {
  // cookies.set('access_token', accessToken,{HttpOnly: true});
  sessionStorage.setItem("session_id", sessionId);
  if (type === "ms") {
    sessionStorage.setItem("type", "msid");
  } else {
    sessionStorage.setItem("type", "oid");
  }
}

export function oidLoginFailure(message) {
  return {
    type: OID_LOGIN_FAILURE,
    isFetching: false,
    message,
  };
}

export function onOidLoginUser(code, type, userType, clientName) {
  return (dispatch) => {
    dispatch(requestOidLogin());
    const url = getOidAuthentication(code, userType, clientName);
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(receiveOidLogin(data, type));
        } else if (response.status === 500) {
          dispatch(onLogoutUser());
        } else {
          dispatch(oidLoginFailure(data.message));
          return Promise.reject(data);
        }
        return data;
      })
      .catch(function (error) {
        dispatch(oidLoginFailure(error));
        dispatch(onLogoutUser());
      });
  };
}

export function onMSIDLoginUser(code, type, userType, clientName) {
  return (dispatch) => {
    dispatch(requestOidLogin());
    const url = getMSIDAuthentication(code, userType, clientName);
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(receiveOidLogin(data, type));
        } else if (response.status === 500) {
          dispatch(onLogoutUser());
        } else {
          dispatch(oidLoginFailure(data.message));
          return Promise.reject(data);
        }
        return data;
      })
      .catch(function (error) {
        dispatch(oidLoginFailure(error));
        dispatch(onLogoutUser());
      });
  };
}
