import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Box, makeStyles } from "@material-ui/core";

export default function RadioButtonsGroup({
  selectedValue,
  setSelectedValue,
  radioOptions,
  formLabel,
  color = "primary",
  row = false,
}) {
  const useStyles = makeStyles((theme) => ({
    label: {
      fontWeight: theme.typography.h3.fontWeight,
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.h3.lineHeight,
      color: "#5A5A57A",
    },
  }));

  const classes = useStyles();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel color="#0000" component="legend" className={classes.label}>
        {formLabel}
      </FormLabel>
      <RadioGroup
        aria-label={formLabel}
        name={formLabel}
        value={selectedValue}
        onChange={handleChange}
        row={row}
        data-testid={"radio-group"}
      >
        {radioOptions.map((value, index) => {
          return (
            <Box sx={{ my: "5px" }}>
              <FormControlLabel
                value={value}
                control={
                  <Radio
                    color={color}
                    inputProps={{
                      "data-testid": `tier-${index}`,
                    }}
                  />
                }
                label={value}
                name={value}
              />
            </Box>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
