import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import ErrorModal from "../../ErrorModal/ErrorModal";

class ApplicationQuickLaunch extends Component {
  constructor() {
    super();
    this.state = {
      error: false,
      openModal: false,
      errorMsg: "",
    };
  }

  componentDidMount() {
    this.setData(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setData(newProps);
  }

  setData(props) {
    const { userProfile } = props;
    if (userProfile && userProfile.opData) {
      this.makeRequest(userProfile.opData, userProfile.profileData);
    }
  }

  makeRequest(opData, profileData) {
    var {
      firstName,
      lastName,
      email,
      userID,
      clientName,
      carrierID,
      role,
      userLocation,
    } = this.findPALiteRequestData(profileData, opData);
    if (
      firstName &&
      lastName &&
      email &&
      userID &&
      clientName &&
      carrierID &&
      role &&
      userLocation
    ) {
      const req = {};
      req["userID"] = userID;
      req["firstName"] = firstName;
      req["lastName"] = lastName;
      req["email"] = email;
      req["clientName"] = clientName;
      req["carrierID"] = carrierID;
      req["role"] = role;
      req["userLocation"] = userLocation;
      this.setState({ error: false });
      return req;
    } else {
      /*  const req = {};
       req["User ID"] = userID;
       req["First Name"] = firstName;
       req["Last Name"] = lastName;
       req["Email"] = email;
       req["Client Name"] = clientName;
       req["Carrier ID"] = carrierID;
       req["Role"] = role;
       req["User Location"] = userLocation;
       const errData = this.checkProperties(req);
       const errorMsg = errData + " is missing for SSO."; */
      const errorMsg =
        "Access denied. Please contact your OptumRx Client management account team for assistance.";
      this.setState({ error: true, errorMsg });
    }
    return null;
  }

  checkProperties(obj) {
    const arr = [];
    for (var key in obj) {
      if (obj[key] === null || obj[key] === "") {
        arr.push(key);
      }
    }
    if (arr && arr.length > 0) {
      return arr.join(", ");
    }
    return null;
  }

  findPALiteRequestData(profileData, opData) {
    var firstName = profileData.given_name;
    var lastName = profileData.family_name;
    var email = opData.workEmail;
    var userID = "";
    var clientName = "";
    var carrierID = "";
    var role = "";
    var userLocation = "";
    if (opData && opData.userIdentifiers && opData.userIdentifiers.length > 0) {
      userID = this.findDataByID(opData.userIdentifiers, "Optum ID");
      clientName = this.findDataByID(opData.userIdentifiers, "u_client");
      carrierID = this.findDataByID(opData.userIdentifiers, "u_carrier");
      role = this.findDataByID(opData.userIdentifiers, "u_pa_roles");
      userLocation = this.findDataByID(
        opData.userIdentifiers,
        "u_pa_user_location"
      );
    }
    return {
      firstName,
      lastName,
      email,
      userID,
      clientName,
      carrierID,
      role,
      userLocation,
    };
  }

  findDataByID(identifiers, id) {
    const ids = identifiers.find((i) => i && i["name"] === id);
    if (ids) {
      return ids.value;
    } else {
      return null;
    }
  }

  handleClicked(item) {
    const { selectedAccount } = this.props;
    if (item["pqlname"] === "RxClaim (IDA)") {
      window.open("/product/launch/?url=" + item["pqlurl"], "_blank");
    } else if (item["pqlname"] === "PA Portal") {
      if (!this.state.error) {
        var winPA = window.open("/home/sso/launch/paportal", "_blank");
        winPA.focus();
      } else {
        this.setState({ openModal: true });
      }
      // } else if (
      //   item["pqlname"] === "RxTrack" ||
      //   item["pqlname"] === "RxTrack IDW" ||
      //   item["pqlname"] === "RxTrack Confidential"
      // ) {
      //   var winRx = window.open(
      //     `/home/rxtrackSSO/launch/${item.pqlname}`,
      //     "_blank"
      //   );
      //   winRx.focus();
    } else {
      if (item["pqlname"] && item["pqlname"].includes("RxClaim")) {
        // if (item["pqlname"] === "RxClaim Web RXDV1" || item["pqlname"] === "RxClaim Web RXCL1TST" || item["pqlname"] === "RxClaim Web") {
        sessionStorage.setItem("acc_id", selectedAccount && selectedAccount.u_account);
        sessionStorage.setItem("QLApp", item["pqlname"]);
      }
      window.open(item["pqlurl"], "_blank");
    }
  }

  handleClose() {
    this.setState({ openModal: false });
  }

  render() {
    const { title, quickLaunchList } = this.props;
    const { openModal, errorMsg } = this.state;
    return (
      <React.Fragment>
        <article data-testid="appQuickLaunch">
          <h6>{title}</h6>
          <div className="content">
            <div className="list">
              {quickLaunchList &&
                quickLaunchList.map((item) => (
                  <ul key={item.analyticsTrackingName}>
                    <li>
                      <Link
                        to="#"
                        link-id={item.pqlname}
                        onClick={
                          // item["pqlname"] === "RxClaim (IDA)"
                          //   ? "/product/launch/?url=" + item["pqlurl"]
                          //   : item["pqlname"] === "RxTrack"
                          //   ? "/home/rxtrackSSO/launch/" + item.pqlname
                          //   : item["pqlname"] === "RxTrack Confidential"
                          //   ? "/home/rxtrackSSO/launch/" + item.pqlname
                          //   : item["pqlurl"]
                          () => this.handleClicked(item)
                        }
                      >
                        {item.pqlname}
                      </Link>
                    </li>
                  </ul>
                ))}
            </div>
          </div>
          <div data-testid="errModal">
            {openModal && (
              <ErrorModal
                title={"Unable to Access PA Portal"}
                responseMessage={errorMsg}
                openModal={openModal}
                handleClose={this.handleClose.bind(this)}
              />
            )}
          </div>
        </article>
      </React.Fragment>

    );
  }
}

const mapStateToProps = ({ paLite, userProfile }) => {
  return { paLite, userProfile };
};

export default connect(mapStateToProps)(withRouter(ApplicationQuickLaunch));
