import React from "react";

import Iframe from "react-iframe";
const LaunchProduct = ({ launchURL }) => {
  return (
    <React.Fragment>
      <main className="divider rPad">
        <div className="container">
          <div className="row">
            <Iframe
              name="theFrame"
              id="theFrame"
              width="880px"
              height="700px"
              className="marT30"
              url={launchURL}
            />
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default LaunchProduct;
