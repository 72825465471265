import produce from "immer";
import objectPath from "object-path";
import {
  FILETREE_CONTENT_REQUEST,
  FILETREE_SUCCESS_EMPTY,
  FILETREE_CONTENT_SUCCESS,
  FILETREE_CONTENT_RESET, FILETREE_CONTENT_REQUEST_ERROR
} from "../actions/fileTree";

function fileTreeContent(
  state = {
    isFetching: false,
    fileTreeContent: null,
    error: false,
    errorMessage: null
  },
  action
) {
  switch (action.type) {
    case FILETREE_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        error: false,
        errorMessage: null
      });

    case FILETREE_SUCCESS_EMPTY:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        error: false,
        errorMessage: null
      });

    case FILETREE_CONTENT_SUCCESS:
      var nextState = produce(state, draftState => {
        draftState.isFetching = false;
        // Modification for nested files and folders
        if (action.isRootResponse) {
          draftState.fileTreeContent = action.childData;
        } else {
          objectPath.set(
            draftState.fileTreeContent && draftState.fileTreeContent.foldersObject,
            `${action.parentKey}`,
            action.childData
          );
        }
      });
      return nextState;

    case FILETREE_CONTENT_REQUEST_ERROR:
      return {
        isFetching: false,
        isFetched: false,
        error: true,
        errorMessage: action.errorMessage
      };

    case FILETREE_CONTENT_RESET:
      return {
        isFetching: false,
        fileTreeContent: null
      };

    default:
      return state;
  }
}

export default fileTreeContent;
