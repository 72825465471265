import React, { Component } from "react";
import { connect } from "react-redux";
import { extractURL } from "../utils/url-utils";
import {
  getClinicalUpdate,
  getDrugRecalls,
  getDrugApprovals,
  getDrugSafety,
  getNewGenerics,
  getRxHighlights,
  getRxOutlook
} from "../actions/clinicalPublicationAEM";
import ClinicalPublicationDetail from "../components/ClinicalServices/ClinicalPublicationDetail/ClinicalPublicationDetail";
import { paginate } from "../utils/paginate";
import Pagination from "../components/Shared/Pagination/Pagination";

class PublicationAEMContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      key: "",
      pageSize: 12,
      currentPage: 1,
      cpMetaData: "",
      contentURL: "",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getClinicalPublicationAEM(nextProps);
  }

  componentDidMount() {
    if (this.props) {
      this.getClinicalPublicationAEM(this.props);
    }
  }
  handlePageChange = page => {
    if (page) {
      this.setState({ currentPage: page });
    }
  };

  getClinicalPublicationAEM(nextProps) {
    this.setState({ currentPage: 1 });
    const {
      subType,
      type,
      clinicalPublicationAEM,
      clinicalPublicationMetaData,
      publicationkey
    } = nextProps;
    const { key } = this.state;
   
    if (
      clinicalPublicationMetaData &&
      !clinicalPublicationMetaData.isFetching
    ) {
      
      const data = this.configData(
        publicationkey,
        clinicalPublicationMetaData.data
      );
      if (data) {
        if (data.key !== this.state.cpMetaData.key) {
          this.setState({
            cpMetaData: data
          });
        }
      }
      const { cpMetaData } = this.state;
      if (cpMetaData && cpMetaData.value) {
        const publicationURL = extractURL(cpMetaData.value.field_body_secondary);
        this.setState({ contentURL: publicationURL });
      }
    }

    if (publicationkey) {
      if (clinicalPublicationAEM) {
        const data = this.configData(
          publicationkey,
          clinicalPublicationAEM.data
        );
        if (data) {
          if (
            data &&
            data.value &&
            data.value.resources &&
            data.value.resources.length > 0 &&
            data.key !== key
          ) {
            this.setState({ data: data.value.resources, key: data.key });
          }
        } else {
          this.getDataFromType(subType, type, publicationkey);
        }
      }
    }
  }

  getKey(subType, type) {
    if (subType) {
      return type + "_" + subType;
    }
    return type;
  }

  getDataFromType(subType, type, key) {
    switch (type) {
      case "RxHighlight":
        this.props.dispatch(getRxHighlights(subType, type, key));
        break;
      case "RxOutlook":
        this.props.dispatch(getRxOutlook(subType, type, key));
        break;
      case "RxNews":
        this.getRxNewsData(subType, type, key);
        break;
      default:
    }
  }

  getRxNewsData(subType, type, key) {
    switch (subType) {
      case "clinicalUpdates":
        this.props.dispatch(getClinicalUpdate(subType, type, key));
        break;
      case "drugRecalls":
        this.props.dispatch(getDrugRecalls(subType, type, key));
        break;
      case "drugSafety":
        this.props.dispatch(getDrugSafety(subType, type, key));
        break;
      case "drugApprovals":
        this.props.dispatch(getDrugApprovals(subType, type, key));
        break;
      case "newGenerics":
        this.props.dispatch(getNewGenerics(subType, type, key));
        break;
      default:
    }
  }

  configData(key, data) {
    if (data && data.length > 0) {
      return data.find(item => {
        return item.key === key;
      });
    }
    return null;
  }

  render() {
    const { data, currentPage, pageSize, contentURL } = this.state;
    const paginateData = paginate(data, currentPage, pageSize);
    if (data && data.length > 0) {
      return (
        <React.Fragment>
          <ClinicalPublicationDetail response={paginateData} contentUrl={contentURL}/>
          {data.length > pageSize && (
            <Pagination
              itemCount={data.length}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          )}
        </React.Fragment>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ clinicalPublicationAEM, auth, clinicalPublicationMetaData }) => {
  return { clinicalPublicationAEM, auth, clinicalPublicationMetaData };
};

export default connect(mapStateToProps)(PublicationAEMContainer);
