import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import "./ClientDropdown.scss";

class ClientDropdown extends Component {
  state = { dropdownData: [], selectedValue: "" };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.clientConfiguration(nextProps);
  }

  componentDidMount() {
    this.clientConfiguration(this.props);
  }

  clientConfiguration(nextProps) {
    if (nextProps.dropdownData) {
      const { dropdownData, selectedValue } = nextProps;
      if (this.state.dropdownData.length !== dropdownData.length) {
        this.setState({ dropdownData });
      }
      if (this.state.selectedValue !== selectedValue) {
        this.setState({ selectedValue });
      }
    }
  }

  render() {
    const { dropdownData, selectedValue } = this.state;
    const { onItemChange, isErrorVisible } = this.props;
    if (dropdownData && dropdownData.length > 0) {
      return (
        <div className="headerForm">
          <FormControl className="clientForm">
            <aside>
              <label>Client</label>
              <div className={isErrorVisible ? "select Selecterror" : ""}>
                <fieldset>
                  <StyledSelect
                    value={selectedValue}
                    onChange={onItemChange("client")}
                    className="ClientDropdown"
                    MenuProps={{
                      className: "ClientDropdownMenu",
                      elevation: 0,
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center"
                      }
                    }}
                  >
                    {dropdownData.map(item => (
                      <StyledMenuItem key={item["id"]} value={item["id"]}>
                        {item.displayName}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </fieldset>
              </div>
            </aside>
          </FormControl>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ getClients }) => {
  return { getClients };
};

const StyledSelect = withStyles({
  select: {
    border: "1px solid #cccccc",
    borderRadius: "3px",
    color: "#333741",
    fontFamily: '"frutiger-light", Arial, sans-serif',
    fontSize: "14px",
    padding: "6px 20px 6px 10px",
    width: "185px",
    "&:focus": {
      background: "none",
      borderRadius: "3px"
    }
  },
  icon: {
    color: "#cccccc"
  }
})(Select);

const StyledMenuItem = withStyles({
  root: {
    color: "#333333",
    fontFamily: '"Open Sans", Arial, sans-serif',
    fontSize: "13px",
    height: "unset",
    lineHeight: "18px",
    minHeight: "18px",
    padding: "5px 10px",
    whiteSpace: "normal",
    "&:hover": {
      background: "#eaeaea"
    }
  },
  selected: {
    background: "#f3f3f3 !important"
  }
})(MenuItem);

export default connect(mapStateToProps)(ClientDropdown);
