import { fetchGenerateReports } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
import globalConstants from "../constants/global-constants";
export const GET_GENERATED_REPORT_LINK_SUCCESS =
  "GET_GENERATED_REPORT_LINK_SUCCESS";

export function requestGenerateReports(accountId, linkId) {
  const GenerateReports = {};
  GenerateReports["accountId"] = accountId;
  GenerateReports["linkId"] = linkId;
  GenerateReports["isFetching"] = true;
  GenerateReports["isError"] = false;
  return {
    type: GET_GENERATED_REPORT_LINK_SUCCESS,
    data: GenerateReports,
  };
}

export function receiveGenerateReports(res, accountId, linkId) {
  const GenerateReports = {};
  GenerateReports["generatedLink"] = res;
  GenerateReports["accountId"] = accountId;
  GenerateReports["linkId"] = linkId;
  GenerateReports["isFetching"] = false;
  GenerateReports["isError"] = false;
  return {
    type: GET_GENERATED_REPORT_LINK_SUCCESS,
    data: GenerateReports,
  };
}

export function GenerateReportsFailure(message, accountId, linkId) {
  const GenerateReports = {};
  GenerateReports["accountId"] = accountId;
  GenerateReports["linkId"] = linkId;
  GenerateReports["generatedLink"] = message;
  GenerateReports["isFetching"] = false;
  GenerateReports["isError"] = true;
  return {
    type: GET_GENERATED_REPORT_LINK_SUCCESS,
    data: GenerateReports,
    message,
  };
}

export function getGenerateReportsData(accountId, linkId) {
  return (dispatch) => {
    dispatch(requestGenerateReports(accountId, linkId));
    const url = fetchGenerateReports(accountId, linkId);
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(receiveGenerateReports(data, accountId, linkId));
          return data;
        } else {
          dispatch(
            GenerateReportsFailure(
              globalConstants.UNABLETOGETTEXT,
              accountId,
              linkId
            )
          );
          return Promise.reject(data);
        }
      })
      .catch(function (error) {
        dispatch(
          GenerateReportsFailure(
            globalConstants.UNABLETOGETTEXT,
            accountId,
            linkId
          )
        );
      });
  };
}
