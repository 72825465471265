import { GET_REPORT_LINKS_SUCCESS } from "../actions/reportLinks";

export function getReportLinks(
  state = {
    isFetching: false,
    reportLinks: [],
  },
  action
) {
  switch (action.type) {
    case GET_REPORT_LINKS_SUCCESS:
      const data = state.reportLinks;
      const index =
        data &&
        data.findIndex(
          (item) => item && item.accountId === action.data.accountId
        );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        isFetching: false,
        reportLinks: data,
      };
    default:
      return state;
  }
}

export default getReportLinks;
