import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const GET_RELATEDLINK_FORMULARY_LIST_REQUEST =
  "GET_RELATEDLINK_FORMULARY_LIST_REQUEST";
export const GET_RELATEDLINK_FORMULARY_LIST_SUCCESS =
  "GET_RELATEDLINK_FORMULARY_LIST_SUCCESS";
export const GET_RELATEDLINK_FORMULARY_LIST_FAILURE =
  "GET_RELATEDLINK_FORMULARY_LIST_FAILURE";

export function requestFormularyList() {
  return {
    type: GET_RELATEDLINK_FORMULARY_LIST_REQUEST,
  };
}

export function receiveFormularyList(data) {
  return {
    type: GET_RELATEDLINK_FORMULARY_LIST_SUCCESS,
    data,
  };
}

export function formularyListFailure(message) {
  return {
    type: GET_RELATEDLINK_FORMULARY_LIST_FAILURE,
    message,
  };
}

export function getFormularyList() {
  return (dispatch) => {
    dispatch(requestFormularyList());
    const url = fetchGeneralContentUrl("/aem/formularyList");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(formularyListFailure(data.message));
          return Promise.reject(data);
        } else {
          if (data && data.resources) {
            dispatch(receiveFormularyList(data));
          }
          return data;
        }
      })
      .catch(function (error) {
        dispatch(formularyListFailure(error));
      });
  };
}
