import React from "react";
import { connect } from "react-redux";
import InputForm from "../Shared/InputForm/InputForm";
import Label from "../../constants/labels";
import Message from "../../constants/messages";
import {
  getMemIdContent,
  getGeneratedSSNID,
} from "../../actions/memberIdGenerator";
import Content from "../Shared/Content/Content";
import FollowUsContainer from "../../containers/FollowUsContainer";
import PreLoginFooterContainer from "../../containers/PreLoginFooterContainer";

const CONSTANTS = {
  CLIENTACCESSCODE: "clientAccessCode",
  MEMBERID: "memberId",
};

class MemberIdGenerator extends InputForm {
  constructor(props) {
    super(props);
    this.initialState = {
      data: {
        clientAccessCode: "",
        memberId: "",
      },
      errors: {},
      memberIdGeneratorContent: "",
      generatedSSNId: "",
    };
    this.resettableFieldsInitialState = {
      data: {
        clientAccessCode: "",
        memberId: "",
      },
      errors: {},
    };
    this.state = this.initialState;
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setMemberIDContent(nextprops);
    if (nextprops.generatedSSNId) {
      this.setState({ generatedSSNId: nextprops.generatedSSNId });
    }
  }

  componentDidMount() {
    document.title = "Client Portal - Member Id generator";
    this.setMemberIDContent(this.props);
  }

  setMemberIDContent(props) {
    const { isFetching, memberIdContent, isError } = props;
    if (!isError) {
      if (!isFetching) {
        if (memberIdContent) {
          if (memberIdContent !== this.state.memberIdGeneratorContent) {
            this.setState({ memberIdGeneratorContent: memberIdContent[0] });
          }
        } else {
          this.props.dispatch(getMemIdContent());
        }
      }
    }
  }

  resetState = () => {
    this.setState({ ...this.resettableFieldsInitialState, generatedSSNId: "" });
  };

  validate = () => {
    const errors = {};
    if (this.state.data.clientAccessCode.trim() === "") {
      errors.clientAccessCode = Message.MemberIDForm.ClientAccessCode.Required;
    }
    if (this.state.data.memberId.trim() === "") {
      errors.memberId = Message.MemberIDForm.MemberId.Required;
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };

  validateProperty = ({ name, value }) => {
    if (name === CONSTANTS.CLIENTACCESSCODE) {
      if (value.trim() === "") {
        return Message.MemberIDForm.ClientAccessCode.Required;
      }
    }
    if (name === CONSTANTS.MEMBERID) {
      if (value.trim() === "") {
        return Message.MemberIDForm.MemberId.Required;
      }

    }
    return null;
  };

  onChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (errorMessage) {
      errors[input.name] = errorMessage;
    }

    else { delete errors[input.name]; }
    const data = { ...this.state.data };
    data[input.name] = input.value;
    if (input.name === CONSTANTS.MEMBERID) {
      if (/^\d+$/.test(input.value)) {
        data[input.name] = input.value;
        this.setState({ data, errors });
      } else {
        data[input.name] = "";
        this.setState({ data, errors });
      }
    }
    this.setState({ data, errors });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) {
      window.scrollTo(0, 0);
    }
    if (errors) {
      return errors;
    }
    this.doSubmit();
    return null;
  };

  doSubmit = () => {
    const { data } = this.state;
    if (data.clientAccessCode && data.memberId) {
      const req = {};
      req["accessCode"] = data.clientAccessCode;
      req["ssnId"] = data.memberId;
      if (req) {
        this.props.dispatch(getGeneratedSSNID(req));
      }
    }
  };

  render() {
    const { clientAccessCode, memberId } = this.state.data;
    const { memberIdGeneratorContent, generatedSSNId, errors } = this.state;
    const { auth } = this.props;
    return (
      <main className="divider rPad">
        <div className="container">
          <div id="loginContainer" className="body-text">
            <h1>
              <Content html={memberIdGeneratorContent.title} />
            </h1>
            <Content html={memberIdGeneratorContent.body} />
            <form onSubmit={this.onSubmit}>
              <div className="row-">
                <div className="col-md-12 marT20">
                  <div className="row-">
                    <fieldset className="col-sm-12 marT10 marB25 clearfix">
                      <label
                        className="marB5 w100 floatL f14"
                      // style={{fontFamily:"Frutiger-Light", Arial, sans-serif; color: #505152;'}}
                      >
                        {Label.CLIENT_ACCESS_CODE}:
                      </label>
                      <input
                        name={CONSTANTS.CLIENTACCESSCODE}
                        value={clientAccessCode}
                        onChange={this.onChange}
                        type="text"
                        style={{ width: "285px" }}
                        className={
                          errors[CONSTANTS.CLIENTACCESSCODE] && "error"
                        }
                      />
                      {errors[CONSTANTS.CLIENTACCESSCODE] && (
                        <p
                          id="fn_error"
                          className="cux-icon-warning_filled error pad0 padT5 txtSub marB0"
                          style={{ display: "block" }}
                        >
                          {errors[CONSTANTS.CLIENTACCESSCODE]}
                        </p>
                      )}
                      {generatedSSNId.messageType === "0" &&
                        generatedSSNId.message ===
                        "This is an invalid client access code. Please re-enter your code." && (
                          <p
                            id="fn_error"
                            className="cux-icon-warning_filled error pad0 padT5 txtSub marB0"
                            style={{ display: "block" }}
                          >
                            {generatedSSNId.message}
                          </p>
                        )}
                    </fieldset>

                    <fieldset className="col-sm-12 marB35 clearfix">
                      <label
                        className="marB5 w100 floatL f14"
                      // style='font-family:"Frutiger-Light", Arial, sans-serif; color: #505152;'
                      >
                        {Label.SSN_MEMBER_ID}:
                      </label>
                      <input
                        name={CONSTANTS.MEMBERID}
                        value={memberId}
                        onChange={this.onChange}
                        type="text"
                        style={{ width: "285px" }}
                        maxLength="9"
                        className={errors[CONSTANTS.MEMBERID] && "error"}
                      />
                      {errors[CONSTANTS.MEMBERID] && (
                        <p
                          id="ln_error"
                          className="cux-icon-warning_filled error pad0 padT5 txtSub marB0"
                          style={{ display: "block" }}
                        >
                          {errors[CONSTANTS.MEMBERID]}
                        </p>
                      )}
                      {generatedSSNId.messageType === "0" &&
                        generatedSSNId.message ===
                        "This number is not a SSN." && (
                          <p
                            id="fn_error"
                            className="cux-icon-warning_filled error pad0 padT5 txtSub marB0"
                            style={{ display: "block" }}
                          >
                            {generatedSSNId.message}
                          </p>
                        )}
                    </fieldset>
                    <br />
                    <fieldset className="col-sm-12 marB40  clearfix">
                      <input
                        link-id={"member id generator - reset"}
                        type="reset"
                        onClick={this.resetState}
                        className="button button-primary button-reverse marT10 noCap"
                      />
                      <input
                        link-id={"member id generator - submit"}
                        type="submit"
                        className="button button-primary marT10 noCap"
                        value="Submit"
                      />
                    </fieldset>

                    {/* <br />
                        The New ID For this Member will be:
                        <br />
                        878787878 */}
                    {generatedSSNId.messageType === "1" && (
                      <div id="successMsg" className="b  padL15 pad0 padB30">
                        <Content html={generatedSSNId.message} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          {auth && !auth.isAuthenticated && <FollowUsContainer />}
          {auth && !auth.isAuthenticated && <PreLoginFooterContainer />}
        </div>
      </main>
    );
  }
}

const mapStateToProps = ({ memberIdContent, auth }) => {
  return { ...memberIdContent, auth };
};

export default connect(mapStateToProps)(MemberIdGenerator);
