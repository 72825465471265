export default {
  LoginContent: {
    ClickHere: "https://www.optum.com/solutions/optumrx.html"
  },
  FollowUsContent: {
    LinkedIn: "https://www.linkedin.com/company/optum",
    YouTube: "http://www.youtube.com/optum/",
    Twitter: "http://www.twitter.com/optumrx/"
  },
  PreLoginFooter: {
    OptumLogo: "https://www.optum.com",
    HealthcareProfessionals: "https://professionals.optumrx.com",
    Members: "https://www.optumrx.com/public/landing",
    Consultants: "https://www.optum.com/solutions/optumrx/experts.html",
    MemberForms:
      "https://www.optumrx.com/public/information-center/public-forms",
    DrugRecallsAndFDAAlerts:
      "https://professionals.optumrx.com/publications/rx-news.html?tagid=professionals-optumrx%3Anewstopics%2Fdrugrecalls",
    AboutUs: "https://www.optum.com/about.html",
    ContactUs: "https://www.optum.com/contact.html",
    Careers: "https://www.optum.com/about/careers.html",
    News: "https://www.optum.com/about/news.html"
  },
  TermsOfUse: {
    DMCARegisteredAgent: "mailto:DMCARegisteredAgent2@optum.com"
  },
  PrivacyPolicy: {
    HIPPANotice:
      "https://www.optumrx.com/content/dam/rxmember/PrivacyandTermsofUse/ORX3861B_161215_20170101PrivacyBooklet_8.pdf",
    FlashMangementTools: "http://www.adobe.com/devnet/security.html",
    AboutAdsConsumers: "http://www.aboutads.info/consumers",
    AboutAdsChoices: "http://www.aboutads.info/choices"
  },
  Footer: {
    AboutUs: "https://www.optum.com/about.html"
  }
};
