import {
  GETBILLING_ENTITY_REQUEST,
  GETBILLING_ENTITY_SUCCESS,
  GETBILLING_ENTITY_FAILURE,
} from "../actions/getBillingEntity";

export function getBillingEntity(
  state = {
    isFetching: false,
    billingEntity: [],
  },
  action
) {
  switch (action.type) {
    case GETBILLING_ENTITY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case GETBILLING_ENTITY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        billingEntity: action.data,
      });

    case GETBILLING_ENTITY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default getBillingEntity;
