import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { getClinicalServicesPart } from "../actions/clinicalServices";
import ClinicalServices from "../components/ClinicalServices/ClinicalServices";
import PublicationsDetailContainer from "./PublicationsDetailContainer";
import CSRelatedLinksContainer from "./CSRelatedLinksContainer";
import { isInternal } from "../constants/service";
import globalConstants from "../constants/global-constants";
import routePath from "../constants/routes";

class ClinicalServicesContainer extends Component {
  state = {
    clinicalServices: "",
  };
  UNSAFE_componentWillReceiveProps(newProps) {
    this.getClinicalServicesData(newProps);
  }

  componentDidMount() {
    document.title = "Client Portal - Clinical Services";
    this.getClinicalServicesData(this.props);
  }

  getClinicalServicesData(props) {
    const { selectedAccount, selectedMode, user, clinicalServices } = props;
    if (isInternal(user)) {
      this.configureForInternalUser(clinicalServices, selectedMode);
    } else {
      this.configuredForAccounts(selectedAccount, clinicalServices);
    }
  }

  configureForInternalUser(clinicalServices, selectedMode) {
    const clinicalServicesInfo = this.configClinicalServices(
      selectedMode,
      clinicalServices.clinicalServices
    );
    if (clinicalServicesInfo) {
      this.setState({
        clinicalServices: clinicalServicesInfo,
      });
    } else {
      this.props.dispatch(getClinicalServicesPart(selectedMode))
    }
  }

  configuredForAccounts(selectedAccount, clinicalServices) {
    if (selectedAccount) {
      const pageType =
        selectedAccount[globalConstants.NAVIGATION_CLINICAL_SERVICE_TAB];
      if (clinicalServices) {
        const clinicalServicesInfo = this.configClinicalServices(
          selectedAccount[globalConstants.NAVIGATION_CLINICAL_SERVICE_TAB],
          clinicalServices.clinicalServices
        );
        if (clinicalServicesInfo) {
          this.setState({
            clinicalServices: clinicalServicesInfo,
          });
        } else {
          this.props.dispatch(getClinicalServicesPart(pageType))
        }
      }
    }
  }

  configClinicalServices(pageType, clinicalServices) {
    return (
      clinicalServices &&
      clinicalServices.length > 0 &&
      clinicalServices.find((item) => {
        return item && item.pageType === pageType;
      })
    );
  }

  render() {
    const { clinicalServices } = this.state;
    var clinicalServicesData = [];
    if (
      clinicalServices &&
      clinicalServices.value &&
      clinicalServices.value.length > 0
    ) {
      clinicalServicesData = clinicalServices.value;
      const { location } = this.props;
      let isVisible;
      if (
        location.pathname.match(routePath.clinicalPublication) ||
        location.pathname.match(routePath.relatedLinkCS)
      ) {
        isVisible = false;
      } else {
        isVisible = true;
      }
      return (
        <React.Fragment>
          {isVisible && <ClinicalServices contentData={clinicalServicesData} />}
          {!isVisible && (
            <Switch>
              <Route
                path={routePath.clinicalPublicationWithType}
                render={(props) => <PublicationsDetailContainer {...props} />}
              />
              <Route
                path={routePath.relatedLinksCSWithType}
                render={(props) => <CSRelatedLinksContainer {...props} />}
              />
            </Switch>
          )}
        </React.Fragment>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ userAccount, clinicalServices, user, auth }) => {
  return {
    selectedMode: userAccount.selectedMode,
    selectedAccount: userAccount.selectedAccount,
    clinicalServices,
    user,
    auth,
  };
};

export default connect(mapStateToProps)(ClinicalServicesContainer);
