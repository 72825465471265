import { Chip } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import { ChipStyles } from "./ChipStyles";

const ChipWithIcon = ({
  label,
  selected = false,
  handleSelection,
  value,
  size,
}) => {
  const Icon = selected ? CloseIcon : Add;
  const color = selected ? "primary" : "default";
  const classes = ChipStyles();
  const dataTestId = selected ? "cross" : "plus";

  return (
    <Chip
      icon={<Icon className={classes.icon} data-testid={dataTestId} />}
      clickable
      color={color}
      size={size}
      label={label}
      onClick={() => handleSelection(value)}
      className={classes.root}
      data-testId={"chip"}
    />
  );
};

ChipWithIcon.propTypes = {
  label: PropTypes.string.isRequired,
};
export default ChipWithIcon;
