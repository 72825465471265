import { ssoUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const REQUEST_SSO_REQUEST = "REQUEST_SSO_REQUEST";
export const REQUEST_SSO_SUCCESS = "REQUEST_SSO_SUCCESS";
export const REQUEST_SSO_FAILURE = "REQUEST_SSO_FAILURE";

export function requestRequestSSO() {
  return {
    type: REQUEST_SSO_REQUEST,
    isFetched: false,
  };
}

export function receiveRequestSSO(data) {
  return {
    type: REQUEST_SSO_SUCCESS,
    isFetched: true,
    data,
  };
}

export function RequestSSOFailure(message) {
  return {
    type: REQUEST_SSO_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getSSOData() {
  return (dispatch) => {
    dispatch(requestRequestSSO());
    const url = ssoUrl();
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(receiveRequestSSO(data));
          return data;
        } else {
          dispatch(RequestSSOFailure(data.message));
          return Promise.reject(data);
        }
      })
      .catch(function (error) {
        dispatch(RequestSSOFailure(error));
      });
  };
}
