import React, { Component } from "react";
import {
  onOidLoginUser,
  onMSIDLoginUser,
} from "../../actions/oidAuthentication";
import { connect } from "react-redux";
import { onLogoutUser } from "../../actions/logout";
import routePath from "../../constants/routes";
import Cookies from "universal-cookie";

const cookies = new Cookies();

var URLSearchParams = require("url-search-params");

class OidResponse extends Component {
  state = {
    code: "",
    state: "",
    type: "",
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (sessionStorage.getItem("session_id")) {
      // if (cookies.get('access_token')) {
      props.history.replace("/home");
    }
  }

  componentDidMount() {
    const {
      match: { params },
      whiteLabel,
      setApplicationContext,
    } = this.props;
    const type = params.type;
    const { location } = this.props;
    const parameter = new URLSearchParams(location.search);
    const code = parameter.get("code");
    const pingtoken = parameter.get("_alpsWsTkn");
    if (pingtoken) {
      sessionStorage.setItem("_alpsWsTkn", pingtoken);
    }
    const state = parameter.get("state");
    const clientName =
      whiteLabel && setApplicationContext && setApplicationContext.basename
        ? setApplicationContext.basename
        : "default";
    const userType = whiteLabel ? "RXCLIENT" : "CLIENTPORTALCLIENT";
    this.setSessionType(type);
    this.setState({ code, type, state });
    const validTypes = ['oid', 'ms'];
    if (code) {
      if(validTypes.includes(type)) {
        if(type === "oid") {
          this.props.dispatch(onOidLoginUser(code, type, userType, clientName));
        } else if (type === "ms") {
          this.props.dispatch(onMSIDLoginUser(code, type, userType, clientName));
        }
      }
    } else if (pingtoken) {
      this.props.history.replace(routePath.oidSSO);
    } else {
      this.props.dispatch(onLogoutUser());
    } 
  }

  setSessionType(type) {
    if (type === "oid") {
      sessionStorage.setItem("type", "oid");
    } else {
      sessionStorage.setItem("type", "msid");
    }
  }

  render() {
    return (
      <React.Fragment>
        <div align="center">Loading Profile...</div>
      </React.Fragment>
    );
  }
}

export default OidResponse;
