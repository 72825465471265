import React, { Component } from "react";
import { connect } from "react-redux";
import { getTermsOfUseContent } from "../actions/termsOfUseContent";
import TermsOfUse from "../components/TermsOfUse/TermsOfUse";

class TermsOfUseContainer extends Component {
  state = {
    termsOfUse: "",
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setTermsOfUse(nextprops);
  }

  componentDidMount() {
    document.title = "Client Portal - Terms of use";
    this.setTermsOfUse(this.props);
  }

  setTermsOfUse(props) {
    const { isFetching, termsOfUseContent, isError } = props;
    if (!isError) {
      if (!isFetching) {
        if (termsOfUseContent) {
          if (
            termsOfUseContent &&
            termsOfUseContent !== this.state.termsOfUse
          ) {
            this.setState({ termsOfUse: termsOfUseContent });
          }
        } else {
          this.props.dispatch(getTermsOfUseContent());
        }
      }
    }
  }

  render() {
    const contentData = this.state.termsOfUse[0];
    const { auth, whiteLabel } = this.props;
    return (
      <TermsOfUse
        contentData={contentData}
        auth={auth}
        whiteLabelVal={whiteLabel && whiteLabel.whiteLabelVal}
      />
    );
  }
}

const mapStateToProps = ({ termsOfUseContent, whiteLabel }) => {
  return { ...termsOfUseContent, whiteLabel };
};

export default connect(mapStateToProps)(TermsOfUseContainer);
