import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getClientRequestsContent } from "../actions/clientRequestContent";
import Request from "../components/Requests/Request";
import DashboardContainer from "./DashboardContainer";
import globalConstants from "../constants/global-constants";
import { isInternal, isExternal } from "../constants/service";
import { getSSOData } from "../actions/ssoRequest";

class ClientRequestsContentContainer extends Component {
  state = {
    clientRequest: "",
    clientRequestMessage: "",
    sso: "",
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setClientRequests(nextprops);
    this.setSSORequests(nextprops);
  }

  componentDidMount() {
    this.setClientRequests(this.props);
    this.setSSORequests(this.props);
  }

  setClientRequests(props) {
    const { clientRequestContent } = props;
    if (!clientRequestContent.isError) {
      if (!clientRequestContent.isFetching) {
        if (clientRequestContent.clientRequestContent) {
          if (
            clientRequestContent.clientRequestContent &&
            clientRequestContent.clientRequestContent !==
              this.state.clientRequest
          ) {
            this.setState({
              clientRequest: clientRequestContent.clientRequestContent,
            });
          }
        } else {
          this.props.dispatch(getClientRequestsContent());
        }
      }
    }
  }

  setSSORequests(props) {
    const { requestSSOData } = props;
    if (!requestSSOData.isError) {
      if (!requestSSOData.isFetching) {
        if (requestSSOData.sso) {
          if (requestSSOData.sso && requestSSOData.sso !== this.state.sso) {
            this.setState({
              sso: requestSSOData.sso,
            });
          }
        } else {
          this.props.dispatch(getSSOData());
        }
      }
    }
  }

  render() {
    const contentData = this.state.clientRequest[0];
    const { sso } = this.state;
    const { selectedAccount, user } = this.props;
    return (
      <React.Fragment>
        <Request
          contentData={contentData}
          isLaunchVisible={true}
          userType={isExternal(user) ? true : false}
          sso={sso}
        />
        {!isInternal(user) &&
          selectedAccount &&
          selectedAccount[globalConstants.NAVIGATION_REQUEST_TAB] ===
            globalConstants.SHOW_DASHBOARD && <DashboardContainer />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  clientRequestContent,
  user,
  auth,
  userProfile,
  requestSSOData,
}) => {
  return { clientRequestContent, user, auth, userProfile, requestSSOData };
};

export default connect(mapStateToProps)(
  withRouter(ClientRequestsContentContainer)
);
