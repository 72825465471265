import React, { Component } from "react";
import { connect } from "react-redux";
import { getAccountProfileContent } from "../actions/accountProfileContent";
import Profile from "../components/Profile/ProfilePage";
import globalConstants from "../constants/global-constants";

class AccountProfileContainer extends Component {
  state = {
    accountProfile: "",
    primaryAccount: {},
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setAccountProfile(nextprops);
  }

  componentDidMount() {
    document.title = "Client Portal - Profile";

    this.setAccountProfile(this.props);
  }

  setAccountProfile(props) {
    this.getProfileInfo(props);
    const { accountProfileContent } = props;
    if (!accountProfileContent.isError) {
      if (!accountProfileContent.isFetching) {
        if (accountProfileContent.accountProfileContent) {
          if (
            accountProfileContent.accountProfileContent &&
            accountProfileContent.accountProfileContent !==
              this.state.accountProfile
          ) {
            this.setState({
              accountProfile: accountProfileContent.accountProfileContent,
            });
          }
        } else {
          this.props.dispatch(getAccountProfileContent());
        }
      }
    }
  }

  getProfileInfo(nextprops) {
    const { userAccount } = nextprops;
    if (userAccount && userAccount.account) {
      const accounts = userAccount.account;
      var primaryAccount = accounts.find((item) => {
        if (item && item.u_primary_account === globalConstants.TRUE) {
          return true;
        }
        return null;
      });
    }
    this.setState({ primaryAccount });
  }

  render() {
    const contentData = this.state.accountProfile[0];
    const { primaryAccount } = this.state;
    const { user, userProfile } = this.props;
    return (
      <Profile
        contentData={contentData}
        primaryAccount={primaryAccount}
        user={user}
        userProfile={userProfile}
      />
    );
  }
}

const mapStateToProps = ({
  accountProfileContent,
  userAccount,
  user,
  userProfile,
  auth,
}) => {
  return { accountProfileContent, userAccount, user, userProfile, auth };
};

export default connect(mapStateToProps)(AccountProfileContainer);
