import {
  NOTIFY_ERROR,
  CLEAR_ERROR
} from "../actions/networkError";

function networkError(
  state = {
    data: ""
  },
  action
) {
  switch (action.type) {
    case NOTIFY_ERROR:
      return {
        ...state,
        data: action.metaData
      };
    case CLEAR_ERROR:
      return {
        ...state,
        data: ""
      };
    default:
      return state;
  }
}
export default networkError;