import React, { Component } from "react";
import { connect } from "react-redux";
import { getClinicalPublicationMetaData } from "../actions/clinicalPublicationMetaData";
import Content from "../components/Shared/Content/Content";

class PublicationContentContainer extends Component {
  state = { cpMetaData: "", key: "" };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getClinicalPublicationCMS(nextProps);
  }

  componentDidMount() {
    if (this.props) {
      this.getClinicalPublicationCMS(this.props);
    }
  }

  getClinicalPublicationCMS(nextProps) {
    const {
      subType,
      type,
      clinicalPublicationMetaData,
      publicationkey,
      auth
    } = nextProps;
    if (type || subType) {
      if (
        clinicalPublicationMetaData &&
        !clinicalPublicationMetaData.isFetching
      ) {
        const data = this.configData(
          publicationkey,
          clinicalPublicationMetaData.data
        );
        if (data) {
          if (data.key !== this.state.cpMetaData.key) {
            this.setState({
              cpMetaData: data
            });
          }
        } else {
          this.props.dispatch(
            getClinicalPublicationMetaData(
              type,
              subType,
              publicationkey,
              auth.accessToken
            )
          );
        }
      }
    }
  }

  configData(key, data) {
    if (data && data.length > 0) {
      return data.find(item => {
        return item.key === key;
      });
    }
    return null;
  }

  render() {
    const { cpMetaData } = this.state;
    var cmsResponse = {};
    if (cpMetaData && cpMetaData.value) {
      cmsResponse = cpMetaData.value;
      return (
        <React.Fragment>
          <h3 className="marT0">
            {cmsResponse && cmsResponse.title && (
              <Content html={cmsResponse.title} />
            )}
          </h3>
          {cmsResponse && cmsResponse.body && (
            <Content html={cmsResponse.body} />
          )}
        </React.Fragment>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ clinicalPublicationMetaData, auth }) => {
  return { clinicalPublicationMetaData, auth };
};

export default connect(mapStateToProps)(PublicationContentContainer);
