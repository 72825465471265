import React from "react";
import { renderHTML } from "../../../constants/service";
import DOMPurify from 'dompurify';

const MessageBox = ({ title, data }) => {
  let html = "";
  if (data) {
    html = renderHTML(data);
  }
 const  sanitizedHTML = DOMPurify.sanitize(html);
  return (
    <article className="overAuto">
      <div className="list">
        <h6>{title}</h6>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      </div>
    </article>
  );
};

export default MessageBox;
