function getRegionURLs() {
  if (
    window.location &&
    process.env.REACT_APP_UI_URL_REGION_CENTRAL &&
    window.location.origin.includes(process.env.REACT_APP_UI_URL_REGION_CENTRAL)
  ) {
    return "CENTRAL";
  } else if (
    window.location &&
    process.env.REACT_APP_UI_URL_REGION_EAST &&
    window.location.origin.includes(process.env.REACT_APP_UI_URL_REGION_EAST)
  ) {
    return "EAST";
  } else {
    return "FRONTDOOR";
  }
}

export function getAPIURLs(context) {
  if (
    window.location &&
    process.env.REACT_APP_UI_URL_REGION_CENTRAL &&
    window.location.origin.includes(process.env.REACT_APP_UI_URL_REGION_CENTRAL)
  ) {
    return process.env.REACT_APP_API_URL_REGION_CENTRAL + context;
  } else if (
    window.location &&
    process.env.REACT_APP_UI_URL_REGION_EAST &&
    window.location.origin.includes(process.env.REACT_APP_UI_URL_REGION_EAST)
  ) {
    return process.env.REACT_APP_API_URL_REGION_EAST + context;
  } else {
    return process.env.REACT_APP_API_URL + context;
  }
}

export function getUIURLs(context) {
  if (
    window.location &&
    process.env.REACT_APP_UI_URL_REGION_CENTRAL &&
    window.location.origin.includes(process.env.REACT_APP_UI_URL_REGION_CENTRAL)
  ) {
    return process.env.REACT_APP_UI_URL_REGION_CENTRAL + context;
  } else if (
    window.location &&
    process.env.REACT_APP_UI_URL_REGION_EAST &&
    window.location.origin.includes(process.env.REACT_APP_UI_URL_REGION_EAST)
  ) {
    return process.env.REACT_APP_UI_URL_REGION_EAST + context;
  } else {
    return process.env.REACT_APP_CLIENTPORTAL_URL + context;
  }
}

function checkActiveCBSUrl() {
  if (
    process.env &&
    process.env.REACT_APP_ACTIVE_REST_PROP &&
    process.env.REACT_APP_ACTIVE_REST_PROP === "true"
  ) {
    return getAPIURLs(process.env.REACT_APP_CBS_REST_URL);
  } else {
    return getAPIURLs(process.env.REACT_APP_CBS_URL);
  }
}

//SERVICE NOW URLs
export function fetchUserAccount() {
  return getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) + "/account/user";
}

export function fetchUserAccountViaContactID() {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) +
    "/account/accountbycontactid"
  );
}

export function fetchUserInternalAccount() {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) + "/account/user/manager"
  );
}

export function fetchBcbsUser(account_number) {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) +
    "/clientaccount/" +
    account_number +
    "/report/access"
  );
}

export function fetchReportLinks(account_number) {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) +
    "/clientaccount/" +
    account_number +
    "/report/links"
  );
}

export function fetchGenerateReports(account_number, linkId) {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) +
    "/clientaccount/" +
    account_number +
    "/report/link/" +
    linkId
  );
}
export function fetchBASDocuments() {
  return getAPIURLs(process.env.REACT_APP_EOBMS_REST_URL) + "/eob/search";
  // return "https://tstocpapi-cloud.optumrx.com/eobms/rest/eob/search";
}

export function fetchDocumentRetrieval(id, productType) {
  return (
    getAPIURLs(process.env.REACT_APP_EOBMS_REST_URL) +
    "/eob/document/" +
    id +
    "?type=" +
    productType
  );
  // return (
  //   "https://tstocpapi-cloud.optumrx.com" +
  //   "/eobms/rest/eob/document/" +
  //   id +
  //   "?type=" + productType
  // );
}

export function fetchAccountProductQuickLauch() {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) +
    "/product/user/getAccountProductQuickLauch"
  );
}

export function fetchAccountProductInternalUser() {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) +
    "/product/productForEmployee"
  );
}

export function fetchAccountTeam(accountId) {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) +
    "/account/" +
    accountId +
    "/accountteam"
  );
}

export function fetchExtLaunchUrl(user) {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) +
    "/servicenowsso/user/external/" +
    user
  );
}

export function fetchPALITEUrl() {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) + "/servicenowsso/palite"
  );
}

export function ssoUrl() {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) + "/servicenowsso/user"
  );
}

export function fetchExtLaunchUrlNew() {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) + "/servicenowsso/user"
  );
}

export function fetchIntLaunchUrl() {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) +
    "/servicenowsso/user/internal/" +
    "user"
  );
}

export function fetchProductOfferingUrl(accountId) {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) +
    "/product/user/" +
    accountId +
    "/productOfferingForClient"
  );
}

export function fetchDashboardUrl() {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) + "/dashboard/userdata"
  );
}

//CONTENT SERVICE Microservices
export function fetchGeneralContentUrl(endPoint) {
  return getAPIURLs(process.env.REACT_APP_CONTENTSERVICE_URL) + endPoint;
}

export function fetchAccountMetadata(accountId) {
  return (
    getAPIURLs(process.env.REACT_APP_CONTENTSERVICE_URL) +
    "/cms/getAccountMetadata/" +
    accountId
  );
}

// CONTENT SERVICE AEM Microservices
export function fetchAEMContentUrl(endPoint) {
  return getAPIURLs(process.env.REACT_APP_CONTENTSERVICE_URL) + endPoint + '/aem';
}

export function fetchMessageBoxContent(pageID) {
  return (
    getAPIURLs(process.env.REACT_APP_CONTENTSERVICE_URL) +
    "/cms/getListByNode/message/" +
    pageID
  );
}

export function fetchVideoContentURL(pageID) {
  return (
    getAPIURLs(process.env.REACT_APP_CONTENTSERVICE_URL) +
    "/cms/getListByNode/video/" +
    pageID
  );
}

export function fetchProductMetaDataUrl(productName) {
  return (
    getAPIURLs(process.env.REACT_APP_CONTENTSERVICE_URL) +
    "/cms/getProductMetadata/" +
    productName
  );
}

export function fetchFileTreeContentUrl() {
  return (
    getAPIURLs(process.env.REACT_APP_CONTENTSERVICE_URL) + "/document/getAll"
  );
}
export function fetchFileWithMSId() {
  return (
    getAPIURLs(process.env.REACT_APP_CONTENTSERVICE_URL) +
    "/document/getAllForMSID"
  );
}

export function fetchFileNames(folderPath) {
  return (
    getAPIURLs(process.env.REACT_APP_CONTENTSERVICE_URL) +
    "/document/getFileStructure/" +
    folderPath
  );
}

export function getRxTrackSSO(userId, userType, product) {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) +
    "/rxtracksso/user/" +
    userId +
    "/" +
    userType +
    "/" +
    product
  );
}

export function getRxClaimSSO() {
  return (
    getAPIURLs(process.env.REACT_APP_SERVICENOW_URL) + "/rxclaimpresto/user"
  );
}

// CBS Urls
export function fetchClients() {
  return checkActiveCBSUrl() + "/cbs/getclients";
}

export function fetchBillingEntity() {
  return checkActiveCBSUrl() + "/cbs/getBillingEntity";
}

export function fetchInvoiceMonths() {
  return getAPIURLs(process.env.REACT_APP_CBS_URL) + "/cbs/months";
}

export function fetchAnnouncements() {
  return (
    getAPIURLs(process.env.REACT_APP_CONTENTSERVICE_URL) +
    "/cms/getAnnouncements"
  );
}
export function fetchDrugFilterContentUrl() {
  return (
    getAPIURLs(process.env.REACT_APP_CONTENTSERVICE_URL) +
    "/cms/getTextObjectFromByCode/drugFilterContent"
  );
}

export function fetchInvoiceDocuments() {
  return checkActiveCBSUrl() + "/cbs/getInvoiceDoc";
}

export function downloadInvoiceDocuments() {
  return checkActiveCBSUrl() + "/cbs/downloadDocument";
}

export function fetchUrlWithSASToken() {
  return checkActiveCBSUrl() + "/cbs/download";
}
export function downloadfromAzure() {
  return getAPIURLs(process.env.REACT_APP_CBS_URL) + "/cbs/downloadFromAzure";
}

export function getEnviormentPropByName(name) {
  return process.env[name];
}

// Authentication
export function getOidAuthentication(code, userType, clientName) {
  return (
    getAPIURLs(process.env.REACT_APP_AUTH_URL) +
    "/oid/getTokenFromCode/" +
    userType +
    "/" +
    clientName +
    "/" +
    code +
    "/" +
    getRegionURLs()
  );
}

export function getProfileDetail() {
  return getAPIURLs(process.env.REACT_APP_AUTH_URL) + "/oid/getProfileDetail";
}

export function sessionUrl() {
  return getAPIURLs(process.env.REACT_APP_AUTH_URL) + "/msid/refreshSignature";
}

export function getOptumProvisioning() {
  return getAPIURLs(process.env.REACT_APP_AUTH_URL) + "/oid/getOPDetail";
}

export function getMSIDAuthentication(code, userType, clientName) {
  return (
    getAPIURLs(process.env.REACT_APP_AUTH_URL) +
    "/msid/getMSTokenFromCode/" +
    userType +
    "/" +
    clientName +
    "/" +
    code +
    "/" +
    getRegionURLs()
  );
}

export function getMSProfileDetail() {
  return getAPIURLs(process.env.REACT_APP_AUTH_URL) + "/msid/getProfileDetail";
}

export function getMSAccessFromRefreshToken() {
  return (
    getAPIURLs(process.env.REACT_APP_AUTH_URL) +
    "/msid/getMSTokenFromRefreshToken"
  );
}

export function getOIDAccessFromRefreshToken() {
  return (
    getAPIURLs(process.env.REACT_APP_AUTH_URL) +
    "/oid/getOIDTokenFromRefreshToken"
  );
}

export function logoutuser() {
  return getAPIURLs(process.env.REACT_APP_AUTH_URL) + "/oid/logoutUser";
}
export function getDrugDetails() {
  return (
    getAPIURLs(process.env.REACT_APP_FORMULARY_URL) +
    "/formularylookup/details"
 );
}
export function getDrugs() {
  return (
    getAPIURLs(process.env.REACT_APP_FORMULARY_URL) + "/formularylookup/drugs"
  );
}
