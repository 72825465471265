import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { drugResultRowCheckLinkAnalytics } from "../../../actions/drugSearchActionFormulary";
function useClientDataGrid(
  initialSortOrder,
  initialPagesize,
  rows,
  filterCriteria
) {
  const [sortOrder, setSortOrder] = useState(initialSortOrder);
  const [pageSize, setPageSize] = useState(initialPagesize);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);

  const dispatch = useDispatch();

  const handleSelectedRows = (updatedRows) => {
   // filter 
   const selectedIDs = new Set(updatedRows);
   const selectedRowDetails = rows.filter((row) =>
     selectedIDs.has(row.id.toString())
   )

   setSelectedRows(updatedRows);
   setSelectedRowData(selectedRowDetails);
  }

  const triggerSelectedRowAnalytics = () => {
    dispatch(drugResultRowCheckLinkAnalytics(selectedRowData));
  }

  useEffect(() => {
    if (selectedRowData.length) {
      triggerSelectedRowAnalytics();
    }

  }, [selectedRowData]);

  const handleSortOrderChange = useCallback((sortModel) => {
    setSortOrder([...sortModel]);
  }, []);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPageSize(newPageSize);
  }, []);

  const compare = (value1, operator, value2) => {
    switch (operator) {
      case ">":
        return parseInt(value1) > parseInt(value2);
      case ">=":
        return parseInt(value1) >= parseInt(value2);
      case "<":
        return parseInt(value1) < parseInt(value2);
      case "<=":
        return parseInt(value1) <= parseInt(value2);
      case "=":
        return value1===value2;
      case "includes":
        return value1.toLowerCase().includes(value2.toLowerCase());
      case "ArrayIncludes":
        return value2.includes(value1);
      case "not in":
        return !value2.includes(value1);
      case "regex_not_in":
        const result = value2.some((val) => {
          return value1.toLowerCase().includes(val.toLowerCase());
        });
        return !result;
      default:
        return true;
    }
  };

  const filterFunction = (data) => {
    return Object.entries(filterCriteria).every(([filterName, filterValue]) => {
      const attribute = filterName;
      const { filterOperator, value } = filterValue;
      return compare(data[attribute], filterOperator, value);
    });
  };

  const filteredRows = useMemo(() => {
    if (Object.keys(filterCriteria).length === 0) {
      return rows;
    }
    return rows.filter(filterFunction);
  }, [rows, filterCriteria, filterFunction]);

  return {
    sortOrder,
    handleSortOrderChange,
    selectedRows,
    handleSelectedRows,
    handlePageSizeChange,
    pageSize,
    filteredRows,
  };
}
export default useClientDataGrid;
