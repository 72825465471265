import {
  MESSAGEBOX_CONTENT_REQUEST,
  MESSAGEBOX_CONTENT_SUCCESS,
  MESSAGEBOX_CONTENT_FAILURE
} from "../actions/messageBox";

export function messageData(
  state = {
    isFetching: false,
    data: []
  },
  action
) {
  switch (action.type) {
    case MESSAGEBOX_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        pageId: action.pageId
      });
    case MESSAGEBOX_CONTENT_SUCCESS:
      let data=[];
      if (state.data) {
         data = [...state.data, action.data]
      }
      return {
        ...state,
        isFetching: false,
        data: data
      };
    case MESSAGEBOX_CONTENT_FAILURE:
      let dataFl=[];
      if (state.data) {
        dataFl = [...state.data, action.data]
      }
      return {
        isFetching: false,
        error: action.message,
        isError: true,
        data: dataFl
      };
    default:
      return state;
  }
}

export default messageData;
