import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const CP_METADATA_REQUEST = "CP_METADATA_REQUEST";
export const CP_METADATA_SUCCESS = "CP_METADATA_UPDATE";
export const CP_METADATA_FAILURE = "CP_METADATA_FAILURE";

export function requestClinicalPublicationMetaData(type, subType, key) {
  const cpMetaData = {};
  cpMetaData["isFetching"] = true;
  cpMetaData["value"] = {};
  cpMetaData["type"] = type;
  cpMetaData["subType"] = subType;
  cpMetaData["key"] = key;
  return {
    type: CP_METADATA_REQUEST,
    data: cpMetaData,
  };
}

export function receiveClinicalPublicationMetaData(data, type, subType, key) {
  const cpMetaData = {};
  cpMetaData["value"] = data;
  cpMetaData["isFetching"] = false;
  cpMetaData["type"] = type;
  cpMetaData["subType"] = subType;
  cpMetaData["key"] = key;
  return {
    type: CP_METADATA_SUCCESS,
    data: cpMetaData,
  };
}

export function clinicalPublicationMetaDataFailure(
  message,
  type,
  subType,
  key
) {
  const cpMetaData = {};
  cpMetaData["value"] = null;
  cpMetaData["isFetching"] = false;
  cpMetaData["type"] = type;
  cpMetaData["subType"] = subType;
  cpMetaData["key"] = key;
  return {
    type: CP_METADATA_FAILURE,
    message,
    data: cpMetaData,
  };
}

export function getClinicalPublicationMetaData(type, subType, key) {
  if (type) {
    return (dispatch) => {
      dispatch(requestClinicalPublicationMetaData(type, subType, key));
      const url = fetchGeneralContentUrl("/cms/getNodeByCode/page/" + key);
      http
        .get(url)
        .then((response) => {
          const data = response.data;
          if (response.status !== 200) {
            dispatch(
              clinicalPublicationMetaDataFailure(
                data.message,
                type,
                subType,
                key
              )
            );
            return Promise.reject(data);
          } else {
            if (data && data.length > 0) {
              dispatch(
                receiveClinicalPublicationMetaData(data[0], type, subType, key)
              );
            }
            return data;
          }
        })
        .catch(function (error) {
          dispatch(
            clinicalPublicationMetaDataFailure(error, type, subType, key)
          );
        });
    };
  }
  return null;
}
