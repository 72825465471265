import React from "react";
import Content from "../Shared/Content/Content";
import VideoContainer from "../../containers/VideoContainer";
import MessageContainer from "../../containers/MessageContainer";
import pageTypeEnum from "../../constants/pageType";
import ClinicalPublicationComponent from "./ClinicalPublicationComponent/ClinicalPublicationComponent";
import RelatedLinksComponent from "./RelatedLinksComponent/RelatedLinksComponent";
const ClinicalServices = ({ contentData }) => {
  if (contentData && contentData.length > 0) {
    return (
      <div className="row-">
        <div className="col-sm-9 leftBlock">
          <article>
            {contentData[0].title && (
              <h1>
                <Content html={contentData[0].title} />
              </h1>
            )}
            {contentData[0].body && <Content html={contentData[0].body} />}
          </article>
        </div>
        <div className="col-sm-4 rightBlock">
          <ClinicalPublicationComponent />
          <VideoContainer
            pageType={pageTypeEnum.CLINICAL_SERVICES}
            pageId={contentData[0].nid}
          />
          <MessageContainer
            pageType={pageTypeEnum.CLINICAL_SERVICES}
            pageId={contentData[0].nid}
          />
          <RelatedLinksComponent />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ClinicalServices;
