import React, { Component } from "react";
import NVD3Chart from "react-nvd3";
import * as _ from "underscore";
import * as d3 from "d3";

class BarChart extends Component {
  state = {};

  componentDidMount() {
    setTimeout(function () {
      var xTicks = d3
        .select(
          "div#VerticalBarChart > div > div > svg > g > g > g.nv-x.nv-axis.nvd3-svg > g"
        )
        .selectAll("g");
      var xLabel = d3.select(
        "div#VerticalBarChart > div > div > svg > g > g > g.nv-x.nv-axis.nvd3-svg"
      );
      xLabel.attr("transform", function (d, i, j) {
        return "translate (0, 205)";
      });

      xTicks
        .selectAll("text")
        .attr("transform", function (d, i, j) {
          return "translate (-15, 45) rotate(-45 0,0)";
        })
        .style("text-anchor", "end"); // position and rotate the xAxis labels
    }, 500);
  }

  updateText() { }

  truncate(string) {
    if (string.length > 14) {
      return string.substring(0, 14) + "...";
    } else {
      return string;
    }
  }
  checkName(name, categories) {
    var checkName = _.find(categories, function (category) {
      return category.label === name;
    });
    return checkName;
  }

  generateUniqueName(name, array) {
    name = name + ".";
    while (this.checkName(name, array)) {
      name = name + ".";
    }
    return name;
  }


  render() {
    const { dashboardData } = this.props;
    if (!dashboardData) {
      return null;
    }
    const value = _.countBy(dashboardData, "categoryName");
    const barChartData = [];
    let count = 0;
    const length = Object.keys(value).length;
    const color = ["#A32A2E"];
    let wrapLabelsValue;
    for (var label in value) {
      var data = {};
      var name;
      if (label && label !== "null") {
        if (length >= 15) {
          name = this.truncate(label); //truncate occurs
          wrapLabelsValue = false;
        } else {
          name = label; //truncate occurs.
          wrapLabelsValue = true;
        }
      } else {
        name = "(empty)";
      }
      if (this.checkName(name, barChartData)) {
        name = this.generateUniqueName(name, barChartData);
      }
      data["label"] = name;
      data["value"] = value[label];
      if (color[count]) {
        data["color"] = color[count];
        count = count + 1;
      } else {
        count = 0;
        data["color"] = color[count];
      }
      barChartData.push(data);
    }

    this.sortBarChartData(barChartData);


    const myData = [{
      values: barChartData
    }];

    //  get chart max value to calculate what top tick should be
    const maxDataVal = myData[0].values.reduce((prev, curr) => {
      if (prev.value < curr.value) {
        return curr;
      }
      return prev;
    }).value;

    const incValue = this.getIncValue(maxDataVal, incValue);

    const increment = incValue; // how much the ticks should increment by (this number can be changed to suit what you need)
    var ticks = [0]; // array to hold all the ticks values and sets the minimum of the y-axis
    for (let i = 1; ticks[i - 1] + increment < maxDataVal; i++) {
      // push each tick value into the ticks array
      ticks.push(ticks[i - 1] + increment);
    }
    ticks.push(ticks[ticks.length - 1] + increment); // add the last tick to the top of the chart

    return (
      <div className="bar-chart">
        <h6>Cases by Category</h6>
        <div className="tk-bar-chart-container" id="VerticalBarChart">
          <div className="tk-bar-chart">
            <NVD3Chart
              id="VerticalBarChart"
              type="discreteBarChart"
              tooltip={{ enabled: false }}
              datum={myData}
              x="label"
              y="value"
              height={300}
              width={500}
              disableBarValues={true}
              enableHover={false}
              margin={{ top: 10 }}
              yAxis={{
                axisLabel: "Case Count", //give y-axis label
                tickValues: ticks, // set configured ticks to the yAxis
                tickFormat: d => {
                  return d3.format("f")(d); //format y-axis values
                }
              }}
              yDomain={[ticks[0], ticks[ticks.length - 1]]}
              wrapLabels={wrapLabelsValue}
            />
          </div>
          <p className="description" />
        </div>
      </div>
    );
  }

  getIncValue(maxDataVal, incValue) {
    if (maxDataVal <= 3) {
      incValue = 1;
    }
    else if (maxDataVal > 3 && maxDataVal <= 10) {
      incValue = 2;
    }
    else if (maxDataVal > 10 && maxDataVal <= 20) {
      incValue = 5;
    }
    else if (maxDataVal > 20 && maxDataVal <= 50) {
      incValue = 10;
    }
    else if (maxDataVal > 50 && maxDataVal <= 100) {
      incValue = 20;
    }
    else if (maxDataVal > 100 && maxDataVal <= 200) {
      incValue = 25;
    }
    else if (maxDataVal > 200 && maxDataVal <= 500) {
      incValue = 50;
    }
    else if (maxDataVal > 500 && maxDataVal <= 1000) {
      incValue = 100;
    }
    else {
      incValue = maxDataVal / 5;
    }
    return incValue;
  }

  sortBarChartData(barChartData) {
    barChartData.sort(function (a, b) {
      const a1 = parseInt(a["value"]);
      const b1 = parseInt(b["value"]);
      const countSortResult = b1 - a1;

      if (countSortResult === 0) {
        let a2 = a["label"];
        let b2 = b["label"];
        a2 = a2.toLowerCase();
        b2 = b2.toLowerCase();
        if (a2 < b2) {
          return -1;
        }
        return a2 > b2 ? 1 : 0;
      }
      else {
        return countSortResult;
      }
    });
  }
}

export default BarChart;
