import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import { isExternal } from "../../../../constants/service";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Typography from "@material-ui/core/Typography";
import {
  getInvoiceDownload,
  clearDownloadResponse,
} from "../../../../actions/downloadInvoice";
import { getdownloadfromAzure } from "../../../../actions/getDownloadAzure";
import {
  getDocumentUrl,
  clearDownloadResponsefromAzure
} from "../../../../actions/getDocumentUrl"
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import "./InvoiceDetailTable.scss";
import { Scrollbars } from "react-custom-scrollbars";
import CustomFooter from "../../../Shared/CustomFooter/CustomFooter";
import { paginate } from "../../../../utils/paginate";
import Pagination from "../../../Shared/Pagination/Pagination";
import JumpNavigation from "../../../Shared/JumpNavigation/JumpNavigation";
import globalConstants from "../../../../constants/global-constants";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

class InvoiceDetailTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      popUpdata: [],
      pageSize: 10,
      currentPage: 1,
      openInvoiceResponse: false,
      responseMessage: null,
      reportName: null,
      documentName: null,
      downloadAzure: null,
      fileUrl: "",
      openInvoiceResAzure: false
    };
  }

  componentDidMount() {
    const { invoiceInfo } = this.props;

    if (invoiceInfo) {
      this.setState({ currentPage: 1 });
    }
    if (this.props.fetchDownloadfromAzure && this.props.fetchDownloadfromAzure.downloadAzure) {
      this.setInvoiceResponseFromAzure(this.props);
    } else {
      this.setInvoiceResponse(this.props);
    }
    this.handleDownloadFromAzure(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.fetchDownloadfromAzure) {
      this.state.downloadAzure = nextProps.fetchDownloadfromAzure.downloadAzure;
    }
    if (this.state.downloadAzure) {
      this.setInvoiceResponseFromAzure(this.props);
    } else {
      this.setInvoiceResponse(this.props);
    }
    this.state.fileUrl = nextProps.fetchDocumentUrl.fileUrl;

  }

  setInvoiceResponse(nextProps) {
    const { getDownloadInvoice } = nextProps;
    if (getDownloadInvoice && getDownloadInvoice.downloadResponse) {
      getDownloadInvoice.downloadResponse.find((item) => {
        if (item && item.responseCode === 404) {
          this.setState({
            openInvoiceResponse: true,
            responseMessage: globalConstants.FILENOTFOUNDMSG,
            reportName: item.displayName,
            documentName: item.documentName,
          });
        } else if (item && item.responseCode === 500) {
          this.setState({
            openInvoiceResponse: true,
            responseMessage: globalConstants.FILESIZEMSG,
            reportName: item.displayName,
            documentName: item.documentName,
          });
        }
      });
    }
  }

  setInvoiceResponseFromAzure(nextProps) {
    const { fetchDocumentUrl } = nextProps;
    if (fetchDocumentUrl && fetchDocumentUrl.documentResponse) {
      fetchDocumentUrl.documentResponse.find((item) => {
        if (item && item.responseCode === 400) {
          this.setState({
            openInvoiceResAzure: true,
            responseMessage: globalConstants.FILENOTFOUNDMSG,
            reportName: item.displayName,
            documentName: item.documentName,
          });
        } else if (item && item.responseCode === 500) {
          this.setState({
            openInvoiceResAzure: true,
            responseMessage: globalConstants.FILESIZEMSG,
            reportName: item.displayName,
            documentName: item.documentName,
          });
        }
      });
    }
  }

  findFileStatus(allResponses, documentName) {
    return allResponses &&
      allResponses.length > 0 &&
      allResponses.find(
        (item) => item.documentName === documentName && item.isFetching
      )
      ? true
      : false;
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          responsiveScroll: {
            maxHeight: "none",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "#efefee",
            },
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "'Frutiger-Bold', Arial, sans-serif",
            fontSize: "13px",
            fontWeight: "bold",
            letterSpacing: ".04rem",
            whiteSpace: "nowrap",
          },
          fixedHeader: {
            backgroundColor: "#63666A",
            color: "#ffffff",
          },
          sortAction: {
            height: "auto",
          },
          sortActive: {
            color: "#ffffff",
          },
          toolButton: {
            height: "auto",
          },
        },
        MUIDataTablePagination: {
          root: {
            borderBottom: "none",
            borderTop: "1px solid #cccccc",
          },
        },
        MuiTableCell: {
          root: {
            padding: "6px 16px",
          },
          body: {
            border: "none",
            fontFamily: '"Open Sans", Arial, sans-serif',
            fontSize: "13px",
            fontWeight: "normal",
          },
          footer: {
            borderBottom: "none",
            borderTop: "1px solid #ccc",
          },
        },
        MuiTablePagination: {
          caption: {
            fontFamily: '"frutiger-light", Arial, sans-serif',
            fontSize: "13px",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#ffffff !important",
          },
        },
      },
      props: {
        MuiTableSortLabel: {
          IconComponent: ArrowDropDownIcon,
        },
      },
    });
  handleClickOpen = (data) => {
    this.setState({
      open: true,
      popUpdata: data,
    });
  };

  handleInovoiceResponseClose(reportName, documentName) {
    this.setState({
      openInvoiceResponse: false,
      openInvoiceResAzure: false,
      responseMessage: null,
      documentName: null,
    });
    if (this.state.downloadAzure) {
      this.props.dispatch(clearDownloadResponsefromAzure(reportName, documentName));
    }
    else {
      this.props.dispatch(clearDownloadResponse(reportName, documentName));
    }
  }

  handleDownloadFromAzure = (props) => {
    const { fetchDownloadfromAzure, downloadAzure } = props;

    if (!fetchDownloadfromAzure.isError) {
      if (!fetchDownloadfromAzure.isFetching) {
        if (fetchDownloadfromAzure.downloadAzure) {
          if (
            fetchDownloadfromAzure.downloadAzure &&
            downloadAzure !== this.state.downloadAzure
          ) {
            this.state.downloadAzure = fetchDownloadfromAzure.downloadAzure;
            this.setState({
              downloadAzure: fetchDownloadfromAzure.downloadAzure
            });
          }
        } else {
          this.props.dispatch(getdownloadfromAzure());
        }
      }
    }
  }


  handleDownload = (item) => {
    if (item) {
      const { getClients } = this.props;
      var req = {};
      if (getClients && getClients.clients && getClients.clients.length > 0) {
        req["authId"] = getClients.userName;
        req["idType"] = getClients.typeId;
        req["documentType"] = item.documentType;
        req["documentName"] = item.documentName;
        req["documentPath"] = item.documentPath;
        if (item.transNum) {
          req["transNum"] = item.transNum;
        } else {
          req["transNum"] = "";
        }
        req["viewDownloadAction"] = "d";

        if (this.state.downloadAzure) {
          this.handleDownloadInvoiceFromAzure(req, item.documentName, item.reportDisplay);
        }
        else {
          this.props.dispatch(
            getInvoiceDownload(req, item.documentName, item.reportDisplay)
          );
        }
      }
    }
  };

  handleDownloadInvoiceFromAzure = (req, documentName, reportDisplay) => {
    const { fetchDocumentUrl } = this.props;
    this.setState({
      fileUrl: ""
    });
    if (!fetchDocumentUrl.isError) {
      if (!fetchDocumentUrl.isFetching) {
        if (fetchDocumentUrl.fileUrl && fetchDocumentUrl.documentName === documentName) {
          if (
            fetchDocumentUrl.fileUrl &&
            fetchDocumentUrl.fileUrl !== this.state.fileUrl
          ) {
            this.state.fileUrl = fetchDocumentUrl.fileUrl;
            this.setState({
              fileUrl: fetchDocumentUrl.fileUrl
            });
          }
        } else {
          this.props.dispatch(
            getDocumentUrl(req, documentName, reportDisplay)
          );
        }
      }
    }
  };

  handleClose = () => {
    this.setState({ open: false, popUpdata: [] });
  };

  handlePageChange = (page) => {
    if (page) {
      this.setState({ currentPage: page });
    }
  };

  handleGoToPageChange = (event) => {
    if (event) {
      this.setState({ currentPage: Number(event.target.value) });
    }
  };

  options = {
    print: false,
    filter: false,
    search: false,
    download: false,
    viewColumns: false,
    pagination: false,
    selectableRows: "none",
    rowHover: false,
    responsive: "stacked",
    customFooter: () => {
      const { currentPage, pageSize } = this.state;
      const { invoiceInfo } = this.props;
      return (
        <CustomFooter
          count={invoiceInfo.length}
          component={
            invoiceInfo &&
            invoiceInfo.length > pageSize && (
              <Pagination
                itemCount={invoiceInfo.length}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            )
          }
          goToPage={
            invoiceInfo &&
            invoiceInfo.length > pageSize && (
              <JumpNavigation
                itemCount={invoiceInfo.length}
                pageSize={pageSize}
                currentPage={currentPage}
                onGoToPageChange={this.handleGoToPageChange}
              />
            )
          }
        />
      );
    },
  };

  columns = [
    {
      label: "Date",
      name: "invoiceDate",
      options: {
        sort: true,
        sortDirection: "desc",
      },
    },
    {
      label: "Invoice #",
      minWidth: 150,
      name: "invoiceNumber",
      options: {
        sort: true,
        sortDirection: "desc",
      },
    },
    {
      id: "billingEntity",
      label: "Billing entity",
      minWidth: 150,
      name: "billingEntity",
      options: {
        sort: true,
        sortDirection: "desc",
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span className="singleLine">
              {value && value.billingEntityName} <br />
              {value && value.billingEntityId}
            </span>
          );
        },
      },
    },
    {
      id: "invoiceReportMap",
      label: "Report",
      minWidth: 250,
      name: "invoiceReportMap",
      // sortable: false,
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          value &&
          this.reportArray(value).map((item, index) => {
            var fileClass = this.state.downloadAzure ?
              this.findFileStatus(
                this.props.fetchDocumentUrl.documentResponse,
                item.documentName
              ) : this.findFileStatus(
                this.props.getDownloadInvoice.downloadResponse,
                item.documentName
              )
                ? "disabled"
                : "";
            return (
              <span className="singleLine" key={item.reportDisplay + index}>
                <a
                  className={fileClass}
                  href="# "
                  download-pdf={item.documentName}
                  friendlyName={item.documentName}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleDownload(item);
                  }}
                >
                  {item.reportDisplay}
                </a>
                <br />
              </span>
            );
          }),
      },
    },
    {
      id: "userAccess",
      label: "User access",
      minWidth: 300,
      name: "invoiceReportMap",
      // sortable: false,
      options: {
        sort: false,
        display: !isExternal(this.props.user),
        customBodyRender: (value, tableMeta, updateValue) =>
          value &&
          this.reportArray(value).map((item) => {
            return (
              <span className="singleLine" key={item.reportCd}>
                {item &&
                  item.invoiceDocAccesses &&
                  item.invoiceDocAccesses.length > 0 &&
                  item.invoiceDocAccesses[0].userActionDttm}{" "}
                {item &&
                  item.invoiceDocAccesses &&
                  item.invoiceDocAccesses.length > 0 &&
                  item.invoiceDocAccesses[0].authId}{" "}
                {item &&
                  item.invoiceDocAccesses &&
                  item.invoiceDocAccesses.length > 0 &&
                  item.invoiceDocAccesses[0] && (
                    <a
                      href="# "
                      className="oi-zoom-in noHU f16 c_black b vertTextBot"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleClickOpen(item.invoiceDocAccesses);
                      }}
                    />
                  )}
                <br />
              </span>
            );
          }),
      },
    },
  ];
  reportArray(value) {
    var arr = [];
    Object.keys(value).forEach(function (key) {
      const val = value[key];
      arr.push(val);
    });
    return arr;
  }

  render() {
    const { invoiceInfo, isInvoiceLoadComplete } = this.props;
    const { open, popUpdata, currentPage, pageSize, openInvoiceResponse } = this.state;
    const { responseMessage, reportName, documentName, openInvoiceResAzure } = this.state;
    const paginateData = paginate(invoiceInfo, currentPage, pageSize);
    if (!isInvoiceLoadComplete) {
      return (
        <React.Fragment>
          <div className="col-sm-9">
            <div id="fileContainer" />
            <div align="center">Loading data...</div>
          </div>
          <div style={{ clear: "both" }} />
        </React.Fragment>
      );
    } else {
      if (invoiceInfo && invoiceInfo.length > 0) {
        return (
          <React.Fragment>
            <div className="col-sm-9">
              <div id="fileContainer" />
              <span
                className="rightscrollable block invoiceTblScroll"
                name="invoicereportpage"
              >
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <MUIDataTable
                    // className={"invoiceTable"}
                    title={""}
                    data={paginateData}
                    columns={this.columns}
                    options={this.options}
                  />
                </MuiThemeProvider>
              </span>
              <div align="center" />
            </div>
            <div style={{ clear: "both" }} />
            <Dialog
              onClose={this.handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.handleClose}
              >
                {""}
              </DialogTitle>
              <Scrollbars style={{ width: 500, height: 500, marginTop: 16 }}>
                <DialogContent>
                  <form name="myForm" method="post">
                    <div align="center">
                      <span name="accessDetail">
                        <div name="invoiceAccessDataPage_DisplayPageWrapper">
                          <div name="Invoices_TOCDisplayGroupWrapper">
                            <table
                              name="GroupContainer"
                              className="displayPageTable"
                              role="presentation"
                              border="1"
                            >
                              <tbody>
                                <tr role="presentation">
                                  <td role="presentation" colSpan="2" />
                                </tr>
                                <tr role="presentation">
                                  <td role="presentation" colSpan="2">
                                    <div name="InvoiceDocAccessItemGroupContainer">
                                      <table
                                        name="InvoiceDocAccessItem_Table"
                                        id="CICInvoiceConsumerinvoiceAccessPageInvoiceDocAccessItem_table"
                                        width="100%"
                                        className="gridTable"
                                        cellSpacing="0"
                                        cellPadding="3"
                                        border="1"
                                      >
                                        <tbody>
                                          <tr
                                            name="TableHeaderContainer"
                                            className="tableHeadRow"
                                          >
                                            <th
                                              name="UserActionDttm_ColumnHeader"
                                              className="tableHead"
                                              nowrap=""
                                              scope="col"
                                            >
                                              <span
                                                name="UserActionDttmHeader"
                                                className="tableHeadText"
                                              >
                                                Time
                                              </span>
                                            </th>

                                            <th
                                              name="UserAction_ColumnHeader"
                                              className="tableHead"
                                              nowrap=""
                                              scope="col"
                                            >
                                              <span
                                                name="UserActionHeader"
                                                className="tableHeadText"
                                              >
                                                {" "}
                                                View/Download
                                              </span>
                                            </th>

                                            <th
                                              name="AuthId_ColumnHeader"
                                              className="tableHead"
                                              nowrap=""
                                              scope="col"
                                            >
                                              <span
                                                name="AuthIdHeader"
                                                className="tableHeadText"
                                              >
                                                User Id
                                              </span>
                                            </th>
                                          </tr>

                                          {popUpdata && popUpdata.length > 0 &&
                                            popUpdata.map((item, index) => (
                                              <tr
                                                key={
                                                  item.userActionDttm + index
                                                }
                                                name="DataContainer"
                                                className={
                                                  index % 2
                                                    ? "tableRowEven"
                                                    : "tableRowOdd"
                                                }
                                              >
                                                <td
                                                  name="UserActionDttm_ColumnData"
                                                  valign="top"
                                                  className="tableCell"
                                                >
                                                  <span
                                                    name="UserActionDttm"
                                                    className="outputData"
                                                  >
                                                    {item.userActionDttm}
                                                  </span>
                                                </td>

                                                <td
                                                  name="UserAction_ColumnData"
                                                  valign="top"
                                                  className="tableCell"
                                                >
                                                  <span
                                                    name="UserAction"
                                                    className="outputData"
                                                  >
                                                    {item.userAction}
                                                  </span>
                                                </td>

                                                <td
                                                  name="AuthId_ColumnData"
                                                  valign="top"
                                                  className="tableCell"
                                                >
                                                  <span
                                                    name="AuthId"
                                                    className="outputData"
                                                  >
                                                    {item.authId}
                                                  </span>
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                      </table>
                                    </div>
                                    <span name="InvoiceDocAccessItem_buttonSpace" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </span>
                    </div>
                  </form>
                </DialogContent>
              </Scrollbars>
            </Dialog>

            {responseMessage && reportName && (
              <Dialog
                onClose={() =>
                  this.handleInovoiceResponseClose(reportName, documentName)
                }
                aria-labelledby="customized-dialog-title"
                open={openInvoiceResponse}
                className="responsedialog"
              >
                <DialogTitle
                  id="customized-dialog-title"
                  onClose={() =>
                    this.handleInovoiceResponseClose(reportName, documentName)
                  }
                >
                  File Response - {reportName}
                </DialogTitle>
                <DialogContent>
                  {" "}
                  <p className="warningMsg body-text">{responseMessage}</p>
                </DialogContent>
              </Dialog>
            )}
            {this.state.downloadAzure && (
              <Dialog
                onClose={() =>
                  this.handleInovoiceResponseClose(reportName, documentName)
                }
                aria-labelledby="customized-dialog-title"
                open={openInvoiceResAzure}
                className="responsedialog"
              >
                <DialogTitle
                  id="customized-dialog-title"
                  onClose={() =>
                    this.handleInovoiceResponseClose(reportName, documentName)
                  }
                >
                  File Response - {reportName}
                </DialogTitle>
                <DialogContent>
                  {" "}
                  <p className="warningMsg body-text">{globalConstants.FILENOTFOUNDMSG}</p>
                </DialogContent>
              </Dialog>
            )}
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <div className="col-sm-9">
            <div id="fileContainer" />
            <div align="center">No Data found</div>
          </div>
          <div style={{ clear: "both" }} />
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = ({
  user,
  userProfile,
  auth,
  getClients,
  getDownloadInvoice,
  fetchDocumentUrl,
  fetchDownloadfromAzure
}) => {
  return { user, userProfile, auth, getClients, getDownloadInvoice, fetchDocumentUrl, fetchDownloadfromAzure };
};
export default connect(mapStateToProps)(InvoiceDetailTable);
