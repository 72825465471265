import React, { Component } from "react";
import { connect } from "react-redux";
import FormularyDrugSearch from "../components/FormularyLookup/FormularyDrugSearch/FormularyDrugSearch";
import { getDrugsSearchFieldsData } from "../actions/drugSearchAction";
import {getDrugsSearchFormularyFieldsData} from "../actions/drugSearchActionFormulary"

class FormularyDrugSearchContainer extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.getDrugSearchData();
  }

  getDrugSearchData() {
    const { dispatch, userAccount } = this.props;
    dispatch(getDrugsSearchFieldsData());

    if(userAccount) {
      dispatch(getDrugsSearchFormularyFieldsData(userAccount.selectedAccount));
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.drugSearchContent.drugSearchContent !== nextProps.drugSearchContent.drugSearchContent) {
      return true;
    }

    if (this.props.drugSearchFormularyContent.drugSearchFormularyContent !== nextProps.drugSearchFormularyContent.drugSearchFormularyContent) {
      return true;
    }
    return false;
  }

  render() {

    const { drugSearchContent, drugSearchFormularyContent } = this.props;

    // if (!drugSearchContent || !drugSearchContent.drugSearchContent || !drugSearchFormularyContent.drugSearchFormularyContent) {
    //   return 'Loading Formulary Drug Search...';
    // }

    return (
      <>
       {drugSearchContent.drugSearchContent && drugSearchFormularyContent.drugSearchFormularyContent && (! Array.isArray(drugSearchFormularyContent.drugSearchFormularyContent)) && (
          <FormularyDrugSearch
            drugSearchContent={drugSearchContent}
            drugSearchFormularyContent={drugSearchFormularyContent}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
   drugSearchContent: state.drugSearchContent,
   userAccount: state.userAccount,
   drugSearchFormularyContent: state.drugSearchFormularyContent
});

export default connect(mapStateToProps)(FormularyDrugSearchContainer);
