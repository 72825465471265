import React, { Component } from "react";
import LeftNavigation from "../ClinicalPublication/LeftNavigation/LeftNavigation";
import PublicationContent from "../ClinicalPublication/PublicationContent/PublicationContent";

class ClinicalPublication extends Component {
  state = {};

  render() {
    const { type, subType } = this.props;
    return (
      <React.Fragment>
        <h1>Clinical publications</h1>
        <div className="row- sep sep25 marB30">
          <LeftNavigation type={type} subType={subType} />
          <div className="col-sm-9">
            <PublicationContent type={type} subType={subType} />
            <div />
            <p />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ClinicalPublication;
