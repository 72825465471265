import React from "react";
import Print from "../Shared/Print/Print";
import Content from "../Shared/Content/Content";
import FollowUsContainer from "../../containers/FollowUsContainer";
import PreLoginFooterContainer from "../../containers/PreLoginFooterContainer";

const PrivacyPolicy = ({ auth, contentData, whiteLabelVal }) => {
  const { isAuthenticated } = auth;
  return (
    <main className={isAuthenticated ? "rPad" : "divider rPad"}>
      <div className="container">
        <div className="row">
          <h1>
            {contentData && contentData.title && (
              <Content html={contentData.title} />
            )}
          </h1>
          <Print onClick={() => window.print()} />
          {contentData && contentData.body && (
            <Content html={contentData.body} />
          )}
        </div>
        {!isAuthenticated && !whiteLabelVal && <FollowUsContainer />}
        {!isAuthenticated && !whiteLabelVal && <PreLoginFooterContainer />}
      </div>
    </main>
  );
};

export default PrivacyPolicy;
