import React from "react";
import { NavLink } from "react-router-dom";
import routePath from "../../../../constants/routes";
const PTCommitteeSupport ="P&TCommitteeSupport";

const PandTCommitteeLN = ({ type, subType }) => {
  return (
    <React.Fragment>
      <strong
        className={type === PTCommitteeSupport && !subType ? "selected" : ""}
      >
        <NavLink
          link-id="P&T Committee support"
          to={routePath.relatedLink_PTCommitteeSupport}
        >
          P&amp;T Committee support
        </NavLink>
      </strong>
      <ul>
        <li
          className={
            type === PTCommitteeSupport &&
            subType === "TherapeuticClassReviews"
              ? "selected"
              : ""
          }
        >
          <NavLink
            link-id="Therapeutic class reviews"
            to={
              routePath.relatedLink_PTCommitteeSupport +
              "?type=TherapeuticClassReviews"
            }
          >
            Therapeutic class reviews
          </NavLink>
        </li>

        <li
          className={
            type === PTCommitteeSupport && subType === "DrugMonographs"
              ? "selected"
              : ""
          }
        >
          <NavLink
            link-id="Drug monographs"
            to={
              routePath.relatedLink_PTCommitteeSupport + "?type=DrugMonographs"
            }
          >
            Drug monographs
          </NavLink>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default PandTCommitteeLN;
