import {
  SIGNIN_CONTENT_REQUEST,
  SIGNIN_CONTENT_SUCCESS,
  SIGNIN_CONTENT_FAILURE,
  OPTUMID_SIGNIN_CONTENT_REQUEST,
  OPTUMID_SIGNIN_CONTENT_SUCCESS,
  OPTUMID_SIGNIN_CONTENT_FAILURE,
} from "../actions/signInContent";

export function signInContent(
  state = {
    isFetching: false,
    signInContent: "",
    isOptumIDFetching: false,
    optumIdSignInContent: "",
  },
  action
) {
  switch (action.type) {
    case SIGNIN_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        signInContent: "",
      });
    case SIGNIN_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        signInContent: action.signInData,
      });
    case SIGNIN_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    case OPTUMID_SIGNIN_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isOptumIDFetching: true,
        optumIdSignInContent: "",
      });
    case OPTUMID_SIGNIN_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isOptumIDFetching: false,
        optumIdSignInContent: action.optumIdsignInData,
      });
    case OPTUMID_SIGNIN_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isOptumIDFetching: false,
        isOptumIdError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default signInContent;
