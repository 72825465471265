import React from "react";
import { Select, MenuItem, Box } from "@material-ui/core";
import { FormularySelectStyles } from "./FormularySelectStyles";
import { OutlinedInput } from "@material-ui/core";

const FormularySelect = ({
  value,
  name,
  onChange,
  values,
  disabled,
  showEllipseText,
  displayNameAttribute,
  displayValueAttribute,
  menuItemwidth,
}) => {
  const classes = FormularySelectStyles();
  if (!Array.isArray(values)) {
    values = [];
  }
  try {
      if (values.length &&  typeof values[0] ==='object'){
        if (!displayNameAttribute) { throw new Error('displayNameAttribute is missing')}
        if (!displayValueAttribute) { throw new Error('displayValueAttribute is missing')}
      }

          function mapMenuItem(
            values,
            showEllipseText,
            displayNameAttribute,
            displayValueAttribute
          ) {

              return values.map((value, index) => {
                let displayText;
                let displayValue;
                if (typeof value === 'object' && displayNameAttribute && displayValueAttribute) {
                    displayText = value[displayNameAttribute];
                    displayValue = value[displayValueAttribute]
                } else {
                  displayText = value;
                  displayValue = value;
                 }

                return(
                  <MenuItem key={index} value={displayValue}>
                  {
                    showEllipseText ? 
                      (<Box
                        sx={{
                          width: menuItemwidth,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {displayText}
                      </Box>)
                      :
                      displayText
                  }
                </MenuItem>
                )
              });
            }

        
          return (
            <Select
              value={value}
              name={name}
              onChange={onChange}
              input={<OutlinedInput />}
              className={classes.textstyle}
              disabled={disabled}
            >
              {mapMenuItem(
                values,
                showEllipseText,
                displayNameAttribute,
                displayValueAttribute
              )}
            </Select>
            )
          } catch(error) {
            const message = error.message || 'Error Occured'
            return <span className={classes.errorMessage}>Error: {message} in the FormularySelect Component</span>
          }
     
        }
    
export default FormularySelect;
