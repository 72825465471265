import React, { Component } from "react";
import { connect } from "react-redux";
import { getInvoiceContent } from "../actions/invoiceContent";
import { getClientsData } from "../actions/getClients";
import Invoice from "../components/FileCabinet/InvoicesTab/Invoice";
import {
  // isInternal,
  isExternal
} from "../constants/service";

class InvoiceAndFinancialReportContainer extends Component {
  state = {
    invoice: "",
    clientsData: "",
    initialLoadComplete: false
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setInvoice(nextprops);
    this.getClientsDropdownData(nextprops);
  }

  componentDidMount() {
    this.setInvoice(this.props);
    this.getClientsDropdownData(this.props);
  }

  setInvoice(props) {
    const { isFetching, invoiceContent, isError } = props;
    if (!isError) {
      if (!isFetching) {
        if (invoiceContent) {
          if (invoiceContent && invoiceContent !== this.state.invoice) {
            this.setState({
              invoice: invoiceContent[0]
            });
          }
        } else {
          this.props.dispatch(getInvoiceContent());
        }
      }
    }
  }

  getClientsDropdownData(nextProps) {
    let userName = this.getName();
    const { getClients, userProfile } = nextProps;
    if (!getClients.isError) {
      if (!getClients.isFetching) {
        if (getClients.clients) {
          if (
            getClients.clients &&
            getClients.clients !== this.state.clientsData
          ) {
            this.setState({
              clientsData: getClients.clients,
              initialLoadComplete: true
            });
          }
        } else {
          const { user } = this.props;
          let typeId;
          if (isExternal(user)) {
            typeId = 2;
          } else {
            typeId = 1;
          }
          this.props.dispatch(
            getClientsData(userName, userProfile.opData, typeId)
          );
        }
      }
    }
  }

  getName() {
    let userName;
    const { userProfile } = this.props;
    if (userProfile && userProfile.profileData) {
      userName = this.getNameFromProfile(userProfile.profileData);
    }
    return userName;
  }

  getNameFromProfile(profileData) {
    const { user } = this.props;
    if (!isExternal(user)) {
      return profileData.sub;
    } else {
      return profileData["preferred_username"];
    }
  }

  configClientData(userName, clientsData) {
    return clientsData.find(item => {
      return item.userName === userName;
    });
  }

  render() {
    const { isDetailVisibleProp, setPageVisiblity } = this.props;
    const { invoice, clientsData } = this.state;
    if (!this.state.initialLoadComplete) {
      return (
        <React.Fragment>
          {
            <h5>
              <p style={{ textAlign: "center", marginTop: "10px" }}>
                Loading...
              </p>
            </h5>
          }
        </React.Fragment>
      );
    } else {
      if (clientsData && clientsData === "No Data Found") {
        return (
          <div id="wrapper">
            <div className="tabWrapper">
              <div className="invoice-wrapper2 padT20">
                <div className="container30">
                  <b>
                    You do not have access to invoices and financial reports. If
                    you require access, please contact your account
                    representative.
                  </b>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (clientsData && clientsData === "Service error") {
        return (
          <div id="wrapper">
            <div className="tabWrapper">
              <div className="invoice-wrapper2 padT20">
                <div className="container30">
                  <b>Service is currently down. Please try again.</b>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (clientsData && clientsData.length > 0) {
        var dropdownData = clientsData;
        return (
          <React.Fragment>
            <Invoice
              invoiceContent={invoice}
              dropdownData={dropdownData}
              isDetailVisibleProp={isDetailVisibleProp}
              setPageVisiblity={setPageVisiblity}
            />
          </React.Fragment>
        );
      } else {
        return null;
      }
    }
  }
}

const mapStateToProps = ({
  invoiceContent,
  getClients,
  userProfile,
  auth,
  user
}) => {
  return { ...invoiceContent, getClients, userProfile, auth, user };
};

export default connect(mapStateToProps)(InvoiceAndFinancialReportContainer);
