import { fetchReportLinks } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
import globalConstants from "../constants/global-constants";
export const GET_REPORT_LINKS_SUCCESS = "GET_REPORT_LINKS_SUCCESS";

export function requestReportLinks(accountId) {
  const ReportLinks = {};
  ReportLinks["accountId"] = accountId;
  ReportLinks["isFetching"] = true;
  ReportLinks["isError"] = false;
  return {
    type: GET_REPORT_LINKS_SUCCESS,
    data: ReportLinks,
  };
}

export function receiveReportLinks(res, accountId) {
  const ReportLinks = {};
  ReportLinks["links"] = res;
  ReportLinks["accountId"] = accountId;
  ReportLinks["isFetching"] = false;
  ReportLinks["isError"] = false;
  return {
    type: GET_REPORT_LINKS_SUCCESS,
    data: ReportLinks,
  };
}

export function ReportLinksFailure(message, accountId) {
  const ReportLinks = {};
  ReportLinks["accountId"] = accountId;
  ReportLinks["links"] = message;
  ReportLinks["isFetching"] = false;
  ReportLinks["isError"] = true;
  return {
    type: GET_REPORT_LINKS_SUCCESS,
    data: ReportLinks,
    message,
  };
}

export function getReportLinksData(accountId) {
  return (dispatch) => {
    dispatch(requestReportLinks(accountId));
    const url = fetchReportLinks(accountId);
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(receiveReportLinks(data, accountId));
          return data;
        } else {
          dispatch(
            ReportLinksFailure(globalConstants.UNABLETOGETTEXT, accountId)
          );
          return Promise.reject(data);
        }
      })
      .catch(function (error) {
        dispatch(
          ReportLinksFailure(globalConstants.UNABLETOGETTEXT, accountId)
        );
      });
  };
}
