import React, { Component } from "react";
import { Box } from "@material-ui/core";
import BASTable from "./InvoicesTab/BASTable";

class BASDetailTable extends Component {
  state = {};
  resultData = this.props.basData;
  render() {
    const {
      filterObject,
      handleDownloadZip,
      handleReprint,
      handleJobSummary,
      handleOpen,
      basData,
      pageSize,
      page,
      handlePageSize,
      handlePageChange,
      isLoading,
      productTypeList,
    } = this.props;

    if (isLoading) {
      return (
        <React.Fragment>
          <div className="col-sm-9" style={{ mrginTop: "20px" }}>
            <div id="fileContainer" />
            <Box pl={6} mb={5} fontWeight={600} fontSize={22}>
              Loading...
            </Box>
          </div>
          <div style={{ clear: "both" }} />
        </React.Fragment>
      );
    } else if (basData && basData.value && basData.value.length > 0) {
      return (
        <React.Fragment>
          <Box px={3} mt={5}>
            <BASTable
              pageSize={pageSize}
              productTypeList={productTypeList}
              page={page}
              handlePageChange={handlePageChange}
              handlePageSize={handlePageSize}
              totalResult={basData.totalCount}
              resultData={basData.value}
              filterObject={filterObject}
              handleDownloadZip={handleDownloadZip}
              handleReprint={handleReprint}
              handleJobSummary={handleJobSummary}
              handleOpen={handleOpen}
            />
          </Box>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className="col-sm-9" style={{ mrginTop: "20px" }}>
          <div id="fileContainer" />
          <Box pl={6} mb={5} fontWeight={600} fontSize={17}>
            Sorry, we couldn't find a file that has the selected criteria.
          </Box>
        </div>
        <div style={{ clear: "both" }} />
      </React.Fragment>
    );
  }
}

export default BASDetailTable;
