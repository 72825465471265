import { fetchAccountMetadata } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const ACCOUNT_METADATA_CONTENT_SUCCESS =
  "ACCOUNT_METADATA_CONTENT_SUCCESS";

export function requestAccountMetaDataContent(accountId) {
  const accountMetaDataResponse = {};
  accountMetaDataResponse["accountId"] = accountId;
  accountMetaDataResponse["isFetching"] = true;
  accountMetaDataResponse["isError"] = false;
  return {
    type: ACCOUNT_METADATA_CONTENT_SUCCESS,
    data: accountMetaDataResponse,
  };
}

export function receiveAccountMetaDataContent(data, accountId) {
  const accountMetaDataResponse = {};
  accountMetaDataResponse["accountId"] = accountId;
  accountMetaDataResponse["accountMetaData"] = data;
  accountMetaDataResponse["isFetching"] = false;
  accountMetaDataResponse["isError"] = false;

  return {
    type: ACCOUNT_METADATA_CONTENT_SUCCESS,
    data: accountMetaDataResponse,
  };
}

export function accountMetaDataContentFailure(message, accountId) {
  const accountMetaDataResponse = {};
  accountMetaDataResponse["accountId"] = accountId;
  accountMetaDataResponse["isFetching"] = false;
  accountMetaDataResponse["isError"] = true;
  return {
    type: ACCOUNT_METADATA_CONTENT_SUCCESS,
    message,
    data: accountMetaDataResponse,
  };
}

export function getAccountMetaDataContent(accountId) {
  if (accountId) {
    return (dispatch) => {
      dispatch(requestAccountMetaDataContent(accountId));
      const url = fetchAccountMetadata(accountId);
      http
        .get(url)
        .then((response) => {
          const data = response.data;
          if (response.status === 200) {
            dispatch(receiveAccountMetaDataContent(data, accountId));
            return data;
          } else {
            dispatch(accountMetaDataContentFailure(data.message, accountId));
            return Promise.reject(data);
          }
        })
        .catch(function (error) {
          dispatch(accountMetaDataContentFailure(error));
        });
    };
  }
  return null;
}
