import React, { Component } from "react";
import "./Table.scss";

class Table extends Component {
  state = {};

  render() {
    const { invoiceMonths, handleMonthSubmit } = this.props;
    return (
      <React.Fragment>
        <div className="tbl-wrapper marB20" name="invoicemonthpage">
          <table
            className="displayPageTable tdPad5 tdBor c"
            cellPadding="3"
            cellSpacing="1"
            name="OuterTable"
            width="100%"
          >
            <tbody>
              <tr name="MonthNode_TableWrapper">
                <td
                  colSpan="4"
                  className="outputDataCell"
                  style={{ padding: 0 }}
                  width="100%"
                >
                  <table
                    name="MonthNode_Table"
                    id="CICInvoiceConsumerinvoicehomepageMonthNode_table"
                    width="100%"
                    className="gridTable"
                    cellSpacing="0"
                    cellPadding="3"
                  >
                    <tbody>
                      <tr name="TableHeaderContainer" className="tableHeadRow">
                        <th
                          name="monthimagefolder_ColumnHeader"
                          className="tableHead"
                          nowrap=""
                        >
                          <span
                            name="monthimagefolderHeader"
                            className="invoicemonthimagefolderHeader"
                          >
                            View
                          </span>
                        </th>

                        <th
                          name="MonthName_ColumnHeader"
                          className="tableHead"
                          nowrap=""
                        >
                          <span
                            name="MonthNameHeader"
                            className="invoicemonthnameheader"
                          >
                            Month
                          </span>
                        </th>
                      </tr>

                      {invoiceMonths &&
                        invoiceMonths.length > 0 &&
                        invoiceMonths.map((item, i) => (
                          <tr
                            key={item.name}
                            name="DataContainer"
                            className={i % 2 ? "tableRowEven" : "tableRowOdd"}
                          >
                            <td
                              name="monthimagefolder_ColumnData"
                              valign="top"
                              className="invoicemonthimagefolder_ColumnData"
                            >
                              <a
                                href={" "}
                                link-id={
                                  "invoice and financial reports - view " +
                                  item.name
                                }
                                name="monthimagefolder"
                                onClick={e => {
                                  e.preventDefault();
                                  handleMonthSubmit(
                                    item.lastDate,
                                    item.startDate
                                  );
                                }}
                              >
                                <img
                                  height="16"
                                  width="23"
                                  name="BstImg63574b"
                                  className="outputData"
                                  src="/assets/images/folder-icon.jpg"
                                  border="0"
                                  alt=""
                                />
                              </a>
                            </td>

                            <td
                              name="MonthName_ColumnData"
                              valign="top"
                              className="invoiceMonthName_ColumnData"
                            >
                              <span
                                name="MonthName"
                                className="invoicemonthname"
                              >
                                {item.name}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <span name="MonthNode_buttonSpace" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default Table;
