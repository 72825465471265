import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Select, MenuItem } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const effect = "none !important";
class JumpNavigation extends Component {
  getMuiTheme = () => createMuiTheme({
    overrides: {
      CssBaseline: {
        "@global": {
          "*, *::before, *::after": {
            transition: effect,
            animation: effect
          }
        }
      },
      MuiMenu: {
        list: {
          padding: "0",
        },
        paper: {
          border: "1px solid #cccccc",
          boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
          marginTop: "-1px",
          maxHeight: "300px",
        }
      },
      MuiMenuItem: {
        root: {
          color: "#333333",
          fontFamily: '"frutiger-light", Arial, sans-serif',
          fontSize: "13px",
          height: "unset",
          lineHeight: "18px",
          minHeight: "18px",
          padding: "5px 10px",
          whiteSpace: "normal",
          "&:hover": {
            background: "#eaeaea"
          },
          "&$selected": {
            background: "#f3f3f3"
          }
        },
      },
      MuiSelect: {
        select: {
          border: "1px solid #cccccc",
          borderRadius: "3px",
          color: "#333741",
          fontFamily: '"frutiger-light", Arial, sans-serif',
          fontSize: "14px",
          marginLeft: "10px",
          padding: "6px 20px 6px 10px",
          "&:focus": {
            background: effect,
            borderRadius: "3px"
          }
        },
        icon: {
          color: "#cccccc"
        }
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true
      },
      MuiInput: {
        disableUnderline: true
      },
      MuiMenu: {
        elevation: 0,
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      },
      MuiPaper: {
        square: true
      },
    },
    transitions: {
      create: () => "none"
    }
  });

  render() {
    const { pageSize, itemCount, currentPage, onGoToPageChange } = this.props;
    const pageCount = itemCount / pageSize;
    const pages = _.range(1, pageCount + 1);
    return (
      <div className="pageSelect">
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <label>Go to page</label>
          <Select value={currentPage} onChange={onGoToPageChange}>
            {pages.map(page => (
              <MenuItem selected={page === currentPage} key={page} value={page}>
                {page}
              </MenuItem>
            ))}
          </Select>
        </MuiThemeProvider>

        {/* <div className="cusSelect" id="invoicePagingdropDown">
          <select onChange={onGoToPageChange}>
            {pages.map(page => (
              <option selected={page === currentPage} key={page} value={page}>
                {page}
              </option>
            ))}
          </select>
        </div> */}

      </div>
    );
  }
}

JumpNavigation.propTypes = {
  pageSize: PropTypes.number.isRequired,
  itemCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onGoToPageChange: PropTypes.func.isRequired
};

export default JumpNavigation;
