import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getHomeDeliveryPart,
} from "../actions/homeDelivery";
import HomeDelivery from "../components/HomeDelivery/HomeDelivery";
import { isInternal } from "../constants/service";
import globalConstants from "../constants/global-constants";

class HomeDeliveryContainer extends Component {
  state = {
    homeDelivery: "",
  };
  UNSAFE_componentWillReceiveProps(newProps) {
    this.getHomeDeliveryData(newProps);
  }

  componentDidMount() {
    document.title = "Client Portal - Home delivery";
    this.getHomeDeliveryData(this.props);
  }

  getHomeDeliveryData(props) {
    const { selectedAccount, selectedMode, user, homeDelivery } = props;
    if (isInternal(user)) {
      this.configureForInternalUser(homeDelivery, selectedMode);
    } else {
      this.configuredForAccounts(selectedAccount, homeDelivery);
    }
  }

  configureForInternalUser(homeDelivery, selectedMode) {
    const homeDeliveryInfo = this.configHomedelivery(
      selectedMode,
      homeDelivery.homeDelivery
    );
    if (homeDeliveryInfo) {
      this.setState({
        homeDelivery: homeDeliveryInfo,
      });
    } else {
      this.props.dispatch(getHomeDeliveryPart(selectedMode));
    }
  }

  configuredForAccounts(selectedAccount, homeDelivery) {
    if (selectedAccount) {
      const pageType =
        selectedAccount[globalConstants.NAVIGATION_HOME_DELIVERY_TAB];
      if (homeDelivery) {
        const homeDeliveryInfo = this.configHomedelivery(
          selectedAccount[globalConstants.NAVIGATION_HOME_DELIVERY_TAB],
          homeDelivery.homeDelivery
        );
        if (homeDeliveryInfo) {
          this.setState({
            homeDelivery: homeDeliveryInfo,
          });
        } else {
          this.props.dispatch(getHomeDeliveryPart(pageType));
        }
      }
    }
  }

  configHomedelivery(pageType, homeDelivery) {
    return homeDelivery.find((item) => {
      return item.pageType === pageType;
    });
  }

  render() {
    const { homeDelivery } = this.state;
    var homeDeliveryData = [];
    if (homeDelivery && homeDelivery.value && homeDelivery.value.length > 0) {
      homeDeliveryData = homeDelivery.value;
      return <HomeDelivery contentData={homeDeliveryData} />;
    }
    return null;
  }
}

const mapStateToProps = ({ userAccount, homeDelivery, user, auth }) => {
  return {
    selectedMode: userAccount.selectedMode,
    selectedAccount: userAccount.selectedAccount,
    homeDelivery,
    user,
    auth,
  };
};

export default connect(mapStateToProps)(HomeDeliveryContainer);
