import React from "react";
import { NavLink } from "react-router-dom";
import routePath from "../../../../constants/routes";
const UtilizationManagementLN = ({ type, subType }) => {
  return (
    <React.Fragment>
      <strong
        className={
          type === "UtilizationManagement" && !subType ? "selected" : ""
        }
      >
        <NavLink
          link-id=" Utilization management"
          to={routePath.relatedLink_UtilizationManagement}
        >
          Utilization management
        </NavLink>
      </strong>
      <ul>
        <li
          className={
            type === "UtilizationManagement" &&
            subType === "OptumRxStandardQuantityLimit"
              ? "selected"
              : ""
          }
        >
          <NavLink
            link-id="OptumRx standard quantity limit"
            to={
              routePath.relatedLink_UtilizationManagement +
              "?type=OptumRxStandardQuantityLimit"
            }
          >
            OptumRx standard quantity limit (title)
          </NavLink>
        </li>

        <li
          className={
            type === "UtilizationManagement" &&
            subType === "OptumRxStandardStepTherapy"
              ? "selected"
              : ""
          }
        >
          <NavLink
            link-id="OptumRx standard step therapy (ST) program"
            to={
              routePath.relatedLink_UtilizationManagement +
              "?type=OptumRxStandardStepTherapy"
            }
          >
            OptumRx standard step therapy (ST) program
          </NavLink>
        </li>
        <li
          className={
            type === "UtilizationManagement" &&
            subType === "OptumRStandardPriorAuthorization"
              ? "selected"
              : ""
          }
        >
          <NavLink
            link-id="OptumRx standard Prior Authorization (PA) List"
            to={
              routePath.relatedLink_UtilizationManagement +
              "?type=OptumRStandardPriorAuthorization"
            }
          >
            OptumRx standard Prior Authorization (PA) List
          </NavLink>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default UtilizationManagementLN;
