import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const CONTACTUS_CONTENT_REQUEST = "CONTACTUS_CONTENT_REQUEST";
export const CONTACTUS_CONTENT_SUCCESS = "CONTACTUS_CONTENT_SUCCESS";
export const CONTACTUS_CONTENT_FAILURE = "CONTACTUS_CONTENT_FAILURE";
export const FEEDBACK_PAGE_CONTENT_REQUEST = "FEEDBACK_PAGE_CONTENT_REQUEST";
export const FEEDBACK_PAGE_CONTENT_SUCCESS = "FEEDBACK_PAGE_CONTENT_SUCCESS";
export const FEEDBACK_PAGE_CONTENT_FAILURE = "FEEDBACK_PAGE_CONTENT_FAILURE";
export const THANK_YOU_PAGE_CONTENT_REQUEST = "THANK_YOU_PAGE_CONTENT_REQUEST";
export const THANK_YOU_PAGE_CONTENT_SUCCESS = "THANK_YOU_PAGE_CONTENT_SUCCESS";
export const THANK_YOU_PAGE_CONTENT_FAILURE = "THANK_YOU_PAGE_CONTENT_FAILURE";
export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

function requestContactUsContent() {
  return {
    type: CONTACTUS_CONTENT_REQUEST,
    isFetched: false
  };
}

function receiveContactUsContent(data) {
  return {
    type: CONTACTUS_CONTENT_SUCCESS,
    isFetched: true,
    data
  };
}

function contactUsContentFailure(message) {
  return {
    type: CONTACTUS_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message
  };
}

function requestFeedbackPageContent() {
  return {
    type: FEEDBACK_PAGE_CONTENT_REQUEST,
    isFetched: false
  };
}

function receiveFeedbackPageContent(data) {
  return {
    type: FEEDBACK_PAGE_CONTENT_SUCCESS,
    isFetched: true,
    data
  };
}

function feedbackPageContentFailure(message) {
  return {
    type: FEEDBACK_PAGE_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message
  };
}

function requestThankYouPageContent() {
  return {
    type: THANK_YOU_PAGE_CONTENT_REQUEST,
    isFetched: false
  };
}

function receiveThankYouPageContent(data) {
  return {
    type: THANK_YOU_PAGE_CONTENT_SUCCESS,
    isFetched: true,
    data
  };
}

function thankYouPageContentFailure(message) {
  return {
    type: THANK_YOU_PAGE_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message
  };
}

function requestSendEmail() {
  return {
    type: SEND_EMAIL_REQUEST
  };
}

function receiveSendEmail(data) {
  return {
    type: SEND_EMAIL_SUCCESS,
    emailData: data
  };
}

function SendEmailFailure(message) {
  return {
    type: SEND_EMAIL_FAILURE,
    isSentEmailError: true,
    emailMessage: message
  };
}

export function getContactUsContent() {
  return dispatch => {
    dispatch(requestContactUsContent());
    const url = fetchGeneralContentUrl("/cms/getNodeByCode/page/ContactUs");
    http
      .get(url)
      .then(response => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(contactUsContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveContactUsContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(contactUsContentFailure(error));
      });
  };
}

export function getFeedbackPageContent() {
  return dispatch => {
    dispatch(requestFeedbackPageContent());
    const url = fetchGeneralContentUrl("/cms/getNodeByCode/page/Feedback");
    http
      .get(url)
      .then(response => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(feedbackPageContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveFeedbackPageContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(feedbackPageContentFailure(error));
      });
  };
}

export function sendFeedbackViaEmail(req) {
  return dispatch => {
    dispatch(requestSendEmail());
    const url = fetchGeneralContentUrl("/cms/getFeedback");
    http
      .post(url, JSON.stringify(req))
      .then(response => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(SendEmailFailure("Failed"));
          return Promise.reject(data);
        } else {
          dispatch(receiveSendEmail("Mail Sent"));
          dispatch(getThankYouPageContent());
          return data;
        }
      })
      .catch(function (error) {
        dispatch(SendEmailFailure(error));
      });
  };
}

export function getThankYouPageContent() {
  return dispatch => {
    dispatch(requestThankYouPageContent());
    const url = fetchGeneralContentUrl("/cms/getNodeByCode/page/ThankYou");
    http
      .get(url)
      .then(response => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(thankYouPageContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveThankYouPageContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(thankYouPageContentFailure(error));
      });
  };
}
