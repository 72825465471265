import React from "react";
import Content from "../Shared/Content/Content";
const NoAccess = ({ contentData }) => {
  return (
    <div className="row">
      <h1>
        {contentData && contentData.title && (
          <Content html={contentData.title} />
        )}
      </h1>
      {contentData && contentData.body && <Content html={contentData.body} />}
    </div>
  );
};

export default NoAccess;
