import {
  GETCLIENTS_REQUEST,
  GETCLIENTS_SUCCESS,
  GETCLIENTS_FAILURE,
} from "../actions/getClients";

export function getClients(
  state = {
    isFetching: false,
    clients: "",
    userName: "",
    typeId: "",
  },
  action
) {
  switch (action.type) {
    case GETCLIENTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        clients: "",
        userName: action.userName,
        typeId: action.typeId,
      });
    case GETCLIENTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        clients: action.data,
        userName: action.userName,
        typeId: action.typeId,
      });
    case GETCLIENTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
        userName: action.userName,
        typeId: action.typeId,
      });
    default:
      return state;
  }
}

export default getClients;
