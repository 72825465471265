import { makeStyles, createStyles } from "@material-ui/core";

export const IndeterminateCheckboxStyle = makeStyles((theme) =>
  createStyles({
    textstyle: {
    '& span' :{
      color: (props) => props?.color || "black"
    }
},
label: {
    fontWeight: theme.typography.h3.fontWeight,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.h3.lineHeight,
    color: "#5A5A57A",
  },
  })
);
