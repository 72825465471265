import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const COPYRIGHT_CONTENT_REQUEST = "COPYRIGHT_CONTENT_REQUEST";
export const COPYRIGHT_CONTENT_SUCCESS = "COPYRIGHT_CONTENT_SUCCESS";
export const COPYRIGHT_CONTENT_FAILURE = "COPYRIGHT_CONTENT_FAILURE";

export function requestCopyRightContent() {
  return {
    type: COPYRIGHT_CONTENT_REQUEST,
    isFetched: false,
  };
}

export function receiveCopyRightContent(copyRightData) {
  return {
    type: COPYRIGHT_CONTENT_SUCCESS,
    isFetched: true,
    copyRightData,
  };
}

export function copyRightContentFailure(message) {
  return {
    type: COPYRIGHT_CONTENT_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getCopyRightContent() {
  return (dispatch) => {
    dispatch(requestCopyRightContent());
    const url = fetchGeneralContentUrl("/cms/footer");
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(copyRightContentFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveCopyRightContent(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(copyRightContentFailure(error));
      });
  };
}
