import React, { Component } from "react";
import RelatedLink from "../components/GovernmentPrograms/RelatedLinksComponent/RelatedLink/RelatedLink";
import globalConstants from "../constants/global-constants";

class GovernmentProgramRelatedLinksContainer extends Component {
  state = {};
  render() {
    const {
      location,
      match: { params }
    } = this.props;
    const parameter = new URLSearchParams(location.search);
    const tags = parameter.get(globalConstants.TYPE);
    if (params && params.type) {
      return <RelatedLink type={params.type} subType={tags} />;
    }
    return null;
  }
}

export default GovernmentProgramRelatedLinksContainer;
