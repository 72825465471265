import React, { Component } from "react";
import { connect } from "react-redux";
import { getSignOutContent } from "../actions/signOutContent";
import SignOut from "../components/SignOut/SignOut";

class SignOutContentContainer extends Component {
  state = {
    signOut: ""
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setSignOut(nextprops);
  }

  componentDidMount() {
    this.setSignOut(this.props);
  }

  setSignOut(props) {
    const { isFetching, signOutContent, isError } = props;
    if (!isError) {
      if (!isFetching) {
        if (signOutContent) {
          if (signOutContent && signOutContent !== this.state.signOut) {
            this.setState({ signOut: signOutContent });
          }
        } else {
          this.props.dispatch(getSignOutContent());
        }
      }
    }
  }

  render() {
    const contentData = this.state.signOut[0];
    return <SignOut contentData={contentData} />;
  }
}

const mapStateToProps = ({ signOutContent }) => {
  return signOutContent;
};

export default connect(mapStateToProps)(SignOutContentContainer);
