import React, { Component } from "react";
import routePath from "../../../constants/routes";
import { NavLink } from "react-router-dom";

class RelatedLinksComponent extends Component {
  state = {};
  render() {
    return (
      <article>
        <div className="list">
          <h6>Related Links</h6>

          <div className="content">
            <p dir="ltr" />
            <ul>
              <li>
                <NavLink link-id={"Employer Group Waiver Plans"} to={routePath.relatedLink_EmployerGroupWaiverPlans}>
                  Employer Group Waiver Plans
                </NavLink>
              </li>
              <li>
                <NavLink link-id={"Health Insurance Exchanges"} to={routePath.relatedLink_HealthInsuranceExchanges}>
                  Health Insurance Exchanges
                </NavLink>
              </li>
              <li>
                <NavLink link-id={"Managed Medicaid"} to={routePath.relatedLink_ManagedMedicaid}>
                  Managed Medicaid
                </NavLink>
              </li>
              <li>
                <NavLink link-id={"Medicare Part D"} to={routePath.relatedLink_MedicarePartD}>
                  Medicare Part D
                </NavLink>
              </li>
            </ul>
            <p />
          </div>
        </div>
      </article>
    );
  }
}

export default RelatedLinksComponent;
