import React from "react";
import { connect } from "react-redux";
import Tree, { TreeNode } from "rc-tree";
import "./FileTree.scss";
import "./FileTreeIndex.css";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import isEqual from "react-fast-compare";
import globalConstants from "../../../constants/global-constants";
import {
  resetFileTreeContent,
  getFileDataById,
  updateTreeContent,
} from "../../../actions/fileTree";
import {
  getallFiles,
  clearUpFileStrucutre,
} from "../../../actions/getAllFiles";
import { convertRelativeFileUrlToAbsoluteUrl } from "../../../constants/service";
import dayjs from "dayjs";
import dayjsUtc from "dayjs/plugin/utc";
import { getFilesFromBytes } from "../../../actions/filesFromBytes";
import FormHelperText from "@material-ui/core/FormHelperText";
import store from "../../../store/store";

// Add UTC plugin to Dayjs
dayjs.extend(dayjsUtc);

let modData = "";
let searchResults = "";
let searchValue = "";
let keys = [];
let fileTitle = "";

// Mapping of file types to icon image names
const fileTypeIconMapping = require("./fileTypeIconMapping.json");
const folderType = "type=folder";
let userProfile = "";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function spl(str) {
  return str.split("-");
}

function isSibling(pos, pos1) {
  pos.pop();
  pos1.pop();
  return pos.join(",") === pos1.join(",");
}

function splitLen(str) {
  return str.split("-").length;
}

function loopData(data, callback) {
  const loop = (d, level = 0) => {
    d &&
      d.forEach((item, index) => {
        const pos = `${level}-${index}`;
        if (item && item.children) {
          loop(item.children, pos);
        }
        callback(item, index, pos);
      });
  };
  loop(data);
}

export function getRadioSelectKeys(data, selectedKeys, key) {
  const res = [];
  const pkObjArr = [];
  const selPkObjArr = [];
  loopData(data, (item, index, pos) => {
    if (item && selectedKeys.indexOf(item.key) > -1) {
      pkObjArr.push([pos, item.key]);
    }
    if (key && item && key === item.key) {
      selPkObjArr.push(pos, item.key);
    }
  });
  const lenObj = {};
  const getPosKey = (pos, k) => {
    const posLen = splitLen(pos);
    if (!lenObj[posLen]) {
      lenObj[posLen] = [[pos, k]];
    } else {
      lenObj[posLen].forEach((pkArr, i) => {
        if (isSibling(spl(pkArr[0]), spl(pos))) {
          lenObj[posLen][i] = [pos, k];
        } else if (spl(pkArr[0]) !== spl(pos)) {
          lenObj[posLen].push([pos, k]);
        }
      });
    }
  };
  pkObjArr.forEach((pk) => {
    getPosKey(pk[0], pk[1]);
  });
  if (key) {
    getPosKey(selPkObjArr[0], selPkObjArr[1]);
  }

  Object.keys(lenObj).forEach((item) => {
    lenObj[item].forEach((i) => {
      if (res.indexOf(i[1]) === -1) {
        res.push(i[1]);
      }
    });
  });
  return res;
}

// Get file extension from a given filename
function extractFiletypeFromFilename(filename) {
  return filename && filename.toLowerCase().split(".").pop();
}

const Icon = ({ filename }) => {
  const fileType = extractFiletypeFromFilename(filename);
  let iconImage = fileTypeIconMapping[fileType] || "unknown.jpg";
  const folderCheck = filename === folderType;
  if (filename === folderType) {
    iconImage = "folderImg.GIF";
  }
  return (
    <div className={folderCheck ? "imgIcon" : ""}>
      {" "}
      <img src={`/assets/images/file_icons/${iconImage}`} alt="" />
    </div>
  );
};

class FileTree extends React.Component {
  initialState = {
    expandedKeys: [],
    autoExpandParent: false,
    checkedKeys: [],
    selectedKeys: [],
    fileTreeContent: null,
    treeData: [],
    initialLoadComplete: false,
    error: false,
    allFileNames: null,
    isLoading: false,
    flag: false,
    expanded: [],
    isEmpty: false,
    fetchFileStructure: false,
    fetchFileStructureError: false,
    fileNotFnd: false,
    dnloadFlag: false,
    downloadable: false,
    clickedFile: false,
    // userProfile: ""
  };

  state = this.initialState;
  activeSort = null;

  componentDidMount() {
    if (this.props.userProfile) {
      userProfile = this.props.userProfile;
    }
    userProfile &&
      this.setState({ ...this.initialState }, () => {
        this.setFileTreeContent(this.props);
      });
    if (this.props.filesFromBytes && this.props.filesFromBytes.isError) {
      this.state.dnloadFlag && this.handleFailure(this.props);
    }
  }

  componentWillUnmount() {
    searchValue = "";
    this.props.dispatch(resetFileTreeContent());
    this.props.dispatch(clearUpFileStrucutre());
    // this.handleFailure(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.filesFromBytes) {
      this.setState({
        downloadable: nextProps.filesFromBytes.downloadable,
        clickedFile: nextProps.filesFromBytes.clickedFile,
      });
    }
    if (
      nextProps &&
      nextProps.fetchAllFiles &&
      nextProps.fetchAllFiles.allFiles
    ) {
      this.setState({
        allFileNames: nextProps.fetchAllFiles.allFiles,
        fetchFileStructure: true,
      });
    }
    userProfile && this.setFileTreeContent(nextProps);
    if (searchValue && searchValue.length >= 3) {
      modData && modData.length > 0 && this.onSearch(searchValue);
    }
    this.state.dnloadFlag && this.handleFailure(nextProps);
  }

  allFiles(props, tid) {
    const { fetchAllFiles, allFiles, isError } = props;
    if (!isError) {
      if (!fetchAllFiles.isFetching) {
        if (
          fetchAllFiles &&
          fetchAllFiles.allFiles &&
          fetchAllFiles.allFiles.length > 0
        ) {
          if (fetchAllFiles.allFiles && allFiles !== this.state.allFileNames) {
            this.state.allFileNames = fetchAllFiles.allFiles;
            this.setState({
              allFileNames: fetchAllFiles.allFiles,
              fetchFileStructure: true,
            });
          }
          if (!isEqual(allFiles, this.state.allFileNames)) {
            this.setState({
              allFileNames: fetchAllFiles.allFiles,
              fetchFileStructure: true,
            });
          }
        } else {
          if (!this.state.fetchFileStructure) {
            this.props.dispatch(
              getallFiles({
                isRootRequest: true,
                rootUrl: tid
                  ? `id/${tid}`
                  : `code/${this.props.productFolderId}`,
                byId: this.props.byId,
                productFolderId: this.props.productFolderId,
              })
            );
          }
        }
      }
    }
  }
  getValue(opData, key) {
    if (opData) {
      const data = opData.filter((item) => {
        if (item && item.name.toLowerCase().includes(key.toLowerCase())) {
          return item.value;
        }
      });
      return data && data.length > 0 ? data[0].value : null;
    }
    return;
  }
  setFileTreeContent(props, sortingChanged = false) {
    const { fileTree, isFetched } = props;
    if (!fileTree.isError && !fileTree.isFetching) {
      if (isFetched && fileTree && fileTree.fileTreeContent === null) {
        return this.setState({ initialLoadComplete: true });
      }
      if (
        (fileTree &&
          !isEqual(
            fileTree && fileTree.fileTreeContent,
            this.state.fileTreeContent
          )) ||
        sortingChanged
      ) {
        if (!this.state.fetchFileStructure && fileTree.fileTreeContent) {
          this.allFiles(
            this.props,
            fileTree.fileTreeContent.current &&
              fileTree.fileTreeContent.current[0].tid
          );
        }
        this.setState({
          fileTreeContent: fileTree.fileTreeContent,
        });
        const treeData = this.processedFolders(
          fileTree && fileTree.fileTreeContent
        );
        modData = treeData;
        this.setState({
          treeData,
          initialLoadComplete: true,
        });
        if (sortingChanged && searchValue) {
          this.onSearch(searchValue);
        }
      } else {
        const usrPrf = userProfile;
        if (!this.state.initialLoadComplete) {
          this.props
            .dispatch(
              this.props.getFileTreeContent({
                isRootRequest: true,
                rootUrl: this.props.byId
                  ? `id/${this.props.productFolderId}`
                  : `code/${this.props.productFolderId}`,
                byId: this.props.byId,
                productFolderId: this.props.productFolderId,
                accessToken: this.props.auth.accessToken,
                contactid:
                  usrPrf.opData &&
                  this.getValue(usrPrf.opData.userIdentifiers, "u_contact"),
                uuid: usrPrf.profileData && usrPrf.profileData.sub,
                emailId: usrPrf.profileData && usrPrf.profileData.email,
                fetchDatafromContactId: this.props.fetchDatafromContactId,
                type: this.props.type,
              })
            )
            .then(() => this.setState({ initialLoadComplete: true }))
            .catch(() =>
              this.setState({
                initialLoadComplete: true,
                error: true,
              })
            );
        }
      }
      return;
    }
    return;
  }

  processedChildrenForFolder = (folderKey, childData) => {
    if (!childData) {
      return [];
    }
    return this.processedFolders(childData);
  };

  createFolderObject(folder) {
    const { ...rest } = folder;
    return {
      ...rest,
      title: folder.name,
      key: folder.tid,
      folderId: folder.tid,
      lastModified: folder.created,
      url: folder.name,
      children: this.processedChildrenForFolder(
        folder.folderId,
        folder.LoadedChildContent
      ),
    };
  }

  createFileObject(file) {
    const { ...rest } = file;
    return {
      ...rest,
      title: file.name,
      key: file.field_media_file,
      fileId: file.field_media_file,
      lastModified: file.created,
      url: convertRelativeFileUrlToAbsoluteUrl(file.field_media_file),
    };
  }

  createFileOrFolderObject(item) {
    if (item.itemType === "folder") {
      return this.createFolderObject(item);
    }
    return this.createFileObject(item);
  }

  processedFolders = (parentData) => {
    if (!parentData) {
      return [];
    }
    const processedFolderObjects =
      parentData.foldersObject &&
      Object.values(parentData.foldersObject).map((item) => {
        return item.itemType === "folder"
          ? this.createFolderObject(item)
          : null;
      });
    const processedFileObjects =
      parentData &&
      parentData.filesObject &&
      Object.values(parentData.filesObject).map((item) => {
        return item.itemType === "file" ? this.createFileObject(item) : null;
      });
    const filesAndFolders = [
      ...processedFolderObjects,
      ...processedFileObjects,
    ];
    return this.sortFilesAndFolders(filesAndFolders);
  };

  sortFilesAndFolders = (filesAndFolders) => {
    if (!filesAndFolders || filesAndFolders.length === 0) {
      return [];
    }
    const sortOrderCompare = (a, b) =>
      this.activeSort && (this.activeSort.type === "down" ? a > b : a < b)
        ? -1
        : 1;
    if (this.activeSort) {
      filesAndFolders.sort((a, b) => {
        if (this.activeSort.by === "date") {
          return sortOrderCompare(a.created, b.created);
        }
        return sortOrderCompare(a.title.toLowerCase(), b.title.toLowerCase());
      });
    }
    return filesAndFolders;
  };

  onLoadData = (node) => {
    const { props } = node;
    const { isAtRootLevel, parentKey } = props;
    const findId = this.findItemNested(
      modData,
      node.props.folderId,
      "children"
    );
    const existsInFiltArr =
      keys &&
      keys.length > 0 &&
      keys.filter((item) => item === node.props.folderId);
    if (findId || existsInFiltArr) {
      return Promise.resolve({ options: [] });
    }
    if (!findId || !existsInFiltArr) {
      const usrPrf = userProfile;
      return new Promise((resolve, reject) => {
        this.props
          .dispatch(
            this.props.getFileTreeContent({
              folderId: node.props.folderId,
              isRootRequest: false,
              productFolderId: node.props.folderId,
              isAtRootLevel,
              parentKey,
              accessToken: this.props.auth.accessToken,
              byId: true,
              contactid:
                usrPrf.opData &&
                this.getValue(usrPrf.opData.userIdentifiers, "u_contact"),
              uuid: usrPrf.profileData.sub,
              emailId: usrPrf.profileData && usrPrf.profileData.email,
              fetchDatafromContactId: this.props.fetchDatafromContactId,
              type: this.props.type,
            })
          )
          .then(resolve)
          .catch(reject);
      });
    }
    return;
  };

  editSearchTerm = (e) => {
    const value = e.target.value.replace(/\s+/g, " ");
    var res = [];
    var pathList = [];
    var filteredArr = [];
    this.setState({
      searchTerm: e.target.value,
      isEmpty: false,
    });
    this.onCollapseAll();
    if (value === "" || value.length < 3) {
      this.setState({
        treeData: modData,
      });
    }
    if (value.length >= 3) {
      for (var key in this.state.allFileNames) {
        var filtArr =
          value &&
          this.state.allFileNames[key].files.filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
          );
        const folders_names = this.state.allFileNames[key].folders_names;
        for (var val in folders_names) {
          if (folders_names[val].toLowerCase().includes(value.toLowerCase())) {
            const obj = {};
            obj.name = folders_names[val];
            obj.tid = parseInt(val.substr(1));
            filtArr.push(obj);
          }
        }
        filteredArr =
          filtArr &&
          filtArr.reduce((acc, current) => {
            const val = acc.find((item) => item.tid === current.tid);
            return !val ? acc.concat([current]) : acc;
          }, []);
      }
      if (filteredArr && filteredArr.length < 1) {
        this.setState({
          treeData: [],
          isEmpty: true,
        });
      } else {
        pathList = this.findPathOfIds(filteredArr, res, pathList);
        if (value.length === 3 || value.length - searchValue.length > 1) {
          pathList &&
            pathList.forEach(async (item) => {
              const prev = "";
              await this.getData(item, prev, value);
            });
        }
        if (value !== "") {
          this.onSearch(value);
          this.onExpandAll();
        }
      }
    }
    searchValue = value;
  };

  findPathOfIds(filteredArr, res, pathList) {
    filteredArr &&
      filteredArr.forEach(async (item, index) => {
        res.splice(0, res.length);
        var path = this.pathTo(
          this.state.allFileNames[Object.keys(this.state.allFileNames)[0]]
            .folders,
          item.tid.toString()
        );
        path &&
          path.split(".").forEach(function (id) {
            res.push(id);
          });
        pathList[index] = [];
        pathList[index] = pathList[index].concat(res);
        keys.push(...res);
        const uniqueKeys = [...new Set(keys)];
        keys = uniqueKeys;
      });
    return pathList;
  }

  generateParentPaths = (res, prev) => {
    let parentKey;
    let generatedPath = "";
    const prevIds = [];
    res &&
      res.forEach((item, index) => {
        if (index !== 0) {
          parentKey = res[index - 1];
        } else {
          parentKey = "";
        }
        if (index === 1) {
          generatedPath = `${parentKey}.LoadedChildContent`;
        } else if (index > 1) {
          generatedPath = `${prev}.foldersObject.${parentKey}.LoadedChildContent`;
        }
        prev = generatedPath;
        prevIds.push(generatedPath);
      });
    return prevIds;
  };

  generateParentKey = (res, fId, index, prevParentPaths) => {
    let parentKey;
    let generatedPath = "";
    if (index !== 0) {
      parentKey = res[index - 1];
    } else {
      parentKey = "";
    }
    if (index === 1) {
      generatedPath = `${parentKey}.LoadedChildContent`;
    } else if (index > 1) {
      generatedPath = `${prevParentPaths[index]}.foldersObject.${parentKey}.LoadedChildContent`;
    }
    return generatedPath;
  };

  getFolderData = (res, fId, index, prevParentPaths) => {
    const parentkey = prevParentPaths[index];
    const usrPrf = userProfile;
    return getFileDataById({
      isRootRequest: false,
      folderId: fId,
      parentKey: parentkey,
      fetchDatafromContactId: this.props.fetchDatafromContactId,
      contactid:
        usrPrf.opData &&
        this.getValue(usrPrf.opData.userIdentifiers, "u_contact"),
      uuid: usrPrf.profileData && usrPrf.profileData.sub,
      emailId: usrPrf.profileData && usrPrf.profileData.email,
      type: this.props.type,
    });
  };

  getData = (res, prev, value) => {
    let statusCheck = true;
    const prevParentPaths = this.generateParentPaths(res, prev);
    var promises =
      res &&
      res.map((fId, index) => {
        var findId = this.findItemNested(modData, fId, "children");
        if (!findId) {
          return this.getFolderData(res, fId, index, prevParentPaths);
        }
        return;
      });
    var that = this;
    Promise.allSettled(promises)
      .then(function (results) {
        res.map((fId, index) => {
          if (results[index].status !== "fulfilled") {
            statusCheck = false;
          }
          return (
            statusCheck &&
            results &&
            that.updateFolderState(results[index], fId, prevParentPaths[index])
          );
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    this.onSearch(value);
    return;
  };

  updateFolderState = (data, fId, parentKey) => {
    this.props
      .dispatch(
        updateTreeContent({
          folderId: fId,
          isRootRequest: false,
          data: data,
          parentKey: parentKey,
        })
      )
      .then((response) => {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  onSearch = (value) => {
    searchResults = this.filter(
      modData,
      ({ name }) => name && name.toLowerCase().includes(value.toLowerCase())
    );
    this.setState({
      flag: true,
      treeData: searchResults,
    });
  };

  filter = (array, fn) => {
    return (
      array &&
      array.reduce((res, o) => {
        var children = o && this.filter(o.children || [], fn);
        if ((o && fn(o)) || (children && children.length)) {
          res.push(Object.assign({}, o, children.length && { children }));
        }
        return res;
      }, [])
    );
  };
  findItemNested = (treeData, itemId, nestingKey) =>
    treeData &&
    treeData.reduce((a, item) => {
      if (a) {
        return a;
      }
      if (item && item.parentKey.includes(itemId.toString())) {
        return item;
      }
      if (item && item[nestingKey]) {
        return this.findItemNested(item[nestingKey], itemId, nestingKey);
      }
      return;
    }, null);

  pathTo = (allFileNames, target) => {
    var result;
    for (var val in allFileNames) {
      if (val.substr(1) === target) {
        return (result = val.substr(1));
      }
      var temp = this.pathTo(allFileNames[val], target);
      if (temp) {
        return (result = val.substr(1) + "." + temp);
      }
    }
    return result;
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onExpandAll = () => {
    this.setState({
      expandedKeys: keys,
    });
  };

  onCollapseAll = () => {
    this.setState({ expandedKeys: [] });
  };

  handleFailure = (props) => {
    const { filesFromBytes } = props;
    if (filesFromBytes) {
      if (filesFromBytes.isError && !filesFromBytes.isFetching) {
        if (
          filesFromBytes.response &&
          filesFromBytes.response.responseCode !== 200
        ) {
          this.setState({
            fileNotFnd: true,
          });
        }
      }
    }
  };

  onRbSelect = (selectedKeys, info) => {
    const { dispatch } = this.props;
    if (info.node.isLeaf() && info.node.props.itemType !== "folder") {
      //  window.open(info.node.props.url, "_blank");
      // File download call
      this.downlaodFiles(info, dispatch);
    }
    let _selectedKeys = (selectedKeys = []);
    if (info.selected) {
      _selectedKeys = getRadioSelectKeys(
        this.processedFolders(this.state.fileTreeContent),
        selectedKeys,
        info.node.props.eventKey
      );
    }
    this.setState({
      selectedKeys: _selectedKeys,
    });
  };

  truncateTitle = (title) => {
    // Truncating filenames in the middle and adding ellipsis
    // We use the first 30 characters and the last 25 characters
    // String is limited to 60 characters
    // 5 characters are used by the ellipsis (including spaces)
    if (title && title.length <= 60) {
      return title;
    }
    return (
      title &&
      title.substr(0, 30) + " ... " + title.substr(title.length - 25, 25)
    );
  };

  customTitle = (item) => {
    const truncatedTitle = this.truncateTitle(item && item.title);
    const formattedDate = dayjs(item && item.lastModified * 1000)
      .utc()
      .format("MM-DD-YYYY HH:mm:ss UTC");
    return (
      <React.Fragment>
        <span
          className={
            this.state.clickedFile &&
            !this.state.downloadable &&
            fileTitle &&
            fileTitle.toLowerCase().includes(item.title.toLowerCase())
              ? "disabled"
              : ""
          }
          title={item && item.title}
        >
          &nbsp;{truncatedTitle}
        </span>

        {this.state.clickedFile &&
          !this.state.downloadable &&
          item &&
          fileTitle &&
          fileTitle.toLowerCase().includes(item.title.toLowerCase()) && (
            <span className={"rc-tree-line-item1"}>downloading ...</span>
          )}
        <span className={"rc-tree-line-item"}>{formattedDate}</span>
      </React.Fragment>
    );
  };

  customFoldTitle = (item, isFolderEmpty) => {
    const truncatedTitle = this.truncateTitle(item && item.title);
    return (
      <React.Fragment>
        {!isFolderEmpty && !this.state.allFileNames && (
          <span title={item && item.title}>{truncatedTitle}</span>
        )}
        {this.state.allFileNames && !isFolderEmpty && (
          <span title={item && item.title}>{truncatedTitle}</span>
        )}
        {this.state.allFileNames && isFolderEmpty && (
          <span title={item && item.title}>&nbsp;{truncatedTitle}</span>
        )}
      </React.Fragment>
    );
  };

  sortByName = () => {
    let type = "up";
    if (this.activeSort && this.activeSort.by === "name") {
      if (this.activeSort.type === "up") {
        type = "down";
      }
    }
    this.activeSort = { by: "name", type };
    userProfile && this.setFileTreeContent(this.props, true);
  };

  sortByDate = () => {
    let type = "up";
    if (this.activeSort && this.activeSort.by === "date") {
      if (this.activeSort.type === "up") {
        type = "down";
      }
    }
    this.activeSort = { by: "date", type };
    userProfile && this.setFileTreeContent(this.props, true);
  };

  downlaodFiles(info, dispatch) {
    const key = info.node.props.eventKey;
    const fileName = key.substr(key.lastIndexOf("/") + 1);
    const req = {};
    req["filePath"] = key;
    fileTitle = info.node.props.name;
    this.setState({
      dnloadFlag: true,
      clickedFile: true,
      downloadable: false,
    });
    this.props.dispatch(getFilesFromBytes(req, fileName));
    // this.handleFailure();
  }

  clearSearch = (e) => {
    this.setState({
      searchTerm: "",
      treeData: modData,
      isEmpty: false,
    });
    searchValue = "";
    this.onCollapseAll();
  };

  isFolderEmpty = (id) => {
    let found = "";
    if (this.state.allFileNames) {
      for (var key in this.state.allFileNames) {
        found = this.state.allFileNames[key].files.find(
          (item) => item && item.tid && item.tid === parseInt(id)
        );
      }
    }
    return found;
  };

  isEmpty(obj) {
    return Object.keys(obj).length === 0 || !obj;
  }

  findChildren(obj, target) {
    const fnd = (obj) => {
      for (const [k, v] of Object.entries(obj)) {
        if (k.substr(1) === target) {
          var flag = this.isEmpty(v);
          return flag;
        }
        if (typeof v === "object") {
          const f = fnd(v);
          if (f) return f;
        }
      }
    };
    return fnd(obj);
  }

  handlePopupClose() {
    this.setState({
      fileNotFnd: false,
      dnloadFlag: false,
    });
  }
  render() {
    var thisState = this;
    const loop = (data) => {
      return (
        data &&
        data.map((item) => {
          var { ...rest } = item;
          const isFoldEmpty =
            thisState.state.allFileNames &&
            thisState.isFolderEmpty(item && item.tid);
          const filesEx =
            thisState.state.allFileNames &&
            thisState.findChildren(
              thisState.state.allFileNames[
                Object.keys(thisState.state.allFileNames)[0]
              ].folders,
              item && item.tid
            );
          const folderFlag = isFoldEmpty || !filesEx;

          if (item && item.itemType === "folder") {
            return (
              <TreeNode
                {...rest}
                title={this.customFoldTitle(item, folderFlag)}
                key={item.key}
                url={item.url}
                showIcon={false}
                isLeaf={!folderFlag}
                icon={!folderFlag && <Icon filename={folderType} />}
                className={
                  !folderFlag ? "marL4m rc-tree li span.rc-tree-iconEle" : ""
                }
              >
                {item.children &&
                  item.children.length > 0 &&
                  loop(item.children)}
              </TreeNode>
            );
          }
          return (
            item && (
              <TreeNode
                {...rest}
                key={item.key}
                title={this.customTitle(item)}
                download-pdf={item.title}
                friendlyName={item.title}
                url={item.url}
                isLeaf={true}
                icon={<Icon filename={item.title} />}
              />
            )
          );
        })
      );
    };

    const sortedArrowState =
      this.activeSort && this.activeSort.type === "up"
        ? "arrowup_activeSort"
        : "arrowdown_activeSort";
    const nameArrowState =
      this.activeSort && this.activeSort.by === "name"
        ? sortedArrowState
        : "arrowup_nonactiveSort";
    const dateArrowState =
      this.activeSort && this.activeSort.by !== "name"
        ? sortedArrowState
        : "arrowup_nonactiveSort";
    const filesFromBytes = this.props.filesFromBytes;
    const fileName =
      filesFromBytes &&
      filesFromBytes.response &&
      filesFromBytes.response.fileName;
    return (
      <div style={{ padding: "0 20px" }}>
        <h2>{this.props.title}</h2>
        <aside className="filterData marB20">
          <ul id="defaultAscendingData">
            <li className="liName">
              <span
                id="one"
                className="sort"
                data-type="byName"
                data-sort="up"
                onClick={this.sortByName}
              >
                Name{" "}
                <span className="img">
                  <img
                    src={`/assets/images/icon/${nameArrowState}.gif?MOD=AJPERES`}
                    className="imgName"
                    alt=""
                  />
                </span>
              </span>
            </li>
            <li className="liDate active">
              <span
                id="two"
                className="sort"
                data-type="byDate"
                data-sort="up"
                onClick={this.sortByDate}
              >
                Date{" "}
                <span className="img">
                  <img
                    src={`/assets/images/icon/${dateArrowState}.gif?MOD=AJPERES`}
                    className="imgDate"
                    alt=""
                  />
                </span>
              </span>
            </li>
          </ul>
        </aside>
        {this.state.error && (
          <h6 className={"error"}>Error loading documents</h6>
        )}
        {this.state.treeData.length < 1 &&
          this.state.initialLoadComplete &&
          searchValue.length <= 0 && (
            <div id="wrapper">
              <div className="tabWrapper">
                <div className="invoice-wrapper2">
                  <b>No documents found.</b>
                </div>
              </div>
            </div>
          )}
        {!this.state.initialLoadComplete && !this.state.error && (
          <h5>Loading...</h5>
        )}
        {(this.state.treeData.length >0  || this.state.searchTerm) &&
           this.state.initialLoadComplete &&
          !this.state.error && (
            <div>
              <div className="searchBarHeight">
                {this.state.allFileNames && (
                  <div className="inputWithButton">
                    <i className="fa fa-search searchIcon" aria-hidden="true" />
                    <input
                      type="text"
                      id="searcher"
                      value={this.state.searchTerm || ""}
                      onChange={this.editSearchTerm}
                      placeholder="Search your documents"
                    />
                    <i
                      className="fa fa-times clearIcon"
                      aria-hidden="true"
                      onClick={this.clearSearch}
                    />
                    <FormHelperText id="component-error-text">
                      {" "}
                      Enter atleast 3 characters
                    </FormHelperText>
                  </div>
                )}
              </div>
              {this.state.isEmpty && (
                <p className="centerAlign">
                  Your search for <b>{this.state.searchTerm}</b> did not match
                  any documents.
                </p>
              )}
              {this.state.treeData && (
                <Tree
                  multiple
                  // defaultExpandAll={this.state.flag}
                  defaultExpandAll={false}
                  onExpand={this.onExpand}
                  expandedKeys={this.state.expandedKeys}
                  autoExpandParent={this.state.autoExpandParent}
                  onSelect={this.onRbSelect}
                  selectedKeys={getRadioSelectKeys(
                    this.state.treeData,
                    this.state.selectedKeys
                  )}
                  loadData={(node) => this.onLoadData(node)}
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginBottom: 30,
                    maxHeight: 550,
                    overflow: "auto",
                    marginLeft: -3,
                  }}
                >
                  {this.state.treeData &&
                    this.state.treeData.length > 0 &&
                    loop(this.state.treeData)}
                </Tree>
              )}
            </div>
          )}
        {this.state.fileNotFnd && (
          <Dialog
            onClose={() => this.handlePopupClose()}
            aria-labelledby="customized-dialog-title"
            open={this.state.fileNotFnd}
            className="errDialogModal"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => this.handlePopupClose()}
            >
              <div>File - {fileName || ""}</div>
            </DialogTitle>
            <DialogContent>
              {" "}
              <p className="warningMsg body-text">
                {globalConstants.FILENOTFOUNDINDOC}
              </p>
            </DialogContent>
          </Dialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  filesFromBytes,
  fetchAllFiles,
  fileTree,
  userProfile,
}) => {
  return {
    auth,
    filesFromBytes,
    fetchAllFiles,
    fileTree,
    userProfile,
  };
};

export default connect(mapStateToProps)(FileTree);