import {
  fetchClients,
  fetchGeneralContentUrl
} from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
export const GETCLIENTS_REQUEST = "GETCLIENTS_REQUEST";
export const GETCLIENTS_SUCCESS = "GETCLIENTS_SUCCESS";
export const GETCLIENTS_FAILURE = "GETCLIENTS_FAILURE";

const NoDtFnd = "No Data Found";
const ServiceErr = "Service error";

export function requestClients(userName, typeId) {
  return {
    type: GETCLIENTS_REQUEST,
    isFetched: false,
    userName,
    typeId
  };
}

export function receiveClients(userName, data, typeId) {
  return {
    type: GETCLIENTS_SUCCESS,
    isFetched: true,
    userName,
    data,
    typeId
  };
}

export function clientsFailure(userName, message, typeId) {
  return {
    type: GETCLIENTS_FAILURE,
    isFetched: false,
    isError: true,
    userName,
    message,
    typeId
  };
}

function receiveSendEmail(data) {
  return {
    data
  };
}
export function getClientsData(username, opData, typeId) {
  const req = {};
  req["userId"] = username;
  req["idType"] = typeId;
  return dispatch => {
    dispatch(requestClients(username, typeId));
    const url = fetchClients(username);
    http
      .post(url, JSON.stringify(req))
      .then(response => {
        const data = response.data;
        if (response.status === 200) {
          if (data && data.length > 0) {
            dispatch(receiveClients(username, data, typeId));
          } else {
            const cicUserId = findCICUserId(opData);
            if (
              cicUserId &&
              cicUserId.length > 0 &&
              !data &&
              data.length === 0
            ) {
              // dispatch(sendEmail(opData));
              dispatch(getClientsDataWithCicUserId(cicUserId[0].value));
            } else {
              dispatch(receiveClients(username, NoDtFnd, typeId));
            }
          }
        } else if (response.status === 204) {
          const cicUserId = findCICUserId(opData);
          if (cicUserId && cicUserId.length > 0) {
            // dispatch(sendEmail(opData));
            dispatch(getClientsDataWithCicUserId(cicUserId[0].value));
          } else {
            dispatch(receiveClients(username, NoDtFnd, typeId));
          }
        } else {
          dispatch(receiveClients(username, NoDtFnd, typeId));
          return Promise.reject(data);
        }
        return data;
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 404) {
          const cicUserId = findCICUserId(opData);
          if (cicUserId && cicUserId.length > 0) {
            // dispatch(sendEmail(opData));
            dispatch(getClientsDataWithCicUserId(cicUserId[0].value));
          } else {
            dispatch(receiveClients(username, NoDtFnd, typeId));
          }
        } else {
          dispatch(receiveClients(username, ServiceErr, typeId));
        }
      });
  };
}

export function getClientsDataWithCicUserId(cicUserId) {
  const typeId = 3;
  const req = {};
  req["userId"] = cicUserId;
  req["idType"] = typeId;
  return dispatch => {
    dispatch(requestClients(cicUserId, typeId));
    const url = fetchClients();
    http
      .post(url, JSON.stringify(req))
      .then(response => {
        const data = response.data;
        if (response.status === 200) {
          if (data && data.length > 0) {
            dispatch(receiveClients(cicUserId, data, typeId));
          } else {
            dispatch(receiveClients(cicUserId, NoDtFnd, typeId));
          }
        } else if (response.status === 204) {
          dispatch(receiveClients(cicUserId, NoDtFnd, typeId));
        } else {
          // In CBS
          dispatch(receiveClients(cicUserId, NoDtFnd, typeId));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 404) {
          dispatch(receiveClients(cicUserId, NoDtFnd, typeId));
        } else {
          dispatch(receiveClients(cicUserId, ServiceErr, typeId));
        }
      });
  };
}

export function sendEmail(opdata) {
  const { optumIdValue, cbsIdValue } = getValues(opdata);
  const req = {};
  req["email"] = opdata.workEmail;
  req["optumId"] = optumIdValue;
  req["cbsId"] = cbsIdValue;
  return dispatch => {
    const url = fetchGeneralContentUrl("/cms/cbsNotification");
    http
      .post(url, JSON.stringify(req))
      .then(response => {
        if (response.status !== 200) {
          return Promise.reject("Failed");
        } else {
          dispatch(receiveSendEmail("Mail Sent"));
          return null;
        }
      })
      .catch(function (error) { });
  };
}

function getValues(opdata) {
  var cicUserIdValue, cbsIdValue, optumIdValue;
  const optumId = findOptumId(opdata);
  if (optumId && optumId.length > 0) {
    optumIdValue = optumId[0].value;
  }
  const cbsId = findCBSId(opdata);
  if (cbsId && cbsId.length > 0) {
    cbsIdValue = cbsId[0].value;
  }
  const cicUserId = findCICUserId(opdata);
  if (cicUserId && cicUserId.length > 0) {
    cicUserIdValue = cicUserId[0].value;
  }
  console.log(cicUserIdValue)

  return {
    optumIdValue,
    cbsIdValue
  };
}

function findCICUserId(OPDATA) {
  if (OPDATA && OPDATA.userIdentifiers) {
    return OPDATA.userIdentifiers.filter(item => {
      return (item.name === "u_cic_user_id") ? true : false;
    });
  }
  return null;
}

function findOptumId(OPDATA) {
  if (OPDATA && OPDATA.userIdentifiers) {
    return OPDATA.userIdentifiers.filter(item => {
      return (item.name === "Optum ID") ? true : false;
    });
  }
  return null;
}

function findCBSId(OPDATA) {
  if (OPDATA && OPDATA.userIdentifiers) {
    return OPDATA.userIdentifiers.filter(item => {
      return (item.name === "u_cbs_user_id") ? true : false;
    });
  }
  return null;
}
