import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../components/Dashboard/Dashboard";
import { getDashboardData } from "../actions/dashboard";
import globalConstants from "../constants/global-constants";

class DashboardContainer extends Component {
  state = {
    dashboardData: "",
  };
  UNSAFE_componentWillReceiveProps(newProps) {
    this.getDashboard(newProps);
  }

  componentDidMount() {
    this.getDashboard(this.props);
  }

  getDashboard(props) {
    const { selectedAccount } = props;
    if (selectedAccount) {
      if (selectedAccount && selectedAccount.u_account) {
        const { dashboard } = props;
        if (dashboard) {
          const dashboardData = this.getdashboardDataDetail(
            selectedAccount.u_account,
            dashboard.dashboard
          );
          if (dashboardData) {
            this.setState({
              dashboardData,
            });
          } else {
            let request = this.makeRequest(selectedAccount);
            if (request) {
              this.props.dispatch(
                getDashboardData(
                  request,
                  selectedAccount[globalConstants.U_ACCOUNT]
                )
              );
            }
          }
        }
      }
    }
  }

  makeRequest(selectedAccount) {
    let request = {};
    request.firstName = selectedAccount[globalConstants.U_CONTACT_FIRST_NAME];
    request.lastName = selectedAccount[globalConstants.U_CONTACT_LAST_NAME];
    request.serviceNowAccess =
      selectedAccount[globalConstants.U_ACCOUNT_SERVICENOW_ACCESS];
    request.accountName = selectedAccount[globalConstants.U_ACCOUNT_NAME];
    request.accountID = selectedAccount[globalConstants.U_ACCOUNT];
    return request;
  }

  getdashboardDataDetail(accountID, dashboardData) {
    return (
      dashboardData &&
      dashboardData.length > 0 &&
      dashboardData.find((item) => {
        return item && item.accountId === accountID;
      })
    );
  }

  render() {
    const { dashboardData } = this.state;
    return <Dashboard dashboardData={dashboardData.dashboardData} />;
  }
}

const mapStateToProps = ({ dashboard, userAccount, auth }) => {
  return { dashboard, selectedAccount: userAccount.selectedAccount, auth };
};
export default connect(mapStateToProps)(DashboardContainer);
