import userTypeEnum from "./UserType";
import DOMPurify from 'dompurify';

export function convertRelativeFileUrlToAbsoluteUrl(url) {
  return process.env.REACT_APP_CONTENTSERVICE_URL + url;
}

export function isInternal(user) {
  if (user && user.userType === userTypeEnum.INTERNAL_USER) {
    return true;
  } else if (user && user.userType === userTypeEnum.MANAGER_USER) {
    if (user.isType === userTypeEnum.INTERNAL_DEFAULT_TYPE) {
      return true;
    }
    return false;
  }
  return false;
}

export function renderHTML(html) {
  const  sanitizedHTML = DOMPurify.sanitize(html);
  var txt = document.createElement("textarea");
  txt.innerHTML = sanitizedHTML;
  return txt.value;
}

export function isExternal(user) {
  if (user && user.userType === userTypeEnum.EXTERNAL_USER) {
    return true;
  }
  return false;
}
