import React, { Component } from "react";
import { connect } from "react-redux";
import Product from "../components/Applications/Product/Product";
import { getProductMetaData } from "../actions/productMetaData";
import globalConstants from "../constants/global-constants";

class ProductContainer extends Component {
  state = {
    internalProducts: "",
    proMetaData: {}
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getProductData(newProps);
  }

  componentDidMount() {
    this.getProductData(this.props);
  }

  getProductData(props) {
    const { product, productsMetaData } = props;
    this.configuredForAccounts(product, productsMetaData, props);
  }

  configuredForAccounts(product, productsMetaData, props) {
    if (
      product &&
      product[globalConstants.PRODUCT_COMPLETE_NAME] &&
      productsMetaData
    ) {
      const productInfo = this.getProduct(
        product[globalConstants.PRODUCT_COMPLETE_NAME],
        productsMetaData.data
      );
      if (!productInfo) {
        this.props.dispatch(
          getProductMetaData(product[globalConstants.PRODUCT_COMPLETE_NAME])
        );
      } else if (
        productInfo &&
        productInfo.value &&
        productInfo.value[globalConstants.FIELD_CODE]
      ) {
        this.setState({ proMetaData: productInfo });
      }
    }
  }

  getProduct(productName, productMetaData) {
    return productMetaData.find(item => {
      return item["name"] === productName;
    });
  }

  render() {
    const { product, selectedAccount } = this.props;
    const { proMetaData } = this.state;
    return (
      <React.Fragment>
        {product &&
          proMetaData &&
          proMetaData.value &&
          proMetaData.value[globalConstants.FIELD_CODE] && (
            <Product item={product}
              proMetaData={proMetaData.value}
              selectedAccount={selectedAccount}
            />
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ productsMetaData, auth, filesFromBytes }) => {
  return { productsMetaData, auth, filesFromBytes };
};

export default connect(mapStateToProps)(ProductContainer);
