import { createTheme } from "@material-ui/core";
export const theme = createTheme({
  customPalette: {
    color: {
      grey1: "#CCCCCC",
      blue1: "#0C55B8",
      grey2: "#5A5A5A",
      grey4: "#c4cfd4",
      black4: "#333741",
      blue2: "#196ECF",
    },
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    type: "light",
    primary: {
      main: "#002677",
      light: "#D9F6FA",
      dark: "#196DCD",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FF612B",
      light: "#FBF9F4",
      dark: "#FF612B",
      contrastText: "#fff",
    },
    error: {
      main: "#C40000",
      light: "#FCF0F0",
      dark: "#C40000",
      contrastText: "#fff",
    },
    warning: {
      main: "#F5B700",
      light: "#FEF9EA",
      dark: "#F5B700",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      main: "#E5F8FB",
      light: "#FAFAFA",
      dark: "#F2F2F2",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    success: {
      main: "#007000",
      light: "#EFF6EF",
      dark: "#007000",
      contrastText: "#fff",
    },
    grey: {
      50: "#FAFAFA",
      100: "#F2F2F2",
      200: "#E5E5E6",
      300: "#CBCCCD",
      400: "#B1B2B4",
      500: "#929496",
      600: "#6E7072",
      700: "#4B4D4F",
      800: "#323334",
      900: "#19191A",
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#303030",
      A700: "#616161",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: "#4B4D4F",
      secondary: "#323334",
      disabled: "#B1B2B4",
      hint: "#4B4D4F",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    background: {
      paper: "#FFFFFF",
      default: "#FBF9F4",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      hoverOpacity: 0.04,
      selected: "rgba(0, 0, 0, 0.08)",
      selectedOpacity: 0.08,
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(0, 0, 0, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    // tint: {
    //   error: "#FCF0F0",
    //   info: "#E5F8FB",
    //   alert: "#FEF9EA",
    //   success: "#EFF6EF",
    //   info2: "#FBF9F4",
    //   info3: "#F2F2F2",
    // },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiOutlinedInput: {
      notched: false,
    },
    MuiFormControl: {
      margin: "normal",
    },
    MuiPaper: {
      square: true,
    },
  },

  transitions: {
    create: () => "none",
  },
  overrides: {
    CssBaseline: {
      "@global": {
        "*, *::before, *::after": {
          transition: "none !important",
          animation: "none !important",
        },
      },
    },
  },
  //   typography: {
  //     useNextVariants: true,
  //   },
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Optum Sans", Arial, sans-serif',
    fontSize: 16,
    h1: {
      fontFamily: '"Optum Sans", Arial, sans-serif',
      fontWeight: 700,
      fontSize: "36.48px",
      lineHeight: "48px",
      color: "#002677",
    },
    h2: {
      fontFamily: '"Optum Sans", Arial, sans-serif',
      fontWeight: 700,
      fontSize: "32.43px",
      lineHeight: "40px",
      color: "#002677",
    },
    h3: {
      fontFamily: '"Optum Sans", Arial, sans-serif',
      fontWeight: 700,
      fontSize: "28.83px",
      lineHeight: "36px",
      color: "#002677",
      "@media (max-width:599.95px)": {
        fontWeight: 700,
        fontSize: "25.63px",
        lineHeight: "32px",
      },
    },
    h4: {
      fontFamily: '"Optum Sans", Arial, sans-serif',
      fontWeight: 700,
      fontSize: "25.63px",
      lineHeight: "32px",
      color: "#4B4D4F",
      "@media (max-width:599.95px)": {
        fontWeight: 700,
        fontSize: "22.78px",
        lineHeight: "28px",
      },
    },
    h5: {
      fontFamily: '"Optum Sans", Arial, sans-serif',
      fontWeight: 700,
      fontSize: "22.78px",
      lineHeight: "28px",
      color: "#4B4D4F",
      "@media (max-width:599.95px)": {
        fontWeight: 700,
        fontSize: "20.25px",
        lineHeight: "24px",
      },
    },
    h6: {
      fontFamily: '"Optum Sans", Arial, sans-serif',
      fontWeight: 700,
      fontSize: "20.25px",
      lineHeight: "24px",
      color: "#4B4D4F",
      "@media (max-width:599.95px)": {
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "24px",
      },
    },
    body1: {
      fontFamily: '"Optum Sans", Arial, sans-serif',
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#323334",
    },

    body2: {
      fontFamily: '"Optum Sans", Arial, sans-serif',
      fontWeight: 400,
      fontSize: "14.22px",
      lineHeight: "18px",
      color: "#4B4D4F",
    },

    subtitle1: {
      fontFamily: '"Optum Sans", Arial, sans-serif',
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "18px",
      color: "#323334",
    },
  },
  search: {
    color: "#002677",
    fontFamily: "Optum Sans",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "48px",
  },
  formularySearchTextName: {
    fontSize: "20.85px",
    color: "#5A5A5A",
    paddingBottom: "16px",
  },
  formularylookup: {
    fontSize: "18px",
    margin: "0",
    textTransform: "inherit",
    borderBottomColor: "#c4cfd4",
  },
});
