import React, { Component } from "react";
import PandTCommitteeLN from "../LeftNavigation/PandTCommitteeLN";
import UtilizationManagementLN from "../LeftNavigation/UtilizationManagementLN";
import FormularyManagementLN from "../LeftNavigation/FormularyManagementLN";
import CSRelatedLinkDetailContainer from "../../../../containers/CSRelatedLinkDetailContainer";
import CSRelatedLinksFormularyAEMContainer from "../../../../containers/CSRelatedLinksFormularyAEMContainer";
import renderHTML from "react-render-html";
class RelatedLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
    this.getTitle = this.getTitle.bind(this);
  }

  getKey(type, subType) {
    if (subType) {
      return type + "_" + subType;
    }
    return type;
  }

  getTitle(name) {
    let title = "";
    if (name) {
      title = name;
      this.setState({ title });
    }
  }

  render() {
    const { title } = this.state;
    const { type, subType } = this.props;
    const key = this.getKey(type, subType);
    return (
      <React.Fragment>
        <h1>{renderHTML(title)}</h1>
        <div className="row- sep sep25 marB30">
          <div className="col-sm-3">
            {type && (
              <div className="list">
                {type === "P&TCommitteeSupport" && (
                  <PandTCommitteeLN type={type} subType={subType} />
                )}
                {type === "UtilizationManagement" && (
                  <UtilizationManagementLN type={type} subType={subType} />
                )}
                {type === "FormularyManagement" && (
                  <FormularyManagementLN type={type} subType={subType} />
                )}
              </div>
            )}
            <div className="marB20">&nbsp;</div>
          </div>
          <div className="col-sm-9">
            <CSRelatedLinkDetailContainer
              type={type}
              subType={subType}
              relatedkey={key}
              getTitle={this.getTitle}
            />
            {type === "FormularyManagement" &&
              subType === "OptumRxFormularies" && (
                <CSRelatedLinksFormularyAEMContainer />
              )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default RelatedLink;
