import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const GP_RELATEDLINK_REQUEST = "GP_RELATEDLINK_REQUEST";
export const GP_RELATEDLINK_SUCCESS = "GP_RELATEDLINK_SUCCESS";
export const GP_RELATEDLINK_FAILURE = "GP_RELATEDLINK_FAILURE";

export function requestRelatedLinkMetaData(pageType, type, subType, key) {
  const relatedLinkMetaData = {};
  relatedLinkMetaData["pageType"] = pageType;
  relatedLinkMetaData["isFetching"] = true;
  relatedLinkMetaData["value"] = {};
  relatedLinkMetaData["type"] = type;
  relatedLinkMetaData["subType"] = subType;
  relatedLinkMetaData["key"] = key;
  return {
    type: GP_RELATEDLINK_REQUEST,
    data: relatedLinkMetaData,
  };
}

export function receiveRelatedLinkMetaData(pageType, data, type, subType, key) {
  const relatedLinkMetaData = {};
  relatedLinkMetaData["pageType"] = pageType;
  relatedLinkMetaData["value"] = data;
  relatedLinkMetaData["isFetching"] = false;
  relatedLinkMetaData["type"] = type;
  relatedLinkMetaData["subType"] = subType;
  relatedLinkMetaData["key"] = key;
  return {
    type: GP_RELATEDLINK_SUCCESS,
    data: relatedLinkMetaData,
  };
}

export function relatedLinkMetaDataFailure(
  pageType,
  message,
  type,
  subType,
  key
) {
  const relatedLinkMetaData = {};
  relatedLinkMetaData["pageType"] = pageType;
  relatedLinkMetaData["value"] = null;
  relatedLinkMetaData["isFetching"] = false;
  relatedLinkMetaData["type"] = type;
  relatedLinkMetaData["subType"] = subType;
  relatedLinkMetaData["key"] = key;
  return {
    type: GP_RELATEDLINK_FAILURE,
    message,
    data: relatedLinkMetaData,
  };
}

export function getRelatedLinkMetaDataPart(pageType, type, subType, key) {
  if (type) {
    return (dispatch) => {
      dispatch(requestRelatedLinkMetaData(pageType, type, subType, key));
      const url = fetchGeneralContentUrl("/cms/getNodeByCode/page/" + key);
      http
        .get(url)
        .then((response) => {
          const data = response.data;
          if (response.status !== 200) {
            dispatch(
              relatedLinkMetaDataFailure(
                pageType,
                data.message,
                type,
                subType,
                key
              )
            );
            return Promise.reject(data);
          } else {
            if (data && data.length > 0) {
              dispatch(
                receiveRelatedLinkMetaData(
                  pageType,
                  data[0],
                  type,
                  subType,
                  key
                )
              );
            }
          }
          return null;
        })
        .catch(function (error) {
          dispatch(
            relatedLinkMetaDataFailure(pageType, error, type, subType, key)
          );
        });
    };
  }
  return null;
}

export function getRelatedLinkMetaDataPro(pageType, type, subType, key) {
  if (type) {
    return (dispatch) => {
      dispatch(requestRelatedLinkMetaData(pageType, type, subType, key));
      const url = fetchGeneralContentUrl(
        // "/cms/getNodeByCode/page/" + key + "Pro"
        "/cms/getNodeByCode/page/" + key
      );
      http
        .get(url)
        .then((response) => {
          const data = response.data;
          if (response.status !== 200) {
            dispatch(
              relatedLinkMetaDataFailure(
                pageType,
                data.message,
                type,
                subType,
                key
              )
            );
            return Promise.reject(data);
          } else {
            if (data && data.length > 0) {
              dispatch(
                receiveRelatedLinkMetaData(
                  pageType,
                  data[0],
                  type,
                  subType,
                  key
                )
              );
            }
          }
          return null;
        })
        .catch(function (error) {
          dispatch(
            relatedLinkMetaDataFailure(pageType, error, type, subType, key)
          );
        });
    };
  }
  return null;
}
