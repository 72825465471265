import React from "react";
import Content from "../Shared/Content/Content";
import VideoContainer from "../../containers/VideoContainer";
import MessageContainer from "../../containers/MessageContainer";
import pageTypeEnum from "../../constants/pageType";

const Specialty = ({ contentData }) => {
  return (
    <div className="row-">
      <div className="col-sm-9 leftBlock">
        <article>
          {contentData && contentData.length > 0 && (
            <div>
              {contentData[0].title && (
                <h1>
                  <Content html={contentData[0].title} />
                </h1>
              )}
              {contentData[0].body && <Content html={contentData[0].body} />}
            </div>
          )}
        </article>
      </div>
      <div className="col-sm-4 rightBlock">
        <VideoContainer
          pageType={pageTypeEnum.SPECIALTY}
          pageId={contentData[0].nid}
        />
        <MessageContainer
          pageType={pageTypeEnum.SPECIALTY}
          pageId={contentData[0].nid}
        />
      </div>
    </div>
  );
};

export default Specialty;
