import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import { paginate } from "../../../utils/paginate";
import CustomFooter from "../../Shared/CustomFooter/CustomFooter";
import Pagination from "../../Shared/Pagination/Pagination";
import JumpNavigation from "../../Shared/JumpNavigation/JumpNavigation";
import "./Table.scss";

const noneCSS = "none !important";
const border = "1px solid #cccccc";
const fontFamilyType = '"frutiger-light", Arial, sans-serif';


class Table extends Component {
  state = {
    tableData: [],
    pageSize: 10,
    currentPage: 1
  };

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        CssBaseline: {
          "@global": {
            "*, *::before, *::after": {
              transition: noneCSS,
              animation: noneCSS
            }
          }
        },
        MUIDataTable: {
          paper: {
            backgroundColor: "unset"
          },
          responsiveScroll: {
            maxHeight: "none"
          }
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "#efefee"
            }
          }
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "'Frutiger-Bold', Arial, sans-serif",
            fontSize: "13px",
            fontWeight: "bold",
            letterSpacing: ".04rem"
            //whiteSpace: "nowrap",
          },
          fixedHeader: {
            position: "sticky",
            top: "0px",
            left: "0px",
            zIndex: 100,
            backgroundColor: "#63666A",
            color: "#ffffff",
            verticalAlign: "inherit"
          },
          sortAction: {
            display: "inline-block",
            verticalAlign: "top",
            cursor: "pointer",
            paddingLeft: "4px",
            height: "auto"
          },
          sortActive: {
            color: "#ffffff"
          },
          toolButton: {
            display: "flex",
            outline: "none",
            cursor: "pointer",
            height: "auto",
            alignItems: "center"
          }
        },
        MUIDataTablePagination: {
          root: {
            borderBottom: "none",
            borderTop: border,
            root: {
              backgroundColor: "yellow"
            }
          }
        },
        MUIDataTableFooter: {
          order: -1
        },
        MuiMenu: {
          list: {
            padding: "0"
          },
          paper: {
            border: border,
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
            marginTop: "-1px",
            maxHeight: "300px"
          }
        },
        MuiMenuItem: {
          root: {
            color: "#333333",
            fontFamily: fontFamilyType,
            fontSize: "13px",
            height: "unset",
            lineHeight: "18px",
            minHeight: "18px",
            padding: "5px 10px",
            whiteSpace: "normal",
            "&:hover": {
              background: "#eaeaea"
            },
            "&$selected": {
              background: "#f3f3f3"
            }
          }
        },
        MuiSelect: {
          select: {
            border: border,
            borderRadius: "3px",
            color: "#333741",
            fontFamily: fontFamilyType,
            fontSize: "14px",
            minWidth: "26px",
            padding: "6px 20px 6px 10px",
            "&:focus": {
              background: noneCSS,
              borderRadius: "3px"
            }
          },
          icon: {
            color: "#cccccc"
          }
        },
        MuiTableCell: {
          root: {
            padding: "6px 16px"
          },
          body: {
            border: "none",
            fontFamily: '"Open Sans", Arial, sans-serif',
            fontSize: "13px",
            fontWeight: "normal"
          },
          head: {
            fontWeight: 500,
            lineHeight: "1.3125rem"
          }
        },
        MuiTablePagination: {
          caption: {
            fontFamily: fontFamilyType,
            fontSize: "13px"
          }
        },
        MuiTableSortLabel: {
          icon: {
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            flexDirection: "inherit",
            justifyContent: "flex-start",
            color: "#ffffff !important",
            verticalAlign: "middle",
            opacity: 1
          }
        }
      },
      props: {
        MuiButtonBase: {
          disableRipple: true
        },
        MuiMenu: {
          elevation: 0,
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        },
        MuiPaper: {
          square: true
        },
        MuiTableSortLabel: {
          IconComponent: ArrowDropDownIcon
        }
      },
      transitions: {
        create: () => "none"
      }
    });

  componentDidMount() {
    this.getTableData();
  }

  getTableData() {
    this.setState({ currentPage: 1 });
    const { dashboardData } = this.props;
    if (dashboardData) {
      const tableData = dashboardData.filter(item => {
        if (item.showToTable) {
          return true;
        }
        return false;
      });
      this.setState({ tableData });
    }
  }

  handlePageChange = page => {
    if (page) {
      this.setState({ currentPage: page });
    }
  };

  handleGoToPageChange = event => {
    if (event) {
      this.setState({ currentPage: Number(event.target.value) });
    }
  };

  options = {
    print: false,
    filter: false,
    search: false,
    download: false,
    viewColumns: false,
    pagination: false,
    selectableRows: "none",
    rowHover: false,
    elevation: 0,
    responsive: "scroll",
    customFooter: () => {
      const { currentPage, pageSize, tableData } = this.state;
      return (
        <CustomFooter
          count={tableData.length}
          component={
            tableData &&
            tableData.length > pageSize && (
              <Pagination
                itemCount={tableData.length}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            )
          }
          goToPage={
            tableData &&
            tableData.length > pageSize && (
              <JumpNavigation
                itemCount={tableData.length}
                pageSize={pageSize}
                currentPage={currentPage}
                onGoToPageChange={this.handleGoToPageChange}
              />
            )
          }
        />
      );
    }
  };

  columns = [
    {
      label: "Number",
      name: "number",
      options: {
        sort: true,
        sortDirection: "desc",
        customHeadRender: (columnMeta, handleToggleColumn) => {
          const styles = this.getMuiTheme();
          let arrow = <ArrowDropDown />;
          if (columnMeta.sortDirection) {
            if (columnMeta.sortDirection === "desc") {
              arrow = <ArrowDropUp />;
            }
          }
          return (
            <th
              style={{
                ...styles.overrides.MUIDataTableHeadCell.fixedHeader,
                ...styles.overrides.MUIDataTableHeadCell.root,
                ...styles.overrides.MuiTableCell.head,
                ...styles.overrides.MuiTableCell.root
              }}
              key={columnMeta.index}
              onClick={() => handleToggleColumn(columnMeta.index)}
            >
              <span
                role="button"
                tabIndex={columnMeta.index}
                style={styles.overrides.MUIDataTableHeadCell.toolButton}
                title=""
              >
                <div>{columnMeta.label}</div>
                <div style={styles.overrides.MUIDataTableHeadCell.sortAction}>
                  <span
                    style={styles.overrides.MuiTableSortLabel.icon}
                    tabIndex={columnMeta.index}
                    role="button"
                  >
                    {arrow}
                  </span>
                </div>
              </span>
            </th>
          );
        }
      }
    },
    {
      label: "Account",
      name: "accountName",
      options: {
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          const styles = this.getMuiTheme();
          let arrow = <ArrowDropDown />;
          if (columnMeta.sortDirection) {
            if (columnMeta.sortDirection === "desc") {
              arrow = <ArrowDropUp />;
            }
          }
          return (
            <th
              style={{
                ...styles.overrides.MUIDataTableHeadCell.fixedHeader,
                ...styles.overrides.MUIDataTableHeadCell.root,
                ...styles.overrides.MuiTableCell.head,
                ...styles.overrides.MuiTableCell.root
              }}
              key={columnMeta.index}
              onClick={() => handleToggleColumn(columnMeta.index)}
            >
              <span
                role="button"
                tabIndex={columnMeta.index}
                style={styles.overrides.MUIDataTableHeadCell.toolButton}
                title=""
              >
                <div>{columnMeta.label}</div>
                <div style={styles.overrides.MUIDataTableHeadCell.sortAction}>
                  <span
                    style={styles.overrides.MuiTableSortLabel.icon}
                    tabIndex={columnMeta.index}
                    role="button"
                  >
                    {arrow}
                  </span>
                </div>
              </span>
            </th>
          );
        }
      }
    },
    {
      label: "Service Type",
      name: "serviceType",
      options: {
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          const styles = this.getMuiTheme();
          let arrow = <ArrowDropDown />;
          if (columnMeta.sortDirection) {
            if (columnMeta.sortDirection === "desc") {
              arrow = <ArrowDropUp />;
            }
          }
          return (
            <th
              style={{
                ...styles.overrides.MUIDataTableHeadCell.fixedHeader,
                ...styles.overrides.MUIDataTableHeadCell.root,
                ...styles.overrides.MuiTableCell.head,
                ...styles.overrides.MuiTableCell.root
              }}
              key={columnMeta.index}
              onClick={() => handleToggleColumn(columnMeta.index)}
            >
              <span
                role="button"
                tabIndex={columnMeta.index}
                style={styles.overrides.MUIDataTableHeadCell.toolButton}
                title=""
              >
                <div>{columnMeta.label}</div>
                <div style={styles.overrides.MUIDataTableHeadCell.sortAction}>
                  <span
                    style={styles.overrides.MuiTableSortLabel.icon}
                    tabIndex={columnMeta.index}
                    role="button"
                  >
                    {arrow}
                  </span>
                </div>
              </span>
            </th>
          );
        }
      }
    },
    {
      label: "Category",
      name: "categoryName",
      options: {
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          const styles = this.getMuiTheme();
          let arrow = <ArrowDropDown />;
          if (columnMeta.sortDirection) {
            if (columnMeta.sortDirection === "desc") {
              arrow = <ArrowDropUp />;
            }
          }
          return (
            <th
              style={{
                ...styles.overrides.MUIDataTableHeadCell.fixedHeader,
                ...styles.overrides.MUIDataTableHeadCell.root,
                ...styles.overrides.MuiTableCell.head,
                ...styles.overrides.MuiTableCell.root
              }}
              key={columnMeta.index}
              onClick={() => handleToggleColumn(columnMeta.index)}
            >
              <span
                role="button"
                tabIndex={columnMeta.index}
                style={styles.overrides.MUIDataTableHeadCell.toolButton}
                title=""
              >
                <div>{columnMeta.label}</div>
                <div style={styles.overrides.MUIDataTableHeadCell.sortAction}>
                  <span
                    style={styles.overrides.MuiTableSortLabel.icon}
                    tabIndex={columnMeta.index}
                    role="button"
                  >
                    {arrow}
                  </span>
                </div>
              </span>
            </th>
          );
        }
      }
    },
    {
      label: "Type",
      name: "caseTypeName",
      options: {
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          const styles = this.getMuiTheme();
          let arrow = <ArrowDropDown />;
          if (columnMeta.sortDirection) {
            if (columnMeta.sortDirection === "desc") {
              arrow = <ArrowDropUp />;
            }
          }
          return (
            <th
              style={{
                ...styles.overrides.MUIDataTableHeadCell.fixedHeader,
                ...styles.overrides.MUIDataTableHeadCell.root,
                ...styles.overrides.MuiTableCell.head,
                ...styles.overrides.MuiTableCell.root
              }}
              key={columnMeta.index}
              onClick={() => handleToggleColumn(columnMeta.index)}
            >
              <span
                role="button"
                tabIndex={columnMeta.index}
                style={styles.overrides.MUIDataTableHeadCell.toolButton}
                title=""
              >
                <div>{columnMeta.label}</div>
                <div style={styles.overrides.MUIDataTableHeadCell.sortAction}>
                  <span
                    style={styles.overrides.MuiTableSortLabel.icon}
                    tabIndex={columnMeta.index}
                    role="button"
                  >
                    {arrow}
                  </span>
                </div>
              </span>
            </th>
          );
        }
      }
    },
    {
      label: "Case Status",
      name: "caseStatus",
      options: {
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          const styles = this.getMuiTheme();
          let arrow = <ArrowDropDown />;
          if (columnMeta.sortDirection) {
            if (columnMeta.sortDirection === "desc") {
              arrow = <ArrowDropUp />;
            }
          }
          return (
            <th
              style={{
                ...styles.overrides.MUIDataTableHeadCell.fixedHeader,
                ...styles.overrides.MUIDataTableHeadCell.root,
                ...styles.overrides.MuiTableCell.head,
                ...styles.overrides.MuiTableCell.root
              }}
              key={columnMeta.index}
              onClick={() => handleToggleColumn(columnMeta.index)}
            >
              <span
                role="button"
                tabIndex={columnMeta.index}
                style={styles.overrides.MUIDataTableHeadCell.toolButton}
                title=""
              >
                <div>{columnMeta.label}</div>
                <div style={styles.overrides.MUIDataTableHeadCell.sortAction}>
                  <span
                    style={styles.overrides.MuiTableSortLabel.icon}
                    tabIndex={columnMeta.index}
                    role="button"
                  >
                    {arrow}
                  </span>
                </div>
              </span>
            </th>
          );
        }
      }
    },
    {
      label: "Short Description",
      name: "description",
      options: {
        sort: true,
        sortActive: true,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          const styles = this.getMuiTheme();
          let arrow = <ArrowDropDown />;
          if (columnMeta.sortDirection) {
            if (columnMeta.sortDirection === "desc") {
              arrow = <ArrowDropUp />;
            }
          }
          return (
            <th
              style={{
                ...styles.overrides.MUIDataTableHeadCell.fixedHeader,
                ...styles.overrides.MUIDataTableHeadCell.root,
                ...styles.overrides.MuiTableCell.head,
                ...styles.overrides.MuiTableCell.root
              }}
              key={columnMeta.index}
              onClick={() => handleToggleColumn(columnMeta.index)}
            >
              <span
                role="button"
                tabIndex={columnMeta.index}
                style={styles.overrides.MUIDataTableHeadCell.toolButton}
                title=""
              >
                <div>{columnMeta.label}</div>
                <div style={styles.overrides.MUIDataTableHeadCell.sortAction}>
                  <span
                    style={styles.overrides.MuiTableSortLabel.icon}
                    tabIndex={columnMeta.index}
                    role="button"
                  >
                    {arrow}
                  </span>
                </div>
              </span>
            </th>
          );
        }
      }
    }
  ];

  render() {
    const { tableData, currentPage, pageSize } = this.state;
    const paginateData = paginate(tableData, currentPage, pageSize);
    return (
      <div className="dashboard-table">
        <h6>My Cases</h6>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            className={"customTableStyling"}
            title={""}
            data={paginateData}
            columns={this.columns}
            options={this.options}
          />
        </MuiThemeProvider>
      </div>
    );
  }
}

export default Table;
