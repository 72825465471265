import { fetchBASDocuments } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
export const GET_BAS_DOC_REQUEST = "GET_BAS_DOC_REQUEST";
export const GET_BAS_DOC_SUCCESS = "GET_BAS_DOC_SUCCESS";
export const GET_BAS_DOC_FAILURE = "GET_BAS_DOC_FAILURE";

export function requestBASDoc() {
  const GenerateBASDocument = {};
  GenerateBASDocument["isFetching"] = true;
  return {
    type: GET_BAS_DOC_REQUEST,
    isFetched: false,
  };
}

export function receiveBASDoc(data) {
  const GenerateBASDocument = {};
  GenerateBASDocument["isFetching"] = false;
  return {
    type: GET_BAS_DOC_SUCCESS,
    isFetched: true,
    data,
  };
}

export function basDocFailure(message) {
  const GenerateBASDocument = {};
  GenerateBASDocument["isFetching"] = false;
  return {
    type: GET_BAS_DOC_FAILURE,
    isFetched: false,
    isError: true,
    message,
  };
}

export function getBASDocumentData(request) {
  return (dispatch) => {
    dispatch(requestBASDoc());
    const url = fetchBASDocuments();
    http
      .post(url, JSON.stringify(request))
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          return Promise.reject(data);
        } else {
          dispatch(receiveBASDoc(data));
          return data;
        }
      })
      .catch(function (error) {
        dispatch(basDocFailure(error));
      });
  };
}
