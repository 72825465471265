import { theme } from "../../../theme";

export const sx = {
  height: 670,
  my: 2,
  "& .MuiDataGrid-root": {
    backgroundColor: theme.palette.primary.light,

    "& .MuiDataGrid-dataContainer": {
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: theme.spacing(2),
      fontWeight: theme.typography.h1.fontWeight,
    },
    "& .Mui-selected": {
      backgroundColor: "transparent !important",
    },
    "& .MuiButton-text": {
      padding: theme.spacing(2),
    },

    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiTablePagination-caption": {
      fontWeight: theme.typography.h1.fontWeight,
    },
    "& .MuiTablePagination-select": {
      fontWeight: theme.typography.h1.fontWeight,
    },
  },
  "& .MuiDataGrid-footerContainer": {
    backgroundColor: theme.palette.info.light,
  },

  "& .MuiTablePagination-root": {
    backgroundColor: theme.palette.info.light,
    color: theme.typography.h1.color,

    fontWeight: theme.typography.h1.fontWeight,
  },
  "& .MuiButton-label": {
    fontWeight: theme.typography.h1.fontWeight,
  },
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: theme.palette.info.light,
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: theme.palette.background.default,
  },
  "& .Mui-disabled": {
    opacity: 0.5,
    cursor: "not-allowed",
    pointerEvents: "none",
  },
  "& .MuiDataGrid-main": {
    backgroundColor: theme.palette.info.light,
    alignItems: "center",
    justifyContent: "center",
  },
  "& .MuiDataGrid-columnHeaderWrapper": {
    backgroundColor: theme.palette.primary.light,
  },
  "& .MuiDataGrid-cell--textLeft": {
    paddingLeft: `16px !important`,
  },
};
