import {
  PRIVACYPOLICY_CONTENT_REQUEST,
  PRIVACYPOLICY_CONTENT_SUCCESS,
  PRIVACYPOLICY_CONTENT_FAILURE,
} from "../actions/privacyPolicyContent";

export function privacyPolicyContent(
  state = {
    isFetching: false,
    privacyPolicyContent: "",
  },
  action
) {
  switch (action.type) {
    case PRIVACYPOLICY_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        privacyPolicyContent: "",
      });
    case PRIVACYPOLICY_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        privacyPolicyContent: action.data,
      });
    case PRIVACYPOLICY_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default privacyPolicyContent;
