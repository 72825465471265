import {
  LANDING_CONTENT_REQUEST,
  LANDING_CONTENT_SUCCESS,
  LANDING_CONTENT_FAILURE,
} from "../actions/landingContent";

export function landingContent(
  state = {
    isFetching: false,
    landingContent: "",
  },
  action
) {
  switch (action.type) {
    case LANDING_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        landingContent: "",
      });
    case LANDING_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        landingContent: action.data,
      });
    case LANDING_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default landingContent;
