import {
  DOWNLOAD_AZURE_REQUEST,
  DOWNLOAD_AZURE_SUCCESS,
  DOWNLOAD_AZURE_FAILURE,
} from "../actions/getDownloadAzure";
export function fetchDownloadfromAzure(
  state = {
    isFetching: false,
    downloadAzure: "",
  },
  action = {}
) {
  switch (action.type) {
    case DOWNLOAD_AZURE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        downloadAzure: "",
      });
    case DOWNLOAD_AZURE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        downloadAzure: action.data,
      });
    case DOWNLOAD_AZURE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message,
      });
    default:
      return state;
  }
}

export default fetchDownloadfromAzure;
