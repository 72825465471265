import { fetchAccountProductInternalUser } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

import userTypeEnum from "../constants/UserType";
export const INTERNAL_PRODUCT_SUCCESS = "INTERNAL_PRODUCT_SUCCESS";
export function requestInternalProduct(accountId) {
  const internalproductResponse = {};
  internalproductResponse["accountId"] = accountId;
  internalproductResponse["isFetching"] = true;
  internalproductResponse["isError"] = false;
  return {
    type: INTERNAL_PRODUCT_SUCCESS,
    accountId: accountId,
    data: internalproductResponse,
  };
}

export function receiveInternalProduct(data, accountId) {
  const internalproductResponse = {};
  internalproductResponse["accountId"] = accountId;
  internalproductResponse["internalProductList"] = data;
  internalproductResponse["isFetching"] = false;
  internalproductResponse["isError"] = false;
  return {
    type: INTERNAL_PRODUCT_SUCCESS,
    data: internalproductResponse,
  };
}

export function InternalProductFailure(message, accountId) {
  const internalproductResponse = {};
  internalproductResponse["accountId"] = accountId;
  internalproductResponse["internalProductList"] = [];
  internalproductResponse["isFetching"] = false;
  internalproductResponse["isError"] = true;
  return {
    type: INTERNAL_PRODUCT_SUCCESS,
    accountId: accountId,
    data: internalproductResponse,
    message,
  };
}

export function getAccountProductForInternalUser() {
  return (dispatch) => {
    dispatch(requestInternalProduct(userTypeEnum.INTERNAL_DEFAULT_TYPE));
    const url = fetchAccountProductInternalUser();
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          dispatch(
            receiveInternalProduct(data, userTypeEnum.INTERNAL_DEFAULT_TYPE)
          );
          return data;
        } else {
          dispatch(
            InternalProductFailure(
              data.message,
              userTypeEnum.INTERNAL_DEFAULT_TYPE
            )
          );
          return Promise.reject(data);
        }
      })
      .catch(function (error) {
        dispatch(
          InternalProductFailure(error, userTypeEnum.INTERNAL_DEFAULT_TYPE)
        );
      });
  };
}
