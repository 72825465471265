import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";
import LaunchProduct from "../../Launch/Launch";
import Typography from "@material-ui/core/Typography";
import { getGenerateReportsData } from "../../../actions/generateReportLink";
import globalConstants from "../../../constants/global-constants";

import "./report.scss";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

class Report extends Component {
  state = {
    open: false,
    launchUrl: "",
    linkId: "",
    accountNumber: "",
    isLoading: true
  };

  handleClick(accountNumber, linkId) {
    this.props.dispatch(getGenerateReportsData(accountNumber, linkId));
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false, launchUrl: "" });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.generateReports(newProps);
  }

  generateReports(props) {
    const { getGeneratedReportLink, accountNumber, linkId } = props;
    if (accountNumber) {
      const launchUrl = this.findData(
        getGeneratedReportLink.link,
        linkId,
        accountNumber
      );
      if (launchUrl) {
        if (launchUrl !== this.state.launchUrl) {
          this.setState({ launchUrl, isLoading: false });
        }
      }
    }
  }

  findData(data, linkId, accId) {
    return data.find(item => {
      return item.accountId === accId && item.linkId === linkId;
    });
  }

  render() {
    const { linkName, accountNumber, linkId } = this.props;
    const { launchUrl, isLoading } = this.state;
    const generatedLink = launchUrl && launchUrl.generatedLink;
    return (
      <>
        <li>
          <a
            href="# "
            onClick={e => {
              e.preventDefault();
              this.handleClick(accountNumber, linkId);
            }}
          >
            {linkName}
          </a>
        </li>

        <Dialog
          className="reports"
          open={this.state.open}
          onClose={this.handleClose.bind(this)}
          aria-labelledby="customized-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.handleClose.bind(this)}
          >
            Report - {linkName}
          </DialogTitle>
          <DialogContent>
            {!isLoading && (
              <React.Fragment>
                {generatedLink &&
                  generatedLink === globalConstants.UNABLETOGETTEXT ? (
                    <main className="divider rPad">
                      <div className="errorMsg">
                        <p className="cux-icon-warning_filled error txtSub marB0">
                          {globalConstants.SERVICEERRORMSG}
                        </p>
                      </div>
                    </main>
                  ) : (
                    <LaunchProduct launchURL={generatedLink} />
                  )}
              </React.Fragment>
            )}
            {isLoading && (
              <div align="center" data-testid="dialog-message">Loading...</div>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = ({ getGeneratedReportLink }) => {
  return { getGeneratedReportLink };
};
export default connect(mapStateToProps)(Report);
