import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.body1.fontFamily,
    color: theme.palette.background.paper,
    border: "1px solid",
    backgroundColor: theme.palette.primary.main,
    height: (props) => props.height || "auto",
    borderRadius: "32px",
    fontWeight: theme.typography.h1.fontWeight,
    fontSize: theme.typography.body1.fontSize,
    width: (props) => props.width || "auto",
    marginBottom: (props) => props.marginBottom || "auto",
    marginRight: (props) => props.marginRight || "auto",
    marginLeft: (props) => props.marginLeft || "auto",
    padding: (props) => props.padding || "auto",
  },
}));

const PrimaryButton = ({
  children,
  width,
  height,
  marginBottom,
  marginRight,
  marginLeft,
  padding,
  onClick,
  disabled
}) => {
  const classes = useStyles({
    width,
    height,
    marginRight,
    marginBottom,
    marginLeft,
    padding,
  });
  return (
    <button className={classes.root} onClick={onClick} data-disabled={disabled}>
      {children}
    </button>
  );
};


export default PrimaryButton;