import React from "react";
import Copyright from "../Copyright/Copyright";
import { connect } from "react-redux";
import { GlobalFooter as GlobalFooterLinks } from "@pcs/specialty-ui";
import Route from "../../constants/routes";
import Label from "../../constants/labels";
import Link from "../../constants/outside-links";
import { footerStyles } from "./GlobalfooterStyles";
import { withRouter } from "react-router-dom";
import {Box} from "@material-ui/core";
export const routeCallback = (path, history) => () => {
  history.push(path);
};

function GlobalFooter(props) {
  const classes = footerStyles();

  const isAccessOnClientPortal = (account) => {
    if (
      account &&
      account["u_cic_access"] === "yes" &&
      account["u_contact.active"] === "true"
    ) {
      return true;
    }
    return false;
  };

  const { auth, selectedAccount, isWhiteLabel } = props;
  const Home = {
    label: Label.Home,
    target: "_self",
    type: "internal",
    onClick: routeCallback(Route.home, props.history),
  };
  const AboutUs = {
    url: Link.Footer.AboutUs,
    label: Label.AboutUs,
  };
  const termsOfUseAuth = {
    label: Label.TermsOfUse,
    target: "_self",
    type: "internal",
    onClick: routeCallback(Route.termsOfUseAuth, props.history),
  };
  const termsOfUse = {
    label: Label.TermsOfUse,
    target: "_self",
    type: "internal",
    onClick: routeCallback(Route.termsOfUse, props.history),
  };
  const privacyPolicyAuth = {
    label: Label.PrivacyPolicy,
    target: "_self",
    type: "internal",
    onClick: routeCallback(Route.privacyPolicyAuth, props.history),
  };
  const privacyPolicy = {
    label: Label.PrivacyPolicy,
    target: "_self",
    type: "internal",
    onClick: routeCallback(Route.privacyPolicy, props.history),
  };
  let isAuthenticated = auth && auth.isAuthenticated;
  let linksArray = [];
  isAuthenticated && linksArray.push(Home);
  isAuthenticated && !isWhiteLabel && linksArray.push(AboutUs);
  isAuthenticated
    ? linksArray.push(termsOfUseAuth)
    : linksArray.push(termsOfUse);
  isAuthenticated
    ? linksArray.push(privacyPolicyAuth)
    : linksArray.push(privacyPolicy);
  if (!isAccessOnClientPortal(selectedAccount)) {
    linksArray = [];

    linksArray.push(isAuthenticated ? termsOfUseAuth : termsOfUse);
    linksArray.push(isAuthenticated ? privacyPolicyAuth : privacyPolicy);
  }

  return (
    <div className={classes.main}>
      <hr className={classes.footerDivider} />

      <Box
        ml="12px"
        position="absolute"
        width="100%"
        bottom="0"
        left="45px"
        mb="5px"
        height="45px"
        lineHeight="30px"
      >
        <GlobalFooterLinks body={<Copyright />} links={linksArray} />
      </Box>
    </div>
  );
}

const mapStateToProps = ({ user }) => {
  return { user };
};
export default connect(mapStateToProps)(withRouter(GlobalFooter));
