import { HOMEDELIVERY_SUCCESS } from "../actions/homeDelivery";

export function homeDelivery(
  state = {
    isFetching: false,
    accountId: "",
    homeDelivery: [],
  },
  action
) {
  switch (action.type) {
    case HOMEDELIVERY_SUCCESS:
      const data = state.homeDelivery;
      const index =
        data &&
        data.findIndex(
          (item) => item && item.pageType === action.data.pageType
        );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        isFetching: false,
        homeDelivery: data,
      };

    default:
      return state;
  }
}

export default homeDelivery;
