import React from "react";
import renderHTML from "react-render-html";

const Content = ({ html }) => {
  return (
    <React.Fragment>{html && renderHTML(html)}</React.Fragment>
  );
};

export default Content;
