import React, { Component } from "react";
import { connect } from "react-redux";
import { getResourceContent } from "../actions/resourceAndNews";
import { getNewsContent } from "../actions/resourceAndNews";
import ResourceAndNews from "../components/LandingPage/ResourceAndNews/ResourceAndNews";

class ResourceAndNewsContainer extends Component {
  state = {
    resources: {},
    news: {},
    title: "",
    isVisible: "",
    isVisibleForWhiteLabel: ""
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getResourcesAndNews(nextProps);
  }

  componentDidMount() {
    if (this.props) {
      this.getResourcesAndNews(this.props);
    }
  }

  getResourcesAndNews(nextProps) {
    const {
      resourceContent,
      newsContent,
      isResourceFetching,
      isNewsFetching,
      isResourceError,
      isNewsError
    } = nextProps.resourceAndNews;
    const { landingContent } = nextProps;
    if (landingContent) {
      const data = landingContent.landingContent;
      if (data && data.length > 0) {
        this.setState({
          title: data[0].field_aem_title,
          isVisible: data[0].field_aem_visibility,
          isVisibleForWhiteLabel: data[0].field_is_aem_visible_white_label
        });
      }
    }
    if (!isResourceError) {
      if (!isResourceFetching) {
        if (resourceContent && resourceContent !== this.state.resources) {
          this.setState({
            resources: resourceContent.resources
          });
        } else {
          this.props.dispatch(getResourceContent());
        }
      }
    }
    if (!isNewsError) {
      if (!isNewsFetching) {
        if (newsContent && newsContent !== this.state.news) {
          this.setState({
            news: newsContent.resources
          });
        } else {
          this.props.dispatch(getNewsContent());
        }
      }
    }
  }

  render() {
    const {
      resources,
      news,
      title,
      isVisible,
      isVisibleForWhiteLabel
    } = this.state;
    const { whiteLabel } = this.props;
    if (
      whiteLabel &&
      whiteLabel.whiteLabelVal &&
      isVisibleForWhiteLabel === "1" &&
      title
    ) {
      return (
        <ResourceAndNews resources={resources} news={news} title={title} />
      );
    } else if (
      whiteLabel &&
      !whiteLabel.whiteLabelVal &&
      title &&
      isVisible === "1"
    ) {
      return (
        <ResourceAndNews resources={resources} news={news} title={title} />
      );
    }
    return null;
  }
}

const mapStateToProps = ({
  resourceAndNews,
  landingContent,
  auth,
  whiteLabel
}) => {
  return { resourceAndNews, landingContent, auth, whiteLabel };
};

export default connect(mapStateToProps)(ResourceAndNewsContainer);
