import {
  fetchUserAccount,
  fetchUserInternalAccount,
  fetchUserAccountViaContactID,
  fetchGeneralContentUrl,
} from "../constants/microservice-helpers";
import { setUserType } from "./user";
import userTypeEnum from "../constants/UserType";
import http from "../constants/token-interceptors";
import { getNoAccessContent } from "../actions/noAccessContent";

export const USER_ACCOUNT_REQUEST = "USER_ACCOUNT_REQUEST";
export const USER_ACCOUNT_SUCCESS = "USER_ACCOUNT_SUCCESS";
export const USER_ACCOUNT_FAILURE = "USER_ACCOUNT_FAILURE";
export const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT";
export const SET_SELECTED_MODE = "SET_SELECTED_MODE";
export const USER_INTERNAL_SUCCESS = "USER_INTERNAL_SUCCESS";
export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";
export const FETCHED_DATA_FROM = "FETCHED_DATA_FROM";

function requestUserAccount() {
  return {
    type: USER_ACCOUNT_REQUEST,
    isFetched: false,
  };
}

function receiveInternalUser() {
  return {
    type: USER_INTERNAL_SUCCESS,
    isFetched: true,
  };
}

function receiveUserAccount(data) {
  return {
    type: USER_ACCOUNT_SUCCESS,
    isFetched: true,
    data,
  };
}

function userAccountFailure(message) {
  return {
    type: USER_ACCOUNT_FAILURE,
    isFetched: true,
    isError: true,
    message,
  };
}

function sendCustomObjectToPixel(obj, clientId) {
  if (
    window &&
    window.pxSendCustomData &&
    (!sessionStorage.getItem("pxCustomData") ||
      sessionStorage.getItem("pxCustomData") === "null")
  ) {
    const customObj = {
      namespace: process.env.REACT_APP_NAMESPACE,
      "user.firstName": obj.given_name,
      "user.lastName": obj.family_name,
      "user.fullName": obj.name,
      "user.userID": "OptumID: " + obj.preferred_username,
      "user.loginStatus": "logged in",
      "user.action": "login",
      "user.loginTime": new Date().getTime(),
      "user.sessionTime": 0,
      "user.clientID": clientId,
    };

    sessionStorage.setItem("darids", process.env.REACT_APP_DARID);
    sessionStorage.setItem("pxCustomData", JSON.stringify(customObj));
    window.pxSendCustomData(customObj);
  }
}

function sendCustomObjectToPixelForMSID(obj, clientId) {
  if (
    window &&
    window.pxSendCustomData &&
    (!sessionStorage.getItem("pxCustomData") ||
      sessionStorage.getItem("pxCustomData") === "null")
  ) {
    const customObj = {
      namespace: process.env.REACT_APP_NAMESPACE,
      "user.firstName": obj.given_name,
      "user.lastName": obj.family_name,
      "user.fullName": obj.name,
      "user.userID": "MSID: " + obj.sub,
      "user.loginStatus": "logged in",
      "user.action": "login",
      "user.loginTime": new Date().getTime(),
      "user.sessionTime": 0,
      "user.clientID": clientId,
    };

    if (obj.family_name && obj.given_name) {
      customObj["user.fullName"] = obj.given_name + " " + obj.family_name;
    }

    sessionStorage.setItem("darids", process.env.REACT_APP_DARID);
    sessionStorage.setItem("pxCustomData", JSON.stringify(customObj));
    window.pxSendCustomData(customObj);
  }
}

export function selectedAccountData(data, profileData, userAcc) {
  if (sessionStorage.getItem("type") === "msid") {
    sendCustomObjectToPixelForMSID(profileData, data["u_account.name"]);
  } else {
    sendCustomObjectToPixel(profileData, data["u_account.name"]);
  }
  const acc_id = sessionStorage.getItem("acc_id");
  let selectedAcc = "";
  let account = "";
  if (acc_id) {
    selectedAcc = getSelectedAccDetails(userAcc, acc_id);
  }
  account = selectedAcc ? selectedAcc : data;
  return {
    type: SET_SELECTED_ACCOUNT,
    isFetched: false,
    selectedAccount: account,
  };
}
function getSelectedAccDetails(userAccount, accountID) {
  if (userAccount && userAccount.length > 0) {
    const selectedAccount = userAccount.find((item) => {
      if (item && item["u_account"] === accountID) {
        return true;
      }
      return null;
    });
    return selectedAccount;
  }
  return null;
}

export function setSelectedMode(newMode) {
  return {
    type: SET_SELECTED_MODE,
    mode: newMode,
  };
}

function requestSendEmail() {
  return {
    type: SEND_EMAIL_REQUEST,
  };
}

function receiveSendEmail(data) {
  return {
    type: SEND_EMAIL_SUCCESS,
    emailData: data,
  };
}

function SendEmailFailure(message) {
  return {
    type: SEND_EMAIL_FAILURE,
    isSentEmailError: true,
    emailMessage: message,
  };
}

function setFetchedDataFrom() {
  return {
    type: FETCHED_DATA_FROM,
    fetchDatafromContactId: true,
  };
}

export function getUserAccount(OPDATA, profileData) {
  return (dispatch) => {
    dispatch(requestUserAccount());
    const url = fetchUserAccount();
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          if (data && data.length > 0) {
            parseResponse(dispatch, data, profileData);
          } else {
            this.props.dispatch(getNoAccessContent());
          }
        } else {
          dispatch(userAccountFailure(data.message));
          return Promise.reject(data);
        }
        // if (response.status !== 200) {
        //   dispatch(userAccountFailure(data.message));
        //   return Promise.reject(data);
        // } else {
        //   if (data && data.length > 0) {
        //     parseResponse(dispatch, data);
        //   } else {
        //     // Hit the service via contact
        //     dispatch(getUserAccountViaContactNumber(OPDATA, profileData));
        //   }
        // }
        return data;
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          dispatch(getUserAccountViaContactNumber(OPDATA, profileData));
        } else {
          dispatch(userAccountFailure(error));
        }
      });
  };
}

export function getUserAccountViaContactNumber(OPDATA, profileData) {
  const contactId = findContactId(OPDATA);
  const contactval = contactId[0].value;
  var req = {};
  req["contactid"] = contactval;
  req["uuid"] = profileData.sub;
  return (dispatch) => {
    dispatch(requestUserAccount());
    const url = fetchUserAccountViaContactID(contactval);
    http
      .post(url, JSON.stringify(req))
      .then((response) => {
        const data = response.data;

        if (response.status === 200) {
          if (data && data.length > 0) {
            parseResponse(dispatch, data, profileData);
            // send email
            dispatch(setFetchedDataFrom());
            dispatch(sendEmail(OPDATA, profileData, contactval));
          } else if (response.status === 404) {
            // No access
          } else {
            dispatch(userAccountFailure(data.message));
            return Promise.reject(data);
          }
        }
        return data;
      })
      .catch(function (error) {
        dispatch(userAccountFailure(error));
      });
  };
}

function findContactId(OPDATA) {
  if (OPDATA && OPDATA.userIdentifiers) {
    return OPDATA.userIdentifiers.filter((item) => {
      return item.name === "u_contact" ? true : false;
    });
  }
  return null;
}

function findOptumId(OPDATA) {
  if (OPDATA && OPDATA.userIdentifiers) {
    return OPDATA.userIdentifiers.filter((item) => {
      return item.name === "Optum ID" ? true : false;
    });
  }
  return null;
}

function findCBSId(OPDATA) {
  if (OPDATA && OPDATA.userIdentifiers) {
    return OPDATA.userIdentifiers.filter((item) => {
      return item.name === "u_cbs_user_id" ? true : false;
    });
  }
  return null;
}

function findClient(OPDATA) {
  if (OPDATA && OPDATA.userIdentifiers) {
    return OPDATA.userIdentifiers.filter((item) => {
      return item.name === "u_client" ? true : false;
    });
  }
  return null;
}

function findBook(OPDATA) {
  if (OPDATA && OPDATA.userIdentifiers) {
    return OPDATA.userIdentifiers.filter((item) => {
      return item.name === "u_book" ? true : false;
    });
  }
  return null;
}

function findCarrier(OPDATA) {
  if (OPDATA && OPDATA.userIdentifiers) {
    return OPDATA.userIdentifiers.filter((item) => {
      return item.name === "u_carrier" ? true : false;
    });
  }
  return null;
}

function findCICUserId(OPDATA) {
  if (OPDATA && OPDATA.userIdentifiers) {
    return OPDATA.userIdentifiers.filter((item) => {
      return item.name === "u_cic_user_id" ? true : false;
    });
  }
  return null;
}

export function sendEmail(opdata, profileData, contactval) {
  const {
    optumIdValue,
    cbsIdValue,
    cicUserIdValue,
    clientValue,
    bookValue,
    carrierValue,
  } = getValues(opdata);
  var req = {};
  req["firstName"] = profileData.given_name;
  req["lastName"] = profileData.family_name;
  req["email"] = opdata.workEmail;
  req["contactId"] = contactval;
  req["uuid"] = profileData.sub;
  req["optumId"] = optumIdValue;
  req["uCbsId"] = cbsIdValue;
  req["uCicUserId"] = cicUserIdValue;
  req["uClient"] = clientValue;
  req["uBook"] = bookValue;
  req["uCarrier"] = carrierValue;
  return (dispatch) => {
    dispatch(requestSendEmail());
    const url = fetchGeneralContentUrl("/cms/sendMail");
    http
      .post(url, JSON.stringify(req))
      .then((response) => {
        if (response.status !== 200) {
          dispatch(SendEmailFailure("Failed"));
          return Promise.reject("Failed");
        } else {
          dispatch(receiveSendEmail("Mail Sent"));
          return null;
        }
      })
      .catch(function (error) {
        dispatch(SendEmailFailure(error));
      });
  };
}

function getValues(opdata) {
  var bookValue,
    carrierValue,
    cbsIdValue,
    cicUserIdValue,
    optumIdValue,
    clientValue;
  const optumId = findOptumId(opdata);
  if (optumId && optumId.length > 0) {
    optumIdValue = optumId[0].value;
  }
  const cbsId = findCBSId(opdata);
  if (cbsId && cbsId.length > 0) {
    cbsIdValue = cbsId[0].value;
  }
  const cicUserId = findCICUserId(opdata);
  if (cicUserId && cicUserId.length > 0) {
    cicUserIdValue = cicUserId[0].value;
  }
  const client = findClient(opdata);
  if (client && client.length > 0) {
    clientValue = client[0].value;
  }
  const carrier = findCarrier(opdata);
  if (carrier && carrier.length > 0) {
    carrierValue = carrier[0].value;
  }
  const book = findBook(opdata);
  if (book && book.length > 0) {
    bookValue = book[0].value;
  }
  return {
    optumIdValue,
    cbsIdValue,
    cicUserIdValue,
    clientValue,
    bookValue,
    carrierValue,
  };
}

export function getUserAccountForInternalUser(username, email, profileData) {
  var req = {};
  req["userID"] = username;
  req["emailID"] = email;
  return (dispatch) => {
    dispatch(requestUserAccount());
    const url = fetchUserInternalAccount(username, email);
    http
      .post(url, JSON.stringify(req))
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          if (response.status === 500) {
            // dispatch(setUserType(userTypeEnum.INTERNAL_USER, null))
            // dispatch(receiveInternalUser())
          } else {
            dispatch(userAccountFailure(data.message));
          }
          return Promise.reject(data);
        } else {
          if (data && data.length > 1) {
            dispatch(
              setUserType(
                userTypeEnum.MANAGER_USER,
                userTypeEnum.INTERNAL_DEFAULT_TYPE
              )
            );
          } else if (data && data.length > 0 && data.length === 1) {
            dispatch(setUserType(userTypeEnum.INTERNAL_USER, null));
            dispatch(receiveInternalUser());
          }
          parseResponse(dispatch, data, profileData);
        }
        return data;
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          console.log("error", error.response);
        }
        dispatch(userAccountFailure(error));
      });
  };
}

function parseResponse(dispatch, data, profileData) {
  dispatch(receiveUserAccount(data));
  var primaryAccount = data.find((item) => {
    if (item && item.accountVisible) {
      return true;
    }
    return null;
  });
  dispatch(selectedAccountData(primaryAccount, profileData, data));
}
