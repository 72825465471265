import React, { Component } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import GlobalHeader from "./components/GlobalHeader/GlobalHeader";
import GlobalFooter from "./components/GlobalFooter/GlobalFooter";
import ProtectedRoute from "./components/Shared/ProtectedRoutes/ProtectedRoutes";
import HomePage from "./components/HomePage/HomePage";
import routePath from "./constants/routes";
import { onLoginUser } from "./actions/login";
import { onLogoutUser } from "./actions/logout";
import SignOutContentContainer from "./containers/SignOutContentContainer";
import LoginContainer from "./containers/LoginContainer";
import TermsOfUseContainer from "./containers/TermsOfUseContainer";
import OidResponse from "./components/OidResponse/OidResponse";
import PrivacyPolicyContainer from "./containers/PrivacyPolicyContainer";
import LaunchContainer from "./containers/LaunchContainer";
import MemberIdGenerator from "./components/MemberIdGenerator/MemberIdGenerator";
import AdobeResponse from "../src/constants/adobe-Response";
import "./styles/style.scss";
import "./App.scss";
import OIDSSO from "./components/OidSSO/OIDSSO";
import store from "./store/store";
import { checkForWhiteLabel, whiteLabelClient } from "./actions/whiteLabel";
// import { pageLoadAction } from "./actions/tealium";
import MsidOpenAuthURL from "./components/Login/MsIdAuth/MsidOpenAuthURL";
import { initializeAzureAppInsights } from "../src/constants/AppInsights/appInsights";
import PrestoSSO from "../src/components/RxClaimPresto/PrestoSSO";
import Cookies from "universal-cookie";
import { FontStyles } from "@pcs/specialty-ui";
import { theme } from "./theme";
import { pageLoadAction } from "./actions/tealium";

const cookies = new Cookies();
const cdnorigin = process.env.REACT_APP_CDNORIGIN;
const fontpath = process.env.REACT_APP_FONTPATH;
class App extends Component {
  constructor(props) {
    super(props);
    if (window.location.pathname.includes("/home")) {
      // const token = cookies.get('access_token');
      const token = sessionStorage.getItem("session_id");
      if (!token) {
        this.props.dispatch(onLogoutUser());
      }
    }
    // this.adobeAnalytics();
    this.state = {
      selectedAccountID: "",
      isWhiteLabel: "",
      setApplicationContext: "",
    };
  }

  componentDidMount() {
    this.setPropertForWhiteLabel(this.props);
    this.adobeAnalytics();
      
      this.unlisten = this.props.history.listen(location => {
        // on route change
        this.adobeAnalytics();
      })

      
    initializeAzureAppInsights(process.env.REACT_APP_APPINSIGHT_KEY);
  }


  componentWillUnmount() {
    this.unlisten()
  }


  UNSAFE_componentWillReceiveProps(newProps) {
    this.setPropertForWhiteLabel(newProps);
  }

  handleAccountChange = (name) => (event) => {
    const { selectedAccountID } = this.state;
    if (selectedAccountID !== event.target.id) {
      this.setState({ selectedAccountID: event.target.id });
      sessionStorage.setItem("acc_id", event.target.id);
    }
  };

  setPropertForWhiteLabel(props) {
    const { isWhiteLabel, setApplicationContext } = props;
    if (isWhiteLabel !== this.state.isWhiteLabel) {
      this.setState({ isWhiteLabel });
      this.props.dispatch(checkForWhiteLabel(isWhiteLabel));
    }
    if (setApplicationContext !== this.state.setApplicationContext) {
      this.setState({ setApplicationContext });
      this.props.dispatch(
        whiteLabelClient(isWhiteLabel, setApplicationContext)
      );
    }
  }

  adobeAnalytics() {
    const { isWhiteLabel, history } = this.props;
   
    if (history.location) {
      this.getLocation(history.location, isWhiteLabel);
    }
  }

  getLocation(location, isWhiteLabel) {
    // initializeAzureAppInsights(process.env.REACT_APP_APPINSIGHT_KEY);
    setTimeout(() => {
      const setState = store.getState();
      const selectedAccountInfo =
        setState &&
        setState.userAccount &&
        setState.userAccount.selectedAccount;
      const pageDataLayer = {};
      const profileInfo =
        setState && setState.userProfile && setState.userProfile.profileData;
      const content = AdobeResponse[location.pathname];
      const user = {};
      user["loginStatus"] = setState.auth.isAuthenticated
        ? "logged in"
        : "not logged in";
      const type = sessionStorage.getItem("type");
      if (type && type === "msid") {
        user["userID"] = profileInfo ? "MSID : " + profileInfo.sub : "";
      } else if (type && type === "oid") {
        user["userID"] = profileInfo
          ? "OptumID : " + profileInfo.preferred_username
          : "";
      } else {
        user["userID"] = "";
      }
      if (isWhiteLabel) {
        user["userType"] = "White label user";
      } else {
        user["userType"] =
          selectedAccountInfo &&
          selectedAccountInfo["u_account.u_acct_mgmt_team_model"] &&
          selectedAccountInfo[
            "u_account.u_acct_mgmt_team_model"
          ].toUpperCase() === "YES"
            ? "AMT user"
            : "Standard user";
      }
      user["clientID"] =
        selectedAccountInfo && selectedAccountInfo["u_account.name"]
          ? selectedAccountInfo["u_account.name"]
          : "";
      pageDataLayer["content"] = content;
      pageDataLayer["user"] = user;
      if (pageDataLayer) {
        this.props && this.props.dispatch(pageLoadAction(pageDataLayer));
      }
    }, 1500);
  }

  render() {
    var isItemVisibleForLaunch;
    if (window.location.pathname === "/product/launch/") {
      isItemVisibleForLaunch = false;
    } else {
      isItemVisibleForLaunch = true;
    }
    const {
      auth,
      dispatch,
      userAccount,
      whiteLabel,
      isWhiteLabel,
      setApplicationContext,
    } = this.props;
    const { selectedAccount } = userAccount;
    const { selectedAccountID } = this.state;
    return (
      <React.Fragment>
        <head>
          <link
            rel="shortcut icon"
            href="https://cdn-aem.optum.com/content/dam/optum3/optum/skin/icons/favicon.ico"
          />
          <FontStyles origin={cdnorigin} path={fontpath} />
        </head>
        <MuiThemeProvider theme={theme}>
          <div
            //className={"mainWrap"}
            className={isWhiteLabel ? "mainWrap whitelabel" : "mainWrap"}
          >
            {window.location.pathname.includes("/home/sso/launch") ? null : (
              <header className="main">
                {isItemVisibleForLaunch && (
                  <GlobalHeader
                    auth={auth}
                    selectedAccount={selectedAccount}
                    isItemVisibleForLaunch={isItemVisibleForLaunch}
                    onItemchange={this.handleAccountChange}
                    whiteLabel={whiteLabel}
                    logoutClick={(props) => dispatch(onLogoutUser(props))}
                    isWhiteLabel={isWhiteLabel}
                  />
                )}
              </header>
            )}
            {window.location.pathname.includes("/resource-server/login") && (
              <Switch>
                <ProtectedRoute
                  auth={auth}
                  isAccessOnPage={true}
                  path="*"
                  render={(props) => (
                    <HomePage
                      {...props}
                      auth={auth}
                      selectedAccountID={selectedAccountID}
                      isWhiteLabel={isWhiteLabel}
                    />
                  )}
                />
              </Switch>
            )}
            <Switch>
              <Redirect from="/" exact to={routePath.home} />
              <Route
                path={routePath.login}
                render={(props) => (
                  <LoginContainer
                    {...props}
                    loginClick={(props) => dispatch(onLoginUser(props))}
                    auth={auth}
                    whiteLabel={isWhiteLabel}
                    setApplicationContext={setApplicationContext}
                  />
                )}
              />
              <Route
                path={routePath.termsOfUse}
                render={(props) => (
                  <TermsOfUseContainer {...props} auth={auth} />
                )}
              />
              {!isWhiteLabel && (
                <Route
                  path={routePath.memberIdGenerator}
                  component={MemberIdGenerator}
                />
              )}
              <Route
                path={routePath.privacyPolicy}
                exact
                render={(props) => (
                  <PrivacyPolicyContainer {...props} auth={auth} />
                )}
              />
              <Route
                path={routePath.signout}
                exact
                render={(props) => <SignOutContentContainer {...props} />}
              />
              <ProtectedRoute
                auth={auth}
                isAccessOnPage={true}
                path={routePath.home}
                render={(props) => (
                  <HomePage
                    {...props}
                    auth={auth}
                    selectedAccountID={selectedAccountID}
                    isWhiteLabel={isWhiteLabel}
                  />
                )}
              />
              <ProtectedRoute
                auth={auth}
                exact
                isAccessOnPage={true}
                path={routePath.launch}
                render={(props) => <LaunchContainer {...props} />}
              />

              {/* <ProtectedRoute
                auth={auth}
                exact
                isAccessOnPage={true}
                path={routePath.rxTrackSSOLaunch}
                render={(props) => (
                  <RxTrackSSO {...props} userProfile={userProfile} />
                )}
              /> */}
              {/* <Route
                path={routePath.oidResponseCode}
                exact
                render={(props) => (
                  <OidResponse
                    {...props}
                    dispatch={dispatch}
                    whiteLabel={isWhiteLabel}
                  />
                )}
              /> */}
              <Route
                path={routePath.responseCode}
                exact
                render={(props) => (
                  <OidResponse
                    {...props}
                    dispatch={dispatch}
                    whiteLabel={isWhiteLabel}
                    setApplicationContext={setApplicationContext}
                  />
                )}
              />
              {/* <Route
              path={routePath.oidSSO}
              exact
              render={props => <SSO {...props} dispatch={dispatch} />}
            /> */}
              <Route
                path={routePath.oidSSO}
                exact
                render={(props) => (
                  <OIDSSO
                    {...props}
                    dispatch={dispatch}
                    whiteLabel={isWhiteLabel}
                    setApplicationContext={setApplicationContext}
                  />
                )}
              />
              <Route
                path={routePath.msidAuth}
                exact
                render={(props) => (
                  <MsidOpenAuthURL
                    {...props}
                    dispatch={dispatch}
                    whiteLabel={isWhiteLabel}
                    setApplicationContext={setApplicationContext}
                  />
                )}
              />
            </Switch>
            {window.location.pathname.includes("/home/sso/launch") ? null : (
              <>
                {isItemVisibleForLaunch && (
                  <GlobalFooter
                    auth={auth}
                    selectedAccount={selectedAccount}
                    isWhiteLabel={isWhiteLabel}
                  />
                )}
              </>
            )}
          </div>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

// globally disable all MUI transitions, animations, and ripple effect

export default withRouter(connect(mapStateToProps)(App));
