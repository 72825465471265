import {
  PRODUCT_METADATA_REQUEST,
  PRODUCT_METADATA_UPDATE
} from "../actions/productMetaData";

export function productsMetaData(
  state = {
    isFetching: false,
    data: []
  },
  action
) {
  switch (action.type) {
    case PRODUCT_METADATA_REQUEST:
      let data=[];
      if (state.data) {
         data = [...state.data, action.data]
      }
       return Object.assign({}, state, {
        data: data
      });
    case PRODUCT_METADATA_UPDATE:
      const productArray = state && state.data && state.data.filter(item => {
        if (item["name"] !== action.data.name) {
          return item;
        }
        return null;
      });
      let prodData=[];
      if (productArray) {
        prodData= [...productArray, action.data]
      }
      return  {
        ...state,
        data: prodData
      };
    default:
      return state;
  }
}

export default productsMetaData;
