import { ACCOUNT_METADATA_CONTENT_SUCCESS } from "../actions/accountsMetaData";

export function accountsMetaData(
  state = {
    isFetching: false,
    data: [],
  },
  action
) {
  switch (action.type) {
    case ACCOUNT_METADATA_CONTENT_SUCCESS:
      const Data = state.data;
      const index =
        Data &&
        Data.findIndex(
          (item) => item && item.accountId === action.data.accountId
        );
      if (index !== -1) {
        Data && Data.splice(index, 1);
      }
      Data && Data.push(action.data);
      return {
        ...state,
        isFetching: false,
        data: Data,
      };

    default:
      return state;
  }
}

export default accountsMetaData;
