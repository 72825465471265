import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const CLINICALSERVICES_SUCCESS = "CLINICALSERVICES_SUCCESS";

export function requestClinicalServices(pageType) {
  const clinicalServicesResponse = {};
  clinicalServicesResponse["pageType"] = pageType;
  clinicalServicesResponse["isFetching"] = true;
  clinicalServicesResponse["isError"] = false;
  return {
    type: CLINICALSERVICES_SUCCESS,
    data: clinicalServicesResponse,
  };
}

export function receiveClinicalServices(data, pageType) {
  const clinicalServicesResponse = {};
  clinicalServicesResponse["pageType"] = pageType;
  clinicalServicesResponse["value"] = data;
  clinicalServicesResponse["isFetching"] = false;
  clinicalServicesResponse["isError"] = false;
  return {
    type: CLINICALSERVICES_SUCCESS,
    data: clinicalServicesResponse,
  };
}

export function clinicalServicesFailure(message, pageType) {
  const clinicalServicesResponse = {};
  clinicalServicesResponse["pageType"] = pageType;
  clinicalServicesResponse["isFetching"] = false;
  clinicalServicesResponse["isError"] = true;
  return {
    type: CLINICALSERVICES_SUCCESS,
    data: clinicalServicesResponse,
    message,
  };
}

export function getClinicalServicesPart(pageType) {
  if (pageType) {
    return (dispatch) => {
      let endPoint = "";
      dispatch(requestClinicalServices(pageType));

      if (pageType.toUpperCase() === "PARTICIPANT") {
        endPoint = "/cms/getNodeByCode/t2/ClinicalServicesPart";
      } else if (pageType.toUpperCase() === "PROMOTIONAL") {
        endPoint = "/cms/getNodeByCode/t2/ClinicalServicesPro";
      }
      const url = fetchGeneralContentUrl(endPoint);

      http
        .get(url)
        .then((response) => {
          const data = response.data;
          if (response.status === 200) {
            dispatch(receiveClinicalServices(data, pageType));
            return data;
          } else {
            dispatch(clinicalServicesFailure(data.message, pageType));
            return Promise.reject(data);
          }
        })
        .catch(function (error) {
          dispatch(clinicalServicesFailure(error, pageType));
        });
    };
  }
  return null;
}
