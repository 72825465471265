import { fetchAccountTeam } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const ACCOUNT_TEAM_REQUEST = "ACCOUNT_TEAM_REQUEST";
export const ACCOUNT_TEAM_SUCCESS = "ACCOUNT_TEAM_SUCCESS";
export const ACCOUNT_TEAM_FAILURE = "ACCOUNT_TEAM_FAILURE";

export function requestAccountTeam(accountId) {
  const workteamRespose = {};
  workteamRespose["accountId"] = accountId;
  workteamRespose["isFetching"] = true;
  workteamRespose["isError"] = false;
  return {
    type: ACCOUNT_TEAM_SUCCESS,
    accountId: accountId,
    data: workteamRespose,
  };
}

export function receiveAccountTeam(data, accountId) {
  const workteamRespose = {};
  workteamRespose["accountId"] = accountId;
  workteamRespose["accountTeam"] = data;
  workteamRespose["isFetching"] = false;
  workteamRespose["isError"] = false;
  return {
    type: ACCOUNT_TEAM_SUCCESS,
    data: workteamRespose,
  };
}

export function accountTeamFailure(message, accountId) {
  const workteamRespose = {};
  workteamRespose["accountId"] = accountId;
  workteamRespose["isFetching"] = false;
  workteamRespose["isError"] = true;
  return {
    type: ACCOUNT_TEAM_SUCCESS,
    accountId: accountId,
    data: workteamRespose,
    message,
  };
}

export function getAccountTeam(accountId) {
  if (accountId) {
    return (dispatch) => {
      dispatch(requestAccountTeam(accountId));
      const url = fetchAccountTeam(accountId);
      http
        .get(url)
        .then((response) => {
          const data = response.data;
          if (response.status === 200) {
            dispatch(receiveAccountTeam(data, accountId));
            return data;
          } else {
            dispatch(accountTeamFailure(data.message, accountId));
            return Promise.reject(data);
          }
        })
        .catch(function (error) {
          dispatch(accountTeamFailure(error, accountId));
        });
    };
  }
  return null;
}
