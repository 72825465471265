import React from "react";
import routePath from "../../../../constants/routes";
import { NavLink } from "react-router-dom";

const LeftNavigation = ({ type, subType }) => {
  return (
    <div className="col-sm-3">
      <ul>
        <li
          className={type === "RxNews" && !subType ? "selected" : ""}
          link-id="RxNews - left nav"
        >
          <NavLink to={routePath.clinicalPublication_RxNews}>
            RxNews<sup>®</sup>
          </NavLink>
        </li>
        <ul>
          <li
            className={
              type === "RxNews" && subType === "clinicalUpdates"
                ? "selected"
                : ""
            }
          >
            <NavLink
              link-id="Clinical updates - left nav"
              to={
                routePath.clinicalPublication_RxNews + "?type=clinicalUpdates"
              }
            >
              Clinical updates
            </NavLink>
          </li>
          <li
            className={
              type === "RxNews" && subType === "drugRecalls" ? "selected" : ""
            }
          >
            <NavLink
              link-id="Drug recalls/shortages - left nav"
              to={routePath.clinicalPublication_RxNews + "?type=drugRecalls"}
            >
              Drug recalls/shortages
            </NavLink>
          </li>
          <li
            className={
              type === "RxNews" && subType === "drugSafety" ? "selected" : ""
            }
          >
            <NavLink
              link-id="Drug safety - left nav"
              to={routePath.clinicalPublication_RxNews + "?type=drugSafety"}
            >
              Drug safety
            </NavLink>
          </li>
          <li
            className={
              type === "RxNews" && subType === "drugApprovals" ? "selected" : ""
            }
          >
            <NavLink
              link-id="Drug approvals - left nav"
              to={routePath.clinicalPublication_RxNews + "?type=drugApprovals"}
            >
              Drug approvals
            </NavLink>
          </li>
          <li
            className={
              type === "RxNews" && subType === "newGenerics" ? "selected" : ""
            }
          >
            <NavLink
              link-id="New generics - left nav"
              to={routePath.clinicalPublication_RxNews + "?type=newGenerics"}
            >
              New generics
            </NavLink>
          </li>
        </ul>
        <li className={type === "RxOutlook" && !subType ? "selected" : ""}>
          <NavLink
            link-id="RxOutlook - left nav"
            to={routePath.clinicalPublication_RxOutlook}
          >
            RxOutlook<sup>®</sup>
          </NavLink>
        </li>
        <li className={type === "RxHighlight" && !subType ? "selected" : ""}>
          <NavLink
            link-id="RxHighlights - left nav"
            to={routePath.clinicalPublication_RxHighlight}
          >
            RxHighlights
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default LeftNavigation;
