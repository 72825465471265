import { Box, Button, Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { sx } from "./ClientDataGridStyle";
import useClientDataGrid from "./useClientDataGrid";
import { exportCSVLinkAnalytics , setDrugResults} from "../../../actions/drugSearchActionFormulary";

const ClientDataGrid = (props) => {
  const dispatch = useDispatch();
  const {
    initialSortOrder,
    pageSize: initialPagesize,
    GridToolBarComponent,
    exportAllColumns = true,
    toolbarExportText,
    filterCriteria,
    rows,
    noRowsMessage,
    ...otherProps
  } = props;

  const {
    pageSize,
    selectedRows,
    handlePageSizeChange,
    handleSelectedRows,
    handleSortOrderChange,
    sortOrder,
    filteredRows,
  } = useClientDataGrid(
    initialSortOrder,
    initialPagesize,
    rows,
    filterCriteria
  );
  const disabled = selectedRows.length === 0;
  
  const handleExportClick = () => {
    dispatch(exportCSVLinkAnalytics());
  }

  useEffect(() => {
    dispatch(setDrugResults(filteredRows));
  }, [filteredRows.length]);

  function CustomExport() {
    return (
      <Button variant="outlined" color="primary" disabled={disabled}>
        <GridToolBarComponent csvOptions={{ allColumns: exportAllColumns }} onClick={handleExportClick}></GridToolBarComponent>
      </Button>
    );
  }

  function NoResultsMessage() {
    return <Typography variant="h4">{noRowsMessage}</Typography>;
  }

  return (
    <Box sx={sx}>
      <DataGrid
        {...otherProps}
        components={{
          Toolbar: CustomExport,
          NoRowsOverlay: NoResultsMessage,
        }}
        rows={filteredRows}
        sortModel={sortOrder}
        onSortModelChange={handleSortOrderChange}
        pageSize={pageSize}
        onPageSizeChange={handlePageSizeChange}
        onSelectionModelChange={handleSelectedRows}
        localeText={{
          toolbarExport: toolbarExportText,
        }}
      />
    </Box>
  );
};

export default React.memo(ClientDataGrid);
