import { fetchUrlWithSASToken } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
import Axios from "axios";

export const DOCUMENT_URL_REQUEST = "DOCUMENT_URL_REQUEST";
export const DOCUMENT_URL_SUCCESS = "DOCUMENT_URL_SUCCESS";
export const DOCUMENT_URL_FAILURE = "DOCUMENT_URL_FAILURE";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";
var FileSaver = require("file-saver");
export function documentUrlRequest(documentName, displayName) {
  const documentResponse = {};
  documentResponse["documentName"] = documentName;
  documentResponse["isFetching"] = true;
  documentResponse["responseCode"] = null;
  documentResponse["displayName"] = displayName;
  return {
    type: DOCUMENT_URL_SUCCESS,
    isFetched: false,
    documentResponse
  };
}

export function documentUrlFailure(documentName, displayName, responseCode) {
  const documentResponse = {};
  documentResponse["documentName"] = documentName;
  documentResponse["isFetching"] = false;
  documentResponse["isError"] = true;
  documentResponse["responseCode"] = responseCode;
  documentResponse["displayName"] = displayName;
  return {
    type: DOCUMENT_URL_SUCCESS,
    isFetched: false,
    isError: true,
    responseCode,
    documentResponse
  };
}

export function documentUrlSuccess(
  documentName,
  displayName,
  responseCode,
  fileUrl
) {
  const documentResponse = {};
  documentResponse["documentName"] = documentName;
  documentResponse["isFetching"] = false;
  documentResponse["responseCode"] = responseCode;
  documentResponse["displayName"] = displayName;
  return {
    type: DOCUMENT_URL_SUCCESS,
    isFetched: false,
    isError: false,
    responseCode,
    documentResponse,
    fileUrl
  };
}

export function clearDownloadResponsefromAzure(reportName, documentName) {
  return {
    type: CLEAR_RESPONSE,
    reportName,
    documentName
  };
}

export function getDocumentUrl(req, documentName, displayName) {
  return dispatch => {
    dispatch(documentUrlRequest(documentName, displayName));
    const url = fetchUrlWithSASToken();
    http
      .post(url, JSON.stringify(req))
      .then(response => {
        const data = response.data;
        if (response.status === 200) {
          if (data) {
            http
              .get(data)
              .then(i => {
                if (i.status === 200) {
                  dispatch(
                    documentUrlSuccess(
                      documentName,
                      displayName,
                      response.status,
                      data
                    )
                  );
                  FileSaver.saveAs(data, documentName);
                  return data;
                } else {
                  dispatch(documentUrlFailure(documentName, displayName, 400));
                  return Promise.reject();
                }
              })
              .catch((error) => {
                if (error && error.response && error.response.status === 404) {
                  dispatch(documentUrlFailure(documentName, displayName, 400));
                }
                else {
                  dispatch(clearDownloadResponsefromAzure(displayName, documentName))
                }
              });
          }
          return data;
        } else {
          dispatch(
            documentUrlFailure(documentName, displayName, response.status)
          );
          return Promise.reject();
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status) {
          dispatch(
            documentUrlFailure(documentName, displayName, error.response.status)
          );
        } else {
          dispatch(documentUrlFailure(documentName, displayName, ""));
        }
      });
  };
}
