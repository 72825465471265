import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClientDropdown from "./ClientDropdown/ClientDropdown";
import moment from "moment";
import "./Filter.scss";

class Filter extends Component {
  state = {};
  render() {
    const { dropdownData } = this.props;
    const {
      handleChangefromData,
      handleChangetoData,
      handleToDateRaw,
      handleFromDateRaw,
      handleToDateBlur,
      handleFromDateBlur,
      onInputChange,
      handleClientChange,
      invoicetext,
      selectedValue,
      toDate,
      fromDate,
      onSearch,
      isToDateValid,
      isErrorVisible,
      isDateErrorVisible,
      isInvoiceNumErrorVisible,
      isInvalidDateErrorVisible,
      isFromDateValid,
      invalidFromDate,
      invalidToDate,
      fromDateMax,
      toDateMin,
      currentDate,
      restrictDate,
    } = this.props;
    return (
      <React.Fragment>
        <div id="clientSelectIdCheck" className="col-sm-3 padR0 cusSelect">
          <ClientDropdown
            dropdownData={dropdownData}
            onItemChange={handleClientChange}
            selectedValue={selectedValue}
            isErrorVisible={isErrorVisible}
          />
          {isErrorVisible && (
            <span
              id="clientSelectIdCheck"
              className="cux-icon-warning_filled error"
            />
          )}
        </div>
        <div className="col-sm-2 padR0">
          <label>Invoice #</label>
          <input
            name="invoicetext"
            value={invoicetext}
            onChange={onInputChange}
            required
            type="text"
            className={
              isErrorVisible || isInvoiceNumErrorVisible ? "error" : ""
            }
          />
          {(isInvoiceNumErrorVisible || isErrorVisible) && (
            <span
              id="invoicetext_error"
              className="cux-icon-warning_filled error"
            />
          )}
        </div>
        <div className="col-sm-5 padR0">
          <label>Invoice date(s)</label>
          <div className="row-">
            <div className="col-sm-5 padR0" id="fromdatediv">
              <DatePicker
                className={
                  isDateErrorVisible || isInvalidDateErrorVisible || isFromDateValid || fromDateMax || invalidFromDate
                    ? "hasDatepicker error"
                    : ""
                }
                selected={!invoicetext ? fromDate : restrictDate && restrictDate._d}
                onChange={handleChangefromData}
                minDate={restrictDate && restrictDate._d}
                maxDate={currentDate && currentDate._d}
                onBlur={handleFromDateBlur}
                onChangeRaw={handleFromDateRaw}
                placeholderText={"mm/dd/yyyy"}
                disabledKeyboardNavigation
                disabled={invoicetext}
                popperModifiers={{
                  flip: {
                    behavior: ['bottom']
                  }
                }}
              />
              <div style={{ height: 20 }}>
                {(isDateErrorVisible || isInvalidDateErrorVisible || isFromDateValid || fromDateMax || invalidFromDate) && (
                  <span
                    id="fromdate_error"
                    className="cux-icon-warning_filled error"
                  />
                )}
              </div>
            </div>
            <div className="col-sm-2 c padT10">to</div>
            <div className="col-sm-5 padL0" id="todatediv">
              <DatePicker
                selected={!invoicetext ? toDate : currentDate && currentDate._d}
                className={
                  isDateErrorVisible || isInvalidDateErrorVisible || isToDateValid || toDateMin || invalidToDate
                    ? "hasDatepicker error"
                    : ""
                }
                onChange={handleChangetoData}
                minDate={fromDate ? fromDate : restrictDate && restrictDate._d}
                maxDate={currentDate && currentDate._d}
                onBlur={handleToDateBlur}
                onChangeRaw={handleToDateRaw}
                placeholderText={"mm/dd/yyyy"}
                disabledKeyboardNavigation
                disabled={invoicetext}
                popperModifiers={{
                  flip: {
                    behavior: ['bottom']
                  }
                }}
              />
              <div style={{ height: 20 }}>
                {(isDateErrorVisible || isInvalidDateErrorVisible || isToDateValid || toDateMin || invalidToDate) && (
                  <span
                    id="todate_error"
                    className="cux-icon-warning_filled error"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-2">
          <label>&nbsp;</label>
          <a
            href=" "
            name="search"
            className="button button-primary block noCap"
            link-id="invoice and financial reports - search"
            onClick={onSearch}
          >
            Search
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default Filter;
