import React from "react";

const Logo = () => {
    return (
        <div>
<svg width="39" height="80" viewBox="0 0 39 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Layer_1">
<path id="Vector" d="M36.7495 0H2.16964C0.971485 0 0 0.971282 0 2.16964V15.1352H38.919V2.16964C38.919 0.971282 37.9477 0 36.7495 0Z" fill="#002677"/>
<g id="Group">
<path id="Vector_2" d="M2.16248 15.1352V75.6757C2.16248 75.9746 2.19263 76.2659 2.25027 76.5477C2.53827 77.9551 3.50996 79.1132 4.8035 79.6601C5.32084 79.8786 5.88966 80 6.48678 80H32.4326C33.0298 80 33.5984 79.8786 34.1159 79.6601C35.4092 79.1132 36.3809 77.9551 36.6691 76.5477C36.7266 76.2659 36.7569 75.9744 36.7569 75.6757V15.1352H2.16248Z" fill="#FF612B"/>
</g>
<path id="Vector_3" d="M6.48636 26.951H32.4322V61.5457H6.48636V26.951Z" fill="white"/>
<path id="Vector_4" d="M27.6256 43.0933H24.3013L22.5009 45.9608L19.5571 41.817C19.6566 41.7694 19.7497 41.7167 19.846 41.6617C20.8652 41.0863 21.5834 40.0865 21.7395 38.9189C21.7856 38.5737 21.7893 38.2211 21.752 37.8738C21.6451 36.8835 21.1938 35.9577 20.3629 35.338C20.2704 35.2683 20.1742 35.2051 20.0745 35.1458C19.3465 34.713 18.5783 34.5557 17.1815 34.5557H10.8453V47.6806H13.6993V42.5253H16.6386L20.6634 48.2137L16.7093 53.9411H20.0796L22.3491 50.643L24.6382 53.9411H28.0751L24.0605 48.1546L27.6256 43.0933ZM18.2835 40.0453C17.9089 40.3208 17.3774 40.419 16.4336 40.419H13.6989V36.8191H16.5518C18.2246 36.8191 18.9723 37.3702 18.9723 38.6096C18.9721 39.2398 18.7364 39.7308 18.2835 40.0453Z" fill="#002677"/>
</g>
</svg>
</div>
);
}
export default Logo;