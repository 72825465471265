import React, { Component } from "react";
import Content from "../Shared/Content/Content";
import { connect } from "react-redux";
class Requests extends Component {
  constructor() {
    super();
    this.state = {};
    this.containerRef = React.createRef();
  }

  handleClick(userType, sso) {
    if (userType) {
   /*    if (sso && sso.actionUrl) {
        this.containerRef &&
          this.containerRef.current &&
          this.containerRef.current.submit();
      } */
      var win = window.open("/home/sso/launch/servicenow", "_blank");
      win.focus();
    } else {
      if (sso && sso.launchUrl) {
        window.open(sso.launchUrl, "_blank");
      }
    }
  }

  render() {
    const { contentData, isLaunchVisible, userType, sso } = this.props;
    return (
      <React.Fragment>
        {contentData && contentData.title && (
          <h1>
            <Content html={contentData.title} />
          </h1>
        )}

        {contentData && contentData.body && <Content html={contentData.body} />}
        {isLaunchVisible && (
          <>
            <div>
              {contentData && contentData.body && (
                <a
                  href="# "
                  target="_blank"
                  name ="launch"
                  link-id="client requests - launch"
                  className="button noCap"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleClick(userType, sso);
                  }}
                >
                  Launch
                </a>
              )}
            </div>
            <form
              target="_blank"
              method="post"
              ref={this.containerRef}
              action={sso.actionUrl || ""}
            >
              <input type="hidden" name="PARTNER" value={sso.partnerId || ""} />
              <input
                type="hidden"
                name="TARGET"
                value={sso.targetResource || ""}
              />
              <input
                type="hidden"
                name="opentoken"
                value={sso.openToken || ""}
              />
            </form>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ user, userProfile, auth }) => {
  return { user, userProfile, auth };
};
export default connect(mapStateToProps)(Requests);
