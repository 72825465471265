import {
  NOACCESS_CONTENT_REQUEST,
  NOACCESS_CONTENT_SUCCESS,
  NOACCESS_CONTENT_FAILURE
} from "../actions/noAccessContent";

function noAccessContent(
  state = {
    isFetching: false,
    noAccessContent: ""
  },
  action
) {
  switch (action.type) {
    case NOACCESS_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        noAccessContent: ""
      });
    case NOACCESS_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        noAccessContent: action.data
      });
    case NOACCESS_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        error: action.message
      });
    default:
      return state;
  }
}

export default noAccessContent;
