import { fetchInvoiceDocuments } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";
import { invoiceActions } from "./tealium";
export const GET_INVOICE_DOC_REQUEST = "GET_INVOICE_DOC_REQUEST";
export const GET_INVOICE_DOC_SUCCESS = "GET_INVOICE_DOC_SUCCESS";
export const GET_INVOICE_DOC_FAILURE = "GET_INVOICE_DOC_FAILURE";

export function requestInvoiceDoc() {
  return {
    type: GET_INVOICE_DOC_REQUEST,
    isFetched: false
  };
}

export function receiveInvoiceDoc(data) {
  return {
    type: GET_INVOICE_DOC_SUCCESS,
    isFetched: true,
    data
  };
}

export function invoiceDocFailure(message) {
  return {
    type: GET_INVOICE_DOC_FAILURE,
    isFetched: false,
    isError: true,
    message
  };
}

export function getInvoiceDocumentData(request) {
  var searchFilters;
  return dispatch => {
    const searchTerm = request && request.invoiceNum ? request.invoiceNum : "";
    if (request && request.toDate && request.fromDate && request.clientID) {
      searchFilters =
        request.clientID +
        " | Start Date: " +
        request.fromDate +
        " | End Date: " +
        request.toDate;
    } else {
      searchFilters = request && request.clientID;
    }
    dispatch(invoiceActions(searchTerm, searchFilters, "zero"));
    dispatch(requestInvoiceDoc());
    const url = fetchInvoiceDocuments();
    http
      .post(url, JSON.stringify(request))
      .then(response => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(invoiceDocFailure(data.message));
          return Promise.reject(data);
        } else {
          dispatch(receiveInvoiceDoc(data));
          if (data && data.length > 0) {
            dispatch(invoiceActions(searchTerm, searchFilters, data.length));
          }
          return data;
        }
      })
      .catch(function (error) {
        dispatch(invoiceDocFailure(error));
      });
  };
}
