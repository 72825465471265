import { DASHBOARD_SUCCESS } from "../actions/dashboard";

export function dashboard(
  state = {
    isFetching: false,
    dashboard: [],
  },
  action
) {
  switch (action.type) {
    case DASHBOARD_SUCCESS:
      const data = state.dashboard;
      const index =
        data &&
        data.findIndex(
          (item) => item && item.accountId === action.data.accountId
        );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        isFetching: false,
        dashboard: data,
      };

    default:
      return state;
  }
}
export default dashboard;
