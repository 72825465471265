import React, { Component } from "react";
import { connect } from "react-redux";
import { getPrivacyPolicyContent } from "../actions/privacyPolicyContent";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";

class PrivacyPolicyContainer extends Component {
  state = {
    privacyPolicy: "",
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.setPrivacyPolicy(nextprops);
  }

  componentDidMount() {
    document.title = "Client Portal - Privacy policy";
    this.setPrivacyPolicy(this.props);
  }

  setPrivacyPolicy(props) {
    const { isFetching, privacyPolicyContent, isError } = props;
    if (!isError) {
      if (!isFetching) {
        if (privacyPolicyContent) {
          if (
            privacyPolicyContent &&
            privacyPolicyContent !== this.state.privacyPolicy
          ) {
            this.setState({ privacyPolicy: privacyPolicyContent });
          }
        } else {
          this.props.dispatch(getPrivacyPolicyContent());
        }
      }
    }
  }

  render() {
    const contentData = this.state.privacyPolicy[0];
    const { auth, whiteLabel } = this.props;
    return (
      <PrivacyPolicy
        contentData={contentData}
        auth={auth}
        whiteLabelVal={whiteLabel && whiteLabel.whiteLabelVal}
      />
    );
  }
}

const mapStateToProps = ({ privacyPolicyContent, whiteLabel }) => {
  return { ...privacyPolicyContent, whiteLabel };
};

export default connect(mapStateToProps)(PrivacyPolicyContainer);
