export default {
  ClientPortal: "Client Portal",
  IDA: "Internet Direct Access",
  CLIENT_ACCESS_CODE: "Client access code",
  SSN_MEMBER_ID: "Please enter the SSN/Member ID for conversion",
  SignIn: "Sign in",
  OptumSignIn: "Sign in",
  MSIDSignIn: "Click here",
  SignOut: "Sign out",
  Username: "User name",
  Password: "Password",
  FollowUs: "Follow Us",
  TermsOfUse: "Terms of use",
  PrivacyPolicy: "Privacy policy",
  HealthCareProfessionals: "Health care professionals",
  Members: "Members",
  Consultants: "Consultants",
  Memberforms: "Member forms",
  DrugRecallsAndFDAAlerts: "Drug recalls & FDA alerts",
  AboutUs: "About us",
  ContactUs: "Contact us",
  Careers: "Careers",
  News: "News",
  Print: "Print",
  Requests: "Requests",
  Applications: "Applications",
  ClinicalServices: "Clinical services",
  HomeDelivery: "Home delivery",
  Specialty: "Specialty",
  GovernmentPrograms: "Government programs",
  FileCabinet: "File cabinet",
  Home: "Home",
  Aboutus_Footer: "About us",
  Contactus_Footer: "Contact us",
  MyProduct: "My product",
  ProductOffering: "Product offering",
  Profile: "Profile",
  INVOICEANDFINANCIALREPORTS: "Invoices and financial reports",
  DOCUMENTLIBRARY: "Document library",
  BENEFITSARCHIVESEARCH: "Benefits archive search"
};
