import React from "react";
import Label from "../../../constants/labels";
const Print = ({ ...rest }) => {
  return (
    <button
      {...rest}
      className="print button marT10 button-reverse noCap"
      link-id="Print"
    >
      {Label.Print}
    </button>
  );
};

export default Print;
