export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
  };
}

function receiveLogin(token, user) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    token,
    user,
  };
}

// function loginFailure(message) {
//   return {
//     type: LOGIN_FAILURE,
//     isFetching: false,
//     isAuthenticated: true,
//     message
//   };
// }

export function onLoginUser(creds) {
  return (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    const token = "123456789452156332";
    dispatch(requestLogin(creds));
    if (token) {
      // dispatch(getUserAccount(creds.username))
      localStorage.setItem("id_token", token);
      // if (creds.username.includes("ms/")) {
      //this is for msid // updatedName = creds.username.substr(3);
      const updatedName = creds.username;

      localStorage.setItem("user", updatedName);
      dispatch(receiveLogin(token, creds.username));
    }
  };
}
